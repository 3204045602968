import Badge from '@/components/badge/Badge.component';
import CircularLoading from '@/components/circular-loading';
import NavigationBreadcrumbs from '@/components/navigation-breadcrumbs';
import SimpleTabs, { TabData } from '@/components/simple-tabs';
import TextStack, {
  TextLabel,
  TextStackItem,
  TextValue
} from '@/components/text-stack/TextStack.component';
import { useGetSecurityQuery } from '@/hooks/suba/useGetSecurity.hook';
import { useTitle } from '@/hooks/useTitle';
import { Divider, Stack, Typography } from '@mui/material';
import { InstrumentType } from '@vestwell-sub-accounting/models/common/InstrumentType';
import { FundStatus } from '@vestwell-sub-accounting/models/securitiesAndPricing/FundStatus';

import { FC, useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { DividendsTab } from './components/tabs/dividends/DividendsTab.route';
import { FundRulesTab } from './components/tabs/FundRulesTab.route';
import { GeneralTab } from './components/tabs/GeneralTab.route';
import { PricesTab } from './components/tabs/PricesTab.route';
import { useSecuritiesDetailRouteParams } from './hooks/useSecurityDetailRouteParams.hook';

// Securities use only a handful of our defined instrument types
type SecurityInstrumentType =
  | InstrumentType.cifd
  | InstrumentType.etfd
  | InstrumentType.mmfd
  | InstrumentType.opfd;

const displayInstrumentType = {
  [InstrumentType.cifd]: 'CIT',
  [InstrumentType.etfd]: 'ETF',
  [InstrumentType.mmfd]: 'Money Market Fund',
  [InstrumentType.opfd]: 'Mutual Fund'
};

export const SecuritiesDetailPage: FC = () => {
  const [searchParams] = useSearchParams();
  const title = useTitle();

  // context

  const routeParams = useSecuritiesDetailRouteParams();

  // state

  const [query, setQuery] = useState<string>(routeParams.cusipOrSymbol || '');
  const [queryInputValue, setQueryInputValue] = useState<string>(
    routeParams.cusipOrSymbol || ''
  );

  // apis

  const getSecurityQuery = useGetSecurityQuery(routeParams.cusipOrSymbol, {
    enabled: routeParams.cusipOrSymbol !== undefined
  });

  // memos

  const tabElements: TabData[] = useMemo(
    // memo to prevent tabs being unloaded and reloaded when this component state changes
    () =>
      queryInputValue // prevent invalid tab paths (`/ops/securities//general`) when missing queryInputValue
        ? [
            {
              component: <GeneralTab />,
              label: 'General',
              path: `/ops/securities/${queryInputValue}/general`
            },
            {
              component: <DividendsTab />,
              label: 'Dividends',
              path: `/ops/securities/${queryInputValue}/dividends`
            },
            {
              component: <PricesTab />,
              label: 'Prices',
              path: `/ops/securities/${queryInputValue}/prices`
            },
            {
              component: <FundRulesTab />,
              label: 'Fund Rules',
              path: `/ops/securities/${queryInputValue}/fund-rules`
            }
          ]
        : [],
    [queryInputValue]
  );

  // effects

  useEffect(() => {
    if (queryInputValue === '') {
      title.setPageName('Securities');
    }
  }, [queryInputValue]);

  useEffect(() => {
    if (query === routeParams.cusipOrSymbol) return;

    // sync query with route param when it updates
    setQuery(routeParams.cusipOrSymbol || '');
    setQueryInputValue(routeParams.cusipOrSymbol || '');
  }, [routeParams.cusipOrSymbol, query]);

  return (
    <>
      <NavigationBreadcrumbs
        paths={[
          { name: 'Securities', to: '/ops/securities' },
          ...(searchParams.get('searchResults')
            ? [
                {
                  name: 'Search Results',
                  to: `/ops/securities/fund-screener?funds=${searchParams.get('searchResults')}`
                }
              ]
            : [])
        ]}
      />
      {getSecurityQuery.isInitialLoading ? (
        <CircularLoading />
      ) : (
        <>
          <Stack direction={{ lg: 'row', xs: 'column' }} spacing={4}>
            <Stack spacing={1}>
              <Typography data-testid='description' role='heading' variant='h5'>
                {getSecurityQuery.data?.description}
              </Typography>
              <Stack direction='row' spacing={1}>
                <Typography
                  color='text.secondary'
                  data-testid='symbol'
                  variant='body1'>
                  {getSecurityQuery.data?.symbol}
                </Typography>
                <Divider orientation='vertical' />
                <Typography
                  color='text.secondary'
                  data-testid='cusip'
                  variant='body1'>
                  {getSecurityQuery.data?.cusip}
                </Typography>
                <Divider orientation='vertical' />
                <Typography
                  color='text.secondary'
                  data-testid='instrument-type'
                  variant='body1'>
                  {displayInstrumentType[
                    getSecurityQuery.data
                      ?.instrumentType as SecurityInstrumentType
                  ] || getSecurityQuery.data?.instrumentType}
                </Typography>
                <Divider orientation='vertical' />
                <Typography
                  color='text.secondary'
                  data-testid='issuer'
                  variant='body1'>
                  Issued by {getSecurityQuery.data?.issuer}
                </Typography>
              </Stack>
            </Stack>
            <TextStack divider rowColumnWidth='dynamic'>
              <TextStackItem>
                <TextLabel>Matrix Fund Status</TextLabel>
                <TextValue data-testid='matrix-fund-status'>
                  <Badge
                    color={
                      getSecurityQuery.data?.custodianFundInformation
                        ?.fundStatus === FundStatus.open
                        ? 'success'
                        : 'neutral'
                    }>
                    {getSecurityQuery.data?.custodianFundInformation
                      ?.fundStatus || 'Not Available'}
                  </Badge>
                </TextValue>
              </TextStackItem>
              <TextStackItem>
                <TextLabel>Asset Class</TextLabel>
                <TextValue data-testid='asset-class'>
                  {
                    getSecurityQuery.data?.standardFundInformation
                      ?.mstarAssetClass
                  }
                </TextValue>
              </TextStackItem>
              <TextStackItem data-testid='share-class'>
                <TextLabel>Share Class</TextLabel>
                <TextValue>
                  {getSecurityQuery.data?.standardFundInformation?.shareClass}
                </TextValue>
              </TextStackItem>
              <TextStackItem data-testid='net-expense-ratio'>
                <TextLabel>Net Expense Ratio</TextLabel>
                <TextValue>
                  {
                    getSecurityQuery.data?.standardFundInformation
                      ?.netExpenseRatio
                  }
                </TextValue>
              </TextStackItem>
            </TextStack>
          </Stack>
          <SimpleTabs
            data-testid='securities-tabs'
            onChange={index => {
              title.setPageName(
                `${getSecurityQuery.data?.symbol} ${getSecurityQuery.data?.description} ${tabElements[index].label}`
              );
            }}
            tabs={tabElements}
            tabsAriaLabel='securities-tabs'
          />
        </>
      )}
    </>
  );
};
