import { CardHeader } from '@/components/card';
import { useSnackbar } from '@/contexts/SnackBarContext';
import { useSearchPendingTransactionsQuery } from '@/hooks/suba/useSearchPendingTransactionsQuery.hook';
import { useUrlState } from '@/hooks/useUrlState.hook';
import { PaginatedApiResponse } from '@/models/PaginatedApiResponse.model';
import { ConfirmedTransactionSearchRequest } from '@/models/suba/transactions/ConfirmedTransactionSearchRequest.model';
import {
  PendingTransactionSearchByPlanIdRequest,
  PendingTransactionSearchRequest
} from '@/models/suba/transactions/PendingTransactionSearchRequest.model';
import { TransactionStatus } from '@/models/suba/transactions/TransactionStatus.model';
import DownloadIcon from '@mui/icons-material/Download';
import { Card } from '@mui/material';
import { UseQueryResult } from '@tanstack/react-query';
import { AccountLevel } from '@vestwell-sub-accounting/models/accountsAndLedgers/AccountLevel';
import { PositionDateType } from '@vestwell-sub-accounting/models/accountsAndLedgers/PositionDateType';
import { ConfirmedTransactionStatus } from '@vestwell-sub-accounting/models/common/ConfirmedTransactionStatus';
import { PendingTransactionStatus } from '@vestwell-sub-accounting/models/common/PendingTransactionStatus';

import dayjs from 'dayjs';
import { FC, useCallback, useMemo, useRef } from 'react';
import { useToggle } from 'react-use';

import {
  ConfirmedTransactions,
  ConfirmedTransactionsProps
} from './components/ConfirmedTransactions.component';
import {
  PendingTransactions,
  PendingTransactionsProps
} from './components/PendingTransactions.component';
import { TransactionsProps } from './models/TransactionsProps.model';

const getCommonFiltersFormData = (
  values:
    | Parameters<ConfirmedTransactionsProps['onFiltersFormSubmit']>[0]
    | Parameters<PendingTransactionsProps['onFiltersFormSubmit']>[0]
) => {
  return {
    amountFrom:
      values.amountFrom === '' || values.amountFrom === null
        ? undefined
        : parseFloat(values.amountFrom).toFixed(2),
    amountTo:
      values.amountTo === '' || values.amountTo === null
        ? undefined
        : parseFloat(values.amountTo).toFixed(2),
    beginningDate: values.beginningDate || undefined,
    cusip: values.cusip || undefined,
    dateType: values.dateType || PositionDateType.Trade,
    endingDate: values.endingDate || undefined,
    page: 1, // reset pagination
    sourceTransactionId: values.sourceTransactionId || undefined,
    tracerId: values.tracerId || undefined,
    transactionBaseTypes:
      values.transactionBaseTypes?.length > 0
        ? values.transactionBaseTypes
        : undefined,
    transactionTypeCode: values.transactionTypeCode || undefined,
    transferSubAccountId: values.transferSubAccountId || undefined,
    unitsFrom:
      values.unitsFrom === '' || values.unitsFrom === null
        ? undefined
        : parseFloat(values.unitsFrom).toFixed(3),
    unitsTo:
      values.unitsTo === '' || values.unitsTo === null
        ? undefined
        : parseFloat(values.unitsTo).toFixed(3)
  };
};

export type TransactionsTabUrlState = {
  accountLevel?: AccountLevel;
  searchParams?:
    | Partial<ConfirmedTransactionSearchRequest>
    | Partial<PendingTransactionSearchRequest>;
  transactionStatus?: TransactionStatus;
};

export type TransactionsTabProps = TransactionsProps;

export const TransactionsTab: FC<TransactionsProps> = ({
  planId,
  ...props
}) => {
  // context

  const { showSnackbar } = useSnackbar();

  // refs

  const exportTransactionsRef = useRef<() => Promise<void>>();
  const queryRef = useRef<UseQueryResult<PaginatedApiResponse<[]>>>();

  // state

  const [isQueryFetching, toggleIsQueryFetching] = useToggle(false);
  const [isExporting, toggleIsExporting] = useToggle(false);

  const initialConfirmedSearchParams: Partial<ConfirmedTransactionSearchRequest> =
    {
      dateType: PositionDateType.Trade,
      endingDate: dayjs().format('YYYY-MM-DD'),
      ...props.searchParams, // overwrites the above defaults
      page: 1,
      pageSize: 25,
      statuses: (props.searchParams
        ?.statuses as ConfirmedTransactionStatus[]) || [
        ConfirmedTransactionStatus.Confirmed
      ]
    };

  const initialPendingSearchParams: Partial<PendingTransactionSearchRequest> = {
    dateType: PositionDateType.Trade,
    endingDate: dayjs().format('YYYY-MM-DD'),
    ...props.searchParams, // overwrites the above defaults
    page: 1,
    pageSize: 25,
    statuses: (props.searchParams?.statuses as PendingTransactionStatus[]) || [
      PendingTransactionStatus.Pending
    ]
  };

  const [urlState, setUrlState] = useUrlState<TransactionsTabUrlState>(
    initialSearchParams =>
      initialSearchParams?.transactionStatus === TransactionStatus.Confirmed ||
      initialSearchParams?.transactionStatus === undefined
        ? {
            accountLevel: props.accountLevel || AccountLevel.ParentAccount,
            searchParams: initialConfirmedSearchParams,
            transactionStatus: TransactionStatus.Confirmed
          }
        : {
            accountLevel: props.accountLevel || AccountLevel.ParentAccount,
            searchParams: initialPendingSearchParams,
            transactionStatus: TransactionStatus.Pending
          },
    {
      parsedValueTypes: {
        accountLevel: AccountLevel,
        searchParams: 'json',
        transactionStatus: TransactionStatus
      }
    }
  );

  // api

  const pendingTransactionsQuery = useSearchPendingTransactionsQuery(
    planId
      ? ({
          accountLevel: urlState.accountLevel,
          dateType: PositionDateType.Trade,
          planId,
          statuses: [PendingTransactionStatus.Pending]
        } as PendingTransactionSearchByPlanIdRequest)
      : {
          accountId: props.accountId,
          accountLevel: urlState.accountLevel,
          dateType: PositionDateType.Trade,
          statuses: [PendingTransactionStatus.Pending]
        }
  );

  // callbacks

  const handleExportTransactions = useCallback(async () => {
    toggleIsExporting();

    try {
      await exportTransactionsRef.current();
    } catch (err) {
      showSnackbar({
        message: `Failed to export transactions: ${err.message}`,
        severity: 'error'
      });
    }

    toggleIsExporting();
  }, [exportTransactionsRef]);

  const handleConfirmedTransactionFiltersFormSubmit = useCallback<
    ConfirmedTransactionsProps['onFiltersFormSubmit']
  >(
    values =>
      setUrlState(prevUrlState => ({
        ...prevUrlState,
        searchParams: {
          ...prevUrlState.searchParams,
          ...getCommonFiltersFormData(values),
          statuses: values.statuses?.length ? values.statuses : undefined
        } as ConfirmedTransactionSearchRequest
      })),
    []
  );

  const handlePendingTransactionFiltersFormSubmit = useCallback<
    PendingTransactionsProps['onFiltersFormSubmit']
  >(
    values =>
      setUrlState(prevUrlState => ({
        ...prevUrlState,
        searchParams: {
          ...prevUrlState.searchParams,
          ...getCommonFiltersFormData(values),
          statuses: values.statuses?.length ? values.statuses : undefined
        } as PendingTransactionSearchRequest
      })),
    []
  );

  const handlePageChange = useCallback<
    | ConfirmedTransactionsProps['onPageChange']
    | PendingTransactionsProps['onPageChange']
  >(
    newPage =>
      setUrlState(prevUrlState => ({
        ...prevUrlState,
        searchParams: {
          ...prevUrlState.searchParams,
          page: newPage
        }
      })),
    []
  );

  const handlePageSizeChange = useCallback<
    | ConfirmedTransactionsProps['onPageSizeChange']
    | PendingTransactionsProps['onPageSizeChange']
  >(
    newPageSize =>
      setUrlState(prevUrlState => ({
        ...prevUrlState,
        searchParams: {
          ...prevUrlState.searchParams,
          page: 1,
          pageSize: newPageSize
        }
      })),
    []
  );

  const handleSortChange = useCallback<
    | ConfirmedTransactionsProps['onSortChange']
    | PendingTransactionsProps['onSortChange']
  >(
    newSort =>
      setUrlState(prevUrlState => ({
        ...prevUrlState,
        searchParams: {
          ...prevUrlState.searchParams,
          ...newSort,
          page: 1
        }
      })),
    []
  );

  // memos

  const cardHeaderToggles = useMemo(
    () =>
      [
        props.hideAccountLevelToggle
          ? undefined
          : {
              'data-testid': 'account-level',
              onChangeAction: (
                event: React.MouseEvent<HTMLElement>,
                value: AccountLevel
              ) => {
                setUrlState(prevUrlState => ({
                  ...prevUrlState,
                  accountLevel: value,
                  page: 1
                }));
              },
              options: [
                {
                  label: 'Parent',
                  value: AccountLevel.ParentAccount
                },
                { label: 'Sub', value: AccountLevel.SubAccount }
              ],
              value: urlState.accountLevel
            },
        {
          'data-testid': 'status',
          onChangeAction: (
            event: React.MouseEvent<HTMLElement>,
            value: TransactionStatus
          ) => {
            setUrlState(
              value === TransactionStatus.Confirmed
                ? {
                    accountLevel: AccountLevel.ParentAccount,
                    searchParams: initialConfirmedSearchParams,
                    transactionStatus: TransactionStatus.Confirmed
                  }
                : {
                    accountLevel: AccountLevel.ParentAccount,
                    searchParams: {
                      ...initialPendingSearchParams,
                      statuses:
                        pendingTransactionsQuery?.data?.pagination.total === 0
                          ? [
                              PendingTransactionStatus.Cancelled,
                              PendingTransactionStatus.Deleted
                            ]
                          : [PendingTransactionStatus.Pending]
                    },
                    transactionStatus: TransactionStatus.Pending
                  }
            );
          },
          options: [
            {
              label: 'Confirmed',
              value: TransactionStatus.Confirmed
            },
            {
              BadgeProps: {
                badgeContent: pendingTransactionsQuery.data?.pagination.total
              },
              label: 'Pending',
              value: TransactionStatus.Pending
            }
          ],
          value: urlState.transactionStatus
        }
      ].filter(Boolean),
    [
      props.hideAccountLevelToggle,
      pendingTransactionsQuery.data,
      urlState.accountLevel,
      urlState.transactionStatus
    ]
  );

  // effects

  return (
    <Card data-testid={props['data-testid']}>
      {!props.hideHeader && (
        <CardHeader
          actionButtonsProps={[
            {
              ['data-testid']: 'export-csv-button',
              disabled:
                exportTransactionsRef.current === undefined ||
                queryRef.current?.data?.results === undefined ||
                queryRef.current.data.results.length === 0,
              label: 'Export CSV',
              loading: isExporting,
              onClick: handleExportTransactions,
              startIcon: <DownloadIcon />
            }
          ]}
          data-testid='transactions-header'
          loading={queryRef.current?.data?.results && isQueryFetching}
          title='Transactions'
          toggles={cardHeaderToggles}
        />
      )}
      {urlState.transactionStatus === TransactionStatus.Confirmed && (
        <ConfirmedTransactions
          {...props}
          exportTransactionsRef={exportTransactionsRef}
          onFiltersFormSubmit={handleConfirmedTransactionFiltersFormSubmit}
          onPageChange={handlePageChange}
          onPageSizeChange={handlePageSizeChange}
          onSortChange={handleSortChange}
          queryRef={queryRef}
          searchParams={
            urlState.searchParams as ConfirmedTransactionSearchRequest
          }
          toggleIsQueryFetching={toggleIsQueryFetching}
          {...(planId
            ? {
                accountLevel: AccountLevel.SubAccount,
                planId
              }
            : {
                accountLevel: urlState.accountLevel
              })}
        />
      )}
      {urlState.transactionStatus === TransactionStatus.Pending && (
        <PendingTransactions
          {...props}
          exportTransactionsRef={exportTransactionsRef}
          onFiltersFormSubmit={handlePendingTransactionFiltersFormSubmit}
          onPageChange={handlePageChange}
          onPageSizeChange={handlePageSizeChange}
          onSortChange={handleSortChange}
          queryRef={queryRef}
          searchParams={
            urlState.searchParams as PendingTransactionSearchRequest
          }
          toggleIsQueryFetching={toggleIsQueryFetching}
          {...(planId
            ? {
                accountLevel: AccountLevel.SubAccount,
                planId
              }
            : {
                accountLevel: urlState.accountLevel
              })}
        />
      )}
    </Card>
  );
};
