import { useSnackbar } from '@/contexts/SnackBarContext';
import { useUserToken } from '@/contexts/UserTokenContext';
import { ParentAccountService } from '@/services/suba/accounts/ParentAccount.service';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';

import { AxiosError } from 'axios';

export const SEARCH_PARENT_ACCOUNTS_QUERY_KEY =
  'ParentAccountService.searchParentAccounts';

export const useSearchParentAccountsQuery = (
  params: Parameters<typeof ParentAccountService.searchParentAccounts>[0],
  options?: UseQueryOptions<
    Awaited<ReturnType<typeof ParentAccountService.searchParentAccounts>>,
    AxiosError
  >
) => {
  const { showSnackbar } = useSnackbar();
  const { userHasValidToken } = useUserToken();

  return useQuery(
    [SEARCH_PARENT_ACCOUNTS_QUERY_KEY, params],
    () => ParentAccountService.searchParentAccounts(params),
    {
      ...(options || {}),
      enabled: (options?.enabled && userHasValidToken) ?? userHasValidToken,
      keepPreviousData: options?.keepPreviousData ?? true,
      onError:
        options?.onError ??
        ((err: AxiosError) => {
          showSnackbar({
            message: `Failed to get parent accounts: ${err.response?.data ?? err.message}`,
            severity: 'error'
          });
        })
    }
  );
};
