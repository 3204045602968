import Card, {
  CardContent,
  CardHeader,
  CardPlaceholder
} from '@/components/card';
import {
  DataTable,
  DataTableBadgeCell,
  DataTableProps,
  DataTableStackCell
} from '@/components/data-table/DataTable.component';
import DatePicker from '@/components/date-picker/DatePicker';
import { useSnackbar } from '@/contexts/SnackBarContext';
import { useGetVestwellStaffQuery } from '@/hooks/suba/useGetVestwellStaffQuery.hook';
import { useUrlState } from '@/hooks/useUrlState.hook';
import { BreakageStatusColorMap } from '@/models/suba/recon/BreakageStatusColorMap.model';
import { ReconExceptionDto } from '@/models/suba/recon/ReconException.model';
import ReconExceptionService from '@/services/suba/recon-exceptions/ReconException.service';
import { numericComparator } from '@/utils/Comparators';
import formatters from '@/utils/Formatters';
import SearchIcon from '@mui/icons-material/Search';
import { Box, Button, FormControl, Stack } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { BreakageDataType } from '@vestwell-sub-accounting/models/recon/BreakageDataType';
import { BreakageProcess } from '@vestwell-sub-accounting/models/recon/BreakageProcess';
import { BreakageStatus } from '@vestwell-sub-accounting/models/recon/BreakageStatus';

import { ColDef } from 'ag-grid-community';
import { AxiosError } from 'axios';
import dayjs from 'dayjs';
import { Field, Form, Formik } from 'formik';
import { FC, useCallback, useMemo } from 'react';

type FiltersFormValues = {
  startDate: string;
  endDate: string;
};

type SearchParams = Parameters<typeof ReconExceptionService.searchRelated>[0];

export type RelatedReconExceptionsTabUrlState = Pick<
  SearchParams,
  'page' | 'pageSize' | keyof FiltersFormValues
>;

type RelatedReconExceptionsTabProps = {
  currentExceptionId: number;
  searchParams?: SearchParams;
};

export const RelatedReconExceptionsTab: FC<
  RelatedReconExceptionsTabProps
> = props => {
  // context
  const { showSnackbar } = useSnackbar();

  // state

  const [urlState, setUrlState] =
    useUrlState<RelatedReconExceptionsTabUrlState>(
      {
        endDate: dayjs(props.searchParams?.exceptionDate)
          .add(7, 'days')
          .format('YYYY-MM-DD'),
        page: 1,
        pageSize: 25,
        startDate: dayjs(props.searchParams?.exceptionDate)
          .subtract(7, 'days')
          .format('YYYY-MM-DD')
      },
      {
        parsedValueTypes: {
          endDate: 'string',
          page: 'number',
          pageSize: 'number',
          startDate: 'string'
        }
      }
    );

  // api

  const getVestwellStaffQuery = useGetVestwellStaffQuery();

  const searchRelatedExceptionsQuery = useQuery(
    [
      'ReconExceptionService.searchRelated',
      {
        ...urlState,
        parentAccountId: props.searchParams?.parentAccountId
      }
    ],
    () =>
      ReconExceptionService.searchRelated({
        ...urlState,
        parentAccountId: props.searchParams?.parentAccountId
      }),
    {
      keepPreviousData: true,
      onError: (err: AxiosError) => {
        showSnackbar({
          message: `Related exception search failed: ${err.message}`,
          severity: 'error'
        });
      }
    }
  );

  // callbacks

  const handleFiltersFormSubmit = useCallback((values: FiltersFormValues) => {
    setUrlState(prevUrlState => ({
      ...prevUrlState,
      ...values,
      endDate: values.endDate || null,
      startDate: values.startDate || null
    }));
  }, []);

  const handlePageChanged = useCallback<DataTableProps['onPageChanged']>(
    newPage =>
      setUrlState(prevUrlState => ({
        ...prevUrlState,
        page: newPage
      })),
    []
  );

  const handlePageSizeChanged = useCallback<
    DataTableProps['onPageSizeChanged']
  >(
    newPageSize =>
      setUrlState(prevUrlState => ({
        ...prevUrlState,
        page: 1,
        pageSize: newPageSize
      })),
    []
  );

  // memos

  const columnDefs = useMemo<ColDef[]>(
    () => [
      {
        cellRenderer: (cellData: { data: ReconExceptionDto }) => {
          return (
            <DataTableStackCell
              primary={String(cellData.data.id)}
              primaryLinkProps={{
                to: `/suba/recon-exceptions/${cellData.data.id}`
              }}
            />
          );
        },
        field: 'id',
        headerName: 'ID',
        pinned: 'left'
      },
      {
        autoHeight: true,
        cellRenderer: (cellData: { data: ReconExceptionDto }) => {
          // fetch friendly display name for status
          const displayStatus = formatters.getValueKey(
            BreakageStatus,
            cellData.data.status
          );

          return (
            <DataTableBadgeCell
              color={BreakageStatusColorMap[cellData.data.status]}>
              {formatters.displayCase(displayStatus)}
            </DataTableBadgeCell>
          );
        },
        field: 'status',
        headerName: 'Status',
        minWidth: 150,
        sortable: true
      },
      {
        autoHeight: true,
        cellRenderer: (cellData: { data: ReconExceptionDto }) => {
          // fetch friendly display name for data type
          const displayDataType = formatters.getValueKey(
            BreakageDataType,
            cellData.data.dataType
          );
          // fetch friendly display name for process
          const displayProcess = formatters.getValueKey(
            BreakageProcess,
            cellData.data.process
          );
          return (
            <DataTableStackCell
              primary={`${formatters.displayCase(displayDataType)}`}
              secondary={`${formatters.displayCase(displayProcess)}`}
              wrap
            />
          );
        },
        field: 'dataType',
        headerName: 'Break Type / Break Process',
        minWidth: 255
      },
      {
        field: 'exceptionDate',
        headerName: 'Exception Date',
        valueFormatter: ({ value }: { value: string }) =>
          formatters.formatFromIsoDateCustom(value, 'MM/DD/YYYY'),
        width: 165
      },
      {
        field: 'security.cusip',
        headerName: 'Cusip',
        minWidth: 125,
        sortable: true
      },
      {
        field: 'notes',
        headerName: 'Notes',
        maxWidth: 320,
        minWidth: 125,
        sortable: true,
        tooltipField: 'notes'
      },
      {
        cellRenderer: (cellData: { data: ReconExceptionDto }) => {
          if (!cellData.data.assignee) {
            return null;
          }
          const matchedAssignee = getVestwellStaffQuery.data?.find(
            staffUser => staffUser.userId === cellData.data.assignee
          );
          return (
            <DataTableStackCell
              primary={matchedAssignee?.label || cellData.data.assignee}
            />
          );
        },
        field: 'assignee',
        headerName: 'Assignee',
        maxWidth: 200,
        minWidth: 165
      },
      {
        comparator: numericComparator,
        field: 'parentValue',
        headerName: 'Parent Value',
        minWidth: 165,
        type: 'numericColumn',
        valueFormatter: ({ value }: { value: string }) =>
          value === null ? '' : formatters.formatDollars(value || 0, 3)
      },
      {
        comparator: numericComparator,
        field: 'comparisonValue',
        headerName: 'Comparison Value',
        minWidth: 165,
        type: 'numericColumn',
        valueFormatter: ({ value }: { value: string }) =>
          value === null ? '' : formatters.formatDollars(value || 0, 3)
      },
      {
        comparator: numericComparator,
        field: 'valueDifference',
        headerName: 'Value Difference',
        minWidth: 165,
        type: 'numericColumn',
        valueFormatter: ({ value }: { value: string }) =>
          value === null ? '' : formatters.formatDollars(value || 0, 3)
      },
      {
        comparator: numericComparator,
        field: 'parentUnits',
        headerName: 'Parent Units',
        minWidth: 165,
        type: 'numericColumn',
        valueFormatter: ({ value }: { value: string }) =>
          value === null ? '' : formatters.formatDollars(value || 0, 3)
      },
      {
        comparator: numericComparator,
        field: 'comparisonUnit',
        headerName: 'Comparison Units',
        minWidth: 165,
        type: 'numericColumn',
        valueFormatter: ({ value }: { value: string }) =>
          value === null ? '' : formatters.formatDollars(value || 0, 3)
      },
      {
        comparator: numericComparator,
        field: 'unitDifference',
        headerName: 'Unit Difference',
        minWidth: 165,
        type: 'numericColumn',
        valueFormatter: ({ value }: { value: string }) =>
          value === null ? '' : formatters.formatDollars(value || 0, 3)
      }
    ],
    []
  );

  return (
    <Box>
      <Card data-testid='related-exceptions'>
        <CardHeader
          justifyContent='left'
          loading={
            !!searchRelatedExceptionsQuery.data?.results?.length &&
            searchRelatedExceptionsQuery.isFetching
          }>
          <Formik<FiltersFormValues>
            initialValues={{
              endDate: urlState.endDate || '',
              startDate: urlState.startDate || ''
            }}
            onSubmit={handleFiltersFormSubmit}>
            <Form data-testid='related-exceptions-update-date-range'>
              <Stack direction='row' spacing={2}>
                <FormControl>
                  <Field
                    as={DatePicker}
                    data-testid='related-exceptions-start-date-picker'
                    label='Start Date'
                    name='startDate'
                    size='small'
                    variant='outlined'
                  />
                </FormControl>
                <FormControl>
                  <Field
                    as={DatePicker}
                    data-testid='related-exceptions-end-date-picker'
                    label='End Date'
                    name='endDate'
                    size='small'
                    variant='outlined'
                  />
                </FormControl>
                <FormControl>
                  <Button
                    data-testid='related-exceptions-update-date-range-button'
                    type='submit'
                    variant='outlined'>
                    Apply
                  </Button>
                </FormControl>
              </Stack>
            </Form>
          </Formik>
        </CardHeader>
        <CardContent
          disablePadding
          loading={searchRelatedExceptionsQuery.isInitialLoading}>
          <DataTable
            columnDefs={columnDefs}
            data-testid='data-related-exceptions-table'
            emptyPlaceholderComponent={
              <Stack
                alignItems='center'
                data-testid='no-data-related-exceptions-table'
                justifyContent='center'
                sx={{ height: '100%' }}>
                <CardPlaceholder
                  icon={<SearchIcon fontSize='inherit' />}
                  subtitle='No related exceptions found.'
                />
              </Stack>
            }
            onPageChanged={handlePageChanged}
            onPageSizeChanged={handlePageSizeChanged}
            page={urlState.page}
            pageSize={urlState.pageSize}
            pageSizeOptions={[5, 10, 25]}
            pagination
            paginationSource='server'
            paginationSx={{ py: 0.375 }}
            paginationTotal={
              searchRelatedExceptionsQuery.data?.pagination?.total
            }
            rowData={
              searchRelatedExceptionsQuery.data &&
              Array.isArray(searchRelatedExceptionsQuery.data?.results)
                ? searchRelatedExceptionsQuery.data.results.filter(
                    ({ id }) => id !== props.currentExceptionId
                  )
                : []
            }
          />
        </CardContent>
      </Card>
    </Box>
  );
};
