import { ModelTypeEnum } from '@/models/ParticipantInvestmentElectionHistoryDTO.model';
import {
  Autocomplete,
  Stack,
  styled,
  TextField,
  Typography
} from '@mui/material';

import { ColDef } from 'ag-grid-community';
import {
  forwardRef,
  SyntheticEvent,
  useCallback,
  useImperativeHandle
} from 'react';

import { MappingStackCell } from './MappingStackCell.component';

const StyledStack = styled(Stack)(() => ({
  height: 'auto !important',
  width: '100%'
}));

const StyledGroupTypography = styled(Typography)(({ theme }) => ({
  backgroundColor: theme.palette.grey[50],
  color: 'rgba(0,0,0,0.87)',
  padding: theme.spacing(0, 2)
}));

type InvestmentOption = {
  cusip: string;
  group: string;
  id: string;
  modelId: number;
  modelType: ModelTypeEnum;
  name: string;
};

export type ChangeMapping = {
  mappings?: { newCusip: string; oldCusip: string }[];
  oldModelId?: number;
  oldPortfolioId?: number;
  oldType: string;
  newModelId?: number;
  newType: string;
  newFund?: string;
  newPortfolioId?: number;
};

type InvestmentAutocompleteProps = Pick<
  ColDef['cellRendererParams'],
  'api' | 'colDef' | 'data' | 'node'
> & {
  context: {
    changes: ChangeMapping[];
    setChanges: (changes: ChangeMapping[]) => void;
  };
  onChange: (event: SyntheticEvent, newValue: any) => void;
  label?: string;
  sx?: Record<string, unknown>;
  autoFocus?: boolean;
  onInputChange: (event: SyntheticEvent, value: string) => void;
  options?: InvestmentOption[];
  onBlur: (event: SyntheticEvent) => void;
  error?: boolean;
  helperText?: string;
  refData: InvestmentOption[];
  value?: InvestmentOption;
};

export const InvestmentAutocomplete = forwardRef(
  (props: InvestmentAutocompleteProps, ref): JSX.Element => {
    const {
      context,
      data,
      error = false,
      label,
      onBlur,
      onInputChange,
      sx,
      refData
    } = props;

    useImperativeHandle(ref, () => {
      return {
        // the final value to send to the grid, on completion of editing
        getValue() {},

        // Gets called once when editing is finished (eg if Enter is pressed).
        // If you return true, then the result of the edit will be ignored.
        isCancelAfterEnd() {
          return true;
        }
      };
    });

    const handleChange = useCallback(
      (updatedData: InvestmentOption) => {
        if (data.modelType === 'pmenu') {
          const pmenuChanges =
            context.changes.find(change => change.oldType === 'pmenu')
              ?.mappings || [];

          if (pmenuChanges.length >= 1) {
            return context.setChanges([
              ...context.changes.filter(
                prevChange => prevChange.oldType !== 'pmenu'
              ),
              {
                mappings: [
                  ...pmenuChanges.filter(prevChange => {
                    return prevChange.oldCusip !== data.cusip;
                  }),
                  {
                    newCusip: updatedData.cusip,
                    oldCusip: data.cusip
                  }
                ],
                newType: 'pmenu',
                oldType: 'pmenu'
              }
            ]);
          }

          return context.setChanges([
            ...context.changes,
            {
              mappings: [
                {
                  newCusip: updatedData.cusip,
                  oldCusip: data.cusip
                }
              ],
              newType: 'pmenu',
              oldType: 'pmenu'
            }
          ]);
        }

        return context.setChanges([
          ...context.changes.filter(prevChange =>
            data.modelType === 'goal' && prevChange.oldType === 'goal'
              ? prevChange.oldPortfolioId !== data.modelId
              : data.modelType === 'ms_rp' || data.modelType === 'ms_ma'
                ? prevChange.oldType !== 'ms_rp' &&
                  prevChange.oldType !== 'ms_ma'
                : prevChange.oldType === data.modelType
                  ? prevChange.oldModelId !== data.modelId
                  : true
          ),
          {
            newFund: updatedData.cusip,
            newModelId: ['target', 'nli', 'risk'].includes(
              updatedData.modelType
            )
              ? updatedData.modelId
              : undefined,
            newPortfolioId:
              updatedData.modelType === 'goal'
                ? updatedData.modelId
                : undefined,
            newType: updatedData.modelType,
            oldModelId: ['target', 'nli', 'risk'].includes(data.modelType)
              ? data.modelId
              : undefined,
            oldPortfolioId:
              data.modelType === 'goal' ? data.modelId : undefined,
            oldType: data.modelType
          }
        ]);
      },
      [data, context]
    );

    return (
      <StyledStack>
        <Autocomplete
          defaultValue={props.value}
          disableClearable
          fullWidth
          getOptionLabel={(option: InvestmentOption) => {
            return option.name;
          }}
          groupBy={(option: InvestmentOption) => {
            return option.group;
          }}
          isOptionEqualToValue={(option, value) => {
            return option.id === value.id;
          }}
          onBlur={onBlur}
          onChange={(event, value: InvestmentOption) => {
            handleChange(value);
            props.api.stopEditing();
          }}
          onInputChange={onInputChange}
          openOnFocus
          options={
            data.modelType === 'pmenu'
              ? refData.filter(row => row.modelType === 'pmenu')
              : [
                  {
                    id: 'default',
                    modelType: 'default',
                    name: 'Default Election'
                  },
                  ...(refData || [])
                ]
          }
          ref={ref}
          renderGroup={params => {
            return (
              <Stack component='li' key={params.key}>
                <StyledGroupTypography variant='overline'>
                  {params.group}
                </StyledGroupTypography>
                <Stack component='ul' padding={0}>
                  {params.children}
                </Stack>
              </Stack>
            );
          }}
          renderInput={renderParams => {
            return (
              <TextField
                {...renderParams}
                autoFocus
                error={error}
                fullWidth
                label={label}
                size='medium'
              />
            );
          }}
          renderOption={(renderProps, option) => {
            if (['target', 'nli'].includes(option.id)) {
              return (
                <li
                  aria-disabled={renderProps['aria-disabled']}
                  aria-selected={renderProps['aria-selected']}
                  className={renderProps.className}
                  key={renderProps.id}
                  role={renderProps.role}>
                  <Typography color='rgba(0,0,0,0.87)' variant='overline'>
                    {option.name}
                  </Typography>
                </li>
              );
            }

            return (
              <li {...renderProps} key={renderProps.id}>
                <MappingStackCell {...option} />
              </li>
            );
          }}
          sx={sx}
        />
      </StyledStack>
    );
  }
);

InvestmentAutocomplete.displayName = 'InvestmentAutocomplete';
