import { useSnackbar } from '@/contexts/SnackBarContext';
import { useUserToken } from '@/contexts/UserTokenContext';
import AuthZService from '@/services/AuthZ.service';
import { useQuery } from '@tanstack/react-query';

import { AxiosError } from 'axios';

export const useGetVestwellStaffQuery = () => {
  const { showSnackbar } = useSnackbar();
  const { userHasValidToken } = useUserToken();

  return useQuery(
    ['AuthZService.getVestwellStaff'],
    async () => {
      const staff = await AuthZService.getVestwellStaff();
      return AuthZService.formatVestwellStaffList(staff);
    },
    {
      enabled: userHasValidToken,
      onError: (err: AxiosError) => {
        showSnackbar({
          message: `Failed to get Vestwell staff: ${err.response?.data ?? err.message}`,
          severity: 'error'
        });
      }
    }
  );
};
