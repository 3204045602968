import { useGetVestwellStaffQuery } from '@/hooks/suba/useGetVestwellStaffQuery.hook';
import { Autocomplete, AutocompleteProps, TextField } from '@mui/material';

import { FieldInputProps } from 'formik';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';

type AssigneeAutocompleteProps = {
  'data-testid'?: string;
  loading?: boolean;
  name?: string;
  onBlur: AutocompleteProps<string, false, false, false>['onBlur'];
  onChange: FieldInputProps<string>['onChange'];
  value: string | null;
};

export const AssigneeAutocomplete: FC<AssigneeAutocompleteProps> = ({
  'data-testid': testId = 'assignee-autocomplete',
  ...props
}) => {
  // state

  const [inputValue, setInputValue] = useState('');
  const [value, setValue] = useState<string | null>(null);

  // api

  const getVestwellStaffQuery = useGetVestwellStaffQuery();

  // callbacks

  const handleChange = useCallback<
    AutocompleteProps<string, false, false, false>['onChange']
  >(
    (event, newValue: string) => {
      setValue(newValue);
      props.onChange({
        ...event,
        // override target element — Autocomplete sends the MenuItem <li> – with the props Formik needs for wiring via Field component
        currentTarget: { name: props.name, value: newValue },
        target: { name: props.name, value: newValue },
        value: newValue
      });
    },
    [props.name, props.value]
  );

  // memos

  const options = useMemo<string[]>(
    () => (getVestwellStaffQuery.data || []).map(user => user.userId),
    [getVestwellStaffQuery.data]
  );

  const optionLabels = useMemo<Record<string, string>>(() => {
    const labels = {};

    for (const user of getVestwellStaffQuery.data || []) {
      labels[user.userId] = user.label;
    }

    return labels;
  }, [getVestwellStaffQuery.data]);

  // effects

  useEffect(() => {
    if (
      !getVestwellStaffQuery.data ||
      (props.value && value && props.value === value)
    )
      return;

    if (!props.value && value !== null) return setValue(null);

    const matchingUser = getVestwellStaffQuery.data.find(
      staff => staff.userId === props.value
    );
    if (!matchingUser) {
      console.error(
        'AssigneeAutocomplete: Given user ID value does not match a current staff member'
      );
      return;
    }

    setValue(matchingUser.userId);
  }, [getVestwellStaffQuery.data, props.value, value]);

  return (
    <>
      <Autocomplete
        data-testid={testId}
        disablePortal
        fullWidth
        getOptionLabel={option => optionLabels[option]}
        inputValue={inputValue}
        loading={getVestwellStaffQuery.isInitialLoading || props.loading}
        onBlur={props.onBlur}
        onChange={handleChange}
        onInputChange={(_event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        options={options}
        renderInput={params => (
          <>
            <TextField
              {...params}
              InputLabelProps={{ shrink: true }}
              label='Assignee'
              placeholder='Any'
            />
            <input name={props.name} type='hidden' value={value || ''} />
          </>
        )}
        size='small'
        sx={{
          minWidth: 256
        }}
        value={value}
      />
    </>
  );
};
