import Card, { CardContent, CardPlaceholder } from '@/components/card';
import { Comments } from '@/components/comments/Comments.component';
import { JSONViewer } from '@/components/json-viewer';
import SimpleTabs, { SimpleTabsProps, TabData } from '@/components/simple-tabs';
import { Box, Stack } from '@mui/material';
import { CommentType } from '@vestwell-sub-accounting/models/conductor/CommentType';

import { useContext, useMemo } from 'react';

import { AlertContext } from '../contexts/AlertContext';

type AlertDetailsTabsProps = React.PropsWithChildren<{
  disableStateInUrl?: boolean;
  hasWritePermissions: boolean;
}>;
export const AlertDetailsTabs = (
  props: AlertDetailsTabsProps
): JSX.Element | null => {
  const { disableStateInUrl, hasWritePermissions } = props;
  const alert = useContext(AlertContext);

  const getTabIdx = (availableTabs: TabData[]) => {
    const index = availableTabs.findIndex(
      tab => location.pathname === tab.path
    );

    return index < 0 ? 0 : index;
  };
  const tabElements: TabData[] = useMemo(
    // memo to prevent tabs being unloaded and reloaded when this component state changes
    () => {
      const calculatedTabElements: TabData[] = [];
      // don't add tabs until data is loaded
      if (alert) {
        calculatedTabElements.push({
          component: (
            <Card>
              <CardContent disablePadding>
                <Comments
                  commentLinkBase={`/ops/alerts/${alert?.id}/comments`}
                  hasWritePermissions={hasWritePermissions}
                  type={CommentType.Alert}
                  typeId={alert.id}
                />
              </CardContent>
            </Card>
          ),
          label: 'Comments',
          path: `/ops/alerts/${alert?.id}/comments`
        });

        calculatedTabElements.push({
          component: (
            <Card>
              <CardContent disablePadding>
                <Box sx={{ fontSize: 12, minHeight: '300px', p: 2.5 }}>
                  {alert.details && (
                    <Box data-testid='alert-details-json'>
                      <JSONViewer
                        collapseStringsAfterLength={100}
                        json={alert.details}
                      />
                    </Box>
                  )}
                  {!alert.details && (
                    <Stack
                      alignItems='center'
                      data-testid='no-alert-details-json'
                      justifyContent='center'
                      sx={{ height: '100%' }}>
                      <CardPlaceholder subtitle='No details json.' />
                    </Stack>
                  )}
                </Box>
              </CardContent>
            </Card>
          ),
          label: 'JSON',
          path: `/ops/alerts/${alert?.id}/json`
        });
      }

      return calculatedTabElements;
    },
    [alert, hasWritePermissions]
  );
  const tabs: SimpleTabsProps = {
    defaultTabIdx: getTabIdx(tabElements),
    tabs: tabElements,
    tabsAriaLabel: 'alert-tabs'
  };

  if (tabs.tabs.length === 0) return null;

  return (
    <Box sx={{ mt: 3 }}>
      <SimpleTabs
        data-testid='alert-tabs'
        {...tabs}
        disableStateInUrl={disableStateInUrl}
        style={{
          border: '1px solid #e0e0e0',
          borderBottom: '0px',
          borderTopLeftRadius: '4px',
          borderTopRightRadius: '4px',
          marginBottom: '0'
        }}
      />
    </Box>
  );
};
