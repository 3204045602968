export enum DocumentStatuses {
  NEW = 'NEW',
  UPLOAD_SUCCESSFUL = 'UPLOAD_SUCCESSFUL',
  UPLOAD_FAILED = 'UPLOAD_FAILED'
}
export const DOCUMENT_TYPES = {
  CASH_ALLOCATION: 'cashAllocation',
  DEFERRAL_RATES: 'deferralRates',
  HISTORICAL_YEARS_OF_SERVICE: 'historicalYearsOfService',
  INVESTMENTS_FUND_LINEUPS: 'investmentsFundLineups',
  INVESTMENTS_MODELS: 'investmentsModels',
  INVESTMENT_ELECTION_MAPPINGS: 'investmentElectionMappings',
  IN_KIND_CONVERSION: 'inKindConversion',
  LOANS: 'loans',
  ROTH_COST_BASIS: 'rothCostBasis'
};

export const CONVERSION_TYPES = {
  CASH_ALLOCATION: 'CashAllocation',
  DEFERRAL_RATES: 'DeferralRates',
  HISTORICAL_YEARS_OF_SERVICE: 'HistoricalYearsOfService',
  INVESTMENT_ELECTION_MAPPINGS: 'InvestmentElectionMappings',
  IN_KIND_CONVERSION: 'InKindConversion',
  LOANS: 'LoanConversion',
  ROTH_COST_BASIS: 'RothCostBasis'
} as const;

export type ConversionValues =
  (typeof CONVERSION_TYPES)[keyof typeof CONVERSION_TYPES];
