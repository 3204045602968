import { CardPlaceholder } from '@/components/card';
import CircularLoading from '@/components/circular-loading';
import { useSnackbar } from '@/contexts/SnackBarContext';
import { CommentAddRequest } from '@/models/suba/comments/CommentAddRequest.model';
import { CommentDto } from '@/models/suba/comments/CommentDTO.model';
import { CommentSearchRequest } from '@/models/suba/comments/CommentSearchRequest.model';
import { CommentUpdateRequest } from '@/models/suba/comments/CommentUpdateRequest.model';
import AuthZService from '@/services/AuthZ.service';
import CommentService from '@/services/suba/comments/Comment.service';
import { userService } from '@/services/User.service';
import formatters from '@/utils/Formatters';
import LinkIcon from '@mui/icons-material/Link';
import {
  Box,
  Button,
  Divider,
  FormControl,
  Stack,
  TextField,
  Tooltip,
  Typography
} from '@mui/material';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { CommentType } from '@vestwell-sub-accounting/models/conductor/CommentType';

import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { Field, Form, Formik } from 'formik';
import { useEffect, useState } from 'react';

import CopyToClipboard from '../copy-to-clipboard';

dayjs.extend(relativeTime);

type CommentsProps = {
  type: CommentType;
  typeId: number;
  // useful when the comments are being displayed in a nested route
  commentLinkBase?: string;
  hasWritePermissions?: boolean;
};

export const Comments = ({
  type,
  typeId,
  commentLinkBase,
  hasWritePermissions
}: CommentsProps): JSX.Element => {
  const { showSnackbar } = useSnackbar();

  const [isAddingComment, setIsAddingComment] = useState(false);

  const [selectedComment, setSelectedComment] = useState<CommentDto>();
  const [isUpdatingComment, setIsUpdatingComment] = useState(false);

  const queryClient = useQueryClient();

  const { data, isFetching } = useQuery(
    ['CommentService.search', type, typeId],
    () => {
      const query: CommentSearchRequest = {
        pageSize: 0,
        type,
        typeId // showing all comments at this time
      };
      return CommentService.search(query);
    },
    {
      onError: (err: any) => {
        const message = err.response?.data ? err.response.data : err.message;
        showSnackbar({
          message: `Failed to fetch comments: ${message}`,
          severity: 'error'
        });
      },
      staleTime: Infinity
    }
  );

  const { data: vestwellStaff, isFetching: isFetchingVestwellStaff } = useQuery(
    ['AuthZService.getVestwellStaff'],
    async () => {
      const staff = await AuthZService.getVestwellStaff();
      return AuthZService.formatVestwellStaffList(staff);
    },
    {
      onError: (err: any) => {
        const message = err.response?.data ? err.response.data : err.message;
        showSnackbar({
          message: `Failed to fetch vestwell staff for comments: ${message}`,
          severity: 'error'
        });
      },
      staleTime: Infinity
    }
  );

  useEffect(() => {
    if (!isFetching && !isFetchingVestwellStaff && window.location.hash) {
      document.getElementById(window.location.hash.slice(1))?.scrollIntoView();
    }
    // we don't want to re-run this effect when the commentId or data changes, only when the fetches are complete
  }, [isFetching, isFetchingVestwellStaff, location.hash]);

  const addCommentMutation = useMutation(
    ['CommentService.add'],
    (params: CommentAddRequest) => {
      setIsAddingComment(true);
      return CommentService.add(params);
    },
    {
      onError: () => {
        setIsAddingComment(false);
        showSnackbar({
          message: `Error adding comment`,
          severity: 'error'
        });
      },
      onSuccess: () => {
        setIsAddingComment(false);
        showSnackbar({
          message: `Comment added`,
          severity: 'success'
        });
        // Invalidate the comment search query so that we get fresh results
        queryClient.invalidateQueries(['CommentService.search']);
      }
    }
  );

  const handleAddSubmit = (values: { content: string }) => {
    // get the auth0 id of the logged in user
    const userId = userService.getUser()?.sub;
    if (!userId) {
      showSnackbar({
        message: `Unable to get logged in user for commenting`,
        severity: 'error'
      });
    } else {
      const newComment = {
        ...values,
        commenter: userId,
        type,
        typeId
      };
      addCommentMutation.mutate(newComment);
    }
  };

  const updateCommentMutation = useMutation(
    ['CommentService.update'],
    (payload: { id: number; params: CommentUpdateRequest }) => {
      setIsUpdatingComment(true);
      return CommentService.update(payload.id, payload.params);
    },
    {
      onError: () => {
        setIsUpdatingComment(false);
        showSnackbar({
          message: `Error updating comment`,
          severity: 'error'
        });
      },
      onSuccess: () => {
        setIsUpdatingComment(false);
        setSelectedComment(undefined);
        showSnackbar({
          message: `Comment updated`,
          severity: 'success'
        });
        // Invalidate the comment search query so that we get fresh results
        queryClient.invalidateQueries(['CommentService.search']);
      }
    }
  );

  const handleUpdateSubmit = (values: { content: string }) => {
    if (selectedComment) {
      updateCommentMutation.mutate({
        id: selectedComment.id,
        params: {
          content: values.content
        }
      });
    }
  };

  return (
    <>
      {(isFetching || isFetchingVestwellStaff) && (
        <Box data-testid='comments-loading'>
          <Stack
            alignItems='center'
            justifyContent='center'
            sx={{
              minHeight: '233px'
            }}>
            <CircularLoading size='36px' />
          </Stack>
        </Box>
      )}
      {!isFetching && !isFetchingVestwellStaff && (
        <>
          {(!data || !Array.isArray(data.results) || !data.results?.length) && (
            <Box data-testid='no-comments'>
              <CardPlaceholder subtitle='No comments.' />
            </Box>
          )}
          {data && Array.isArray(data.results) && data.results.length > 0 && (
            <>
              <Stack
                data-testid='comment-list'
                divider={<Divider flexItem orientation='horizontal' />}>
                {data.results.map(comment => (
                  <Box
                    data-testid={`${type.toLowerCase()}-comment-${comment.id}`}
                    id={`${type.toLowerCase()}-comment-${comment.id}`}
                    key={comment.id}
                    sx={{
                      p: 2.5,
                      ...(window.location.hash.includes(
                        `${type.toLowerCase()}-comment`
                      ) &&
                      Number(window.location.hash.split('-').pop()) ===
                        comment.id
                        ? { bgcolor: theme => theme.palette.grey[100] } // highlight the comment if it is the one we are viewing
                        : {})
                    }}>
                    <Stack alignItems='center' direction='row' spacing={1}>
                      <Typography
                        sx={{
                          fontSize: 14,
                          fontWeight: 500,
                          lineHeight: '22px'
                        }}>
                        {(comment.commenter.startsWith('saml') &&
                          vestwellStaff?.find(
                            staff => staff.userId === comment.commenter
                          )?.label) ||
                          comment.commenter}
                      </Typography>
                      <Stack alignItems='center' direction='row' spacing={0.5}>
                        <Tooltip
                          PopperProps={{
                            modifiers: [
                              {
                                name: 'offset',
                                options: {
                                  offset: [0, -15]
                                }
                              }
                            ]
                          }}
                          enterDelay={500}
                          placement='top'
                          title={formatters.formatFromIsoDateCustom(
                            comment.createdAt,
                            'MM/DD/YYYY HH:mm'
                          )}>
                          <Typography
                            sx={{ lineHeight: '22px' }}
                            variant='caption'>
                            {dayjs(comment.createdAt).fromNow()}
                          </Typography>
                        </Tooltip>
                        {comment.lastSnapshotCreatedAt && (
                          <>
                            <Typography
                              sx={{ lineHeight: '22px' }}
                              variant='caption'>
                              •
                            </Typography>
                            <Tooltip
                              PopperProps={{
                                modifiers: [
                                  {
                                    name: 'offset',
                                    options: {
                                      offset: [0, -15]
                                    }
                                  }
                                ]
                              }}
                              enterDelay={500}
                              placement='top'
                              title={formatters.formatFromIsoDateCustom(
                                comment.lastSnapshotCreatedAt,
                                'MM/DD/YYYY HH:mm'
                              )}>
                              <Typography
                                data-testid={`${type.toLowerCase()}-comment-${
                                  comment.id
                                }-edited`}
                                sx={{ lineHeight: '22px' }}
                                variant='caption'>
                                Edited
                              </Typography>
                            </Tooltip>
                          </>
                        )}
                      </Stack>
                      <CopyToClipboard
                        copyName='Comment Link'
                        copyValue={`${window.location.origin}${
                          commentLinkBase || window.location.pathname
                        }#${type.toLowerCase()}-comment-${comment.id}`}
                        icon={<LinkIcon fontSize='inherit' />}
                        size='small'
                      />
                    </Stack>
                    {selectedComment?.id === comment.id ? (
                      <Box sx={{ mt: 1 }}>
                        {isUpdatingComment && (
                          <Box data-testid='updating-comment-loading'>
                            <Stack
                              alignItems='center'
                              justifyContent='center'
                              sx={{
                                minHeight: '233px'
                              }}>
                              <CircularLoading size='36px' />
                            </Stack>
                          </Box>
                        )}
                        {!isUpdatingComment && (
                          <Box>
                            <Formik
                              initialValues={{
                                content: selectedComment.content
                              }}
                              onSubmit={handleUpdateSubmit}>
                              {({ values }) => (
                                <Form data-testid='update-comment-form'>
                                  <Stack
                                    alignItems='flex-start'
                                    justifyContent='flex-start'
                                    spacing={2}>
                                    <FormControl
                                      fullWidth
                                      size='small'
                                      variant='outlined'>
                                      <Field
                                        as={TextField}
                                        data-testid='update-comment-value-text-field'
                                        id='update-comment-value'
                                        label='Edit comment'
                                        multiline
                                        name='content'
                                      />
                                    </FormControl>

                                    <Stack direction='row' spacing={2}>
                                      <Button
                                        data-testid='update-comment-button'
                                        disabled={
                                          !values.content ||
                                          values.content ===
                                            selectedComment?.content
                                        }
                                        type='submit'
                                        variant='contained'>
                                        Save
                                      </Button>
                                      <Button
                                        data-testid='cancel-update-comment-button'
                                        onClick={() => {
                                          setSelectedComment(undefined);
                                        }}
                                        variant='outlined'>
                                        Cancel
                                      </Button>
                                    </Stack>
                                  </Stack>
                                </Form>
                              )}
                            </Formik>
                          </Box>
                        )}
                      </Box>
                    ) : (
                      <>
                        <Box sx={{ mt: 1, whiteSpace: 'pre-line' }}>
                          {comment.content}
                        </Box>
                        {hasWritePermissions &&
                          userService.getUser()?.sub === comment.commenter && (
                            <Stack direction='row' spacing={1}>
                              <Button
                                data-testid={`${type.toLowerCase()}-comment-${
                                  comment.id
                                }-edit-button`}
                                onClick={() => {
                                  setSelectedComment(comment);
                                }}
                                size='small'
                                sx={{
                                  lineHeight: '22px',
                                  minWidth: 'auto',
                                  mt: 1.5,
                                  p: 0
                                }}
                                variant='text'>
                                Edit
                              </Button>
                            </Stack>
                          )}
                      </>
                    )}
                  </Box>
                ))}
              </Stack>
              <Divider flexItem orientation='horizontal' />
            </>
          )}
          {hasWritePermissions && (
            <>
              {isAddingComment && (
                <Box data-testid='adding-comment-loading'>
                  <Stack
                    alignItems='center'
                    justifyContent='center'
                    sx={{
                      minHeight: '233px'
                    }}>
                    <CircularLoading size='36px' />
                  </Stack>
                </Box>
              )}
              {!isAddingComment && (
                <Box sx={{ my: 2, px: 2.5 }}>
                  <Formik
                    initialValues={{
                      content: ''
                    }}
                    onSubmit={handleAddSubmit}>
                    {({ values, setFieldValue }) => (
                      <Form data-testid='add-comment-form'>
                        <Stack
                          alignItems='flex-start'
                          justifyContent='flex-start'
                          spacing={2}>
                          <FormControl
                            fullWidth
                            size='small'
                            variant='outlined'>
                            <Field
                              as={TextField}
                              data-testid='comment-value-text-field'
                              id='comment-value'
                              label='Add a comment...'
                              multiline
                              name='content'
                            />
                          </FormControl>

                          <Stack direction='row' spacing={2}>
                            <Button
                              data-testid='add-comment-button'
                              sx={{
                                visibility: !values.content
                                  ? 'hidden'
                                  : undefined
                              }}
                              type='submit'
                              variant='contained'>
                              Post
                            </Button>
                            <Button
                              data-testid='cancel-comment-button'
                              onClick={() => {
                                setFieldValue('content', '');
                              }}
                              sx={{
                                visibility: !values.content
                                  ? 'hidden'
                                  : undefined
                              }}
                              variant='outlined'>
                              Cancel
                            </Button>
                          </Stack>
                        </Stack>
                      </Form>
                    )}
                  </Formik>
                </Box>
              )}
            </>
          )}
        </>
      )}
    </>
  );
};
export default Comments;
