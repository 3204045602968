import Card, {
  CardContent,
  CardHeader,
  CardPlaceholder
} from '@/components/card';
import {
  DataTable,
  DataTableBadgeCell,
  DataTableProps,
  DataTableStackCell
} from '@/components/data-table/DataTable.component';
import { useSnackbar } from '@/contexts/SnackBarContext';
import { useUrlState } from '@/hooks/useUrlState.hook';
import { OrderByDirection } from '@/models/suba/common/OrderByDirection.model';
import { executionStatusColorMap } from '@/models/suba/workflows/ExecutionStatusColorMap.model';
import { TradeRequest } from '@/models/suba/workflows/TradeRequest.model';
import { WorkflowDto } from '@/models/suba/workflows/WorkflowDTO.model';
import { WorkflowSearchRequest } from '@/models/suba/workflows/WorkflowSearchRequest.model';
import { workflowStatusColorMap } from '@/models/suba/workflows/WorkflowStatusColorMap.model';
import { WorkflowStatusEnumsObject } from '@/models/suba/workflows/WorkflowStatusEnumObject.model';
import WorkflowService from '@/services/suba/workflows/Workflow.service';
import formatters from '@/utils/Formatters';
import SearchIcon from '@mui/icons-material/Search';
import { Stack } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { AccountLevel } from '@vestwell-sub-accounting/models/accountsAndLedgers/AccountLevel';
import { SubAccountType } from '@vestwell-sub-accounting/models/common/SubAccountType';
import { WorkflowExecutionStatusEnum } from '@vestwell-sub-accounting/models/conductor/WorkflowExecutionStatus.model';
import { WorkflowName } from '@vestwell-sub-accounting/models/conductor/WorkflowName.model';
import { WorkflowStatus as WorkflowStatusEnums } from '@vestwell-sub-accounting/models/conductor/WorkflowStatus.model';

import { ColDef } from 'ag-grid-community';
import dayjs from 'dayjs';
import { Formik } from 'formik';
import { FC, useCallback, useMemo } from 'react';

import {
  ConductorFiltersForm,
  ConductorFiltersFormValues
} from './ConductorFiltersForm.component';

export type ConductorTabUrlState = Pick<
  WorkflowSearchRequest & {
    // arrays only here
    workflowName?: WorkflowName[];
    executionStatus?: WorkflowExecutionStatusEnum[];
    workflowStatus?: WorkflowStatusEnums[];
  },
  | 'orderBy'
  | 'orderByDirection'
  | 'page'
  | 'pageSize'
  | keyof ConductorFiltersFormValues
>;

type ConductorTabProps = {
  accountId?: string;
  accountLevel?: AccountLevel;
  planId?: string;
  subAccountType?: SubAccountType;
};

export const ConductorTab: FC<ConductorTabProps> = props => {
  // context

  const { showSnackbar } = useSnackbar();

  // state

  const [urlState, setUrlState] = useUrlState<ConductorTabUrlState>(
    {
      endDate: dayjs().format('YYYY-MM-DD'),
      executionStatus: [],
      page: 1,
      pageSize: 25,
      startDate: dayjs().subtract(1, 'month').format('YYYY-MM-DD'),
      workflowName: [],
      workflowStatus: []
    },
    {
      arrayParamKeys: ['executionStatus', 'workflowName', 'workflowStatus'],
      parsedValueTypes: {
        endDate: 'string',
        executionStatus: 'string',
        orderBy: 'string',
        orderByDirection: 'string',
        page: 'number',
        pageSize: 'number',
        startDate: 'string',
        tracerId: 'string',
        workflowName: 'string',
        workflowStatus: 'string'
      }
    }
  );

  // api

  const workflowSearchQuery = useQuery(
    ['WorkflowService.search', props.accountId, props.planId, urlState],
    () => {
      return WorkflowService.search({
        ...urlState,
        accountId: props.accountId,
        planId: props.planId
      });
    },
    {
      keepPreviousData: true,
      onError: (err: any) => {
        showSnackbar({
          message: `Workflow search failed: ${err.response?.data ?? err.message}`,
          severity: 'error'
        });
      }
    }
  );

  // callbacks

  const handlePageChanged = useCallback<DataTableProps['onPageChanged']>(
    newPage => {
      setUrlState(prevUrlState => ({
        ...prevUrlState,
        page: newPage
      }));
    },
    []
  );

  const handlePageSizeChanged = useCallback<
    DataTableProps['onPageSizeChanged']
  >(newPageSize => {
    setUrlState(prevUrlState => ({
      ...prevUrlState,
      page: 1,
      pageSize: newPageSize
    }));
  }, []);

  const handleSortChanged = useCallback<DataTableProps['onSortChanged']>(
    newSort => {
      setUrlState(prevUrlState =>
        !newSort || newSort.length === 0
          ? {
              ...prevUrlState,
              orderBy: undefined,
              orderByDirection: undefined
            }
          : {
              ...prevUrlState,
              orderBy: newSort[0].colId as keyof WorkflowDto,
              orderByDirection: newSort[0].sort as OrderByDirection
            }
      );
    },
    []
  );

  // memos

  const columnDefs = useMemo<ColDef[]>(
    () => [
      {
        autoHeight: true,
        cellRenderer: (cellData: { data: WorkflowDto }) => {
          return (
            <DataTableStackCell
              primary={
                cellData.data.workflowName === WorkflowName.tradeRequest
                  ? formatters.displayCase(
                      `${cellData.data.workflowName} - ${
                        (cellData.data.input as TradeRequest)
                          ?.tradeCalculatorMethod
                      }`
                    )
                  : formatters.displayCase(cellData.data.workflowName)
              }
              primaryLinkProps={{
                to: `/ops/workflows/${cellData.data.id}`
              }}
              secondary={`Tracer ID: ${cellData.data.tracerId}`}
            />
          );
        },
        field: 'workflowName',
        headerName: 'Request',
        sortable: true
      },
      {
        autoHeight: true,
        cellRenderer: (cellData: { data: WorkflowDto }) => {
          // fetch friendly display name for status
          const displayExecutionStatus = formatters.getValueKey(
            WorkflowExecutionStatusEnum,
            cellData.data.executionStatus
          );

          return (
            <DataTableBadgeCell
              color={executionStatusColorMap[cellData.data.executionStatus]}>
              {formatters.displayCase(displayExecutionStatus)}
            </DataTableBadgeCell>
          );
        },
        field: 'executionStatus',
        headerName: 'Execution Status',
        sortable: true
      },
      {
        autoHeight: true,
        cellRenderer: (cellData: { data: WorkflowDto }) => {
          const displayWorkflowStatus = formatters.getValueKey(
            WorkflowStatusEnumsObject,
            cellData.data.workflowStatus
          );
          return (
            <>
              {cellData.data.workflowStatus && (
                <DataTableBadgeCell
                  color={workflowStatusColorMap[cellData.data.workflowStatus]}>
                  {formatters.displayCase(displayWorkflowStatus)}
                </DataTableBadgeCell>
              )}
            </>
          );
        },
        field: 'workflowStatus',
        headerName: 'Workflow Status',
        sortable: true
      },
      {
        field: 'updatedAt',
        headerName: 'Updated At',
        sortable: true,
        valueFormatter: ({ value }: { value: string }) =>
          formatters.formatFromIsoDate(value)
      },
      {
        field: 'createdAt',
        headerName: 'Created At',
        sortable: true,
        valueFormatter: ({ value }: { value: string }) =>
          formatters.formatFromIsoDate(value)
      }
    ],
    []
  );

  const FiltersFormFormik = useMemo(
    () => (
      <Formik<ConductorFiltersFormValues>
        initialValues={{
          endDate: urlState.endDate,
          executionStatus: urlState.executionStatus || [],
          startDate: urlState.startDate,
          tracerId: urlState.tracerId || '',
          workflowName: urlState.workflowName || [],
          workflowStatus: urlState.workflowStatus || []
        }}
        onSubmit={values =>
          setUrlState(prevUrlState => ({
            ...prevUrlState,
            ...values,
            endDate: values.endDate || null,
            page: 1,
            startDate: values.startDate || null
          }))
        }>
        <ConductorFiltersForm
          accountLevel={props.accountLevel}
          subAccountType={props.subAccountType}
        />
      </Formik>
    ),
    []
  );

  return (
    <Card>
      <CardHeader
        data-testid='conductor-header'
        loading={
          !workflowSearchQuery.isInitialLoading &&
          workflowSearchQuery.isFetching
        }
        title='Conductor Requests'
      />
      <CardContent
        disablePadding
        overlayLoading={workflowSearchQuery.isInitialLoading}>
        <DataTable
          columnDefs={columnDefs}
          data-testid='data-workflows-table'
          emptyPlaceholderComponent={
            <Stack
              alignItems='center'
              data-testid='no-data-workflows-table'
              justifyContent='center'
              sx={{ height: '100%' }}>
              <CardPlaceholder
                icon={<SearchIcon fontSize='inherit' />}
                subtitle='No results found.'
              />
            </Stack>
          }
          filterSidePanelComponent={FiltersFormFormik}
          onPageChanged={handlePageChanged}
          onPageSizeChanged={handlePageSizeChanged}
          onSortChanged={handleSortChanged}
          page={urlState.page}
          pageSize={urlState.pageSize}
          pagination
          paginationSource='server'
          paginationTotal={workflowSearchQuery.data?.pagination?.total}
          rowData={workflowSearchQuery.data?.results || []}
          sort={
            urlState.orderBy
              ? [
                  {
                    colId: urlState.orderBy,
                    sort: urlState.orderByDirection
                  }
                ]
              : []
          }
        />
      </CardContent>
    </Card>
  );
};
