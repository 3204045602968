import { LoadingButton } from '@mui/lab';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Divider,
  Stack
} from '@mui/material';

import { Form, Formik, FormikHelpers, useFormikContext } from 'formik';
import { isEmpty, isEqual, omit } from 'lodash';
import {
  Children,
  cloneElement,
  ComponentType,
  FC,
  ReactElement,
  useMemo,
  useState
} from 'react';
import * as yup from 'yup';

type FormDialogProps = Omit<DialogProps, 'onSubmit'> & {
  title?: string | React.ReactNode;
  onSubmit: (
    values?: Record<string, any> | any,
    formikHelpers?: FormikHelpers<Record<string, any>>
  ) => void | Promise<void>;
  children:
    | ReactElement<FormContainerProps>
    | ReactElement<FormContainerProps>[];
  initialValues?: Record<string, any>;
  validationSchema?: yup.AnyObjectSchema;
  onClose: () => void;
};

export const FormDialog: FC<FormDialogProps> = props => {
  const [activeStep, setActiveStep] = useState(0);
  const count = Children.count(props.children);

  return (
    <Dialog
      transitionDuration={{ exit: 0 }}
      {...(omit(props, [
        'title',
        'onSubmit',
        'children',
        'initialValues',
        'validationSchema'
      ]) as any)}>
      <DialogTitle data-testid='form-dialog-title'>{props.title}</DialogTitle>
      <Formik
        initialValues={props.initialValues || {}}
        onSubmit={async (data, helpers) => {
          await props.onSubmit(data);
          helpers.setSubmitting(false);
          setActiveStep(0);
        }}
        validateOnChange
        validateOnMount
        validationSchema={props.validationSchema}>
        {() =>
          Children.map(props.children, (child, index) => {
            return activeStep === index ? (
              cloneElement<FormContainerProps>(child || <></>, {
                activeStep,
                count,
                name: child?.props?.name || `step${index + 1}`,
                onCancel: () => {
                  props.onClose();
                  setActiveStep(0);
                },
                setActiveStep
              })
            ) : (
              <></>
            );
          })
        }
      </Formik>
    </Dialog>
  );
};

FormDialog.displayName = 'FormDialog';

type FormContainerProps = {
  onCancel?: () => void;
  name?: string;
  count?: number;
  activeStep?: number;
  setActiveStep?: React.Dispatch<React.SetStateAction<number>>;
  children: React.ReactNode;
  ContainerElement?: ComponentType<any>;
  ContainerProps?: any;
  cancelButtonText?: string;
  continueButtonText?: string;
};

export const FormContainer: FC<FormContainerProps> = (
  props: FormContainerProps
) => {
  const formikContext = useFormikContext();

  const ContainerElement = props.ContainerElement || Stack;
  const ContainerElementProps = props.ContainerProps || { spacing: 2 };

  const unchangedValues = useMemo(
    () =>
      !isEmpty(formikContext.values) &&
      isEqual(formikContext.initialValues, formikContext.values),
    [formikContext.values, formikContext.initialValues]
  );

  return (
    <>
      <DialogContent data-testid={`form-dialog-content-${props.name}`}>
        <Form>
          <ContainerElement {...ContainerElementProps}>
            {props.children}
          </ContainerElement>
        </Form>
      </DialogContent>
      <Divider />
      <DialogActions data-testid={`form-dialog-actions-${props.name}`}>
        <Stack
          direction='row'
          justifyContent='space-between'
          sx={{ p: 2, pt: 1, width: '100%' }}>
          {props.activeStep === 0 ? (
            <Button
              data-testid='form-dialog-content-cancel-button'
              disabled={formikContext.isSubmitting}
              onClick={() => props.onCancel()}>
              {props.cancelButtonText || 'Cancel'}
            </Button>
          ) : (
            <Button
              data-testid='form-dialog-content-back-button'
              disabled={formikContext.isSubmitting}
              onClick={() => props.setActiveStep(prev => prev - 1)}>
              {props.cancelButtonText || 'Back'}
            </Button>
          )}

          {props.activeStep + 1 === props.count ? (
            <LoadingButton
              autoFocus
              data-testid='form-dialog-content-submit-button'
              disabled={
                isEmpty(formikContext.values) && !formikContext.errors
                  ? false
                  : !formikContext.isValid || unchangedValues
              }
              loading={formikContext.isSubmitting}
              onClick={() => formikContext.submitForm()}
              variant='contained'>
              {props.continueButtonText || 'Submit'}
            </LoadingButton>
          ) : (
            <Button
              autoFocus
              data-testid='form-dialog-content-continue-button'
              disabled={!!formikContext.errors[props.name]}
              onClick={() => {
                props.setActiveStep(prev => prev + 1);
              }}
              variant='contained'>
              {props.continueButtonText || 'Continue'}
            </Button>
          )}
        </Stack>
      </DialogActions>
    </>
  );
};

FormContainer.displayName = 'FormContainer';

type FieldDisplayControllerProps = {
  disabledWhen?: (formValues: Record<string, any>) => boolean;
  showWhen?: (
    formValues: Record<string, any>,
    errors?: Record<string, any>
  ) => boolean;
  children: ReactElement | ReactElement[];
};

export const FieldDisplayController: FC<FieldDisplayControllerProps> = (
  props: FieldDisplayControllerProps
) => {
  const formikContext = useFormikContext();

  const show = useMemo(
    () => props.showWhen(formikContext.values, formikContext.errors),
    [formikContext.values, formikContext.errors, props.showWhen]
  );

  const disabled = useMemo(
    () => props.disabledWhen(formikContext.values),
    [formikContext.values, props.disabledWhen]
  );

  return (
    show &&
    Children.map(props.children, child =>
      cloneElement(child, {
        disabled
      })
    )
  );
};

FieldDisplayController.defaultProps = {
  disabledWhen: () => false,
  showWhen: () => true
};

FieldDisplayController.displayName = 'FieldDisplayController';
