import formatters from '@/utils/Formatters';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import {
  AppBar,
  Box,
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
  Tab,
  Tabs,
  Typography,
  useTheme
} from '@mui/material';

import { kebabCase } from 'lodash';
import { FC, Fragment, useCallback, useEffect, useMemo, useState } from 'react';

/**
 * Fetches the preferred tabs from local storage
 */
const fetchPreferredTabs = (
  name: string,
  tabOptions: string[],
  limit?: number
): string[] => {
  const preferredTabs = localStorage.getItem(
    `${name ? `${name}-` : ''}my-tabs-preferred-tabs`
  );
  // filter out any tabs that are not in the tabOptions
  const cleanedTabs = preferredTabs
    ? JSON.parse(preferredTabs).filter((tab: string) =>
        tabOptions.includes(tab)
      )
    : [];

  // only return the allowed number of tabs
  return cleanedTabs.slice(0, limit || 6);
};

type TabPreferenceDialogProps = {
  /**
   * Optionally used to add specificity the data-testid's and the url state param
   */
  name?: string;
  tabOptions: string[];
  /**
   * Optionally set preselected tabs if user has not set any preferred tabs
   */
  defaultTabPreferences?: string[];
  /**
   * Optionally support formatting tabOptions for the label of each tab (defaults to display casing the tab option)
   */
  tabLabelFormatter?: (tab: string) => string;
  open: boolean;
  onClose: () => void;
  onChange?: (preferredTabs: string[]) => void;
  // defaults to 6
  limit?: number;
};

const TabPreferenceDialog: FC<TabPreferenceDialogProps> = ({
  name = '',
  tabOptions = [],
  defaultTabPreferences = [],
  limit = 6,
  ...props
}) => {
  const theme = useTheme();

  const tabLabelFormatter =
    typeof props.tabLabelFormatter === 'function'
      ? props.tabLabelFormatter
      : (tab: string) => formatters.displayCase(tab);

  const preferredTabs = useMemo(
    () => fetchPreferredTabs(name, tabOptions, limit),
    [name, tabOptions, limit]
  );

  const [tempCheckedTabs, setTempCheckedTabs] = useState<string[]>(
    preferredTabs.length > 0 ? preferredTabs : defaultTabPreferences
  );

  const handleToggleTab = (tab: string) => {
    if (tempCheckedTabs.includes(tab)) {
      setTempCheckedTabs(tempCheckedTabs.filter(t => t !== tab));
    } else {
      // prevent checking tabs beyond the limit
      if (tempCheckedTabs.length >= limit) {
        return;
      }
      setTempCheckedTabs([...tempCheckedTabs, tab]);
    }
  };

  const handleSave = () => {
    // save the tempCheckedTabs to the local storage for this user
    localStorage.setItem(
      `${name ? `${name}-` : ''}my-tabs-preferred-tabs`,
      JSON.stringify(tempCheckedTabs)
    );

    if (typeof props.onChange === 'function') {
      props.onChange(tempCheckedTabs);
    }
  };

  return (
    <Dialog
      aria-describedby={`${
        name ? `${name}-` : ''
      }my-tabs-preference-dialog-description`}
      aria-labelledby={`${
        name ? `${name}-` : ''
      }my-tabs-preference-dialog-title`}
      data-testid={`${name ? `${name}-` : ''}my-tabs-preference-dialog`}
      fullWidth
      maxWidth='xs'
      onClose={props.onClose}
      open={props.open}>
      <DialogTitle
        id={`${name ? `${name}-` : ''}my-tabs-preference-dialog-title`}>
        Tab Preference
      </DialogTitle>
      <DialogContent>
        <Typography
          id={`${name ? `${name}-` : ''}my-tabs-preference-dialog-description`}
          variant='body1'>
          Select up to {limit || 6} options to be displayed.
        </Typography>
        <List>
          {tabOptions.map(tab => (
            <Fragment key={tab}>
              <ListItem
                data-testid={`${
                  name ? `${name}-` : ''
                }my-tabs-preference-dialog-tab-${tab}`}
                disableGutters
                disablePadding
                onClick={() => handleToggleTab(tab)}>
                <ListItemButton
                  onClick={() => handleToggleTab(tab)}
                  role={undefined}
                  sx={{ py: 0 }}>
                  <ListItemIcon>
                    <Checkbox
                      checked={tempCheckedTabs.includes(tab)}
                      disableRipple
                      disabled={
                        tempCheckedTabs.length === limit &&
                        !tempCheckedTabs.includes(tab)
                      }
                      edge='start'
                      inputProps={{
                        'aria-labelledby': `${
                          name ? `${name}-` : ''
                        }my-tabs-preference-dialog-tab-${tab}-label`
                      }}
                      tabIndex={-1}
                    />
                  </ListItemIcon>
                  <ListItemText
                    id={`${
                      name ? `${name}-` : ''
                    }my-tabs-preference-dialog-tab-${tab}-label`}
                    primary={tabLabelFormatter(tab)}
                    primaryTypographyProps={{
                      color:
                        tempCheckedTabs.length === limit &&
                        !tempCheckedTabs.includes(tab)
                          ? theme.palette.action.disabled
                          : undefined
                    }}
                  />
                </ListItemButton>
              </ListItem>
              {tab === 'All' && <Divider />}
            </Fragment>
          ))}
        </List>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onClose}>Cancel</Button>
        <Button onClick={handleSave}>Ok</Button>
      </DialogActions>
    </Dialog>
  );
};

interface TabData {
  label: string;
  icon?: JSX.Element;
  /** icon position defaults to end */
  iconPosition?: 'start' | 'end';
  onClick?: () => void;
}

type MyTabsProps = {
  /**
   * Optionally used to add specificity the data-testid's and the url state param
   */
  name?: string;
  defaultTab?: string;
  /**
   * Controls the tabs that are displayed by default
   * Will automatically have "All" prepended to the list of tabs as the first tab
   */
  defaultDisplayTabs?: string[];
  /**
   * Will automatically have "All" prepended to the list of tabs as the first tab
   */
  tabOptions: string[];
  /**
   * An object where the keys are the tabs and the value of each key is the total number of records to show
   * If this is omitted, then no totals chips will be shown
   */
  totals?: Record<string, number>;
  loading?: boolean;
  /**
   * Optionally support formatting tabOptions for the label of each tab (defaults to display casing the tab option)
   */
  tabLabelFormatter?: (tab: string) => string;
  onChange?: (tab?: string) => void;
  /**
   * Optional actions to display to the right of the tabs and to the left of the user preferences gear icon
   */
  actions?: JSX.Element;
  // defaults to 6
  limit?: number;
  // controlled tab selection
  value?: string;
};

const MyTabs: FC<MyTabsProps> = ({
  name = '',
  defaultTab = 'All',
  loading = false,
  totals = {},
  limit = 6,
  ...props
}) => {
  const tabLabelFormatter =
    typeof props.tabLabelFormatter === 'function'
      ? props.tabLabelFormatter
      : (tab: string) => formatters.displayCase(tab);

  const defaultDisplayTabs = useMemo<string[]>(
    () => ['All', ...(props.defaultDisplayTabs || [])],
    [props.defaultDisplayTabs]
  );

  const tabOptions = useMemo<string[]>(
    () => ['All', ...props.tabOptions],
    [props.tabOptions]
  );

  const allTabTotal = useMemo(
    () =>
      Object.values(totals).reduce((acc, curr) => {
        return acc + curr;
      }, 0),
    [totals]
  );

  const [otherTypesAnchorEl, setOtherTypesAnchorEl] =
    useState<null | HTMLElement>(null);
  const isOtherTypesOpen = Boolean(otherTypesAnchorEl);
  const [isTabPreferencesOpen, setIsTabPreferencesOpen] = useState(false);

  // ensure the displayTabs only contain the preferred tabs
  const [preferredTabs, setPreferredTabs] = useState<string[]>(
    fetchPreferredTabs(name, tabOptions, limit)
  );
  useEffect(
    () => setPreferredTabs(fetchPreferredTabs(name, tabOptions, limit)),
    [name, tabOptions, limit]
  );
  const displayTabs = useMemo(
    () =>
      preferredTabs.length > 0
        ? tabOptions.filter(eachTab => preferredTabs.includes(eachTab))
        : defaultDisplayTabs,
    [tabOptions, preferredTabs, defaultDisplayTabs]
  );

  const [selectedTab, setSelectedTab] = useState<string>(props.value || 'All');

  useEffect(() => {
    if (selectedTab === props.value) return;

    if (tabOptions.includes(props.value)) {
      setSelectedTab(props.value);
    } else if (props.value === undefined && selectedTab !== 'All') {
      setSelectedTab('All');
    }
  }, [defaultTab, props.value, tabOptions]);

  const handleChange = (tab: string) => {
    setSelectedTab(tab);
    if (typeof props.onChange === 'function') {
      props.onChange(tab);
    }
  };

  const otherTabs = useMemo(() => {
    if (!Array.isArray(displayTabs) || !displayTabs.length) {
      return tabOptions;
    }
    return tabOptions.filter(tab => !displayTabs.includes(tab));
  }, [tabOptions, displayTabs]);

  const tabElements: TabData[] = useMemo(
    // memo to prevent tabs being unloaded and reloaded when this component state changes
    () => {
      const calculatedTabElements: TabData[] = [];
      displayTabs.forEach(eachTab => {
        calculatedTabElements.push({
          icon:
            Object.keys(totals)?.length > 0 ? (
              <Chip
                data-testid={`my-tabs-${name}-${kebabCase(eachTab)}-tab`}
                label={eachTab === 'All' ? allTabTotal : totals?.[eachTab] || 0}
                size='small'
                sx={theme => ({
                  background: theme.palette.grey[200],
                  color: theme.palette.grey[600]
                })}
              />
            ) : undefined,
          label: tabLabelFormatter(eachTab),
          onClick: () => {
            handleChange(eachTab);
          }
        });
      });
      return calculatedTabElements;
    },

    [displayTabs, totals]
  );

  // handle keeping the selected tab selected in the displayed tabs
  const getTabIdx = useCallback(() => {
    const index = displayTabs.findIndex(eachTab => selectedTab === eachTab);

    return index < 0 ? false : index;
  }, [selectedTab, displayTabs]);
  const [activelyDisplayedTab, setActivelyDisplayedTab] = useState<
    number | false
  >(getTabIdx());
  useEffect(() => {
    setActivelyDisplayedTab(getTabIdx());
  }, [selectedTab, displayTabs, getTabIdx]);

  const handleDisplayedTabChange = (
    event: React.SyntheticEvent<Element, Event>,
    newActivelyDisplayedTab: number
  ) => {
    setActivelyDisplayedTab(newActivelyDisplayedTab);
  };

  const handleOtherOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setOtherTypesAnchorEl(event.currentTarget);
  };
  const handleOtherClose = () => {
    setOtherTypesAnchorEl(null);
  };
  const handleOtherClick = (tab: string) => {
    handleChange(tab);
    setActivelyDisplayedTab(false);
    handleOtherClose();
  };

  const a11yProps = (label: string) => {
    return {
      'aria-controls': `${name ? `${name}-` : ''}my-tab-tabpanel-${label}`,
      id: `${name ? `${name}-` : ''}my-tab-${label}`
    };
  };

  const handleTabPreferencesChange = (newTabPreferences: string[]) => {
    setIsTabPreferencesOpen(false);
    setPreferredTabs(newTabPreferences);
  };

  return (
    <Stack
      alignItems='start'
      data-testid={`${name ? `${name}-` : ''}my-tabs`}
      direction='row'
      justifyContent='space-between'
      sx={{
        pr: 2,
        py: 0
      }}>
      {loading ? (
        <Stack
          alignItems='center'
          data-testid={`${name ? `${name}-` : ''}my-tabs-loading`}
          sx={{ pl: 1.5, py: 1.5 }}>
          <CircularProgress size='24px' />
        </Stack>
      ) : (
        <Stack alignItems='stretch' direction='row'>
          {tabElements.length > 0 && (
            <Box
              data-testid={`${name ? `${name}-` : ''}my-tabs`}
              sx={{
                backgroundColor: 'transparent',
                flexGrow: 1
              }}>
              <AppBar color='transparent' elevation={0} position='static'>
                <Tabs
                  aria-label={`${name ? `${name}-` : ''}my-tabs`}
                  color='transparent'
                  onChange={handleDisplayedTabChange}
                  value={activelyDisplayedTab}
                  variant='scrollable'>
                  {tabElements.map((tab: TabData) => {
                    return (
                      <Tab
                        icon={tab.icon}
                        iconPosition={
                          tab.icon ? tab.iconPosition || 'end' : undefined
                        }
                        key={`tab-header-${formatters.textToDataTestId(
                          tab.label
                        )}`}
                        label={tab.label}
                        onClick={tab.onClick}
                        sx={{
                          // MUI adds a min-height of 72px when an icon is present to support the top or bottom positioning
                          // of the icon but we don't use top or bottom positioning so we can remove the min-height
                          minHeight: 'auto'
                        }}
                        {...a11yProps(tab.label)}
                      />
                    );
                  })}
                </Tabs>
              </AppBar>
            </Box>
          )}
          {otherTabs.length > 0 && (
            <Box
              sx={theme => ({
                borderBottomColor: theme.palette.primary.main,
                borderBottomStyle: otherTabs.find(
                  eachTab => eachTab === selectedTab
                )?.length
                  ? 'solid'
                  : undefined,
                borderBottomWidth: '2px',
                color: theme.palette.grey[600]
              })}>
              <Button
                aria-controls={
                  isOtherTypesOpen
                    ? `${name ? `${name}-` : ''}my-tabs-other-menu`
                    : undefined
                }
                aria-expanded={isOtherTypesOpen ? 'true' : undefined}
                aria-haspopup='true'
                color='inherit'
                data-testid={`${name ? `${name}-` : ''}my-tabs-other-button`}
                endIcon={<ArrowDropDownIcon />}
                id={`${name ? `${name}-` : ''}my-tabs-other-button`}
                onClick={handleOtherOpen}
                sx={{
                  lineHeight: '24px',
                  py: '9px'
                }}>
                Other
              </Button>
              <Menu
                MenuListProps={{
                  'aria-labelledby': `${
                    name ? `${name}-` : ''
                  }my-tabs-other-button`
                }}
                anchorEl={otherTypesAnchorEl}
                id={`${name ? `${name}-` : ''}my-tabs-other-menu`}
                onClose={handleOtherClose}
                open={isOtherTypesOpen}>
                {otherTabs.map(eachTab => (
                  <MenuItem
                    data-testid={`${eachTab}-item-my-tabs-other-button`}
                    key={eachTab}
                    onClick={() => handleOtherClick(eachTab)}
                    selected={eachTab === selectedTab}
                    sx={
                      eachTab === 'All'
                        ? theme => ({
                            '::after': {
                              borderBottomColor: theme.palette.grey[300],
                              borderBottomStyle: 'solid',
                              borderBottomWidth: '1px',
                              bottom: '-8px',
                              content: '""',
                              display: 'block',
                              left: 0,
                              position: 'absolute',
                              width: '100%'
                            },
                            // fake a divider element since Menu doesn't support fragments as children which we'd need to conditionally add one here
                            mb: eachTab === 'All' ? 2 : undefined
                          })
                        : undefined
                    }>
                    {tabLabelFormatter(eachTab)}
                    {((eachTab === 'All' && allTabTotal > 0) ||
                      (totals?.[eachTab] || 0) > 0) && (
                      <Chip
                        label={
                          eachTab === 'All'
                            ? allTabTotal
                            : totals?.[eachTab] || 0
                        }
                        size='small'
                        sx={theme => ({
                          '& .MuiChip-label': {
                            px: 0.75
                          },
                          background: theme.palette.grey[200],
                          color: theme.palette.grey[600],
                          fontSize: 12,
                          height: 20,
                          ml: 0.5
                        })}
                      />
                    )}
                  </MenuItem>
                ))}
              </Menu>
            </Box>
          )}
        </Stack>
      )}
      <Stack direction='row' spacing={1}>
        {props.actions}
        {tabOptions.length > 1 && (
          <>
            <IconButton
              data-testid={`${
                name ? `${name}-` : ''
              }my-tabs-preferences-button`}
              disabled={loading}
              onClick={() => setIsTabPreferencesOpen(true)}>
              <SettingsOutlinedIcon />
            </IconButton>
            <TabPreferenceDialog
              defaultTabPreferences={defaultDisplayTabs}
              limit={limit}
              name={name}
              onChange={handleTabPreferencesChange}
              onClose={() => setIsTabPreferencesOpen(false)}
              open={isTabPreferencesOpen}
              tabLabelFormatter={tabLabelFormatter}
              tabOptions={tabOptions}
            />
          </>
        )}
      </Stack>
    </Stack>
  );
};
export default MyTabs;
