import { Fade, SxProps } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

import React from 'react';

interface CircularLoadingProps {
  size?: string | number;
  fadeIn?: boolean;
  sx?: SxProps;
}

const useStyles = makeStyles((theme: Theme) => ({
  colorPrimary: {
    color: theme.palette.info.main
  }
}));

export const CircularLoading: React.FunctionComponent<CircularLoadingProps> = (
  props: CircularLoadingProps
) => {
  const classes = useStyles();

  const { size } = props;

  return (
    <div>
      <Fade in={props.fadeIn !== undefined ? props.fadeIn : true}>
        <CircularProgress
          classes={classes}
          size={size != null ? size : '1.25rem'}
          sx={props.sx}
        />
      </Fade>
    </div>
  );
};

export default CircularLoading;
