import { CardContent, CardPlaceholder } from '@/components/card';
import DataTable, {
  DataTableProps,
  DataTableStackCell
} from '@/components/data-table/DataTable.component';
import { useSnackbar } from '@/contexts/SnackBarContext';
import { useUrlState } from '@/hooks/useUrlState.hook';
import { OrderByDirection } from '@/models/suba/common/OrderByDirection.model';
import { SubaPlanDto } from '@/models/suba/suba-plans/SubaPlanDto.model';
import { SubaPlanIncludeOption } from '@/models/suba/suba-plans/SubaPlanIncludeOption.model';
import { SubaPlanSearchRequest } from '@/models/suba/suba-plans/SubaPlanSearchRequest.model';
import { useAccountsDetailRouteParams } from '@/routes/suba/accounts/accounts-detail/hooks/useAccountsDetailRouteParams.hook';
import { SubaPlanService } from '@/services/suba/suba-plans/SubaPlan.service';
import formatters from '@/utils/Formatters';
import SearchIcon from '@mui/icons-material/Search';
import {
  Button,
  Card,
  Divider,
  FormControl,
  OutlinedInput,
  Stack
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';

import { ColDef } from 'ag-grid-community';
import { AxiosError } from 'axios';
import { Field, Form, Formik } from 'formik';
import { FC, useCallback, useMemo } from 'react';

export const PlansTab: FC = () => {
  // context

  const routeParams = useAccountsDetailRouteParams();
  const { showSnackbar } = useSnackbar();

  // state

  const [urlState, setUrlState] = useUrlState<
    Pick<
      SubaPlanSearchRequest,
      'orderBy' | 'orderByDirection' | 'page' | 'pageSize' | 'query'
    >
  >(
    {
      page: 1,
      pageSize: 25,
      query: ''
    },
    {
      parsedValueTypes: {
        orderBy: 'string',
        orderByDirection: 'string',
        page: 'number',
        pageSize: 'number',
        query: 'string'
      }
    }
  );

  const searchPlansQuery = useQuery(
    ['SubaPlanService.search', urlState],
    () =>
      SubaPlanService.search({
        ...urlState,
        include: [SubaPlanIncludeOption.marketValueAndCashBalance],
        parentAccountId: routeParams.accountId
      }),

    {
      onError: (err: AxiosError) => {
        showSnackbar({
          message: `Plan search failed: ${err.response?.data ? err.response.data : err.message}`,
          severity: 'error'
        });
      }
    }
  );

  // callbacks

  const handleFiltersFormSubmit = useCallback(
    (values: SubaPlanSearchRequest) => {
      setUrlState(prevUrlState => ({
        ...prevUrlState,
        ...values,
        page: 1
      }));
    },
    []
  );

  const handlePageChanged = useCallback<DataTableProps['onPageChanged']>(
    newPage => {
      setUrlState(prevUrlState => ({
        ...prevUrlState,
        page: newPage
      }));
    },
    []
  );

  const handlePageSizeChanged = useCallback<
    DataTableProps['onPageSizeChanged']
  >(newPageSize => {
    setUrlState(prevUrlState => ({
      ...prevUrlState,
      page: 1,
      pageSize: newPageSize
    }));
  }, []);

  const handleSortChanged = useCallback<DataTableProps['onSortChanged']>(
    newSort => {
      if (!newSort || newSort.length === 0) {
        setUrlState(prevUrlState => ({
          ...prevUrlState,
          orderBy: undefined,
          orderByDirection: undefined,
          page: 1
        }));
      } else {
        setUrlState(prevUrlState => ({
          ...prevUrlState,
          orderBy: newSort[0].colId as keyof SubaPlanDto,
          orderByDirection: newSort[0].sort as OrderByDirection,
          page: 1
        }));
      }
    },
    []
  );

  // memos

  const columnDefs: ColDef[] = useMemo(
    () => [
      {
        autoHeight: true,
        cellRenderer: (cellData: { data: SubaPlanDto }) => (
          <DataTableStackCell
            primary={cellData.data.plan?.name || cellData.data.planId}
            primaryLinkProps={{
              target: '_blank',
              to: `/ops/accounts/${routeParams.accountId}/plans/${cellData.data.planId}`
            }}
            secondary={
              cellData.data.plan?.name
                ? `ID: ${cellData.data.planId}`
                : undefined
            }
          />
        ),
        field: 'planId',
        flex: 1,
        headerName: 'Plan',
        sortable: true
      },
      {
        field: 'planSetupType',
        sortable: true
      },
      {
        field: 'totalMarketValue',
        headerName: 'Market Value',
        sortable: true,
        type: 'numericColumn',
        valueFormatter: ({ value }) => formatters.formatDollars(value)
      },
      {
        field: 'cashBalance',
        headerName: 'Cash Balance',
        sortable: true,
        type: 'numericColumn',
        valueFormatter: ({ value }) => formatters.formatDollars(value)
      }
    ],
    []
  );

  return (
    <Card>
      <CardContent>
        <Formik<SubaPlanSearchRequest>
          initialValues={{
            query: urlState.query || ''
          }}
          onSubmit={handleFiltersFormSubmit}>
          <Form>
            <Stack alignItems='center' direction='row' spacing={2} useFlexGap>
              <FormControl fullWidth variant='outlined'>
                <Field
                  as={OutlinedInput}
                  autoComplete='off'
                  endAdornment={<SearchIcon color='secondary' />}
                  inputProps={{
                    'aria-labelledby': 'plans-tab',
                    'data-testid': 'query-input'
                  }}
                  name='query'
                  placeholder='Plan name or Plan ID'
                  size='small'
                />
              </FormControl>
              <Button
                data-testid='apply-button'
                type='submit'
                variant='outlined'>
                Apply
              </Button>
            </Stack>
          </Form>
        </Formik>
      </CardContent>
      <Divider />
      <CardContent disablePadding loading={searchPlansQuery.isLoading}>
        <DataTable
          columnDefs={columnDefs}
          columnSizing='flex'
          data-testid='plans-data-table'
          emptyPlaceholderComponent={
            <Stack
              alignItems='center'
              justifyContent='center'
              sx={{ height: '100%' }}>
              <CardPlaceholder
                data-testid='no-plans-card-placeholder'
                title='No plans found'
              />
            </Stack>
          }
          onPageChanged={handlePageChanged}
          onPageSizeChanged={handlePageSizeChanged}
          onSortChanged={handleSortChanged}
          page={urlState.page}
          pageSize={urlState.pageSize}
          pagination
          paginationSource='server'
          paginationTotal={searchPlansQuery.data?.pagination?.total}
          rowData={searchPlansQuery.data?.results || []}
          sort={
            urlState.orderBy
              ? [
                  {
                    colId: urlState.orderBy,
                    sort: urlState.orderByDirection
                  }
                ]
              : []
          }
        />
      </CardContent>
    </Card>
  );
};
