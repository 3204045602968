import Card, { CardHeader } from '@/components/card/Card.component';
import DownloadCSVButton from '@/components/download-csv-button/DownloadCSVButton.component';
import { useParentAccountById } from '@/hooks/suba/useParentAccountById.hook';
import { SEARCH_SUB_ACCOUNTS_QUERY_KEY } from '@/hooks/suba/useSearchSubAccountsQuery.hook';
import { useUrlState } from '@/hooks/useUrlState.hook';
import { SubAccountApiIncludeOption } from '@/models/suba/accounts/SubAccountApiIncludeOption.model';
import { OrderByDirection } from '@/models/suba/common/OrderByDirection.model';
import FundingSource from '@/models/suba/FundingSourceEnum.model';
import { OperationalSubAccounts } from '@/routes/suba/accounts/common/components/OperationalSubAccounts.component';
import {
  SubAccounts,
  SubAccountsProps
} from '@/routes/suba/accounts/common/components/sub-accounts/SubAccounts.component';
import SubAccountService from '@/services/suba/accounts/SubAccount.service';
import formatters from '@/utils/Formatters';
import { Unstable_Grid2 as Grid } from '@mui/material';
import { useIsFetching } from '@tanstack/react-query';
import { OperationalSubAccountList } from '@vestwell-sub-accounting/models/accountsAndLedgers/OperationalSubAccountList';
import { ParticipantSubAccountList } from '@vestwell-sub-accounting/models/accountsAndLedgers/ParticipantSubAccountList';
import { ParentAccountType } from '@vestwell-sub-accounting/models/common/ParentAccountType';

import { useCallback, useState } from 'react';

enum SubAccountsTabValue {
  'Operational' = 'Operational',
  'Participants' = 'Participants'
}

const exportOperationalSubAccounts = async (parentAccountId: string) => {
  return (
    await SubAccountService.searchSubAccounts({
      accountType: OperationalSubAccountList,
      hasPlanId: true,
      include: [
        SubAccountApiIncludeOption.cashBalance,
        SubAccountApiIncludeOption.totalMarketValue
      ],
      page: 1,
      pageSize: 0,
      parentAccountId
    })
  ).results?.map(subAccount =>
    subAccount.parentAccount?.accountType === ParentAccountType.SuperOmnibus
      ? {
          /* eslint-disable sort-keys-plus/sort-keys */
          Type: formatters.displayCase(subAccount.accountType),
          'Plan ID': subAccount.planId,
          'Plan Name': subAccount.plan?.name,
          'Market Value': subAccount.totalMarketValue,
          'Cash Balance': subAccount.cashBalance
          /* eslint-enable sort-keys-plus/sort-keys */
        }
      : {
          /* eslint-disable sort-keys-plus/sort-keys */
          Type: formatters.displayCase(subAccount.accountType),
          'Plan Name': subAccount.plan?.name,
          'Market Value': subAccount.totalMarketValue,
          'Cash Balance': subAccount.cashBalance
          /* eslint-enable sort-keys-plus/sort-keys */
        }
  );
};

const exportParticipantSubAccounts = async (parentAccountId: string) => {
  return (
    await SubAccountService.searchSubAccounts({
      accountType: ParticipantSubAccountList,
      include: [
        SubAccountApiIncludeOption.cashBalance,
        SubAccountApiIncludeOption.totalMarketValue
      ],
      page: 1,
      pageSize: 0,
      parentAccountId
    })
  ).results?.map(subAccount => ({
    /* eslint-disable sort-keys-plus/sort-keys */
    'Sub Account ID': subAccount.subAccountId,
    'Plan ID': subAccount.planId,
    'Part ID': subAccount.investorId,
    'Participant Name':
      subAccount.participant?.firstName || subAccount.participant?.lastName
        ? `${subAccount.participant?.firstName} ${subAccount.participant?.lastName}`
        : '',
    'Funding Source': subAccount.fundingSource
      ? FundingSource[subAccount.fundingSource]
      : '',
    'Market Value': subAccount.totalMarketValue,
    'Cash Balance': subAccount.cashBalance,
    'Created At': formatters.formatFromIsoDate(subAccount.createdAt)
    /* eslint-enable sort-keys-plus/sort-keys */
  }));
};

type SubAccountsTabProps = {
  parentAccountId: string;
};

export const SubAccountsTab = (props: SubAccountsTabProps) => {
  // context

  const isFetchingSubAccounts = useIsFetching([SEARCH_SUB_ACCOUNTS_QUERY_KEY]);

  // state

  const [urlState, setUrlState] = useUrlState<
    Pick<
      SubAccountsProps['searchParams'],
      | 'hasDebitSubAccounts'
      | 'orderBy'
      | 'orderByDirection'
      | 'page'
      | 'pageSize'
      | 'query'
    >
  >(
    {
      hasDebitSubAccounts: false,
      page: 1,
      query: ''
    },
    {
      parsedValueTypes: {
        hasDebitSubAccounts: 'boolean',
        orderBy: 'string',
        orderByDirection: OrderByDirection,
        page: 'number',
        pageSize: 'number',
        query: 'string'
      }
    }
  );

  const [subAccountsType, setSubAccountsType] = useState<SubAccountsTabValue>(
    SubAccountsTabValue.Participants
  );

  // api

  const parentAccountQuery = useParentAccountById({
    accountId: props.parentAccountId
  });

  // callbacks

  const handleSubAccountsPageChange = useCallback<
    SubAccountsProps['onPageChange']
  >(newPage => {
    setUrlState(prevUrlState => ({
      ...prevUrlState,
      page: newPage
    }));
  }, []);

  const handleSubAccountsPageSizeChange = useCallback<
    SubAccountsProps['onPageChange']
  >(newPageSize => {
    setUrlState(prevUrlState => ({
      ...prevUrlState,
      pageSize: newPageSize
    }));
  }, []);

  const handleSubAccountsSortChange = useCallback<
    SubAccountsProps['onSortChange']
  >(newSort => {
    setUrlState(prevUrlState => ({
      ...prevUrlState,
      ...newSort,
      page: 1
    }));
  }, []);

  const handleSubAccountsSearchFormSubmit = useCallback<
    SubAccountsProps['onSearchFormSubmit']
  >(values => {
    setUrlState(prevUrlState => ({
      ...prevUrlState,
      ...values,
      page: 1
    }));
  }, []);

  return (
    <Grid container spacing={2}>
      <Grid lg={4} xs={12}>
        <Card>
          <CardHeader title='Operational Accounts' />
          <OperationalSubAccounts
            DataTableProps={{
              columnSizing: 'fit',
              ['data-testid']: 'data-operational-accounts'
            }}
            hasPlanId={
              ![
                ParentAccountType.House,
                ParentAccountType.SuperOmnibus
              ].includes(parentAccountQuery.data?.accountType)
            }
            parentAccountId={props.parentAccountId}
          />
        </Card>
      </Grid>
      <Grid lg={8} xs={12}>
        <Card>
          <CardHeader
            loading={!!isFetchingSubAccounts}
            title='Sub Accounts'
            toggles={
              parentAccountQuery.data?.accountType ===
              ParentAccountType.SuperOmnibus
                ? [
                    {
                      onChangeAction: (
                        event: React.MouseEvent<HTMLElement>,
                        value: SubAccountsTabValue
                      ) => {
                        setSubAccountsType(value);
                      },
                      options: [
                        {
                          label: 'Participants',
                          value: SubAccountsTabValue.Participants
                        },
                        {
                          label: 'Operational',
                          value: SubAccountsTabValue.Operational
                        }
                      ],
                      value: subAccountsType
                    }
                  ]
                : undefined
            }>
            <DownloadCSVButton
              buttonProps={{
                sx: {
                  fontSize: 'small'
                },
                variant: 'outlined'
              }}
              fileName={`SubAccounts-${parentAccountQuery.data?.parentAccountId}-${subAccountsType}`}
              getInfo={() =>
                subAccountsType === SubAccountsTabValue.Participants
                  ? exportParticipantSubAccounts(
                      parentAccountQuery.data?.parentAccountId
                    )
                  : exportOperationalSubAccounts(
                      parentAccountQuery.data?.parentAccountId
                    )
              }
              text='Export CSV'
            />
          </CardHeader>
          {subAccountsType === SubAccountsTabValue.Participants && (
            <SubAccounts
              data-testid='data-participants-accounts'
              onPageChange={handleSubAccountsPageChange}
              onPageSizeChange={handleSubAccountsPageSizeChange}
              onSearchFormSubmit={handleSubAccountsSearchFormSubmit}
              onSortChange={handleSubAccountsSortChange}
              searchParams={{
                ...urlState,
                accountType: ParticipantSubAccountList,
                parentAccountId: props.parentAccountId
              }}
            />
          )}
          {subAccountsType === SubAccountsTabValue.Operational && (
            <OperationalSubAccounts
              data-testid='data-operational-accounts'
              hasPlanId
              pagination
              parentAccountId={props.parentAccountId}
              showPlan
            />
          )}
        </Card>
      </Grid>
    </Grid>
  );
};
