import {
  Card,
  CardContent,
  CardHeader,
  CardPlaceholder
} from '@/components/card/Card.component';
import {
  DataTable,
  DataTableMenuCell,
  DataTableProps
} from '@/components/data-table/DataTable.component';
import { DatePicker } from '@/components/date-picker';
import { useSearchDividendAccrualRatesQuery } from '@/hooks/suba/useSearchDividendAccrualRates.hook';
import { DividendAccrualRateDto } from '@/models/suba/dividend-accrual-rates/DividendAccrualRateDTO.model';
import { DividendAccrualRateService } from '@/services/suba/dividend-accrual-rates/DividendAccrualRates.service';
import DOMInteraction from '@/utils/DOMInteraction';
import formatters from '@/utils/Formatters';
import { json2csvParser } from '@/utils/Json2csvParser';
import { Search as SearchIcon } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import {
  Button,
  FormControl,
  MenuItem,
  Stack,
  Typography
} from '@mui/material';
import { useToggle } from '@vestwell-frontend/hooks';

import dayjs from 'dayjs';
import { Field, Form, Formik } from 'formik';
import { FC, useState } from 'react';

import { AddAccrualDetailsDialog } from './dialogs/AddAccrualDetailsDialog.component';
import { DeleteAccrualDetailsDialog } from './dialogs/DeleteAccrualDetailsDialog.component';
import { EditAccrualDetailsDialog } from './dialogs/EditAccrualDetailsDialog.component';

export type AccrualDetailsFiltersFormValues = {
  effectiveEndDate: string;
  effectiveStartDate: string;
};

type SearchParams = Parameters<typeof useSearchDividendAccrualRatesQuery>[0];

const exportAccrualDetails = async (params: SearchParams) => {
  const data = await DividendAccrualRateService.search({
    ...params,
    page: 1,
    pageSize: 0 // fetch all data
  });

  const csv = await json2csvParser(
    data.results.map(item => ({
      'Accrual Rate': item.rate,
      // format raw data to match visual presentation
      'Effective Date': dayjs(item.effectiveDate).format('MM/DD/YY'),
      'Effective Day Count': item.dailyDayCount
    }))
  );

  let filename = 'dividend-accrual-details.csv';

  const effectiveStartDate = dayjs(params.effectiveStartDate).format(
    'MM-DD-YYYY'
  );

  const effectiveEndDate = dayjs(params.effectiveEndDate).format('MM-DD-YYYY');

  if (params.effectiveStartDate && params.effectiveEndDate) {
    filename = `dividend-accrual-details-${effectiveStartDate}-to-${effectiveEndDate}.csv`;
  } else if (params.effectiveStartDate) {
    filename = `dividend-accrual-details-${effectiveStartDate}.csv`;
  } else if (params.effectiveEndDate) {
    filename = `dividend-accrual-details-${effectiveEndDate}.csv`;
  }

  DOMInteraction.triggerDownload(csv, filename);
};

export type AccrualDetailsCardProps = {
  onFiltersFormSubmit: (values: AccrualDetailsFiltersFormValues) => void;
  onPageChange: DataTableProps['onPageChanged'];
  onPageSizeChange: DataTableProps['onPageSizeChanged'];
  searchParams: SearchParams;
};

export const AccrualDetailsCard: FC<AccrualDetailsCardProps> = props => {
  // state

  const [selectedDividendAccrual, setSelectedDividendAccrual] =
    useState<DividendAccrualRateDto | null>(null);
  const [openAddAccrualDetailsDialog, toggleOpenAddAccrualDetailsDialog] =
    useToggle(false);
  const [openEditAccrualDetailsDialog, toggleOpenEditAccrualDetailsDialog] =
    useToggle(false);
  const [openDeleteAccrualDetailsDialog, toggleOpenDeleteAccrualDetailsDialog] =
    useToggle(false);

  // apis

  const dividendAccrualRateSearchQuery = useSearchDividendAccrualRatesQuery(
    props.searchParams
  );

  return (
    <>
      {openAddAccrualDetailsDialog && (
        <AddAccrualDetailsDialog
          onClose={() => toggleOpenAddAccrualDetailsDialog()}
        />
      )}
      {openEditAccrualDetailsDialog && selectedDividendAccrual && (
        <EditAccrualDetailsDialog
          dividendAccrual={selectedDividendAccrual}
          onClose={() => toggleOpenEditAccrualDetailsDialog()}
        />
      )}
      {openDeleteAccrualDetailsDialog && selectedDividendAccrual && (
        <DeleteAccrualDetailsDialog
          dividendAccrual={selectedDividendAccrual}
          onClose={() => toggleOpenDeleteAccrualDetailsDialog()}
        />
      )}
      <Card data-testid='accrual-details-card'>
        <CardHeader
          actionButtonsProps={[
            {
              'data-testid': 'accrual-details-export-csv-button',
              disabled: !dividendAccrualRateSearchQuery.data?.results?.length,
              label: 'Export CSV',
              onClick: () => exportAccrualDetails(props.searchParams)
            },
            {
              'data-testid': 'accrual-details-add-button',
              disabled: dividendAccrualRateSearchQuery.isInitialLoading,
              label: (
                <Stack alignItems='center' direction='row' spacing={0.5}>
                  <Typography sx={{ fontSize: 14, fontWeight: 500 }}>
                    Add
                  </Typography>
                  <Stack sx={{ fontSize: 22 }}>
                    <AddIcon fontSize='inherit' />
                  </Stack>
                </Stack>
              ),
              onClick: () => toggleOpenAddAccrualDetailsDialog(true)
            }
          ]}
          title='Accrual Details'
        />
        <CardContent>
          <Formik<AccrualDetailsFiltersFormValues>
            initialValues={{
              effectiveEndDate: props.searchParams.effectiveEndDate || '',
              effectiveStartDate: props.searchParams.effectiveStartDate || ''
            }}
            onSubmit={props.onFiltersFormSubmit}>
            <Form data-testid='accrual-details-search-form'>
              <Stack
                direction={{ md: 'row', xs: 'column' }}
                justifyContent={{ xl: 'stretch', xs: 'left' }}
                spacing={2}>
                <FormControl size='small' variant='outlined'>
                  <Field
                    as={DatePicker}
                    autoComplete='off'
                    data-testid='accrual-details-effective-date-start-input'
                    disableFuture
                    label='Effective Date Start'
                    name='effectiveStartDate'
                  />
                </FormControl>
                <FormControl size='small' variant='outlined'>
                  <Field
                    as={DatePicker}
                    autoComplete='off'
                    data-testid='accrual-details-effective-date-end-input'
                    disableFuture
                    label='Effective Date End'
                    name='effectiveEndDate'
                  />
                </FormControl>
                <Button
                  data-testid='accrual-details-apply-button'
                  sx={{ height: 40 }}
                  type='submit'
                  variant='outlined'>
                  Apply
                </Button>
              </Stack>
            </Form>
          </Formik>
        </CardContent>
        <CardContent
          disablePadding
          overlayLoading={dividendAccrualRateSearchQuery.isInitialLoading}>
          <DataTable
            columnDefs={[
              {
                cellRenderer: (cellData: { data: DividendAccrualRateDto }) => (
                  <DataTableMenuCell>
                    <MenuItem
                      onClick={() => {
                        setSelectedDividendAccrual(cellData.data);
                        toggleOpenEditAccrualDetailsDialog(true);
                      }}>
                      Edit
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        setSelectedDividendAccrual(cellData.data);
                        toggleOpenDeleteAccrualDetailsDialog(true);
                      }}>
                      Delete
                    </MenuItem>
                  </DataTableMenuCell>
                ),
                cellStyle: {
                  paddingLeft: 16,
                  paddingRight: 16
                },
                field: 'cusip',
                headerName: ''
              },
              {
                field: 'effectiveDate',
                suppressMenu: true,
                valueFormatter: ({ value }) =>
                  formatters.formatFromIsoDateCustom(value, 'MM/DD/YY')
              },
              {
                field: 'rate',
                headerName: 'Accrual Rate',
                suppressMenu: true,
                type: 'numericColumn'
              },
              {
                field: 'dailyDayCount',
                headerName: 'Effective Day Count',
                suppressMenu: true,
                type: 'numericColumn'
              }
            ]}
            columnSizing='fit'
            emptyPlaceholderComponent={
              <Stack
                alignItems='center'
                data-testid='no-accrual-details-message'
                justifyContent='center'
                sx={{ height: '100%' }}>
                <CardPlaceholder
                  icon={<SearchIcon fontSize='inherit' />}
                  subtitle='No accrual details found'
                />
              </Stack>
            }
            onPageChanged={props.onPageChange}
            onPageSizeChanged={props.onPageSizeChange}
            page={props.searchParams.page}
            pageSize={props.searchParams.pageSize}
            pagination
            paginationSource='server'
            paginationTotal={
              dividendAccrualRateSearchQuery.data?.pagination?.total
            }
            rowData={dividendAccrualRateSearchQuery.data?.results}
          />
        </CardContent>
      </Card>
    </>
  );
};
