import { Badge } from '@/components/badge';
import { AlertStatusColorMap } from '@/models/suba/alerts/AlertStatusColorMap.model';
import { AssigneeAutocomplete } from '@/routes/suba/common/components/forms/AssigneeAutocomplete.component';
import formatters from '@/utils/Formatters';
import SearchIcon from '@mui/icons-material/Search';
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormHelperText,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  Stack
} from '@mui/material';
import { AlertPriority } from '@vestwell-sub-accounting/models/common/AlertPriority';
import { AlertStatus } from '@vestwell-sub-accounting/models/common/AlertStatus';
import { AlertSubType } from '@vestwell-sub-accounting/models/common/AlertSubType';

import { Field, Form, useFormikContext } from 'formik';
import { isEqual } from 'lodash';
import { FC } from 'react';

export type AlertsFiltersFormValues = {
  alertStatus: AlertStatus[];
  alertSubType?: AlertSubType | '';
  assignee?: string;
  priority?: AlertPriority | '';
  query?: string;
};

const alertStatusesExceptClosed = Object.values(AlertStatus).filter(
  status => status !== AlertStatus.Closed
);

export const AlertsFiltersForm: FC = () => {
  const formikContext = useFormikContext<AlertsFiltersFormValues>();

  return (
    <Form data-testid='filter-form'>
      <Stack
        alignItems='flex-start'
        justifyContent='flex-start'
        spacing={2}
        width={256}>
        <FormControl fullWidth size='small'>
          <Field
            as={OutlinedInput}
            endAdornment={<SearchIcon />}
            inputProps={{
              'data-testid': 'query-input'
            }}
            name='query'
            placeholder='Search'
          />
          <FormHelperText>Search by alert ID, ARN, or tracer ID</FormHelperText>
        </FormControl>
        <FormControl fullWidth size='small'>
          <InputLabel id='menu-status-label' shrink>
            Status
          </InputLabel>
          <Field
            MenuProps={{
              'data-testid': 'menu-status'
            }}
            as={Select}
            data-testid='query-status'
            displayEmpty
            label='Status'
            labelId='menu-status-label'
            multiple
            name='alertStatus'
            renderValue={(selected: AlertStatus[]) => {
              if (selected.length === 0) {
                return <>Any</>;
              }

              const sortedSelected = [...selected].sort();
              const sortedStatusesExceptClosed = [
                ...alertStatusesExceptClosed
              ].sort();
              if (isEqual(sortedSelected, sortedStatusesExceptClosed)) {
                return <>All except Closed</>;
              }

              return (
                <Box
                  sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    gap: 0.5
                  }}>
                  {selected.map(value => {
                    return (
                      <Badge
                        color={AlertStatusColorMap[value]}
                        key={value}
                        size='small'>
                        {formatters.displayCase(
                          formatters.getValueKey(AlertStatus, value)
                        )}
                      </Badge>
                    );
                  })}
                </Box>
              );
            }}>
            {Object.values(AlertStatus).map(value => {
              const displayStatus = formatters.getValueKey(AlertStatus, value);
              return (
                <MenuItem key={value} value={value}>
                  <Checkbox
                    checked={(formikContext.values.alertStatus || []).includes(
                      value
                    )}
                    sx={{ py: 0 }}
                  />
                  <ListItemText>
                    {formatters.displayCase(displayStatus)}
                  </ListItemText>
                </MenuItem>
              );
            })}
          </Field>
        </FormControl>
        <FormControl fullWidth size='small'>
          <Field
            as={AssigneeAutocomplete}
            data-testid='alert-assignee'
            name='assignee'
          />
        </FormControl>
        <FormControl fullWidth size='small'>
          <InputLabel id='menu-priority-label' shrink>
            Priority
          </InputLabel>
          <Field
            MenuProps={{
              'data-testid': 'menu-priority'
            }}
            as={Select}
            data-testid='query-priority'
            displayEmpty
            label='Priority'
            labelId='menu-priority-label'
            name='priority'>
            <MenuItem value=''>Any</MenuItem>
            {Object.values(AlertPriority).map(value => {
              const displayPriority = formatters.getValueKey(
                AlertPriority,
                value
              );
              return (
                <MenuItem key={value} value={value}>
                  {formatters.displayCase(displayPriority)}
                </MenuItem>
              );
            })}
          </Field>
        </FormControl>
        <FormControl fullWidth size='small'>
          <InputLabel id='menu-sub-type-label' shrink>
            Sub Type
          </InputLabel>
          <Field
            MenuProps={{
              'data-testid': 'menu-sub-type'
            }}
            as={Select}
            data-testid='query-sub-type'
            displayEmpty
            label='Sub Type'
            labelId='menu-sub-type-label'
            name='alertSubType'>
            <MenuItem value=''>Any</MenuItem>
            {Object.entries(AlertSubType)
              .sort(([a], [b]) => a.localeCompare(b))
              .map(([displaySubType, value]) => (
                <MenuItem key={value} value={value}>
                  {formatters.displayCase(displaySubType)}
                </MenuItem>
              ))}
          </Field>
        </FormControl>
        <Button type='submit' variant='outlined'>
          Apply
        </Button>
      </Stack>
    </Form>
  );
};
