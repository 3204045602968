import { TokenData } from '@/services/User.service';

import React, { createContext, useState } from 'react';

const UserTokenContext = createContext<
  | {
      userHasValidToken: boolean;
      token: TokenData | null;
      setUserToken: (token: TokenData) => void;
    }
  | undefined
>(undefined);

export function useUserToken(): {
  userHasValidToken: boolean;
  token: TokenData | null;
  setUserToken: (token: TokenData) => void;
} {
  const context = React.useContext(UserTokenContext);
  if (context === undefined) {
    throw new Error('useUserToken must be used within a UserTokenProvider');
  }

  return context;
}

type UserTokenProviderProps = {
  token?: TokenData;
  children: React.ReactNode;
};

export function UserTokenProvider(
  props: {
    children: JSX.Element;
  } & UserTokenProviderProps
): JSX.Element {
  const { token: initialToken, children } = props;
  const [token, setToken] = useState(initialToken || null);

  return (
    <UserTokenContext.Provider
      value={{
        setUserToken: setToken,
        token,
        userHasValidToken: token != null
      }}>
      {children}
    </UserTokenContext.Provider>
  );
}

export default UserTokenProvider;
