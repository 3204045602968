import dayjs from 'dayjs';

import ApiService from './Api.service';

export class ReportingService {
  static async getSigmaUrl(
    planIds: Array<number>,
    dateRange: object,
    reportType: string,
    tpaId: number
  ): Promise<string> {
    try {
      const sigmaUrl = await ApiService.postJson<any, string>(`/reporting`, {
        endDate: dayjs(dateRange[1]).format('YYYY-MM-DD'),
        planIds,
        reportType,
        startDate: dayjs(dateRange[0]).format('YYYY-MM-DD'),
        tpaId
      });

      if (!sigmaUrl) {
        throw new Error(`Unable to generate Sigma URL`);
      }

      return sigmaUrl;
    } catch (error) {
      throw new Error(`Unable to generate Sigma URL: ${error.message}`);
    }
  }
}
