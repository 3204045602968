import { GLOBAL_SEARCH_RESULT_PLANS_TYPE } from '@/components/main-layout/GlobalSearch/GlobalSearchResults/constants';
import { useSnackbar } from '@/contexts/SnackBarContext';
import GlobalSearchService from '@/services/GlobalSearch.service';
import { ReportingService } from '@/services/SigmaReports.service';
import TpaService from '@/services/Tpa.service';
import { DateRange } from '@mui/lab';
import {
  Autocomplete,
  Box,
  Chip,
  CircularProgress,
  Divider,
  FormControl,
  Grid,
  MenuItem,
  Select,
  Stack,
  TextField,
  Theme,
  Typography
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { PickersShortcutsItem } from '@mui/x-date-pickers';
import {
  DateRangePicker,
  LocalizationProvider,
  SingleInputDateRangeField
} from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { useQuery } from '@tanstack/react-query';
import { useToggle } from '@vestwell-frontend/hooks';
import { Button } from '@vestwell-frontend/ui';

import dayjs, { Dayjs } from 'dayjs';
import { FC, useState } from 'react';
import { useDebounce } from 'use-debounce';

import { PlanInputSearch } from './components/PlanInputSearch';

const useStyles = makeStyles((theme: Theme) => ({
  globalSearchResult: {
    maxHeight: '51vh',
    overflowY: 'scroll',
    position: 'absolute',
    width: theme.spacing(60)
  },
  iframeContainer: {
    flex: '1 1 auto',
    height: `${window.innerHeight}px`,
    marginBottom: `${theme.spacing(15)} !important`,
    overflow: 'hidden'
  }
}));

const Reports: FC = () => {
  const classes = useStyles();
  const snackbar = useSnackbar();
  const [searchTerm, setSearchTerm] = useState('');
  const [debouncedSearchTerm] = useDebounce(searchTerm, 500);
  const [iframeUrl, setIframeUrl] = useState('');
  const [generated, toggleGenerated] = useToggle(false);
  const [dateRange, setDateRange] = useState<DateRange<Dayjs>>([null, null]);
  const [reportType, setReportType] = useState('par');
  const [plans, setPlans] = useState([]);

  const planSearchSigmaReport = useQuery(
    ['sigmaReportPlanSearch', debouncedSearchTerm],
    () =>
      GlobalSearchService.getGlobalSearchResult(
        debouncedSearchTerm,
        1,
        100,
        GLOBAL_SEARCH_RESULT_PLANS_TYPE
      ),
    { enabled: Boolean(debouncedSearchTerm) }
  );

  const reportTypes = [
    {
      label: 'Participant Activity Report',
      value: 'par'
    },
    {
      label: 'Audit Report',
      value: 'audit'
    }
  ];

  const TPAsQuery = useQuery(
    ['TpaService.getSelf'],
    () => {
      return TpaService.getSelf();
    },
    {
      staleTime: Infinity
    }
  );

  const shortcutsItems: PickersShortcutsItem<DateRange<Dayjs>>[] = [
    {
      getValue: () => {
        const today = dayjs();
        return [today.startOf('week'), today.endOf('week')];
      },
      label: 'This Week'
    },
    {
      getValue: () => {
        const today = dayjs();
        const prevWeek = today.subtract(7, 'day');
        return [prevWeek.startOf('week'), prevWeek.endOf('week')];
      },
      label: 'Last Week'
    },
    {
      getValue: () => {
        const today = dayjs();
        return [today.subtract(7, 'day'), today];
      },
      label: 'Last 7 Days'
    },
    {
      getValue: () => {
        const today = dayjs();
        return [today.startOf('month'), today.endOf('month')];
      },
      label: 'Current Month'
    },
    {
      getValue: () => {
        const startOfYear = dayjs().startOf('year');
        const today = dayjs();
        return [startOfYear, today];
      },
      label: 'Year to Date'
    },
    {
      getValue: () => {
        const startOfYear = dayjs().startOf('year').subtract(1, 'year');
        const endOfYear = dayjs().endOf('year').subtract(1, 'year');
        return [startOfYear, endOfYear];
      },
      label: 'Prior Year'
    },
    { getValue: () => [null, null], label: 'Reset' }
  ];

  const getSigmaURL = async () => {
    const tpaId = Array.isArray(TPAsQuery.data?.data)
      ? 0
      : TPAsQuery.data?.data?.id;
    await ReportingService.getSigmaUrl(
      plans.map(plan => plan.sponsorPlanId),
      [dateRange[0].unix() * 1000, dateRange[1].unix() * 1000],
      reportType,
      tpaId
    )
      .then(url => {
        setIframeUrl(url);
        toggleGenerated();
      })
      .catch(e => {
        snackbar.showSnackbar({
          message: e.message,
          severity: 'error'
        });
      });
  };

  return (
    <>
      <Typography mb={2} mt={3.5} variant='h4'>
        Reports
      </Typography>
      <Stack divider={<Divider flexItem />} spacing={2} sx={{ height: '100%' }}>
        <Grid alignItems='flex-end' columns={9} container spacing={2}>
          <Grid item xs={2}>
            <Typography variant='h6'>Report Type</Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant='h6'>Plan</Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography variant='h6'>Dates</Typography>
          </Grid>
          <Grid item xs={2}></Grid>
          <Grid item sx={{ paddingTop: '0px !important' }} xs={2}>
            <FormControl fullWidth size='medium'>
              <Select
                displayEmpty
                fullWidth
                onChange={event => {
                  setReportType(event.target.value);
                  setSearchTerm('');
                  setPlans([]);
                  setDateRange([null, null]);
                }}
                value={reportType}>
                {reportTypes.map(report => (
                  <MenuItem key={report.label} value={report.value}>
                    {report.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item sx={{ paddingTop: '0px !important' }} xs={3}>
            <Autocomplete
              filterOptions={x => x}
              filterSelectedOptions
              fullWidth
              getOptionLabel={() => ''}
              isOptionEqualToValue={(option, value) =>
                option.value.sponsorPlanId === value.sponsorPlanId
              }
              multiple
              onChange={(event, value) => {
                setPlans(
                  value.map(option => (option?.value ? option.value : option))
                );
              }}
              options={
                planSearchSigmaReport.data?.data?.plans?.data?.map(plan => ({
                  label: plan.planName,
                  value: plan
                })) || []
              }
              renderInput={params => {
                return (
                  <TextField
                    {...params}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: planSearchSigmaReport.isFetching ? (
                        <CircularProgress
                          color='primary'
                          disableShrink
                          size={16}
                          variant='indeterminate'
                        />
                      ) : (
                        params.InputProps.endAdornment
                      ),
                      startAdornment: (
                        <Box
                          display='flex'
                          sx={{ maxWidth: '70%', overflowX: 'scroll' }}>
                          {params.InputProps.startAdornment}
                        </Box>
                      )
                    }}
                    data-component='autoCompleteInput'
                    onChange={event => setSearchTerm(event.target.value)}
                    ref={params.InputProps.ref}
                    value={searchTerm}
                  />
                );
              }}
              renderOption={(params, option) => (
                <PlanInputSearch
                  key={option.value.sponsorPlanId}
                  parentParams={params}
                  plan={option.value}
                  searchTerm={searchTerm}
                  setSearchTerm={setSearchTerm}
                />
              )}
              renderTags={(tagValue, getTagProps) =>
                tagValue.map((option, index) => {
                  const { key, ...tagProps } = getTagProps({ index });
                  return (
                    <Chip {...tagProps} key={key} label={option.planName} />
                  );
                })
              }
              value={plans}
            />
          </Grid>
          <Grid item sx={{ paddingTop: '0px !important' }} xs={2}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DateRangePicker
                localeText={{ end: 'End Date', start: 'Start Date' }}
                onChange={value => setDateRange(value)}
                slotProps={{
                  actionBar: { actions: [] },
                  field: { sx: { width: '100%' } },
                  shortcuts: {
                    items: shortcutsItems
                  }
                }}
                slots={{ field: SingleInputDateRangeField }}
                value={dateRange}
              />
            </LocalizationProvider>
          </Grid>
          <Grid
            item
            sx={{ alignSelf: 'stretch', paddingTop: '0px !important' }}
            xs={2}>
            <Button
              disabled={!plans.length || !dateRange[0] || !dateRange[1]}
              onClick={() => {
                getSigmaURL();
              }}
              sx={{ height: '100%' }}>
              {generated ? 'Update Report' : 'Generate Report'}
            </Button>
          </Grid>
        </Grid>
        {!!iframeUrl && (
          <Box className={classes.iframeContainer}>
            <iframe
              src={iframeUrl}
              style={{
                border: 'none',
                height: '100%',
                overflow: 'auto',
                width: '100%'
              }}
              title='Placeholder'
            />
          </Box>
        )}
      </Stack>
    </>
  );
};

export default Reports;
