import CardInfoField from '@/components/card-info-field';
import FileUploadTable from '@/components/file-upload-table/FileUploadTable.component';
import LinearLoading from '@/components/linear-loading';
import { CONVERSION_TYPES, DOCUMENT_TYPES } from '@/consts/uploads';
import { useSnackbar } from '@/contexts/SnackBarContext';
import { Conversion } from '@/models/ConversionDTO.model';
import ActionTableForm from '@/routes/plans/plan-detail/PlanActionTableV2/ConversionMainComponents/ActionTableForm.component';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  Stack
} from '@mui/material';
import { formatSsn } from '@vestwell-frontend/helpers';

import React, { useMemo, useState } from 'react';

import ConversionCardButton from './ConversionMainComponents/ConversionCardButton.component';
import ConversionDialog from './ConversionMainComponents/ConversionDialog';
import ConversionDialogHeader from './ConversionMainComponents/ConversionDialogHeader.component';
import {
  useConversionAgGrid,
  useConversionFile,
  useUploadOnboardingAgGridFile,
  rothBasisValidationSchema as validationSchema
} from './hooks';

interface RothCostBasisButtonProps {
  sponsorPlanId: number;
  conversion?: Conversion;
}

const columnDefs = [
  {
    alternates: [
      'social',
      'SS #',
      'ss#',
      'social security number',
      'social_security_number',
      'social security',
      'ssn#',
      'Taxpayer ID (SSN or Fed ID)',
      'socialsecuritynumber',
      'employee ssn',
      'national_identifier',
      'employee social',
      'national identifier',
      'ssn number',
      'ss number',
      'ssn/fein',
      'Social Security Number'
    ],
    cellEditorParams: {
      showExcludeRowButton: true
    },
    editable: true,
    field: 'ssn',
    headerName: 'SSN',
    headerTooltip: 'Enter 9 digit SSN with or without dashes.',
    minWidth: 250,
    required: true,
    suppressMenu: true,
    type: 'ssn',
    valueParser: (value: string) => {
      if (/^(?!(000))\d{3}(?!00)\d{2}(?!0000)\d{4}$/.test(value)) {
        return formatSsn(value);
      }
      return value;
    }
  },
  {
    alternates: ['Established Date'],
    editable: true,
    field: 'rothEstablishedDate',
    headerName: 'Established Date',
    minWidth: 250,
    required: true,
    suppressMenu: true,
    type: 'date'
  },
  {
    editable: true,
    field: 'rothCostBasis',
    headerName: 'Roth Cost Basis',
    minWidth: 250,
    required: true,
    suppressMenu: true,
    type: 'currency'
  }
];

const RothCostBasis: React.FunctionComponent<RothCostBasisButtonProps> = ({
  sponsorPlanId,
  conversion
}: RothCostBasisButtonProps) => {
  const [openPlanActionDialog, setOpenPlanActionDialog] = useState(false);
  const [openBalanceDialog, setOpenBalanceDialog] = useState(false);
  const rothBasisAgGrid = useConversionAgGrid(sponsorPlanId);
  const { showSnackbar } = useSnackbar();
  const EMPTY_ROWS = 10;

  const {
    mutateAsync: uploadRothBasis,
    isLoading,
    isSuccess,
    error
  } = useUploadOnboardingAgGridFile(
    sponsorPlanId as number,
    DOCUMENT_TYPES.ROTH_COST_BASIS
  );

  const { onClicked: editFileClicked, isLoading: isLoadingEditFile } =
    useConversionFile(
      rothBasisAgGrid.handleLoad,
      sponsorPlanId,
      CONVERSION_TYPES.ROTH_COST_BASIS,
      conversion?.documentId,
      columnDefs
    );

  const uniqueParticipantsCount = useMemo(() => {
    const ssnList = new Set();
    rothBasisAgGrid.gridRows.forEach((row: any) => {
      if (row.ssn) ssnList.add(row.ssn);
    });
    return ssnList.size;
  }, [rothBasisAgGrid.gridRows]);

  return (
    <>
      {conversion ? (
        <ConversionCardButton
          conversion={conversion}
          isLoading={isLoadingEditFile}
          onClick={() => {
            setOpenPlanActionDialog(true);
            editFileClicked();
          }}
          sponsorPlanId={sponsorPlanId}
        />
      ) : (
        <LoadingButton
          disabled={!sponsorPlanId || isLoading}
          loading={isLoading}
          onClick={() => {
            setOpenPlanActionDialog(true);
          }}
          startIcon={<FileUploadOutlinedIcon />}>
          Upload
        </LoadingButton>
      )}
      <ConversionDialog
        fullWidth
        maxWidth='lg'
        onClose={() => {
          setOpenPlanActionDialog(false);
        }}
        open={openPlanActionDialog}>
        <DialogTitle>
          <ConversionDialogHeader
            columnDefs={columnDefs}
            onUpload={rothBasisAgGrid.handleLoad}
            title='Roth Cost Basis'
          />
        </DialogTitle>
        <Stack direction='row' justifyContent='flex-end' mb={1}>
          <Stack direction='row' mr={2}>
            <WarningAmberIcon
              color='error'
              sx={{ marginRight: theme => theme.spacing(1) }}
            />
            {rothBasisAgGrid.errorsCount} Errors
          </Stack>
        </Stack>
        <DialogContent>
          <ActionTableForm
            emptyRowsCount={!conversion ? EMPTY_ROWS : undefined}
            initialValues={rothBasisAgGrid.gridRows}
            onChange={rothBasisAgGrid.setGridRows}
            onErrors={rothBasisAgGrid.handleErrors}
            onSubmit={rothBasisAgGrid.handleSubmit}
            ref={rothBasisAgGrid.form}
            validateOnChange
            validateOnMount
            validationSchema={validationSchema(sponsorPlanId)}>
            <FileUploadTable
              columnDefs={columnDefs}
              errors={rothBasisAgGrid.gridErrors}
              onCellChanged={rothBasisAgGrid.handleCellChange}
              onRowsChanged={rothBasisAgGrid.handleRowsChange}
              rowData={rothBasisAgGrid.gridRows}
            />
            {isLoadingEditFile && (
              <Box sx={{ mt: 2 }}>
                <LinearLoading />
              </Box>
            )}
          </ActionTableForm>
        </DialogContent>
        <DialogActions>
          {rothBasisAgGrid.gridRows.length > 0 && (
            <Grid container>
              <Stack
                direction='row'
                divider={<Divider flexItem orientation='vertical' />}
                spacing={1}>
                <CardInfoField
                  fieldName='Number of rows'
                  fieldValue={rothBasisAgGrid.rowCount}
                />
                <CardInfoField
                  fieldName='Unique participants'
                  fieldValue={`${uniqueParticipantsCount}`}
                />
              </Stack>
            </Grid>
          )}
          <Button
            onClick={() => {
              setOpenPlanActionDialog(false);
              rothBasisAgGrid.form.current?.setValues([]);
            }}
            variant='text'>
            Cancel
          </Button>
          {!conversion && (
            <LoadingButton
              disabled={
                rothBasisAgGrid.errorsCount > 0 ||
                rothBasisAgGrid.rowCount === 0
              }
              loading={isLoading}
              onClick={() => {
                setOpenBalanceDialog(true);
              }}
              variant='contained'>
              Submit
            </LoadingButton>
          )}
        </DialogActions>
      </ConversionDialog>
      <Dialog
        onClose={() => setOpenBalanceDialog(false)}
        open={openBalanceDialog}>
        <DialogTitle>Submit & overwrite existing roth cost basis</DialogTitle>
        <DialogContent>
          <DialogContentText
            sx={{ color: theme => theme.palette.text.primary }}>
            Continuing will overwrite any existing converted Roth details for
            participants. Are you sure you want to proceed?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenBalanceDialog(false)}>
            BACK TO EDIT
          </Button>
          <LoadingButton
            loading={isLoading}
            onClick={async () => {
              try {
                await uploadRothBasis({
                  results: rothBasisAgGrid.getDataForUpload({
                    planId: sponsorPlanId
                  })
                });
              } catch (e) {
                if (error) {
                  showSnackbar({
                    message: error?.message ?? 'Error',
                    severity: 'error'
                  });
                }
              } finally {
                setOpenPlanActionDialog(false);
                setOpenBalanceDialog(false);
                if (isSuccess) rothBasisAgGrid.form.current?.setValues([]);
              }
            }}>
            SUBMIT
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default RothCostBasis;
