import DatePicker from '@/components/date-picker/DatePicker';
import { useSnackbar } from '@/contexts/SnackBarContext';
import { useGetSecurityQuery } from '@/hooks/suba/useGetSecurity.hook';
import { DividendAccrualRateAddRequest } from '@/models/suba/dividend-accrual-rates/DividendAccrualRateAddRequest.model';
import { useSecuritiesDetailRouteParams } from '@/routes/suba/securities/securities-detail/hooks/useSecurityDetailRouteParams.hook';
import { doesNotExceedPrecision } from '@/routes/suba/securities/securities-detail/utils';
import DividendAccrualRateService from '@/services/suba/dividend-accrual-rates/DividendAccrualRates.service';
import formatters from '@/utils/Formatters';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormHelperText,
  InputLabel,
  OutlinedInput,
  Stack,
  Typography
} from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { AxiosError } from 'axios';
import dayjs from 'dayjs';
import { Field, Form, Formik } from 'formik';
import * as yup from 'yup';

type AddAccrualRateRecordFormValues = {
  dailyDayCount: number;
  effectiveDate: string;
  rate: number;
};

const validationSchema = yup.object({
  dailyDayCount: yup
    .number()
    .required()
    .moreThan(0, 'Must be positive')
    .integer('Must be integer'),
  effectiveDate: yup
    .string()
    .test('dates-test', 'Invalid date', (value: string) => {
      return dayjs(value, 'YYYY-MM-DD', true).isValid();
    }),
  rate: yup
    .number()
    .required()
    .moreThan(0, 'Must be positive')
    .test(
      'is-decimal',
      'Must not exceed 8 decimal places',
      doesNotExceedPrecision(8)
    )
});

type AddAccrualDetailsDialogProps = {
  onClose: () => void;
  onAdd?: () => void;
};

export const AddAccrualDetailsDialog = ({
  onClose,
  onAdd
}: AddAccrualDetailsDialogProps): JSX.Element => {
  const routeParams = useSecuritiesDetailRouteParams();
  const { showSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const getSecurityQuery = useGetSecurityQuery(routeParams.cusipOrSymbol);

  const AddAccrualRateRecordMutation = useMutation(
    ['DividendAccrualRateService.add'],
    (params: DividendAccrualRateAddRequest) => {
      return DividendAccrualRateService.add(params);
    },
    {
      onError: (error: AxiosError) => {
        showSnackbar({
          message: error.message,
          severity: 'error'
        });
      },
      onSuccess: () => {
        showSnackbar({
          message: 'Dividend Accrual added',
          severity: 'success'
        });

        queryClient.invalidateQueries(['DividendAccrualRateService.search']);
        onClose();
        if (typeof onAdd === 'function') {
          onAdd();
        }
      }
    }
  );

  const handleSubmit = async (values: AddAccrualRateRecordFormValues) => {
    const possibleDuplicates = await DividendAccrualRateService.search({
      cusip: getSecurityQuery.data?.cusip,
      effectiveEndDate: dayjs(values.effectiveDate).format('YYYY-MM-DD'),
      effectiveStartDate: dayjs(values.effectiveDate).format('YYYY-MM-DD')
    });

    if (possibleDuplicates?.results?.length) {
      showSnackbar({
        message: 'A record already exists for the entered effective date',
        severity: 'error'
      });
      return;
    }

    AddAccrualRateRecordMutation.mutate({
      ...values,
      cusip: getSecurityQuery.data?.cusip,
      rate: values.rate.toFixed(8)
    });
  };

  return (
    <Dialog
      fullWidth
      maxWidth='md'
      onClose={() => {
        onClose();
      }}
      open>
      <Formik
        initialValues={{
          dailyDayCount: 0,
          effectiveDate: dayjs(new Date()).format('YYYY-MM-DD'),
          rate: 0
        }}
        onSubmit={(values: AddAccrualRateRecordFormValues) =>
          handleSubmit(values)
        }
        validationSchema={validationSchema}>
        {({ handleSubmit: handleFormSubmit, errors, touched }) => (
          <>
            <DialogTitle
              sx={{
                pb: 1 // there is an unknown rule somewhere setting 8px important padding to the top of DialogContent so we have to compensate here
              }}>
              Add Accrual Rate Record
            </DialogTitle>
            <DialogContent
              sx={{
                p: 0
              }}>
              <Box
                data-testid='dividend-declaration-add-summary'
                sx={{
                  bgcolor: theme => theme.palette.primary.light,
                  px: 3.25,
                  py: 2
                }}>
                <Typography>
                  {getSecurityQuery.data.description || '\u2014'}
                </Typography>
                <Typography
                  sx={{
                    color: theme => theme.palette.grey[600],
                    fontSize: 14
                  }}>
                  {formatters.formatSecurityName(
                    getSecurityQuery.data?.symbol,
                    getSecurityQuery.data?.cusip
                  ) || '\u2014'}
                </Typography>
              </Box>
              <Box sx={{ p: 3 }}>
                <Form data-testid='add-accrual-detail-form'>
                  <Stack
                    alignItems='start'
                    direction='row'
                    spacing={2}
                    sx={{ mt: 3 }}>
                    <FormControl sx={{ alignSelf: 'flex-start' }}>
                      <Field
                        as={DatePicker}
                        autoComplete='off'
                        data-testid='effectiveDate'
                        label='Payment Date'
                        name='effectiveDate'
                        size='small' // FormControl doesn't pass to our DatePicker
                        sx={{ width: 213 }}
                        variant='outlined'
                      />
                    </FormControl>
                    <FormControl
                      error={touched.rate && Boolean(errors.rate)}
                      fullWidth
                      size='small'
                      sx={{ maxWidth: 213 }}>
                      <InputLabel htmlFor='rate-input'>Rate</InputLabel>
                      <Field
                        as={OutlinedInput}
                        autoComplete='off'
                        id='rate-input'
                        label='Accrual Rate'
                        name='rate'
                        type='number'
                      />
                      <FormHelperText>
                        {(touched.rate && errors.rate) || ' '}
                      </FormHelperText>
                    </FormControl>
                    <FormControl
                      error={
                        touched.dailyDayCount && Boolean(errors.dailyDayCount)
                      }
                      fullWidth
                      size='small'
                      sx={{ maxWidth: 213 }}>
                      <InputLabel htmlFor='dailyDayCount-input'>
                        Effective Day Count
                      </InputLabel>
                      <Field
                        as={OutlinedInput}
                        autoComplete='off'
                        id='dailyDayCount-input'
                        label='Effective Day Count'
                        name='dailyDayCount'
                        type='number'
                      />
                      <FormHelperText>
                        {(touched.dailyDayCount && errors.dailyDayCount) || ' '}
                      </FormHelperText>
                    </FormControl>
                  </Stack>
                </Form>
              </Box>
            </DialogContent>
            <Divider />
            <DialogActions
              sx={{
                px: 3,
                py: 2.25
              }}>
              <Button
                data-testid='cancel-button'
                disabled={AddAccrualRateRecordMutation.isLoading}
                onClick={() => {
                  onClose();
                }}>
                Cancel
              </Button>

              <LoadingButton
                data-testid='add-button'
                loading={AddAccrualRateRecordMutation.isLoading}
                onClick={() => handleFormSubmit()}
                variant='contained'>
                Add
              </LoadingButton>
            </DialogActions>
          </>
        )}
      </Formik>
    </Dialog>
  );
};
