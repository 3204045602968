import {
  Card,
  CardContent,
  CardHeader,
  CardMainActionButtonProps,
  CardPlaceholder
} from '@/components/card/Card.component';
import {
  DataTable,
  DataTableBadgeCell,
  DataTableMenuCell,
  DataTableProps
} from '@/components/data-table/DataTable.component';
import { DatePicker } from '@/components/date-picker';
import { useSearchDividendDeclarationsQuery } from '@/hooks/suba/useSearchDividendDeclarations.hook';
import { displayDividendTypeMap } from '@/models/suba/dividend-declarations/DisplayDividendTypeMap.model';
import { DividendDeclarationDto } from '@/models/suba/dividend-declarations/DividendDeclarationDto.model';
import { dividendStatusColorMap } from '@/models/suba/dividend-declarations/DividendStatusColorMap.model';
import { FeatureLevelPermissions } from '@/models/UserPermissions.model';
import { determineStatusAndActionsByActivity } from '@/routes/suba/securities/securities-detail/utils';
import { DividendDeclarationsService } from '@/services/suba/dividend-declarations/DividendDeclarations.service';
import { userService } from '@/services/User.service';
import DOMInteraction from '@/utils/DOMInteraction';
import formatters from '@/utils/Formatters';
import { json2csvParser } from '@/utils/Json2csvParser';
import {
  Search as SearchIcon,
  StackedBarChartOutlined as StackedBarChartOutlinedIcon
} from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import {
  Box,
  Button,
  FormControl,
  IconButton,
  MenuItem,
  Stack,
  Typography
} from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';

import dayjs from 'dayjs';
import { Field, Form, Formik } from 'formik';
import { FC, useCallback, useMemo, useState } from 'react';

import { AccrualDetailsFiltersFormValues } from './AccrualDetailsCard.component';
import { AddDividendDeclarationDialog } from './dialogs/AddDividendDeclarationDialog.component';
import { CalculateDividendDeclarationDialog } from './dialogs/CalculateDividendDeclarationDialog.component';
import { DeleteDividendDeclarationDialog } from './dialogs/DeleteDividendDeclarationDialog.component';
import { EditDividendDeclarationDialog } from './dialogs/EditDividendDeclarationDialog.component';
import { RecalculateDividendDeclarationDialog } from './dialogs/RecalculateDividendDeclarationDialog.component';
import { ReverseDividendDeclarationDialog } from './dialogs/ReverseDividendDeclarationDialog.component';

export type FiltersFormValues = {
  paymentEndDate: string;
  paymentStartDate: string;
};

type SearchParams = Parameters<typeof useSearchDividendDeclarationsQuery>[0];

const exportDeclarations = async (params: SearchParams) => {
  const data = await DividendDeclarationsService.search({
    ...params,
    page: 1,
    pageSize: 0 // fetch all data
  });

  const csv = await json2csvParser(
    data.results.map(item => ({
      // format raw data to match visual presentation
      'Distribution Type': displayDividendTypeMap[item.distributionType],
      'Payment Date': dayjs(item.paymentDate).format('MM/DD/YY'),
      'Record Date': item.recordDate
        ? dayjs(item.recordDate).format('MM/DD/YY')
        : undefined,
      'Reinvestment Date': item.reinvestDate
        ? dayjs(item.reinvestDate).format('MM/DD/YY')
        : undefined,
      'Reinvestment Price': item.reinvestPrice
    }))
  );

  let filename = 'dividend-declarations.csv';

  const paymentStartDate = dayjs(params.paymentStartDate).format('MM-DD-YYYY');
  const paymentEndDate = dayjs(params.paymentEndDate).format('MM-DD-YYYY');

  if (params.paymentStartDate && params.paymentEndDate) {
    filename = `dividend-declarations-${paymentStartDate}-to-${paymentEndDate}.csv`;
  } else if (params.paymentStartDate) {
    filename = `dividend-declarations-${paymentStartDate}.csv`;
  } else if (params.paymentEndDate) {
    filename = `dividend-declarations-${paymentEndDate}.csv`;
  }

  DOMInteraction.triggerDownload(csv, filename);
};

export type DividendDeclarationsCardProps = {
  searchParams: SearchParams;
  onAccrualDetailsClick: (
    filtersFormValues: AccrualDetailsFiltersFormValues
  ) => void;
  onFiltersFormSubmit: (values: FiltersFormValues) => void;
  onPageChange: DataTableProps['onPageChanged'];
  onPageSizeChange: DataTableProps['onPageSizeChanged'];
  setDividendDeclarationForDetailView: React.Dispatch<
    React.SetStateAction<DividendDeclarationDto | undefined>
  >;
};

export const DividendDeclarationsCard: FC<
  DividendDeclarationsCardProps
> = props => {
  // context

  const queryClient = useQueryClient();

  // state

  const [
    openAddDividendDeclarationDialog,
    setOpenAddDividendDeclarationDialog
  ] = useState(false);
  const [
    openEditDividendDeclarationDialog,
    setOpenEditDividendDeclarationDialog
  ] = useState(false);
  const [
    openDeleteDividendDeclarationDialog,
    setOpenDeleteDividendDeclarationDialog
  ] = useState(false);
  const [openReverseDialog, setOpenReverseDialog] = useState(false);
  const [openCalculateDialog, setOpenCalculateDialog] = useState(false);
  const [openRecalculateDialog, setOpenRecalculateDialog] = useState(false);
  const [selectedDeclaration, setSelectedDeclaration] =
    useState<DividendDeclarationDto>();

  // api

  const dividendDeclarationsSearchQuery = useSearchDividendDeclarationsQuery(
    props.searchParams
  );

  // callbacks

  const invalidateSearchQuery = useCallback(() => {
    // a step function is called through the API, so we don't know if it was
    // successful after requesting, so we invalidate cache after 10 seconds to give enough time
    // we will do a heavy invalidate of all queries due to multiple levels of queries populating the div pages
    setTimeout(() => {
      queryClient.invalidateQueries();
    }, 10000);
  }, []);

  const handleAccrualDetailClick = useCallback<
    (rowData: DividendDeclarationDto) => void
  >(rowData => {
    props.onAccrualDetailsClick({
      effectiveEndDate: rowData.paymentDate,
      effectiveStartDate: dayjs(rowData.paymentDate)
        .subtract(30, 'days')
        .format('YYYY-MM-DD')
    });
  }, []);

  // memos

  const cardHeaderActionButtons = useMemo<CardMainActionButtonProps[]>(() => {
    const hasWritePermissions = userService.hasPermission(
      FeatureLevelPermissions.WRITE_SUBA_DIVIDENDS
    );

    return [
      {
        'data-testid': 'declarations-export-csv-button',
        disabled:
          dividendDeclarationsSearchQuery.isFetching ||
          !dividendDeclarationsSearchQuery.data?.results?.length,
        label: 'Export CSV',
        onClick: () => exportDeclarations(props.searchParams)
      },
      hasWritePermissions && {
        'data-testid': 'declarations-add-button',
        label: (
          <Stack alignItems='center' direction='row' spacing={0.5}>
            <Typography sx={{ fontSize: 14, fontWeight: 500 }}>Add</Typography>
            <Stack sx={{ fontSize: 22 }}>
              <AddIcon fontSize='inherit' />
            </Stack>
          </Stack>
        ),
        onClick: () => setOpenAddDividendDeclarationDialog(true)
      }
    ].filter(Boolean);
  }, [userService.hasPermission(FeatureLevelPermissions.WRITE_SUBA_DIVIDENDS)]);

  // consts

  const hasReadAccountsPermissions = userService.hasPermission(
    FeatureLevelPermissions.READ_SUBA_ACCOUNTS
  );

  const hasWritePermissions = userService.hasPermission(
    FeatureLevelPermissions.WRITE_SUBA_DIVIDENDS
  );

  return (
    <>
      {openAddDividendDeclarationDialog && (
        <AddDividendDeclarationDialog
          onClose={() => setOpenAddDividendDeclarationDialog(false)}
        />
      )}
      {openEditDividendDeclarationDialog && (
        <EditDividendDeclarationDialog
          declaration={selectedDeclaration}
          onClose={() => setOpenEditDividendDeclarationDialog(false)}
        />
      )}
      {openDeleteDividendDeclarationDialog && (
        <DeleteDividendDeclarationDialog
          declaration={selectedDeclaration}
          onClose={() => setOpenDeleteDividendDeclarationDialog(false)}
        />
      )}
      {selectedDeclaration && (
        <CalculateDividendDeclarationDialog
          cusip={selectedDeclaration.cusip}
          declarationId={selectedDeclaration.id}
          distributionType={selectedDeclaration.distributionType}
          dividendType={selectedDeclaration.dividendType}
          onCalculate={() => invalidateSearchQuery()}
          onClose={() => setOpenCalculateDialog(false)}
          open={openCalculateDialog}
          reinvestDate={selectedDeclaration.reinvestDate}
        />
      )}
      {selectedDeclaration && (
        <ReverseDividendDeclarationDialog
          cusip={selectedDeclaration.cusip}
          declarationId={selectedDeclaration.id}
          distributionType={selectedDeclaration.distributionType}
          dividendType={selectedDeclaration.dividendType}
          onClose={() => setOpenReverseDialog(false)}
          onReverse={() => invalidateSearchQuery()}
          open={openReverseDialog}
          reinvestDate={selectedDeclaration.reinvestDate}
        />
      )}
      {selectedDeclaration && (
        <RecalculateDividendDeclarationDialog
          cusip={selectedDeclaration.cusip}
          declarationId={selectedDeclaration.id}
          distributionType={selectedDeclaration.distributionType}
          dividendType={selectedDeclaration.dividendType}
          onClose={() => setOpenRecalculateDialog(false)}
          onRecalculate={() => invalidateSearchQuery()}
          open={openRecalculateDialog}
          reinvestDate={selectedDeclaration.reinvestDate}
        />
      )}
      <Card data-testid='declarations-card'>
        <CardHeader
          actionButtonsProps={cardHeaderActionButtons}
          loading={
            !!dividendDeclarationsSearchQuery.data?.results?.length &&
            dividendDeclarationsSearchQuery.isFetching
          }
          title='Dividend Declarations'
        />
        <CardContent>
          <Formik<FiltersFormValues>
            initialValues={{
              paymentEndDate: props.searchParams.paymentEndDate || '',
              paymentStartDate: props.searchParams.paymentStartDate || ''
            }}
            onSubmit={props.onFiltersFormSubmit}>
            <Form data-testid='declarations-search-form'>
              <Stack direction={{ md: 'row', xs: 'column' }} spacing={2}>
                <FormControl size='small' variant='outlined'>
                  <Field
                    as={DatePicker}
                    autoComplete='off'
                    data-testid='declarations-payment-start-date-input'
                    disableFuture
                    label='Payment Date Start'
                    name='paymentStartDate'
                  />
                </FormControl>
                <FormControl size='small' variant='outlined'>
                  <Field
                    as={DatePicker}
                    autoComplete='off'
                    data-testid='declarations-payment-end-date-input'
                    disableFuture
                    label='Payment Date End'
                    name='paymentEndDate'
                  />
                </FormControl>
                <Button
                  data-testid='declarations-apply-button'
                  sx={{ height: 40 }}
                  type='submit'
                  variant='outlined'>
                  Apply
                </Button>
              </Stack>
            </Form>
          </Formik>
        </CardContent>
        <CardContent
          disablePadding
          overlayLoading={dividendDeclarationsSearchQuery.isInitialLoading}>
          <DataTable
            columnDefs={[
              {
                cellRenderer: (cellData: { data: DividendDeclarationDto }) => {
                  const actions = determineStatusAndActionsByActivity(
                    cellData.data
                  );
                  const isAfterReinvest =
                    cellData.data.reinvestDate &&
                    dayjs().format('YYYY-MM-DD') > cellData.data.reinvestDate;

                  const canDelete =
                    cellData.data.dividendActivities === undefined ||
                    cellData.data.dividendActivities.length === 0;
                  const canReverse =
                    hasWritePermissions &&
                    isAfterReinvest &&
                    actions.canReverse;
                  const canCalculate =
                    hasWritePermissions &&
                    isAfterReinvest &&
                    actions.canCalculate;
                  const canRecalculate =
                    hasWritePermissions &&
                    isAfterReinvest &&
                    actions.canRecalculate;
                  const canViewParentAccounts =
                    hasReadAccountsPermissions &&
                    isAfterReinvest &&
                    actions.canViewParentAccounts;
                  return (
                    <DataTableMenuCell>
                      <MenuItem
                        onClick={() => {
                          setSelectedDeclaration(cellData.data);
                          setOpenEditDividendDeclarationDialog(true);
                        }}>
                        Edit
                      </MenuItem>
                      {canDelete && (
                        <MenuItem
                          onClick={() => {
                            setSelectedDeclaration(cellData.data);
                            setOpenDeleteDividendDeclarationDialog(true);
                          }}>
                          Delete
                        </MenuItem>
                      )}
                      {canReverse && (
                        <MenuItem
                          onClick={() => {
                            setSelectedDeclaration(cellData.data);
                            setOpenReverseDialog(true);
                          }}>
                          Reverse Dividend Declaration
                        </MenuItem>
                      )}
                      {canCalculate && (
                        <MenuItem
                          onClick={() => {
                            setSelectedDeclaration(cellData.data);
                            setOpenCalculateDialog(true);
                          }}>
                          Calculate Dividend Declaration
                        </MenuItem>
                      )}
                      {canRecalculate && (
                        <MenuItem
                          onClick={() => {
                            setSelectedDeclaration(cellData.data);
                            setOpenRecalculateDialog(true);
                          }}>
                          Recalculate Dividend Declaration
                        </MenuItem>
                      )}
                      {canViewParentAccounts && (
                        <MenuItem
                          onClick={() => {
                            props.setDividendDeclarationForDetailView(
                              cellData.data
                            );
                          }}>
                          View Associated Parent Accounts
                        </MenuItem>
                      )}
                    </DataTableMenuCell>
                  );
                },
                cellStyle: {
                  paddingLeft: 16,
                  paddingRight: 16
                },
                field: 'cusip',
                headerName: '',
                maxWidth: 75,
                minWidth: 75
              },
              {
                autoHeight: true,
                cellRenderer: (cellData: { data: DividendDeclarationDto }) => {
                  // fetch friendly display name for status
                  const activity = determineStatusAndActionsByActivity(
                    cellData.data
                  );
                  return (
                    <DataTableBadgeCell
                      color={dividendStatusColorMap[activity.overallStatus]}>
                      {formatters.displayCase(activity.overallStatus)}
                    </DataTableBadgeCell>
                  );
                },
                field: 'dividendMaster.status',
                headerName: 'Status',
                headerTooltip: 'Dividend Master Status',
                suppressMenu: true
              },
              {
                field: 'recordDate',
                headerTooltip: 'Record Date',
                suppressMenu: true,
                valueFormatter: ({ value }) =>
                  value === null
                    ? ''
                    : formatters.formatFromIsoDateCustom(value, 'MM/DD/YY')
              },
              {
                field: 'paymentDate',
                headerTooltip: 'Payment Date',
                suppressMenu: true,
                valueFormatter: ({ value }) =>
                  formatters.formatFromIsoDateCustom(value, 'MM/DD/YY')
              },
              {
                field: 'reinvestDate',
                headerTooltip: 'Reinvest Date',
                suppressMenu: true,
                valueFormatter: ({ value }) =>
                  value === null
                    ? ''
                    : formatters.formatFromIsoDateCustom(value, 'MM/DD/YY')
              },
              {
                field: 'reinvestPrice',
                headerTooltip: 'Reinvest Price',
                suppressMenu: true,
                type: 'numericColumn',
                valueFormatter: ({ value }) =>
                  value === null ? '' : formatters.formatDollars(value, 2)
              },
              {
                cellRenderer: ({
                  data: rowData
                }: {
                  data: DividendDeclarationDto;
                }) => {
                  if (rowData.dividendType === 'Accrual') {
                    return (
                      <Box color={theme => theme.palette.grey['500']}>
                        <IconButton
                          aria-label='See Accrual Details'
                          color='inherit'
                          data-testid={`declarations-accrual-details-button-${rowData.paymentDate}`}
                          onClick={() => handleAccrualDetailClick(rowData)}>
                          <StackedBarChartOutlinedIcon />
                        </IconButton>
                      </Box>
                    );
                  }

                  return rowData.dividendRate || null;
                },
                field: 'dividendRate',
                headerName: 'Rate',
                suppressMenu: true,
                type: 'numericColumn'
              },
              {
                field: 'distributionType',
                headerName: 'Type',
                suppressMenu: true,
                valueFormatter: ({
                  value
                }: {
                  value: keyof typeof displayDividendTypeMap;
                }) => displayDividendTypeMap[value]
              }
            ]}
            columnSizing='auto'
            emptyPlaceholderComponent={
              <Stack
                alignItems='center'
                data-testid='no-declarations-message'
                justifyContent='center'
                sx={{ height: '100%' }}>
                <CardPlaceholder
                  icon={<SearchIcon fontSize='inherit' />}
                  subtitle='No declarations found'
                />
              </Stack>
            }
            onPageChanged={newPage => props.onPageChange(newPage)}
            onPageSizeChanged={newPageSize =>
              props.onPageSizeChange(newPageSize)
            }
            page={props.searchParams.page}
            pageSize={props.searchParams.pageSize}
            pagination
            paginationSource='server'
            paginationTotal={
              dividendDeclarationsSearchQuery.data?.pagination?.total
            }
            rowData={dividendDeclarationsSearchQuery.data?.results}
          />
        </CardContent>
      </Card>
    </>
  );
};
