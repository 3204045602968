import Card, {
  CardContent,
  CardHeader,
  CardPlaceholder
} from '@/components/card/Card.component';
import DataTable, {
  DataTableStackCell
} from '@/components/data-table/DataTable.component';
import { JSONViewer } from '@/components/json-viewer/JSONViewer.component';
import TextStack, {
  TextLabel,
  TextStackItem,
  TextValue
} from '@/components/text-stack/TextStack.component';
import { useGetSecurityQuery } from '@/hooks/suba/useGetSecurity.hook';
import { SecurityDto } from '@/models/suba/security/SecurityDTO.model';
import { useSecuritiesDetailRouteParams } from '@/routes/suba/securities/securities-detail/hooks/useSecurityDetailRouteParams.hook';
import SecurityService from '@/services/suba/security/Security.service';
import formatters from '@/utils/Formatters';
import { Search as SearchIcon } from '@mui/icons-material';
import { Box, Divider, Grid, Stack, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';

import { FC } from 'react';

enum SubTransferAgentMethod {
  'B' = 'B',
  'P' = 'P',
  'S' = 'S',
  'T' = 'T'
}

const displaySubtaMethod = {
  [SubTransferAgentMethod.B]: 'Lesser of Asset or Participant',
  [SubTransferAgentMethod.P]: 'Per Participant',
  [SubTransferAgentMethod.S]: 'Asset Based',
  [SubTransferAgentMethod.T]: 'Asset Based and Per Participant'
};

export const GeneralTab: FC = () => {
  const routeParams = useSecuritiesDetailRouteParams();

  const getSecurityQuery = useGetSecurityQuery(routeParams.cusipOrSymbol);

  const mstarFundId =
    getSecurityQuery.data?.standardFundInformation?.mstarFundId;

  const { data: relatedFunds, isFetching: isFetchingRelatedFunds } = useQuery(
    ['relatedFunds', mstarFundId],
    () =>
      SecurityService.search({
        mstarFundId: mstarFundId || undefined // avoid null type error (impossible at runtime)
      }),
    {
      enabled: mstarFundId !== null && mstarFundId !== undefined,
      keepPreviousData: true,
      staleTime: Infinity
    }
  );

  const custodianFundInfo = getSecurityQuery.data?.custodianFundInformation;
  const standardFundInfo = getSecurityQuery.data?.standardFundInformation;
  const subtaMethod = custodianFundInfo?.subtaMethod as SubTransferAgentMethod;

  return (
    <>
      <Stack spacing={4}>
        <Card>
          <CardHeader title='General Details' />
          <CardContent disablePadding>
            <Grid container spacing={2}>
              <Grid item xs={true}>
                <Box padding={[3, 2]}>
                  <Stack spacing={4}>
                    <div>
                      <Typography
                        display='inline-block'
                        mb={1}
                        variant='overline'>
                        Asset Class
                      </Typography>
                      <TextStack direction='column' textLabelColumnWidth='50%'>
                        <TextStackItem>
                          <TextLabel>Broad Category Group ID</TextLabel>
                          <TextValue data-testid='broad-category-group-id'>
                            {standardFundInfo?.mstarBroadCategoryGroup}
                          </TextValue>
                        </TextStackItem>
                        <TextStackItem>
                          <TextLabel>Category ID</TextLabel>
                          <TextValue data-testid='category-id'>
                            {standardFundInfo?.mstarCategoryCode}
                          </TextValue>
                        </TextStackItem>
                        <TextStackItem>
                          <TextLabel>Primary Benchmark ID</TextLabel>
                          <TextValue data-testid='primary-benchmark-id'>
                            {standardFundInfo?.mstarPrimaryIndexId}
                          </TextValue>
                        </TextStackItem>
                      </TextStack>
                    </div>
                    <div>
                      <Typography
                        display='inline-block'
                        mb={1}
                        variant='overline'>
                        Matrix Fund Settings
                      </Typography>
                      <TextStack direction='column' textLabelColumnWidth='50%'>
                        <TextStackItem>
                          <TextLabel>
                            Qualified Plan Minimum Purchase Amount
                          </TextLabel>
                          <TextValue data-testid='qualified-plan-minimum-purchase-amount'>
                            {custodianFundInfo?.custodianQPMinPurchase !==
                              null &&
                              formatters.formatDollars(
                                custodianFundInfo?.custodianQPMinPurchase
                              )}
                          </TextValue>
                        </TextStackItem>
                        <TextStackItem>
                          <TextLabel>12b1</TextLabel>
                          <TextValue data-testid='12b1'>
                            {custodianFundInfo?.custodian12B1Fee}
                          </TextValue>
                        </TextStackItem>
                        <TextStackItem>
                          <TextLabel>SubTA Fee - Asset Based</TextLabel>
                          <TextValue data-testid='subta-fee-asset-based'>
                            {(subtaMethod === SubTransferAgentMethod.S ||
                              subtaMethod === SubTransferAgentMethod.T) &&
                              custodianFundInfo?.subtaFee}
                          </TextValue>
                        </TextStackItem>
                        <TextStackItem>
                          <TextLabel>SubTA Fee - Per Participant</TextLabel>
                          <TextValue data-testid='subta-fee-per-participant'>
                            {(subtaMethod === SubTransferAgentMethod.S ||
                              subtaMethod === SubTransferAgentMethod.T) &&
                              custodianFundInfo?.subtaPerParticipant}
                          </TextValue>
                        </TextStackItem>
                        <TextStackItem>
                          <TextLabel>SubTA Method</TextLabel>
                          <TextValue data-testid='subta-method'>
                            {displaySubtaMethod[subtaMethod]}
                          </TextValue>
                        </TextStackItem>
                        <TextStackItem>
                          <TextLabel>BD Service Fee</TextLabel>
                          <TextValue data-testid='bd-service-fee'>
                            {custodianFundInfo?.custodianBDServiceFee}
                          </TextValue>
                        </TextStackItem>
                        <TextStackItem>
                          <TextLabel>Notes</TextLabel>
                          <TextValue data-testid='notes'>
                            {custodianFundInfo?.notes}
                          </TextValue>
                        </TextStackItem>
                      </TextStack>
                    </div>
                  </Stack>
                </Box>
              </Grid>
              <Grid display='flex' item justifyContent='flex-end' xs='auto'>
                <Divider orientation='vertical' />
              </Grid>
              <Grid item xs={true}>
                <Box padding={[3, 2]}>
                  <Stack spacing={4}>
                    <div>
                      <Typography
                        display='inline-block'
                        mb={1}
                        variant='overline'>
                        Fees and Expenses
                      </Typography>
                      <TextStack direction='column' textLabelColumnWidth='50%'>
                        <TextStackItem>
                          <TextLabel>Share Class</TextLabel>
                          <TextValue data-testid='share-class-expense'>
                            {standardFundInfo?.shareClass}
                          </TextValue>
                        </TextStackItem>
                        <TextStackItem>
                          <TextLabel>Net Expense Ratio</TextLabel>
                          <TextValue data-testid='net-expense-ratio-expense'>
                            {standardFundInfo?.netExpenseRatio}
                          </TextValue>
                        </TextStackItem>
                        <TextStackItem>
                          <TextLabel>Gross Expense Ratio</TextLabel>
                          <TextValue data-testid='gross-expense-ratio'>
                            {standardFundInfo?.grossExpenseRatio}
                          </TextValue>
                        </TextStackItem>
                        <TextStackItem>
                          <TextLabel>12b1 Fee</TextLabel>
                          <TextValue data-testid='12b1-fee'>
                            {standardFundInfo?._12B1Fee}
                          </TextValue>
                        </TextStackItem>
                        <TextStackItem>
                          <TextLabel>Management Fee</TextLabel>
                          <TextValue>
                            {standardFundInfo?.managementFee}
                          </TextValue>
                        </TextStackItem>
                        <TextStackItem>
                          <TextLabel>Max Initial Sales Charge</TextLabel>
                          <TextValue data-testid='max-initial-sales-charge'>
                            {standardFundInfo?.maxInitialSalesCharge}
                          </TextValue>
                        </TextStackItem>
                        <TextStackItem>
                          <TextLabel>Max Deferred Sales Charge</TextLabel>
                          <TextValue data-testid='max-deferred-sales-charge'>
                            {standardFundInfo?.maxDeferredSalesCharge}
                          </TextValue>
                        </TextStackItem>
                      </TextStack>
                    </div>
                    <div>
                      <Typography
                        display='inline-block'
                        mb={1}
                        variant='overline'>
                        Redemption Fees
                      </Typography>
                      {standardFundInfo?.redemptionFees ? (
                        <Box
                          bgcolor={theme => theme.palette.grey[50]}
                          padding={2}>
                          <JSONViewer json={standardFundInfo?.redemptionFees} />
                        </Box>
                      ) : (
                        <Typography
                          sx={{
                            color: theme => theme.palette.grey[400]
                          }}>
                          {'\u2014'}
                        </Typography>
                      )}
                    </div>
                  </Stack>
                </Box>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        <Card>
          <CardHeader title='Related Funds' />
          <CardContent disablePadding loading={isFetchingRelatedFunds}>
            <DataTable
              columnDefs={[
                {
                  autoHeight: true,
                  cellRenderer: ({ data }: { data: SecurityDto }) => (
                    <DataTableStackCell
                      primary={`${data.symbol} | ${data.cusip}`}
                      primaryLinkProps={{
                        to: `/ops/securities/${data.cusip}`
                      }}
                      secondary={data.description}
                    />
                  ),
                  field: 'symbol',
                  headerName: 'Ticker'
                },
                {
                  field: 'standardFundInformation.netExpenseRatio',
                  headerName: 'Net Expense Ratio',
                  type: 'numericColumn'
                },
                {
                  field: 'custodianFundInformation._12b1',
                  headerName: '12b1',
                  type: 'numericColumn'
                },
                {
                  field: 'custodianFundInformation.subtaFee',
                  headerName: 'SubTA Fee',
                  type: 'numericColumn'
                },
                {
                  field: 'standardFundInformation.shareClass',
                  headerName: 'Share Class'
                }
              ]}
              data-testid='related-funds-table'
              emptyPlaceholderComponent={
                <Stack
                  alignItems='center'
                  data-testid='no-related-funds-message'
                  justifyContent='center'
                  sx={{ height: '100%' }}>
                  <CardPlaceholder
                    icon={<SearchIcon fontSize='inherit' />}
                    subtitle='No related funds found'
                  />
                </Stack>
              }
              rowData={relatedFunds?.filter(
                fund => fund.cusip !== getSecurityQuery.data?.cusip
              )}
              sort={[
                {
                  colId: 'standardFundInformation.netExpenseRatio',
                  sort: 'desc'
                }
              ]}
            />
          </CardContent>
        </Card>
      </Stack>
    </>
  );
};
