import * as yup from 'yup';

import { alphaNumericComaSeparated } from './commonYupValidations';

const additionalDataSchema = yup.object({
  additionalData: yup.object({
    hce: yup.array().of(
      yup.object({
        '1099_codes': alphaNumericComaSeparated.required(
          '1099 Code is required'
        ),
        fundingSource: yup.string().required('Source is required'),
        id: yup.number().required('Name is required'),
        lostGain: yup
          .number()
          .transform(value => (Number.isNaN(value) ? null : value))
          .nullable()
          .required('Lost Gain is required'),
        value: yup
          .number()
          .transform(value => (Number.isNaN(value) ? null : value))
          .nullable()
          .required('Value is required')
      })
    )
  })
});

const additionalDataSchemaWithEarningsValidation = additionalDataSchema.concat(
  yup.object({
    additionalData: yup.object({
      hce: yup.array().of(
        yup.object({
          earnings: yup
            .number()
            .transform(value => (Number.isNaN(value) ? null : value))
            .nullable()
            .required('Earnings are required')
        })
      )
    })
  })
);

export const annualTestExecutionValidationSchema = yup.object({
  result402g: additionalDataSchemaWithEarningsValidation,
  result415: additionalDataSchemaWithEarningsValidation,
  resultACP: additionalDataSchema,
  resultADP: additionalDataSchema
});
