import Card, {
  CardContent,
  CardHeader,
  CardPlaceholder
} from '@/components/card';
import {
  DataTable,
  DataTableProps
} from '@/components/data-table/DataTable.component';
import DatePicker from '@/components/date-picker/DatePicker';
import { useUrlState } from '@/hooks/useUrlState.hook';
import { ParentToCustodianCashBalanceDTO } from '@/models/suba/parent-to-custodian-cash-balance/ParentToCustodianCashBalanceDTO.model';
import { ParentToCustodianCashBalanceRequest } from '@/models/suba/parent-to-custodian-cash-balance/ParentToCustodianCashBalanceRequest.model';
import ParentAccountService from '@/services/suba/accounts/ParentAccount.service';
import formatters from '@/utils/Formatters';
import SearchIcon from '@mui/icons-material/Search';
import { Box, Button, FormControl, Stack } from '@mui/material';
import { useQuery } from '@tanstack/react-query';

import { ColDef } from 'ag-grid-community';
import dayjs from 'dayjs';
import { Decimal } from 'decimal.js';
import { Field, Form, Formik } from 'formik';
import { FC, useCallback, useMemo } from 'react';

type FiltersFormValues = {
  endDate: string;
  startDate: string;
};

export type ParentToCustodianCashBalancesTabUrlState = Pick<
  ParentToCustodianCashBalanceRequest,
  'page' | 'pageSize' | keyof FiltersFormValues
>;

type ParentToCustodianCashBalancesTabProps = {
  parentAccountId: string;
  exceptionDate: string;
};

export const ParentToCustodianCashBalancesTab: FC<
  ParentToCustodianCashBalancesTabProps
> = props => {
  // state

  const [urlState, setUrlState] =
    useUrlState<ParentToCustodianCashBalancesTabUrlState>(
      {
        endDate: dayjs(props.exceptionDate).add(7, 'days').format('YYYY-MM-DD'),
        page: 1,
        pageSize: 25,
        startDate: dayjs(props.exceptionDate)
          .subtract(7, 'days')
          .format('YYYY-MM-DD')
      },
      {
        parsedValueTypes: {
          endDate: 'string',
          page: 'number',
          pageSize: 'number',
          startDate: 'string'
        }
      }
    );

  // api

  const getParentToCustodianCashBalancesQuery = useQuery(
    ['ParentAccountService.getParentToCustodianCashBalances', urlState],
    () => {
      return ParentAccountService.getParentToCustodianCashBalances({
        ...urlState,
        parentAccountId: props.parentAccountId
      });
    }
  );

  // memos

  const parentToCustodianBalancesDataColumnDefs = useMemo<ColDef[]>(
    () => [
      {
        field: 'effectiveDate',
        headerName: 'Effective Date',
        minWidth: 200,
        valueFormatter: ({ value }: { value: string }) =>
          formatters.formatFromIsoDateCustom(value, 'MM/DD/YYYY')
      },
      {
        field: 'custodianBalance',
        headerName: 'Custodian Balance',
        minWidth: 150,
        type: 'numericColumn',
        valueFormatter: ({ value }: { value: string }) =>
          formatters.formatDollars(value)
      },
      {
        field: 'tradeDateBalance',
        headerName: 'Trade Date Balance',
        minWidth: 150,
        type: 'numericColumn',
        valueFormatter: ({ value }: { value: string }) =>
          formatters.formatDollars(value)
      },
      {
        field: 'settlementDateBalance',
        headerName: 'Settlement Date Balance',
        minWidth: 150,
        type: 'numericColumn',
        valueFormatter: ({ value }: { value: string }) =>
          formatters.formatDollars(value)
      },
      {
        headerName: 'Trade Date Difference',
        minWidth: 150,
        type: 'numericColumn',
        valueFormatter: ({
          data: row
        }: {
          data: ParentToCustodianCashBalanceDTO;
        }) =>
          formatters.formatDollars(
            Decimal.sub(
              new Decimal(row.custodianBalance),
              new Decimal(row.tradeDateBalance)
            ).toFixed(3),
            3
          )
      },
      {
        headerName: 'Settlement Date Difference',
        minWidth: 150,
        type: 'numericColumn',
        valueFormatter: ({
          data: row
        }: {
          data: ParentToCustodianCashBalanceDTO;
        }) =>
          formatters.formatDollars(
            Decimal.sub(
              new Decimal(row.custodianBalance),
              new Decimal(row.settlementDateBalance)
            ).toFixed(3),
            3
          )
      }
    ],
    []
  );

  const handleFiltersFormSubmit = useCallback((values: FiltersFormValues) => {
    setUrlState(prevUrlState => ({
      ...prevUrlState,
      ...values,
      endDate: values.endDate || null,
      startDate: values.startDate || null
    }));
  }, []);

  const handlePageChanged = useCallback<DataTableProps['onPageChanged']>(
    newPage =>
      setUrlState(prevUrlState => ({
        ...prevUrlState,
        page: newPage
      })),
    []
  );

  const handlePageSizeChanged = useCallback<
    DataTableProps['onPageSizeChanged']
  >(
    newPageSize =>
      setUrlState(prevUrlState => ({
        ...prevUrlState,
        page: 1,
        pageSize: newPageSize
      })),
    []
  );

  return (
    <Box>
      <Card>
        <CardHeader
          justifyContent='left'
          loading={
            !!getParentToCustodianCashBalancesQuery.data?.results?.length &&
            getParentToCustodianCashBalancesQuery.isFetching
          }>
          <Formik<FiltersFormValues>
            initialValues={{
              endDate: urlState.endDate || '',
              startDate: urlState.startDate || ''
            }}
            onSubmit={handleFiltersFormSubmit}>
            <Form data-testid='parent-to-custodian-cash-balances-update-date-range'>
              <Stack direction='row' spacing={2}>
                <FormControl size='small' variant='outlined'>
                  <Field
                    as={DatePicker}
                    data-testid='parent-to-custodian-cash-balances-start-date-picker'
                    label='Start Date'
                    name='startDate'
                  />
                </FormControl>
                <FormControl size='small' variant='outlined'>
                  <Field
                    as={DatePicker}
                    data-testid='parent-to-custodian-cash-balances-end-date-picker'
                    label='End Date'
                    name='endDate'
                  />
                </FormControl>
                <FormControl>
                  <Button
                    data-testid='parent-to-custodian-cash-balance-update-date-range-button'
                    type='submit'
                    variant='outlined'>
                    Apply
                  </Button>
                </FormControl>
              </Stack>
            </Form>
          </Formik>
        </CardHeader>
        <CardContent
          disablePadding
          loading={getParentToCustodianCashBalancesQuery.isInitialLoading}>
          <DataTable
            columnDefs={parentToCustodianBalancesDataColumnDefs}
            data-testid='data-parent-to-custodian-cash-balances-table'
            emptyPlaceholderComponent={
              <Stack
                alignItems='center'
                data-testid='no-data-parent-to-custodian-cash-balances-table'
                justifyContent='center'
                sx={{ height: '100%' }}>
                <CardPlaceholder
                  icon={<SearchIcon fontSize='inherit' />}
                  subtitle='No cash balances found.'
                />
              </Stack>
            }
            onPageChanged={handlePageChanged}
            onPageSizeChanged={handlePageSizeChanged}
            page={urlState.page}
            pageSize={urlState.pageSize}
            pagination
            paginationSource='server'
            paginationSx={{ py: 0.375 }}
            paginationTotal={
              getParentToCustodianCashBalancesQuery.data?.pagination?.total
            }
            rowData={getParentToCustodianCashBalancesQuery.data?.results}
          />
        </CardContent>
      </Card>
    </Box>
  );
};
