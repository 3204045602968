import {
  ExtendedColDef,
  ExtendedGroupColDef
} from '@/components/file-upload-table/FileUploadTable.component';
import { Conversion } from '@/models/ConversionDTO.model';
import { Box, Stack, Typography } from '@mui/material';

import dayjs from 'dayjs';
import React from 'react';

import RequestStatus from './RequestStatus.component';
import UploadButton from './UploadButton.component';

interface ConversionDialogHeaderProps {
  title: string;
  onUpload: (data: Record<string, unknown>[]) => void;
  columnDefs?: (ExtendedGroupColDef | ExtendedColDef)[];
  conversion?: Conversion;
  checkFileNameExists?: (fileName: string) => Promise<any>;
  viewMode?: boolean;
}

const ConversionDialogHeader: React.FunctionComponent<
  ConversionDialogHeaderProps
> = ({
  title,
  conversion,
  checkFileNameExists,
  onUpload,
  columnDefs,
  viewMode = false
}: ConversionDialogHeaderProps) => {
  return (
    <Box display='flex' justifyContent='space-between'>
      <Stack direction='row' spacing={2}>
        <Box>
          <Typography variant='h5'>
            {conversion?.fileName ||
              `${title} - ${dayjs().format('MM/DD/YYYY')}`}
          </Typography>
          <Typography
            color={theme => theme.palette.grey[700]}
            data-testid='uploaded-by-heading'
            variant='caption'>
            {conversion?.submittedBy
              ? `Uploaded by ${conversion.submittedBy.id} on ${dayjs(
                  conversion.createdAt
                ).format('MM/DD/YYYY hh:mm')}`
              : ''}
          </Typography>
        </Box>
        {conversion?.status && <RequestStatus status={conversion.status} />}
      </Stack>
      {!viewMode && (
        <UploadButton
          checkFileNameExists={checkFileNameExists}
          columnDefs={columnDefs}
          onUpload={onUpload}
        />
      )}
    </Box>
  );
};

export default ConversionDialogHeader;
