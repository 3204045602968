import Card, { CardContent, CardPlaceholder } from '@/components/card';
import DataTable, {
  DataTableBadgeCell,
  DataTableStackCell
} from '@/components/data-table/DataTable.component';
import OpenInNewIcon from '@/components/icon/OpenInNewIcon';
import { AlertDto } from '@/models/suba/alerts/AlertDTO.model';
import { OrderByDirection } from '@/models/suba/common/OrderByDirection.model';
import { BreakageStatusColorMap } from '@/models/suba/recon/BreakageStatusColorMap.model';
import { AlertContext } from '@/routes/suba/common/contexts/AlertContext';
import AuthZService from '@/services/AuthZ.service';
import AlertService from '@/services/suba/alerts/Alert.service';
import formatters from '@/utils/Formatters';
import SearchIcon from '@mui/icons-material/Search';
import { Box, Divider, Link, Stack } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { AlertSubType } from '@vestwell-sub-accounting/models/common/AlertSubType';

import { ColDef } from 'ag-grid-community';
import { FC, useContext } from 'react';

import { CardHeader } from '../common/CardHeader.component';

export const AchReversalExpandedRecentReversals: FC = () => {
  const alert = useContext(AlertContext);
  const alertSearchQuery = useQuery(
    [
      'AlertService.search',
      {
        alertSubType: AlertSubType.ACHReversal,
        details: alert.details.event.body[0].custodianAccountNumber,
        orderBy: 'createdAt',
        orderByDirection: OrderByDirection.desc,
        page: 1,
        pageSize: 0
      }
    ],
    async () => {
      const alerts = await AlertService.search({
        alertSubType: AlertSubType.ACHReversal,
        details: alert.details.event.body[0].custodianAccountNumber,
        orderBy: 'createdAt',
        orderByDirection: OrderByDirection.desc,
        page: 1,
        pageSize: 0
      });
      return {
        ...alerts,
        results: alerts.results.filter(
          filteredRow => filteredRow.id !== alert.id
        )
      };
    }
  );
  const vestwellStaffQuery = useQuery(
    ['AuthZService.getVestwellStaff'], // need to modify cache key for some reason otherwise it reloads over and over
    async () => {
      const staff = await AuthZService.getVestwellStaff();
      return AuthZService.formatVestwellStaffList(staff);
    }
  );

  const columnDefs: ColDef[] = [
    {
      cellRenderer: (cellData: { data: AlertDto }) => (
        <Link href={`/ops/alerts/${cellData.data.id}`} target='_blank'>
          {cellData.data.id}
          <Box
            component='span'
            sx={{
              lineHeight: 1,
              pl: 0.5
            }}>
            <OpenInNewIcon sx={{ fontSize: 14 }} />
          </Box>
        </Link>
      ),
      field: 'id',
      headerName: 'ID',
      minWidth: 75,
      suppressMenu: true
    },
    {
      autoHeight: true,
      cellRenderer: (cellData: { data: AlertDto }) => {
        return (
          <DataTableBadgeCell
            color={BreakageStatusColorMap[cellData.data.alertStatus]}>
            {formatters.displayCase(cellData.data.alertStatus)}
          </DataTableBadgeCell>
        );
      },
      field: 'status',
      headerName: 'Status',
      minWidth: 100,
      suppressMenu: true
    },

    {
      autoHeight: true,
      cellRenderer: (cellData: { data: AlertDto }) => {
        if (!cellData.data.assignee) {
          return null;
        }
        const matchedAssignee = vestwellStaffQuery?.data?.find(
          staffUser => staffUser.userId === cellData.data.assignee
        );
        return (
          <DataTableStackCell
            primary={matchedAssignee?.label || cellData.data.assignee}
          />
        );
      },
      field: 'assignee',
      headerName: 'Assignee',
      minWidth: 100,
      suppressMenu: true
    },
    {
      autoHeight: true,
      headerName: 'Amount',
      minWidth: 100,
      suppressMenu: true,
      valueFormatter: (cellData: { data: AlertDto }) => {
        return formatters.formatDollars(
          cellData.data.details?.event?.body[0]?.amount
        );
      }
    },
    {
      autoHeight: true,
      field: 'date',
      headerName: 'Date',
      minWidth: 175,
      suppressMenu: true,
      valueFormatter: ({ value }: { value: string }) =>
        formatters.formatFromIsoDateCustom(value, 'MM/DD/YYYY')
    }
  ];

  return (
    <Card data-testid='ach-reversal-expanded-recent-reversals-card'>
      <CardHeader title='Recent ACH Reversals'></CardHeader>
      <Divider />
      <CardContent
        disablePadding
        loading={alertSearchQuery.isLoading || vestwellStaffQuery.isLoading}>
        <DataTable
          columnDefs={columnDefs}
          emptyPlaceholderComponent={
            <Stack
              alignItems='center'
              data-testid='no-data-alerts-table'
              justifyContent='center'
              sx={{ height: '100%' }}>
              <CardPlaceholder
                icon={<SearchIcon fontSize='inherit' />}
                subtitle='No results found'
              />
            </Stack>
          }
          pageSize={3}
          pageSizeOptions={[3]}
          pagination={true}
          rowData={alertSearchQuery?.data?.results || []}></DataTable>
      </CardContent>
    </Card>
  );
};

AchReversalExpandedRecentReversals.displayName =
  'AchReversalExpandedRecentReversals';
