import { Badge } from '@/components/badge';
import { DatePicker } from '@/components/date-picker';
import { executionStatusColorMap } from '@/models/suba/workflows/ExecutionStatusColorMap.model';
import { workflowStatusColorMap } from '@/models/suba/workflows/WorkflowStatusColorMap.model';
import { WorkflowStatusEnumsObject } from '@/models/suba/workflows/WorkflowStatusEnumObject.model';
import formatters from '@/utils/Formatters';
import SearchIcon from '@mui/icons-material/Search';
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  Stack
} from '@mui/material';
import { AccountLevel } from '@vestwell-sub-accounting/models/accountsAndLedgers/AccountLevel';
import { SubAccountType } from '@vestwell-sub-accounting/models/common/SubAccountType';
import { WorkflowExecutionStatusEnum } from '@vestwell-sub-accounting/models/conductor/WorkflowExecutionStatus.model';
import { WorkflowName } from '@vestwell-sub-accounting/models/conductor/WorkflowName.model';
import { WorkflowStatus as WorkflowStatusEnums } from '@vestwell-sub-accounting/models/conductor/WorkflowStatus.model';

import { Field, Form, useFormikContext } from 'formik';
import { FC } from 'react';

export type ConductorFiltersFormValues = {
  endDate: string;
  executionStatus: WorkflowExecutionStatusEnum[];
  startDate: string;
  tracerId: string;
  workflowName: WorkflowName[];
  workflowStatus: WorkflowStatusEnums[];
};

type ConductorFiltersFormProps = {
  accountLevel: AccountLevel;
  subAccountType: SubAccountType;
};

export const ConductorFiltersForm: FC<ConductorFiltersFormProps> = props => {
  const formikContext = useFormikContext<ConductorFiltersFormValues>();

  return (
    <Form data-testid='filter-form'>
      <Stack
        alignItems='flex-start'
        justifyContent='flex-start'
        spacing={2}
        width={256}>
        <FormControl fullWidth size='small'>
          <Field
            as={OutlinedInput}
            endAdornment={<SearchIcon />}
            inputProps={{
              'data-testid': 'tracerId-input'
            }}
            name='tracerId'
            placeholder='Enter a Tracer ID'
          />
        </FormControl>
        <FormControl fullWidth size='small'>
          <InputLabel id='aggregateWorkflowTypeFilter-label' shrink>
            Type
          </InputLabel>
          <Field
            MenuProps={{
              'data-testid': 'menu-aggregateWorkflowTypeFilter'
            }}
            as={Select}
            displayEmpty
            label='Type'
            labelId='aggregateWorkflowTypeFilter-label'
            multiple
            name='workflowName'
            renderValue={(selected: WorkflowName[]) => {
              if (!selected?.length) {
                return <>Any</>;
              }
              return (
                <Box
                  sx={{
                    whiteSpace: 'normal'
                  }}>
                  {selected.map((value, valueIndex) => {
                    const displayWorkflowName = formatters.getValueKey(
                      WorkflowName,
                      value
                    );
                    return `${formatters.displayCase(displayWorkflowName)}${
                      valueIndex < selected.length - 1 ? ', ' : ''
                    }`;
                  })}
                </Box>
              );
            }}>
            {Object.values(WorkflowName)
              .sort()
              .map(value => {
                if (
                  props.accountLevel === AccountLevel.SubAccount &&
                  [
                    WorkflowName.newPlan,
                    WorkflowName.investmentUpdate
                  ].includes(value)
                ) {
                  // don't show the parent only options for sub accounts
                  return null;
                }
                if (
                  props.accountLevel === AccountLevel.SubAccount &&
                  value === WorkflowName.depositRequest &&
                  props.subAccountType !== SubAccountType.moneyIn
                ) {
                  // Deposit Request - only if the subAccount.accountType = moneyIn
                  return null;
                }
                const displayWorkflowName = formatters.getValueKey(
                  WorkflowName,
                  value
                );
                return (
                  <MenuItem key={value} value={value}>
                    <Checkbox
                      checked={formikContext.values.workflowName?.includes(
                        value
                      )}
                      sx={{ py: 0 }}
                    />
                    <ListItemText>
                      {formatters.displayCase(displayWorkflowName)}
                    </ListItemText>
                  </MenuItem>
                );
              })}
          </Field>
        </FormControl>
        <FormControl fullWidth size='small'>
          <InputLabel id='executionStatus-label' shrink>
            Execution Status
          </InputLabel>
          <Field
            MenuProps={{
              'data-testid': 'menu-executionStatus'
            }}
            as={Select}
            displayEmpty
            label='Execution Status'
            labelId='executionStatus-label'
            multiple
            name='executionStatus'
            renderValue={(selected: WorkflowExecutionStatusEnum[]) => {
              if (!selected?.length) {
                return <>Any</>;
              }
              return (
                <Box
                  sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    gap: 0.5
                  }}>
                  {selected.map(value => {
                    const displayExecutionStatus = formatters.getValueKey(
                      WorkflowExecutionStatusEnum,
                      value
                    );
                    return (
                      <Badge
                        color={executionStatusColorMap[value]}
                        key={value}
                        size='small'>
                        {formatters.displayCase(displayExecutionStatus)}
                      </Badge>
                    );
                  })}
                </Box>
              );
            }}>
            {Object.values(WorkflowExecutionStatusEnum).map(value => {
              const displayStatus = formatters.getValueKey(
                WorkflowExecutionStatusEnum,
                value
              );
              return (
                <MenuItem key={value} value={value}>
                  <Checkbox
                    checked={formikContext.values.executionStatus?.includes(
                      value
                    )}
                    sx={{ py: 0 }}
                  />
                  <ListItemText>
                    {formatters.displayCase(displayStatus)}
                  </ListItemText>
                </MenuItem>
              );
            })}
          </Field>
        </FormControl>
        <FormControl fullWidth size='small'>
          <InputLabel id='workflowStatus-label' shrink>
            Workflow Status
          </InputLabel>
          <Field
            MenuProps={{
              'data-testid': 'menu-workflowStatus'
            }}
            as={Select}
            displayEmpty
            label='Workflow Status'
            labelId='workflowStatus-label'
            multiple
            name='workflowStatus'
            renderValue={(selected: WorkflowStatusEnums[]) => {
              if (!selected?.length) {
                return <>Any</>;
              }
              return (
                <Box
                  sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    gap: 0.5
                  }}>
                  {selected.map(value => {
                    const displayWorkflowStatus = formatters.getValueKey(
                      WorkflowStatusEnumsObject,
                      value
                    );
                    return (
                      <Badge
                        color={workflowStatusColorMap[value]}
                        key={value}
                        size='small'>
                        {formatters.displayCase(displayWorkflowStatus)}
                      </Badge>
                    );
                  })}
                </Box>
              );
            }}>
            {Object.values(WorkflowStatusEnumsObject)
              .sort() // close enough to sorting by key so lets call it good
              .map(value => {
                const displayWorkflowStatus = formatters.getValueKey(
                  WorkflowStatusEnumsObject,
                  value
                );
                return (
                  <MenuItem key={value} value={value}>
                    <Checkbox
                      checked={formikContext.values.workflowStatus?.includes(
                        value
                      )}
                      sx={{ py: 0 }}
                    />
                    <ListItemText>
                      {formatters.displayCase(displayWorkflowStatus)}
                    </ListItemText>
                  </MenuItem>
                );
              })}
          </Field>
        </FormControl>
        <FormControl fullWidth size='small' variant='outlined'>
          <Field
            as={DatePicker}
            autoComplete='off'
            data-testid='startDate'
            disableFuture
            label='From'
            name='startDate'
          />
        </FormControl>
        <FormControl fullWidth size='small' variant='outlined'>
          <Field
            as={DatePicker}
            autoComplete='off'
            data-testid='endDate'
            disableFuture
            label='To'
            name='endDate'
          />
        </FormControl>
        <Button data-testid='submit' type='submit' variant='outlined'>
          Apply
        </Button>
      </Stack>
    </Form>
  );
};
