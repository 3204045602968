import formatters from '@/utils/Formatters';
import SearchIcon from '@mui/icons-material/Search';
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Stack
} from '@mui/material';
import { CustodianId } from '@vestwell-sub-accounting/models/common/CustodianId';
import { ParentAccountType } from '@vestwell-sub-accounting/models/common/ParentAccountType';

import { Field, Form, useFormikContext } from 'formik';
import { FC } from 'react';

export type ParentAccountsSearchFormValues = {
  custodianId?: CustodianId | '';
  query?: string;
};

type ParentAccountsSearchFormProps = {
  accountType: ParentAccountType;
};

export const ParentAccountsSearchForm: FC<
  ParentAccountsSearchFormProps
> = props => {
  const formikContext = useFormikContext<ParentAccountsSearchFormValues>();

  return (
    <Form data-testid='query-form' onSubmit={formikContext.handleSubmit}>
      <Stack direction='row' spacing={2} useFlexGap>
        {props.accountType === ParentAccountType.PlanLevel && (
          <FormControl fullWidth variant='outlined'>
            <Field
              as={OutlinedInput}
              autoComplete='off'
              endAdornment={<SearchIcon color='primary' />}
              inputProps={{
                'aria-labelledby': 'search-accounts-heading',
                'data-testid': 'query-input'
              }}
              name='query'
              placeholder='Account ID, plan ID, plan name, or Custodian Account number'
              size='small'
            />
          </FormControl>
        )}
        <FormControl fullWidth size='small' sx={{ flexShrink: 2 }}>
          <InputLabel id='menu-custodian-label'>Custodian</InputLabel>
          <Field
            MenuProps={{
              'data-testid': 'menu-custodian'
            }}
            as={Select}
            data-testid='query-custodian'
            label='Custodian'
            labelId='menu-custodian-label'
            name='custodianId'>
            <MenuItem value=''>Any</MenuItem>
            {Object.values(CustodianId).map(value => (
              <MenuItem key={value} value={value}>
                {value === 'bnyTreasury'
                  ? 'BNY Treasury'
                  : formatters.displayCase(value)}
              </MenuItem>
            ))}
          </Field>
        </FormControl>
        <Button
          data-testid='all-accounts-apply-button'
          type='submit'
          variant='outlined'>
          Apply
        </Button>
      </Stack>
    </Form>
  );
};
