import { useSnackbar } from '@/contexts/SnackBarContext';
import { useUserToken } from '@/contexts/UserTokenContext';
import PriceService from '@/services/suba/prices/Price.service';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';

import { AxiosError } from 'axios';

export const SEARCH_PRICES_QUERY_KEY = 'PriceService.search';

export const useSearchPricesQuery = (
  params: Parameters<typeof PriceService.search>[0],
  options?: UseQueryOptions<
    Awaited<ReturnType<typeof PriceService.search>>,
    AxiosError
  >
) => {
  const { showSnackbar } = useSnackbar();
  const { userHasValidToken } = useUserToken();

  return useQuery(
    [SEARCH_PRICES_QUERY_KEY, params],
    () => PriceService.search(params),
    {
      ...(options || {}),
      enabled: (options?.enabled && userHasValidToken) ?? userHasValidToken,
      keepPreviousData: options?.keepPreviousData ?? true,
      onError:
        options?.onError ??
        ((err: AxiosError) => {
          showSnackbar({
            message: `Failed to search prices: ${err.response?.data ?? err.message}`,
            severity: 'error'
          });
        })
    }
  );
};
