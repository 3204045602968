import formatters from '@/utils/Formatters';

import { isPlainObject } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';

type NumericEnum = Record<string, number | string>;
type StringEnum = Record<string, string>;

type UrlSearchParamType =
  | 'boolean'
  | 'json'
  | 'number'
  | NumericEnum
  | NumericEnum[]
  | 'string'
  | StringEnum
  | StringEnum[];

const getNumericEnumValues = value => {
  return Object.entries(value)
    .filter(
      // exclude reverse mappings in numeric enum
      ([[key, value]]) => typeof value !== 'string' || Number.isNaN(Number(key))
    )
    .map(([, value]) => value);
};

const isEmptyValue = value =>
  value === '' ||
  value === undefined ||
  (Array.isArray(value) && !value.length);

const isNumericEnumLike = (
  maybeNumericEnum: unknown
): maybeNumericEnum is NumericEnum => {
  if (!isPlainObject(maybeNumericEnum)) return false;

  return Object.entries(maybeNumericEnum).every(
    ([key, value]) =>
      // check bidirectional mapping
      (!isNaN(Number(key)) && typeof value === 'string') ||
      (typeof key === 'string' &&
        typeof value === 'number' &&
        maybeNumericEnum[value] === key)
  );
};

const isStringEnumLike = (
  maybeStringEnum: unknown
): maybeStringEnum is StringEnum => {
  if (!isPlainObject(maybeStringEnum)) return false;

  return Object.values(maybeStringEnum).every(
    value => typeof value === 'string'
  );
};

/**
 * Shallowly iterates through source object and deletes any empty or undefined values. Avoids cluttering URL and complicating consumer call sites.
 * Preserves null values to allow for user override of fallbacks to initial state.
 */
const deleteEmptyValues = (source: Record<string, unknown>) => {
  for (const [key, value] of Object.entries(source)) {
    if (isEmptyValue(value)) {
      delete source[key];
    }
  }
  return source;
};

/**
 * Gets known params from URL, casting and parsing values to conform with runtime and static types
 */
const parseParamValues = <T>({
  processedInitialUrlState,
  arrayParamKeys = [],
  parsedValueTypes,
  urlSearchParams
}: {
  processedInitialUrlState?: T;
  arrayParamKeys: (keyof T)[];
  parsedValueTypes: Parameters<typeof useUrlState>[1]['parsedValueTypes'];
  urlSearchParams: URLSearchParams;
}): T => {
  const parsedParams = {};

  // params not declared via initial params or provided type mapping are implicitly ignored
  for (const [key, type] of Object.entries(parsedValueTypes)) {
    if (processedInitialUrlState && !urlSearchParams.has(key)) {
      parsedParams[key] = processedInitialUrlState[key];
      continue;
    }

    if (urlSearchParams.get(key) === 'null') {
      parsedParams[key] = null;
      continue;
    }

    if (type === 'boolean') {
      parsedParams[key] =
        urlSearchParams.get(key) === 'true'
          ? true
          : urlSearchParams.get(key) === 'false'
            ? false
            : undefined;
      if (urlSearchParams.get(key) && !parsedParams[key]) {
        console.warn(`useUrlState: Failed to parse \`${key}\` boolean value`);
      }
    } else if (type === 'json') {
      try {
        const initialParamValue = processedInitialUrlState?.[key];
        if (!urlSearchParams.get(key)) return initialParamValue;

        const parsedValue = deleteEmptyValues(
          JSON.parse(urlSearchParams.get(key))
        );

        parsedParams[key] =
          initialParamValue !== undefined && isPlainObject(initialParamValue)
            ? {
                ...(initialParamValue as object),
                ...parsedValue
              }
            : parsedValue;
      } catch (err) {
        console.warn(`useUrlState: Failed to parse \`${key}\` JSON value`, err);
      }
    } else if (type === 'string' && arrayParamKeys.includes(key as keyof T)) {
      parsedParams[key] = urlSearchParams.getAll(key);
    } else if (type === 'string') {
      parsedParams[key] = urlSearchParams.get(key);
    } else if (type === 'number' && arrayParamKeys.includes(key as keyof T)) {
      const maybeNumbers = urlSearchParams.getAll(key);

      parsedParams[key] = maybeNumbers.map(Number).filter(value => {
        if (Number.isNaN(value)) {
          console.warn(
            `useUrlState: Failed to parse \`${value}\` number value in \`${key}\` array`
          );
          return false;
        } else {
          return true;
        }
      });
    } else if (type === 'number') {
      const maybeNumber = +urlSearchParams.get(key);

      if (Number.isNaN(maybeNumber)) {
        console.warn(`useUrlState: Failed to parse \`${key}\` number value`);
      } else {
        parsedParams[key] = maybeNumber;
      }
    } else if (
      isStringEnumLike(type) &&
      arrayParamKeys.includes(key as keyof T)
    ) {
      const maybeStringEnumValues = urlSearchParams.getAll(key);
      const validValues = Object.values(type);

      parsedParams[key] = maybeStringEnumValues.filter(value =>
        validValues.includes(value)
      );
    } else if (
      isNumericEnumLike(type) &&
      arrayParamKeys.includes(key as keyof T)
    ) {
      const maybeNumericEnumValues = urlSearchParams.getAll(key).map(Number);
      const validValues = getNumericEnumValues(type);

      parsedParams[key] = maybeNumericEnumValues.filter(value =>
        validValues.includes(value)
      );
    } else if (isStringEnumLike(type)) {
      const maybeStringEnumValue = urlSearchParams.get(key);

      if (Object.values(type).includes(maybeStringEnumValue)) {
        parsedParams[key] = maybeStringEnumValue;
      }
    } else if (isNumericEnumLike(type)) {
      const maybeNumericEnumValue = Number(urlSearchParams.get(key));

      if (getNumericEnumValues(type).includes(maybeNumericEnumValue)) {
        parsedParams[key] = maybeNumericEnumValue;
      }
    }
  }

  return deleteEmptyValues(parsedParams) as T;
};

/**
 * Infers desired parsed value types from values passed via `initialUrlState`
 */
const inferParsedValueTypes = <T>(initialUrlState: T) => {
  const arrays: (keyof T)[] = [];
  const types: Partial<Record<keyof T, UrlSearchParamType>> = {};

  for (const [key, value] of Object.entries(initialUrlState) as [
    keyof T,
    T[keyof T]
  ][]) {
    if (typeof value === 'boolean') {
      types[key] = 'boolean';
    } else if (typeof value === 'string') {
      types[key] = 'string';
    } else if (Array.isArray(value)) {
      let itemType;

      for (const item of value) {
        itemType = typeof item;

        if (itemType !== 'string' && itemType !== 'number') {
          throw new Error(
            'useUrlState: Arrays must consist entirely of strings or numbers'
          );
        }
      }

      arrays.push(key);
      if (itemType === 'number') types[key] = 'number';
      if (itemType === 'string') types[key] = 'string';
    } else if (!Number.isNaN(Number(value))) {
      types[key] = 'number';
    } else if (isPlainObject(value)) {
      types[key] = 'json';
    }
    // we can't identify the origin of an enum value so inferrence is unsupported for that type
  }

  return { arrays, types };
};

type DefaultUrlState = Record<string, unknown>;

/**
 * Persists state in the URL query string and returns current values parsed to specified types. Enables sharing links to pages that will hydrate their state from the URL.

* [Docs](https://github.com/vestwell/vestwell-admin-ui/blob/develop/src/hooks/useUrlState.README.md)
 */
export const useUrlState = <
  T extends Record<string, unknown> = DefaultUrlState
>(
  initialUrlState: T | ((deserializedSearchParams: T) => T) = {} as T,
  options?: {
    /**
     * Query string parameters that should be parsed as an array of values.
     *
     * [Docs](https://github.com/vestwell/vestwell-admin-ui/blob/develop/src/hooks/useUrlState.README.md)
     */
    arrayParamKeys?: (keyof T)[];
    /**
     * Preferences for query string parsing. For each key, define one of:
     * - `boolean`
     * - `number`
     * - `string`
     * - `json`
     *   - Values will be parsed and stringified with native JSON methods
     * - TypeScript enum _(number or string)_
     *
     * [Docs](https://github.com/vestwell/vestwell-admin-ui/blob/develop/src/hooks/useUrlState.README.md)
     */
    parsedValueTypes?: keyof T extends never
      ? Record<
          typeof initialUrlState extends DefaultUrlState
            ? keyof typeof initialUrlState
            : string,
          UrlSearchParamType
        >
      : Record<keyof T, UrlSearchParamType>;
  }
): [T, (newUrlState: Partial<T> | ((prevState: T) => Partial<T>)) => void] => {
  // context

  const location = useLocation();
  const [urlSearchParams, setUrlSearchParams] = useSearchParams();

  // state

  const [previousLocationKey, setPreviousLocationKey] = useState<string>(
    location.key
  );

  // memos

  const processedInitialUrlState = useMemo<T | undefined>(() => {
    if (typeof initialUrlState !== 'function') return initialUrlState;

    if (!options?.parsedValueTypes)
      throw new Error(
        'useUrlState: Must specify `parsedValueTypes` option if `initialUrlState` is given as a function'
      );

    return initialUrlState(
      parseParamValues({
        arrayParamKeys: options.arrayParamKeys,
        parsedValueTypes: options.parsedValueTypes,
        urlSearchParams
      })
    );
  }, [initialUrlState, urlSearchParams]);

  const [arrayParamKeys, parsedValueTypes] = useMemo(() => {
    const inferredConfig = processedInitialUrlState
      ? inferParsedValueTypes<T>(processedInitialUrlState)
      : undefined;

    return [
      options?.arrayParamKeys || inferredConfig?.arrays || [],
      options?.parsedValueTypes || inferredConfig?.types || {}
    ];
  }, [
    options?.arrayParamKeys,
    options?.parsedValueTypes,
    processedInitialUrlState
  ]);

  const [state, setState] = useState<Record<string, Record<string, T>>>({
    [location.pathname]: {
      [location.key]: parseParamValues<T>({
        arrayParamKeys,
        parsedValueTypes,
        processedInitialUrlState,
        urlSearchParams
      })
    }
  });

  // effects

  useEffect(() => {
    if (previousLocationKey === location.key) return;

    // persist historical state for retrieval on history navigation
    // this avoids issues re: temporary loss of state that occurs while React Router updates the URL
    const newState = {
      ...state,
      [location.pathname]: {
        ...(state[location.pathname] || {}),
        [location.key]:
          location.state?.parsedParams ||
          parseParamValues<T>({
            arrayParamKeys,
            parsedValueTypes,
            processedInitialUrlState,
            urlSearchParams
          })
      }
    };

    setState(newState);
    setPreviousLocationKey(location.key);
  }, [location.key]);

  // consts

  const parsedParams =
    state[location.pathname]?.[location.key] ??
    parseParamValues<T>({
      arrayParamKeys,
      parsedValueTypes,
      processedInitialUrlState,
      urlSearchParams
    });

  return [
    parsedParams,
    newUrlState => {
      const processedParams = deleteEmptyValues(
        newUrlState instanceof Function
          ? newUrlState(
              // re-calculate with params current at time of invocation
              parseParamValues<T>({
                arrayParamKeys,
                parsedValueTypes,
                processedInitialUrlState,
                // must read directly from URL to avoid race conditions involving concurrent callback triggers on param updates, e.g. order + current page
                urlSearchParams: new URLSearchParams(window.location.search)
              })
            )
          : newUrlState
      );

      const stateParams: Record<string, unknown> = {};
      const urlParams = new URLSearchParams();

      for (const key of Object.keys(parsedValueTypes)) {
        if (isEmptyValue(processedParams[key])) continue;

        // reduce json to diff with initial value
        if (parsedValueTypes[key] === 'json') {
          const diff = deleteEmptyValues(
            processedInitialUrlState?.[key]
              ? formatters.objectDiff(
                  processedParams[key] as Record<string, unknown>,
                  processedInitialUrlState[key] as Record<string, unknown>
                )
              : (processedParams[key] as Record<string, unknown>)
          );

          // exclude from URL completely if end result is empty
          if (Object.keys(diff).length) {
            urlParams.set(key, JSON.stringify(diff));
          }

          // store processed object in full without empty or nullish values, for use by consumers
          stateParams[key] = deleteEmptyValues(
            processedParams[key] as Record<string, unknown>
          );

          continue;
        }

        stateParams[key] = processedParams[key];

        // exclude values from initial state from URL
        if (
          processedParams[key] === processedInitialUrlState?.[key] &&
          parsedValueTypes[key] !== 'json'
        )
          continue;

        if (Array.isArray(processedParams[key])) {
          for (const value of Object.values(processedParams[key])) {
            urlParams.append(key, value);
          }
        } else {
          urlParams.set(
            key,
            processedParams[key] === null
              ? 'null' // allow in URL to capture user intent
              : processedParams[key].toString()
          );
        }
      }

      // update the url; state will update as a side effect of `location.key` change
      setUrlSearchParams(urlParams, {
        replace: true,
        state: {
          parsedParams: stateParams
        }
      });
    }
  ];
};
