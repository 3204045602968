import { useSnackbar } from '@/contexts/SnackBarContext';
import { useUserToken } from '@/contexts/UserTokenContext';
import { DividendDeclarationsService } from '@/services/suba/dividend-declarations/DividendDeclarations.service';
import { useQuery } from '@tanstack/react-query';

import { AxiosError } from 'axios';

export const SEARCH_DIVIDEND_DECLARATIONS_QUERY_KEY =
  'DividendDeclarationsService.search';

export const useSearchDividendDeclarationsQuery = (
  params: Parameters<typeof DividendDeclarationsService.search>[0]
) => {
  const { showSnackbar } = useSnackbar();
  const { userHasValidToken } = useUserToken();

  return useQuery(
    [SEARCH_DIVIDEND_DECLARATIONS_QUERY_KEY, params],
    () => DividendDeclarationsService.search(params),
    {
      enabled: userHasValidToken,
      keepPreviousData: true,
      onError: (err: AxiosError) => {
        showSnackbar({
          message: `Failed to get dividend declarations: ${err.response?.data ?? err.message}`,
          severity: 'error'
        });
      }
    }
  );
};
