import AppConfig from '@/App.config';
import { CopyToClipboard } from '@/components/copy-to-clipboard';
import { useUserToken } from '@/contexts/UserTokenContext';
import { VersionDetails } from '@/models/AppConfigSchema.model';
import ApiService from '@/services/Api.service';
import { List, ListItem, Theme, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useQuery } from '@tanstack/react-query';

import { sortBy } from 'lodash';
import React from 'react';

interface SiteVersionInfoProps {
  name: 'vestwell-admin-ui' | 'vestwell-admin-api' | 'hello401-backend';
}

const useStyles = makeStyles((theme: Theme) => ({
  copyIcon: {
    marginLeft: theme.spacing(4)
  }
}));

const SiteVersionInfo: React.FunctionComponent<SiteVersionInfoProps> = (
  props: SiteVersionInfoProps
) => {
  const { name } = props;

  const classes = useStyles();

  const { userHasValidToken } = useUserToken();

  const remoteVersionRequest = useQuery<VersionDetails>(
    ['remoteVersionRequest', name],
    () => {
      const path =
        name === 'vestwell-admin-api'
          ? '/version'
          : '/version/hello401-backend';

      return ApiService.getJson(path);
    },
    {
      cacheTime: 0,
      enabled: Boolean(userHasValidToken && name !== 'vestwell-admin-ui'),
      staleTime: 0
    }
  );

  const version = {
    'hello401-backend': remoteVersionRequest.data,
    'vestwell-admin-api': remoteVersionRequest.data,
    'vestwell-admin-ui': AppConfig.uiVersionDetails
  }[name];

  const keys = sortBy(Object.keys(version || {}));

  return (
    <>
      <Typography component='h2' variant='h2'>
        {name}{' '}
        {version && (
          <CopyToClipboard
            copyName={name}
            copyValue={JSON.stringify(version)}
            size='small'
          />
        )}
      </Typography>
      <List>
        {!version ? (
          <Typography component='h5' variant='h5'>
            waiting... (api call status: {remoteVersionRequest.status})
          </Typography>
        ) : (
          keys.map(k => {
            const value = version[k as keyof VersionDetails];
            return (
              <ListItem key={k}>
                <Typography component='h5' variant='h5'>
                  {`${k}: ${value}`}
                </Typography>
                <div className={classes.copyIcon}>
                  <CopyToClipboard
                    copyName={k}
                    copyValue={value}
                    size='small'
                  />
                </div>
              </ListItem>
            );
          })
        )}
      </List>
    </>
  );
};

const SiteVersionRoute: React.FunctionComponent = () => {
  return (
    <>
      <SiteVersionInfo name='vestwell-admin-ui' />
      <SiteVersionInfo name='vestwell-admin-api' />
      <SiteVersionInfo name='hello401-backend' />
    </>
  );
};

export default SiteVersionRoute;
