import Card, {
  CardContent,
  CardHeader,
  CardPlaceholder
} from '@/components/card';
import {
  DataTable,
  DataTableProps
} from '@/components/data-table/DataTable.component';
import DatePicker from '@/components/date-picker/DatePicker';
import { useUrlState } from '@/hooks/useUrlState.hook';
import { ParentToCustodianPositionDTO } from '@/models/suba/parent-to-custodian-position/ParentToCustodianPositionDTO.model';
import { ParentToCustodianPositionRequest } from '@/models/suba/parent-to-custodian-position/ParentToCustodianPositionRequest.model';
import ParentAccountService from '@/services/suba/accounts/ParentAccount.service';
import formatters from '@/utils/Formatters';
import SearchIcon from '@mui/icons-material/Search';
import { Box, Button, FormControl, Stack } from '@mui/material';
import { useQuery } from '@tanstack/react-query';

import { ColDef } from 'ag-grid-community';
import dayjs from 'dayjs';
import Decimal from 'decimal.js';
import { Field, Form, Formik } from 'formik';
import { FC, useCallback, useMemo } from 'react';

type FiltersFormValues = {
  endDate: string;
  startDate: string;
};

export type ParentToCustodianPostionsUrlState = Pick<
  ParentToCustodianPositionRequest,
  'page' | 'pageSize' | keyof FiltersFormValues
>;

type ParentToCustodianPositionsProps = {
  cusip: string;
  exceptionDate: string;
  parentAccountId: string;
};

export const ParentToCustodianPositionsTab: FC<
  ParentToCustodianPositionsProps
> = props => {
  // state

  const [urlState, setUrlState] =
    useUrlState<ParentToCustodianPostionsUrlState>(
      {
        endDate: dayjs(props.exceptionDate).add(7, 'days').format('YYYY-MM-DD'),
        page: 1,
        pageSize: 25,
        startDate: dayjs(props.exceptionDate)
          .subtract(7, 'days')
          .format('YYYY-MM-DD')
      },
      {
        parsedValueTypes: {
          endDate: 'string',
          page: 'number',
          pageSize: 'number',
          startDate: 'string'
        }
      }
    );

  // api

  const getParentToCustodianPositionsQuery = useQuery(
    [
      'ParentAccountService.getParentToCustodianPositions',
      props.cusip,
      props.parentAccountId,
      urlState
    ],
    () => {
      return ParentAccountService.getParentToCustodianPositions({
        ...urlState,
        cusip: props.cusip,
        parentAccountId: props.parentAccountId
      });
    }
  );

  // callbacks

  const handleFiltersFormSubmit = useCallback((values: FiltersFormValues) => {
    setUrlState(prevUrlState => ({
      ...prevUrlState,
      ...values,
      endDate: values.endDate || null,
      startDate: values.startDate || null
    }));
  }, []);

  const handlePageChanged = useCallback<DataTableProps['onPageChanged']>(
    newPage =>
      setUrlState(prevUrlState => ({
        ...prevUrlState,
        page: newPage
      })),
    []
  );

  const handlePageSizeChanged = useCallback<
    DataTableProps['onPageSizeChanged']
  >(
    newPageSize =>
      setUrlState(prevUrlState => ({
        ...prevUrlState,
        page: 1,
        pageSize: newPageSize
      })),
    []
  );

  // memos

  const parentToCustodianPositionsDataColumnDefs = useMemo<ColDef[]>(
    () => [
      {
        field: 'effectiveDate',
        headerName: 'Effective Date',
        minWidth: 200,
        valueFormatter: ({ value }: { value: string }) =>
          formatters.formatFromIsoDateCustom(value, 'MM/DD/YYYY')
      },
      {
        field: 'custodianUnits',
        headerName: 'Custodian Units',
        minWidth: 150,
        type: 'numericColumn',
        valueFormatter: ({ value }: { value: string }) =>
          formatters.formatDollars(value, 3)
      },
      {
        field: 'tradeDateUnits',
        headerName: 'Trade Date Units',
        minWidth: 150,
        type: 'numericColumn',
        valueFormatter: ({ value }: { value: string }) =>
          formatters.formatDollars(value, 3)
      },
      {
        field: 'settlementDateUnits',
        headerName: 'Settlement Date Units',
        minWidth: 150,
        type: 'numericColumn',
        valueFormatter: ({ value }: { value: string }) =>
          formatters.formatDollars(value, 3)
      },
      {
        headerName: 'Trade Date Difference',
        minWidth: 150,
        type: 'numericColumn',
        valueFormatter: ({
          data: row
        }: {
          data: ParentToCustodianPositionDTO;
        }) =>
          formatters.formatDollars(
            Decimal.sub(
              new Decimal(row.custodianUnits),
              new Decimal(row.tradeDateUnits)
            ).toFixed(3)
          )
      },
      {
        headerName: 'Settlement Date Difference',
        minWidth: 150,
        type: 'numericColumn',
        valueFormatter: ({
          data: row
        }: {
          data: ParentToCustodianPositionDTO;
        }) =>
          formatters.formatDollars(
            Decimal.sub(
              new Decimal(row.custodianUnits),
              new Decimal(row.settlementDateUnits)
            ).toFixed(3)
          )
      }
    ],
    []
  );

  return (
    <Box>
      <Card>
        <CardHeader
          justifyContent='left'
          loading={
            !!getParentToCustodianPositionsQuery.data?.results?.length &&
            getParentToCustodianPositionsQuery.isFetching
          }>
          <Formik<FiltersFormValues>
            initialValues={{
              endDate: urlState.endDate || '',
              startDate: urlState.startDate || ''
            }}
            onSubmit={handleFiltersFormSubmit}>
            {() => (
              <Form data-testid='parent-to-custodian-positions-update-date-range'>
                <Stack direction='row' spacing={2}>
                  <FormControl size='small' variant='outlined'>
                    <Field
                      as={DatePicker}
                      data-testid='parent-to-custodian-positions-start-date-picker'
                      label='Start Date'
                      name='startDate'
                    />
                  </FormControl>
                  <FormControl size='small' variant='outlined'>
                    <Field
                      as={DatePicker}
                      data-testid='parent-to-custodian-positions-end-date-picker'
                      label='End Date'
                      name='endDate'
                    />
                  </FormControl>
                  <FormControl>
                    <Button
                      data-testid='parent-to-custodian-position-update-date-range-button'
                      type='submit'
                      variant='outlined'>
                      Apply
                    </Button>
                  </FormControl>
                </Stack>
              </Form>
            )}
          </Formik>
        </CardHeader>
        <CardContent
          disablePadding
          loading={getParentToCustodianPositionsQuery.isInitialLoading}>
          <DataTable
            columnDefs={parentToCustodianPositionsDataColumnDefs}
            data-testid='data-parent-to-custodian-positions-table'
            emptyPlaceholderComponent={
              <Stack
                alignItems='center'
                data-testid='no-data-parent-to-custodian-positions-table'
                justifyContent='center'
                sx={{ height: '100%' }}>
                <CardPlaceholder
                  icon={<SearchIcon fontSize='inherit' />}
                  subtitle='No positions found.'
                />
              </Stack>
            }
            onPageChanged={handlePageChanged}
            onPageSizeChanged={handlePageSizeChanged}
            page={urlState.page}
            pageSize={urlState.pageSize}
            pagination
            paginationSource='server'
            paginationSx={{ py: 0.375 }}
            paginationTotal={
              getParentToCustodianPositionsQuery.data?.pagination?.total
            }
            rowData={getParentToCustodianPositionsQuery.data?.results}
          />
        </CardContent>
      </Card>
    </Box>
  );
};
