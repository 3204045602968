import AccessControl from '@/components/access-control/AccessControl.component';
import CardInfoField from '@/components/card-info-field';
import CollapsibleLabel from '@/components/collapsible-label/CollapsibleLabel.component';
import CopyToClipboard from '@/components/copy-to-clipboard';
import { CipStatus } from '@/components/kyc/CipStatus';
import OverrideVestwellEligibilityForm from '@/components/override-vestwell-eligibility-form/OverrideVestwellEligibilityForm.component';
import { EMPTY_FIELD_PLACEHOLDER } from '@/consts/formatting';
import { FormStep, useDialog } from '@/contexts/DialogContext';
import { useSnackbar } from '@/contexts/SnackBarContext';
import { useUserToken } from '@/contexts/UserTokenContext';
import { ParticipantEligibilityDto } from '@/models/ParticipantEligibilityDTO.model';
import ParticipantInfo from '@/models/ParticipantInfo.model';
import { UpdateParticipantInfoDto } from '@/models/UpdateParticipantInfoDTO.model';
import { FeatureLevelPermissions } from '@/models/UserPermissions.model';
import ParticipantService from '@/services/Participant.service';
import { userService } from '@/services/User.service';
import formatters from '@/utils/Formatters';
import validationFunctions from '@/utils/validations/helpers/InputValidations';
import createParticipantEligibilityValidationSchema from '@/utils/validations/ParticipantEligibilityValidationSchema.schema';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  Theme,
  Typography
} from '@mui/material';
import { grey } from '@mui/material/colors';
import makeStyles from '@mui/styles/makeStyles';
import {
  useMutation,
  UseMutationResult,
  useQueryClient
} from '@tanstack/react-query';

import clsx from 'clsx';
import dayjs from 'dayjs';
import React, { useMemo } from 'react';

interface ParticipantEligibilityCardProps {
  participant: ParticipantInfo;
  isStateIRA: boolean;
  surpasAccount: string;
  refreshParticipantEligibilityMutation: UseMutationResult<
    ParticipantEligibilityDto,
    unknown,
    boolean,
    unknown
  >;
  dupAddrId: string;
  terminationDate?: string;
  isEsa?: boolean;
  variant: 'salaryDeferral' | 'employerMatch' | 'profitSharing';
}

const useStyles = makeStyles((theme: Theme) => ({
  cardContainer: {
    overflow: 'visible',
    [theme.breakpoints.up('lg')]: {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1
    }
  },
  cardContent: {
    '& > *:first-child': {
      paddingBottom: theme.spacing(2)
    },
    paddingBottom: theme.spacing(1),
    paddingLeft: 0
  },
  contentBody: {
    paddingBottom: 0
  },
  copyIcon: {
    color: theme.palette.text.secondary,
    height: theme.spacing(2)
  },
  darkSubtitle: {
    color: 'rgb(0, 0, 0, 0.87)'
  },
  root: {
    [theme.breakpoints.up('lg')]: {
      display: 'flex',
      flexDirection: 'column'
    }
  },
  surpasAccountContainer: {
    border: `1px solid ${grey[300]}`,
    borderRadius: '100px',
    display: 'flex',
    marginTop: '-2px',
    padding: `2px 8px`
  }
}));

const isUpdatedValue = (old: any, updated: any) =>
  updated && old !== updated && updated !== '--';

const ParticipantEligibilityCard: React.FunctionComponent<
  ParticipantEligibilityCardProps
> = (props: ParticipantEligibilityCardProps) => {
  const classes = useStyles();

  const {
    participant,
    isStateIRA,
    surpasAccount,
    refreshParticipantEligibilityMutation,
    dupAddrId
  } = props;
  const { showSnackbar } = useSnackbar();
  const { openDialog } = useDialog();
  const { token } = useUserToken();
  const queryClient = useQueryClient();
  const isBNYUser = token?.tpaId === 42;

  const overrideEligibilityStatusMutation = useMutation(
    ['ParticipantService.overrideEligibilityStatus'],
    (data: {
      eligibility: {
        status: string;
        entryDate?: string;
        isOverride: boolean;
        overrideNotes: string;
        isLtpt: boolean;
        fundingSourceId?: boolean;
      };
    }) => {
      return ParticipantService.overrideEligibilityStatus(
        participant.participantId as number,
        participant.sponsorPlanId as number,
        data
      );
    },
    {
      onError: () => {
        showSnackbar({
          message: 'Error overriding eligibility',
          severity: 'error'
        });
      },
      onSuccess: async (_data, variables) => {
        await queryClient.refetchQueries([
          'ParticipantService.getParticipantById',
          participant.participantId?.toString()
        ]);

        showSnackbar({
          message: 'Success! The Information has been saved.',
          severity: 'success'
        });

        if (!variables.eligibility.isOverride) {
          refreshParticipantEligibilityMutation.mutate(false);
        }
      }
    }
  );

  const updateSurpasAccount = useMutation(
    (data: UpdateParticipantInfoDto) => {
      return ParticipantService.updateSurpasAccount(
        participant.participantId as number,
        participant.sponsorPlanId as number,
        data
      );
    },
    {
      onError: () => {
        showSnackbar({
          message: 'Error!',
          severity: 'error'
        });
      },
      onSuccess: async () => {
        await queryClient.refetchQueries([
          'ParticipantService.getParticipantExternalId',
          participant.participantId?.toString()
        ]);
        if (
          !userService.hasPermission(
            FeatureLevelPermissions.WRITE_EMPLOYMENT_ELIGIBILITY
          )
        ) {
          showSnackbar({
            message: 'Success! The Information has been saved.',
            severity: 'success'
          });
        }
      }
    }
  );

  const step: FormStep = {
    fields: {},
    title: isStateIRA
      ? isBNYUser
        ? 'Edit Surpas Account #'
        : 'Employee Status'
      : 'Manage Eligibility'
  };

  const isOverrideFormShowed = userService.hasPermission(
    FeatureLevelPermissions.WRITE_EMPLOYMENT_ELIGIBILITY
  );

  const formattedEligibility = useMemo(
    () => ({
      ...(props.variant === 'salaryDeferral'
        ? formatters.formatEligibility(participant.eligibilityRequirements)
        : {}),
      ...(props.variant === 'employerMatch'
        ? formatters.formatEligibility({
            isAgeRequirementMet:
              participant.matchEligibility?.ageRequirementDetail?.isMet,
            isServiceRequirementMet:
              participant.matchEligibility?.serviceRequirementDetail?.isMet
          })
        : {}),
      ...(props.variant === 'profitSharing'
        ? formatters.formatEligibility({
            isAgeRequirementMet:
              participant.profitSharingEligibility?.ageRequirementDetail?.isMet,
            isServiceRequirementMet:
              participant.profitSharingEligibility?.serviceRequirementDetail
                ?.isMet
          })
        : {})
    }),
    [participant]
  );

  const eligibility = useMemo(
    () => ({
      ...(props.variant === 'salaryDeferral'
        ? {
            entryDate: participant.entryDate,
            fundingSourceId: participant.fundingSourceId,
            isLtpt: participant.isLtpt ?? false,
            isOverride: participant.isOverride,
            note: participant.overrideNotes,
            status: participant.eligibilityStatus
          }
        : {}),
      ...(props.variant === 'employerMatch'
        ? {
            entryDate: participant.matchEligibility?.entryDate,
            fundingSourceId: participant.matchEligibility?.fundingSourceId,
            isLtpt: false,
            isOverride: participant.matchEligibility?.isOverride,
            note: participant.matchEligibility?.overrideNotes,
            status: participant.matchEligibility?.status
          }
        : {}),
      ...(props.variant === 'profitSharing'
        ? {
            entryDate: participant.profitSharingEligibility?.entryDate,
            fundingSourceId:
              participant.profitSharingEligibility?.fundingSourceId,
            isLtpt: false,
            isOverride: participant.profitSharingEligibility?.isOverride,
            note: participant.profitSharingEligibility?.overrideNotes,
            status: participant.profitSharingEligibility?.status
          }
        : {})
    }),
    [participant]
  );

  if (isOverrideFormShowed) {
    step.fields.overrideForm = {
      component: (
        <OverrideVestwellEligibilityForm
          fieldId='overrideForm'
          initialEligibilityStatus={eligibility.status}
          initialNote={eligibility.note}
          initialOverride={eligibility.isOverride}
          isStateIRA={isStateIRA}
          variant={props.variant}
        />
      ),
      initialValue: {
        checked: Boolean(eligibility.isOverride),
        entryDate:
          eligibility.entryDate === '--'
            ? dayjs()
            : dayjs(eligibility.entryDate),
        fundingSourceId: eligibility.fundingSourceId,
        isLtpt: eligibility.isLtpt,
        note: eligibility.note,
        status: eligibility.status
      },
      label: 'Override'
    };
  }

  const isSurpasAccountShowed =
    (isStateIRA || isBNYUser) &&
    userService.hasPermission(
      FeatureLevelPermissions.WRITE_EMPLOYMENT_ELIGIBILITY_SURPAS
    );

  if (isSurpasAccountShowed) {
    step.fields.surpasAccount = {
      initialValue: surpasAccount === '--' ? '' : surpasAccount,
      label: 'Surpas Account #',
      maxLength: 10,
      validate: validationFunctions.validateSurpasAccount
    };

    step.fields.dupAddrId = {
      initialValue: dupAddrId === '--' ? '' : dupAddrId,
      label: 'DUP ID',
      maxLength: 10,
      validate: validationFunctions.validateSurpasAccount
    };
  }

  return (
    <div
      className={classes.root}
      data-testid='participant-eligibility-card-by-type'>
      <Card
        className={classes.cardContainer}
        elevation={0}
        sx={{ border: 'none', padding: 0 }}
        variant='outlined'>
        <CardContent className={classes.contentBody} sx={{ padding: 0 }}>
          <Card elevation={0} style={{ maxWidth: '50%' }} variant='outlined'>
            <Box display='flex' flexDirection='column' padding='10px'>
              <Box
                alignItems='center'
                data-testid='eligibility-status-subtitle'
                display='flex'
                flexDirection='row'>
                <Typography
                  color={theme => theme.palette.grey[700]}
                  data-testid='status-heading'
                  variant='caption'>
                  {isStateIRA ? 'Employee Status' : 'Status'}
                </Typography>
              </Box>
              <div data-testid='eligibilityStatusBox'>
                <Typography data-testid='eligibilityStatus' variant='h6'>
                  {isStateIRA
                    ? participant.employeeStatus || EMPTY_FIELD_PLACEHOLDER
                    : eligibility.status || EMPTY_FIELD_PLACEHOLDER}
                  {(participant.employeeStatus === 'Eligible' ||
                    ['Eligible', 'Awaiting Entry Date'].includes(
                      eligibility.status
                    )) &&
                    eligibility.isLtpt && <span> (LTPT)</span>}
                </Typography>
                {eligibility.isOverride && (
                  <CollapsibleLabel
                    content={() => <span>{eligibility.note}</span>}
                    data-testid='overrideNotesCollabsibleLabel'
                    label={
                      isStateIRA
                        ? 'VW Eligibility Status Overridden'
                        : 'Overridden'
                    }
                  />
                )}
              </div>
            </Box>
          </Card>
          {!isStateIRA && (
            <CardInfoField
              fieldName='Entrance Date'
              fieldValue={eligibility.entryDate || EMPTY_FIELD_PLACEHOLDER}
            />
          )}
          <CardContent
            className={clsx(classes.cardContent, 'CardInfoField_cardContent')}>
            <Typography
              color={theme => theme.palette.grey[700]}
              data-testid='requirements-heading'
              variant='caption'>
              Requirements
            </Typography>
            {(!isStateIRA || props.isEsa) && (
              <Grid container data-testid='age-field' spacing={2}>
                <Grid item xs={4}>
                  <Typography
                    className={clsx(
                      classes.darkSubtitle,
                      'EligibilityCard__eligibilityRequirementName'
                    )}
                    color={theme => theme.palette.grey[700]}
                    data-testid='age-heading'
                    display='inline'
                    variant='caption'>
                    Age
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography
                    className='EligibilityCard__eligibilityRequirementValue'
                    color={theme => theme.palette.grey[700]}
                    data-testid='age-value'
                    display='inline'
                    variant='caption'>
                    {props.isEsa
                      ? dayjs(participant.birthDate).isAfter(dayjs(), 'date')
                        ? 'Unmet'
                        : 'Met'
                      : formattedEligibility.age}
                  </Typography>
                </Grid>
              </Grid>
            )}
            {!isStateIRA && !props.isEsa && (
              <Grid container data-testid='service-field' spacing={2}>
                <Grid item xs={4}>
                  <Typography
                    className={clsx(
                      classes.darkSubtitle,
                      'EligibilityCard__eligibilityRequirementName'
                    )}
                    color={theme => theme.palette.grey[700]}
                    data-testid='service-heading'
                    display='inline'
                    variant='caption'>
                    Service
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography
                    className='EligibilityCard__eligibilityRequirementValue'
                    color={theme => theme.palette.grey[700]}
                    data-testid='service-value'
                    display='inline'
                    variant='caption'>
                    {formattedEligibility.service}
                  </Typography>
                </Grid>
              </Grid>
            )}
            {isStateIRA && (
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <Typography
                    className={classes.darkSubtitle}
                    color={theme => theme.palette.grey[700]}
                    data-testid='waiting-period-heading'
                    display='inline'
                    variant='caption'>
                    Waiting Period
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <Typography
                    color={theme => theme.palette.grey[700]}
                    data-testid='waiting-period-value'
                    display='inline'
                    variant='caption'>
                    {formatters.calculateWaitingPeriod(participant.createdAt)}
                  </Typography>
                </Grid>
              </Grid>
            )}
            {(isStateIRA || props.isEsa) && (
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <Typography
                    className={classes.darkSubtitle}
                    color={theme => theme.palette.grey[700]}
                    data-testid='cip-status-heading'
                    display='inline'
                    variant='caption'>
                    CIP Status
                  </Typography>
                </Grid>
                <Grid item xs='auto'>
                  {props.isEsa && (
                    <Typography
                      color={theme => theme.palette.grey[700]}
                      data-testid='cip-status-value'
                      display='inline'
                      variant='caption'>
                      <CipStatus participantId={participant.participantId} />
                    </Typography>
                  )}
                  {!props.isEsa && (
                    <Typography
                      color={theme => theme.palette.grey[700]}
                      data-testid='cip-status-value'
                      display='inline'
                      variant='caption'>
                      {formatters.capitalizeFirstChar(
                        participant.stateIraCipStatus
                      )}
                    </Typography>
                  )}
                </Grid>
              </Grid>
            )}
            {isStateIRA && (
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <Typography
                    className={classes.darkSubtitle}
                    color={theme => theme.palette.grey[700]}
                    data-testid='account-status-heading'
                    display='inline'
                    variant='caption'>
                    Account Status
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    color={theme => theme.palette.grey[700]}
                    data-testid='account-status-value'
                    display='inline'
                    variant='caption'>
                    {formatters.capitalizeFirstChar(
                      participant.stateIraAccountStatus
                    )}
                  </Typography>
                </Grid>
                <Grid item>
                  <div className={classes.surpasAccountContainer}>
                    <Typography
                      className={classes.darkSubtitle}
                      color={theme => theme.palette.grey[700]}
                      data-testid='surpas-account-heading'
                      display='inline'
                      variant='caption'>
                      Surpas Account #: {surpasAccount}
                      {surpasAccount !== '--' && (
                        <CopyToClipboard
                          copyName='Surpas Account Number'
                          copyValue={surpasAccount}
                          iconCssClass={classes.copyIcon}
                          size='small'
                        />
                      )}
                    </Typography>
                  </div>
                </Grid>
                <Grid item>
                  <div className={classes.surpasAccountContainer}>
                    <Typography
                      className={classes.darkSubtitle}
                      color={theme => theme.palette.grey[700]}
                      data-testid='surpas-dup-id-heading'
                      display='inline'
                      variant='caption'>
                      DUP ID: {dupAddrId}
                      {dupAddrId !== '--' && (
                        <CopyToClipboard
                          copyName='DUP ID'
                          copyValue={dupAddrId}
                          iconCssClass={classes.copyIcon}
                          size='small'
                        />
                      )}
                    </Typography>
                  </div>
                </Grid>
              </Grid>
            )}
            {isStateIRA && (
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <Typography
                    className={classes.darkSubtitle}
                    color={theme => theme.palette.grey[700]}
                    data-testid='status-with-employer-heading'
                    display='inline'
                    variant='caption'>
                    Status with Employer
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography
                    color={theme => theme.palette.grey[700]}
                    data-testid='status-with-employer-value'
                    display='inline'
                    variant='caption'>
                    {formatters.formatStateIraPerEmployerStatus(
                      participant.stateIraPerEmployerStatus
                    )}
                  </Typography>
                </Grid>
              </Grid>
            )}
            {isStateIRA && (
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <Typography
                    className={classes.darkSubtitle}
                    data-testid='vestwell-eligibility-status-heading'
                    display='inline'
                    variant='caption'>
                    Vestwell Eligibility Status
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography
                    color={theme => theme.palette.grey[700]}
                    data-testid='vestwell-eligibility-status-value'
                    display='inline'
                    variant='caption'>
                    {eligibility.status}
                  </Typography>
                </Grid>
              </Grid>
            )}
            <Grid container data-testid='includedClass' spacing={2}>
              <Grid item xs={4}>
                <Typography
                  className={classes.darkSubtitle}
                  color={theme => theme.palette.grey[700]}
                  data-testid='included-class-heading'
                  display='inline'
                  variant='caption'>
                  Included Class
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography
                  color={theme => theme.palette.grey[700]}
                  data-testid='included-class-value'
                  display='inline'
                  variant='caption'>
                  {participant.isExcludedEmployee
                    ? `No - ${participant.employeeClass}`
                    : 'Yes'}
                </Typography>
              </Grid>
            </Grid>
          </CardContent>
        </CardContent>
        {props.isEsa || participant.isExcludedEmployee ? (
          ''
        ) : (
          <AccessControl
            requiresOneOf={[
              FeatureLevelPermissions.WRITE_EMPLOYMENT_ELIGIBILITY,
              FeatureLevelPermissions.WRITE_EMPLOYMENT_ELIGIBILITY_SURPAS
            ]}>
            <CardActions>
              <Button
                data-testid='manage-eligibility'
                onClick={() => {
                  const validationSchema =
                    createParticipantEligibilityValidationSchema({
                      initialEligibilityStatus: eligibility.status,
                      initialNote: eligibility.note,
                      initialOverrideStatus: eligibility.isOverride,
                      initialSurpasAccount: surpasAccount,
                      initialSurpasDupId: dupAddrId,
                      isBNYUser,
                      isOverrideFormShowed,
                      isStateIRA,
                      isSurpasAccountShowed,
                      terminationDate: props.terminationDate
                    });
                  openDialog({
                    actionButtons: {
                      cancelButton: {
                        children: 'Cancel'
                      },
                      submitButton: {
                        children: 'Save'
                      }
                    },
                    disableSubmitWhenInvalid: isOverrideFormShowed,
                    onSubmit: async (rawInputValues: any) => {
                      const isSurpasAcctUpdated = isUpdatedValue(
                        surpasAccount,
                        rawInputValues.surpasAccount
                      );
                      const isSurpasDupIdUpdated = isUpdatedValue(
                        dupAddrId,
                        rawInputValues.dupAddrId
                      );

                      if (isSurpasAcctUpdated || isSurpasDupIdUpdated) {
                        const body = {
                          data: {
                            attributes: {
                              sendEmailUpdate: false,
                              surpasAccountNumber: isSurpasAcctUpdated
                                ? rawInputValues.surpasAccount
                                : undefined,
                              surpasDupAddrId: isSurpasDupIdUpdated
                                ? rawInputValues.dupAddrId
                                : undefined
                            },
                            id: participant.participantId as number,
                            type: 'participant'
                          }
                        };
                        await updateSurpasAccount.mutateAsync(body);
                      }
                      if (
                        userService.hasPermission(
                          FeatureLevelPermissions.WRITE_EMPLOYMENT_ELIGIBILITY
                        )
                      ) {
                        await overrideEligibilityStatusMutation.mutateAsync({
                          eligibility: {
                            entryDate:
                              rawInputValues.overrideForm.status === 'Eligible'
                                ? rawInputValues.overrideForm.entryDate.format(
                                    'YYYY-MM-DD'
                                  )
                                : null,
                            fundingSourceId:
                              rawInputValues.overrideForm.fundingSourceId,
                            isLtpt: rawInputValues.overrideForm?.isLtpt,
                            isOverride: rawInputValues.overrideForm.checked,
                            overrideNotes: rawInputValues.overrideForm.note,
                            status: rawInputValues.overrideForm.status
                          }
                        });
                      }
                    },
                    steps: [step],
                    subcomponentProps: {
                      dialogContentProps: {
                        dividers: true
                      }
                    },
                    validationSchema
                  });
                }}
                type='button'
                variant='text'>
                MANAGE
              </Button>
            </CardActions>
          </AccessControl>
        )}
      </Card>
    </div>
  );
};

export default ParticipantEligibilityCard;
