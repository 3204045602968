import { useTitle } from '@/hooks/useTitle';
import { useUrlState } from '@/hooks/useUrlState.hook';
import { AlertSearchRequest } from '@/models/suba/alerts/AlertSearchRequest.model';
import {
  Alerts,
  AlertsProps
} from '@/routes/suba/common/components/alerts/Alerts.component';
import { AlertsFiltersFormValues } from '@/routes/suba/common/components/alerts/components/AlertsFiltersForm.component';
import formatters from '@/utils/Formatters';
import { Typography } from '@mui/material';
import { AlertPriority } from '@vestwell-sub-accounting/models/common/AlertPriority';
import { AlertStatus } from '@vestwell-sub-accounting/models/common/AlertStatus';
import { AlertSubType } from '@vestwell-sub-accounting/models/common/AlertSubType';
import { AlertType } from '@vestwell-sub-accounting/models/common/AlertType';

import { FC, useCallback, useEffect, useState } from 'react';

export const SearchAlertsPage: FC = () => {
  const title = useTitle('Alert Center');

  // state

  const [actualTabName, setActualTabName] = useState<string>('');
  const [urlState, setUrlState] = useUrlState<
    Pick<
      AlertSearchRequest,
      | 'alertType'
      | 'orderBy'
      | 'orderByDirection'
      | 'page'
      | 'pageSize'
      | keyof AlertsFiltersFormValues
    >
  >(
    {
      alertStatus: Object.values(AlertStatus).filter(
        status => status !== AlertStatus.Closed
      ),
      page: 1,
      pageSize: 25
    },
    {
      arrayParamKeys: ['alertStatus'],
      parsedValueTypes: {
        alertStatus: AlertStatus,
        alertSubType: AlertSubType,
        alertType: AlertType,
        assignee: 'string',
        orderBy: 'string',
        orderByDirection: 'string',
        page: 'number',
        pageSize: 'number',
        priority: AlertPriority,
        query: 'string'
      }
    }
  );

  // callbacks

  const handleAlertTypeChange = useCallback<AlertsProps['onAlertTypeChange']>(
    newAlertType => {
      setActualTabName(newAlertType);
      setUrlState(prevUrlState => ({
        ...prevUrlState,
        alertType: newAlertType
      }));
    },
    []
  );

  const handleAssigneeChange = useCallback<AlertsProps['onAssigneeChange']>(
    newAssignee => {
      setUrlState(prevUrlState => ({
        ...prevUrlState,
        assignee: newAssignee
      }));
    },
    []
  );

  const handleFiltersFormSubmit = useCallback<
    AlertsProps['onFiltersFormSubmit']
  >(values => {
    setUrlState(prevUrlState => ({
      ...prevUrlState,
      ...values,
      alertSubType: values.alertSubType || undefined,
      priority: values.priority || undefined,
      query: values.query || undefined
    }));
  }, []);

  const handlePageChange = useCallback<AlertsProps['onPageChange']>(newPage => {
    setUrlState(prevUrlState => ({
      ...prevUrlState,
      page: newPage
    }));
  }, []);

  const handlePageSizeChange = useCallback<AlertsProps['onPageChange']>(
    newPageSize => {
      setUrlState(prevUrlState => ({
        ...prevUrlState,
        page: 1,
        pageSize: newPageSize
      }));
    },
    []
  );

  const handleSortChange = useCallback<AlertsProps['onSortChange']>(newSort => {
    setUrlState(prevUrlState => ({
      ...prevUrlState,
      ...newSort,
      page: 1
    }));
  }, []);

  // effects

  useEffect(() => {
    title.setPageName(
      actualTabName
        ? `Alert Center ${formatters.displayCase(actualTabName)}`
        : 'Alert Center'
    );
  }, [actualTabName]);

  return (
    <>
      <Typography component='h2' mb={5} variant='h4'>
        Alert Center
      </Typography>
      <Alerts
        onAlertOpen={alert => {
          title.setPageName(`Alert ${alert.id} ${alert.alertName}`);
        }}
        onAlertTypeChange={handleAlertTypeChange}
        onAssigneeChange={handleAssigneeChange}
        onDrawerClose={() => {
          title.setPageName(
            actualTabName
              ? `Alert Center ${formatters.displayCase(actualTabName)}`
              : 'Alert Center'
          );
        }}
        onFiltersFormSubmit={handleFiltersFormSubmit}
        onPageChange={handlePageChange}
        onPageSizeChange={handlePageSizeChange}
        onSortChange={handleSortChange}
        searchParams={urlState}
      />
    </>
  );
};
