import CardInfoField from '@/components/card-info-field';
import FileUploadTable from '@/components/file-upload-table/FileUploadTable.component';
import LinearLoading from '@/components/linear-loading';
import { CONVERSION_TYPES, DOCUMENT_TYPES } from '@/consts/uploads';
import { useSnackbar } from '@/contexts/SnackBarContext';
import { Conversion, ConversionStatus } from '@/models/ConversionDTO.model';
import { FeatureLevelPermissions } from '@/models/UserPermissions.model';
import ActionTableForm from '@/routes/plans/plan-detail/PlanActionTableV2/ConversionMainComponents/ActionTableForm.component';
import { PlanService } from '@/services/Plan.service';
import { userService } from '@/services/User.service';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Stack
} from '@mui/material';
import { formatSsn } from '@vestwell-frontend/helpers';

import Decimal from 'decimal.js';
import React, { useCallback, useMemo, useRef, useState } from 'react';

import ConversionCardButton from './ConversionMainComponents/ConversionCardButton.component';
import ConversionDialog from './ConversionMainComponents/ConversionDialog';
import ConversionDialogHeader from './ConversionMainComponents/ConversionDialogHeader.component';
import {
  useConversionAgGrid,
  useConversionFile,
  useUploadOnboardingAgGridFile
} from './hooks';

interface InKindConversion {
  sponsorPlanId: number;
  conversion?: Conversion;
}

const columnDefs = [
  {
    alternates: [
      'social',
      'SS #',
      'ss#',
      'social security number',
      'social_security_number',
      'social security',
      'ssn#',
      'Taxpayer ID (SSN or Fed ID)',
      'socialsecuritynumber',
      'employee ssn',
      'national_identifier',
      'employee social',
      'national identifier',
      'ssn number',
      'ss number',
      'ssn/fein',
      'Social Security Number'
    ],
    cellEditorParams: {
      showExcludeRowButton: true
    },
    editable: true,
    field: 'ssn',
    headerName: 'SSN',
    minWidth: 250,
    required: true,
    type: 'ssn',
    valueParser: (value: string) => {
      if (/^(?!(000))\d{3}(?!00)\d{2}(?!0000)\d{4}$/.test(value)) {
        return formatSsn(value);
      }
      return value;
    }
  },
  {
    editable: true,
    field: 'source',
    headerName: 'Source',
    minWidth: 250,
    required: true,
    resizable: true,
    type: 'text'
  },
  {
    editable: true,
    field: 'cusip',
    headerName: 'CUSIP',
    minWidth: 250,
    required: true,
    resizable: true,
    type: 'text'
  },
  {
    editable: true,
    field: 'units',
    headerName: 'Units',
    minWidth: 250,
    required: true,
    resizable: true,
    type: 'text',
    valueParser: (value: string) => {
      if (isNaN(parseFloat(value))) return value;
      const decimalValue = new Decimal(parseFloat(value.replace(/,/g, '')));
      return decimalValue.toFixed(2);
    }
  },
  {
    alternates: ['sdba', 'isSdba', 'SDBA', 'isSDBA'],
    editable: true,
    field: 'isSdba',
    headerName: 'isSDBA',
    minWidth: 250,
    required: true,
    resizable: true,
    type: 'text',
    valueParser: (value: string) => {
      return value?.trim()?.toLowerCase();
    }
  }
];

const submitButtonText = (status?: string) => {
  if (status === ConversionStatus.Draft || !status)
    return 'Submit for approval';
  else if (status === ConversionStatus.Failed) return 'Resubmit';
  else return 'Approve & Submit';
};
const EMPTY_ROWS = 10;

const InKindConversion: React.FunctionComponent<InKindConversion> = ({
  sponsorPlanId,
  conversion
}: InKindConversion) => {
  const [openPlanActionDialog, setOpenPlanActionDialog] = useState(false);
  const conversionAgGrid = useConversionAgGrid(sponsorPlanId);
  const { showSnackbar } = useSnackbar();
  const userFileNameRef = useRef('');
  const userInfo = userService.getUser();
  const isUploadingUser = userInfo?.nickname === conversion?.submittedBy?.id;
  const { onClicked: editFileClicked, isLoading: isLoadingEditFile } =
    useConversionFile(
      conversionAgGrid.handleLoad,
      sponsorPlanId,
      CONVERSION_TYPES.IN_KIND_CONVERSION,
      conversion?.documentId,
      columnDefs
    );

  const {
    mutateAsync: uploadInKindConversion,
    isLoading,
    error
  } = useUploadOnboardingAgGridFile(
    sponsorPlanId as number,
    DOCUMENT_TYPES.IN_KIND_CONVERSION,
    userFileNameRef.current
  );

  const uniqueParticipantsCount = useMemo(() => {
    const ssnList = new Set();
    conversionAgGrid.gridRows.forEach((row: any) => {
      if (row.ssn) ssnList.add(row.ssn);
    });
    return ssnList.size;
  }, [conversionAgGrid.gridRows]);

  const getRowsWithPlanId = () => {
    return conversionAgGrid.getDataForUpload({ planId: sponsorPlanId });
  };

  const conversionFileExists = async (fileName: string): Promise<boolean> => {
    userFileNameRef.current = fileName;
    return PlanService.conversionFileExists(sponsorPlanId, fileName);
  };

  const isViewMode =
    conversion?.status && conversion.status !== ConversionStatus.Draft;

  const isDraftStage =
    conversion?.status === ConversionStatus.Draft || !conversion?.status;

  const handleSubmit = async () => {
    try {
      await uploadInKindConversion({
        conversionDto: {
          ...conversion,
          context: {
            ...conversion?.context
          },
          documentId:
            conversion?.status && conversion?.status !== ConversionStatus.Draft
              ? conversion.documentId
              : undefined,
          status:
            conversion?.status === ConversionStatus.Draft || !conversion?.status
              ? ConversionStatus.PendingApproval
              : ConversionStatus.PendingInvestment
        },
        results: getRowsWithPlanId()
      });
    } catch (e) {
      if (error) {
        showSnackbar({
          message: error?.message ?? 'Error',
          severity: 'error'
        });
      }
    } finally {
      setOpenPlanActionDialog(false);
      conversionAgGrid.form.current?.setValues([]);
    }
  };

  const handleSaveDraft = useCallback(async () => {
    try {
      await uploadInKindConversion({
        conversionDto: {
          ...conversion,
          context: {
            ...conversion?.context
          },
          documentId:
            conversion?.status && conversion?.status !== ConversionStatus.Draft
              ? conversion.documentId
              : undefined,
          status: ConversionStatus.Draft
        },
        results: getRowsWithPlanId()
      });
    } catch (e) {
      if (error) {
        showSnackbar({
          message: error?.message ?? 'Error',
          severity: 'error'
        });
      }
    } finally {
      setOpenPlanActionDialog(false);
      conversionAgGrid.form.current?.setValues([]);
    }
  }, [conversionAgGrid.form.current, conversion]);

  return (
    <>
      {conversion ? (
        <ConversionCardButton
          conversion={conversion}
          isLoading={isLoadingEditFile}
          onClick={() => {
            setOpenPlanActionDialog(true);
            editFileClicked();
          }}
          sponsorPlanId={sponsorPlanId}
        />
      ) : (
        <LoadingButton
          disabled={!sponsorPlanId || isLoading}
          loading={isLoading}
          onClick={() => {
            setOpenPlanActionDialog(true);
          }}
          startIcon={<FileUploadOutlinedIcon />}>
          Upload
        </LoadingButton>
      )}
      <ConversionDialog
        fullWidth
        maxWidth='lg'
        onClose={() => {
          setOpenPlanActionDialog(false);
          conversionAgGrid.form.current?.setValues([]);
        }}
        open={openPlanActionDialog}>
        <DialogTitle>
          <ConversionDialogHeader
            checkFileNameExists={conversionFileExists}
            columnDefs={columnDefs}
            conversion={conversion}
            onUpload={conversionAgGrid.handleLoad}
            title='In-Kind Conversion'
            viewMode={isViewMode}
          />
        </DialogTitle>
        <DialogContent>
          <ActionTableForm
            emptyRowsCount={!conversion ? EMPTY_ROWS : undefined}
            initialValues={conversionAgGrid.gridRows}
            onChange={conversionAgGrid.setGridRows}
            onErrors={conversionAgGrid.handleErrors}
            onSubmit={conversionAgGrid.handleSubmit}
            ref={conversionAgGrid.form}
            validateOnChange
            validateOnMount
            validationSchema={
              conversionAgGrid.inKindConversionValidationSchema
            }>
            <FileUploadTable
              columnDefs={columnDefs}
              errors={conversionAgGrid.gridErrors}
              isViewMode={isViewMode}
              onCellChanged={conversionAgGrid.handleCellChange}
              onRowsChanged={conversionAgGrid.handleRowsChange}
              rowData={conversionAgGrid.gridRows}
            />
            {isLoadingEditFile && (
              <Box mt={2}>
                <LinearLoading />
              </Box>
            )}
          </ActionTableForm>
        </DialogContent>
        <DialogActions>
          <Grid container>
            <Stack
              direction='row'
              divider={<Divider flexItem orientation='vertical' />}
              spacing={1}>
              <CardInfoField
                fieldName='Number of rows'
                fieldValue={conversionAgGrid.rowCount}
              />
              <CardInfoField
                fieldName='Unique participants'
                fieldValue={`${uniqueParticipantsCount}`}
              />
            </Stack>
          </Grid>

          {((conversion?.status &&
            [ConversionStatus.PendingApproval, ConversionStatus.Draft].includes(
              conversion.status
            )) ||
            !conversion?.status) && (
            <>
              <LoadingButton
                disabled={conversionAgGrid.rowCount === 0}
                loading={isLoading}
                onClick={handleSaveDraft}
                variant='text'>
                {isDraftStage ? 'Save as Draft' : 'Revert to Draft'}
              </LoadingButton>
              {((isUploadingUser && isDraftStage) ||
                (!isUploadingUser &&
                  userService.hasPermission(
                    FeatureLevelPermissions.WRITE_UPLOADS
                  ))) && (
                <LoadingButton
                  disabled={
                    conversionAgGrid.errorsCount > 0 ||
                    conversionAgGrid.rowCount === 0
                  }
                  loading={isLoading}
                  onClick={() => {
                    handleSubmit();
                  }}
                  sx={{ width: 300 }}
                  variant='contained'>
                  {submitButtonText(conversion?.status)}
                </LoadingButton>
              )}
            </>
          )}
        </DialogActions>
      </ConversionDialog>
    </>
  );
};

export default InKindConversion;
