import Card, {
  CardContent,
  CardHeader,
  CardPlaceholder
} from '@/components/card';
import {
  DataTable,
  DataTableBadgeCell,
  DataTableProps,
  DataTableStackCell
} from '@/components/data-table/DataTable.component';
import DownloadCSVButton from '@/components/download-csv-button/DownloadCSVButton.component';
import { redirectToErrorPage } from '@/components/error-detail/ErrorDetailPage.component';
import OpenInNewIcon from '@/components/icon/OpenInNewIcon';
import { useSnackbar } from '@/contexts/SnackBarContext';
import { useGetVestwellStaffQuery } from '@/hooks/suba/useGetVestwellStaffQuery.hook';
import { OrderByDirection } from '@/models/suba/common/OrderByDirection.model';
import { BreakageStatusColorMap } from '@/models/suba/recon/BreakageStatusColorMap.model';
import { ReconExceptionDto } from '@/models/suba/recon/ReconException.model';
import { FeatureLevelPermissions } from '@/models/UserPermissions.model';
import ReconExceptionService from '@/services/suba/recon-exceptions/ReconException.service';
import { userService } from '@/services/User.service';
import { numericComparator } from '@/utils/Comparators';
import formatters from '@/utils/Formatters';
import AcUnitIcon from '@mui/icons-material/AcUnit';
import SearchIcon from '@mui/icons-material/Search';
import {
  Box,
  FormControlLabel,
  Link,
  Stack,
  Switch,
  SwitchProps,
  Typography
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { BreakageDataType } from '@vestwell-sub-accounting/models/recon/BreakageDataType';
import { BreakageProcess } from '@vestwell-sub-accounting/models/recon/BreakageProcess';
import { BreakageStatus } from '@vestwell-sub-accounting/models/recon/BreakageStatus';

import { ColDef } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import dayjs from 'dayjs';
import { Formik } from 'formik';
import { FC, useCallback, useMemo, useRef } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import {
  ReconExceptionsFiltersForm,
  ReconExceptionsFiltersFormValues
} from './components/ReconExceptionsFiltersForm.component';
import { exportReconExceptions } from './utils/exportReconExceptions';

type SearchParams = Parameters<typeof ReconExceptionService.search>[0];

export type ReconExceptionsProps = {
  hideParentAccountIdInput?: boolean;
  onAssigneeChange: (assignee: string | null) => void;
  onFiltersFormSubmit: (values: ReconExceptionsFiltersFormValues) => void;
  onPageChange: DataTableProps['onPageChanged'];
  onPageSizeChange: DataTableProps['onPageSizeChanged'];
  onSortChange: (
    newSort: Pick<SearchParams, 'orderBy' | 'orderByDirection'>
  ) => void;
  searchParams: SearchParams;
};

export const ReconExceptions: FC<ReconExceptionsProps> = props => {
  // context

  const { showSnackbar } = useSnackbar();

  // refs

  const gridRef = useRef<AgGridReact>(null);

  // api

  const getVestwellStaffQuery = useGetVestwellStaffQuery();

  const searchReconExceptionsQuery = useQuery(
    ['ReconExceptionService.search', props.searchParams],
    () => ReconExceptionService.search(props.searchParams),
    {
      keepPreviousData: true,
      onError: (err: any) => {
        const message = err.response?.data ? err.response.data : err.message;
        showSnackbar({
          message: `Exception search failed: ${message}`,
          severity: 'error'
        });
      }
    }
  );

  // callbacks

  const handleAssigneeChange = useCallback<SwitchProps['onChange']>(event => {
    props.onAssigneeChange(
      event.target.checked ? userService.getUser()?.sub : null
    );
  }, []);

  const handleFiltersFormSubmit = useCallback(
    (values: ReconExceptionsFiltersFormValues) => {
      props.onFiltersFormSubmit(values);

      // always refetch when they submit the search form in case they made edits to exceptions in another browser tab
      searchReconExceptionsQuery.refetch();
    },
    []
  );

  const handleSortChanged = useCallback<DataTableProps['onSortChanged']>(
    newSortArray => {
      props.onSortChange(
        !newSortArray || newSortArray.length === 0
          ? {
              orderBy: undefined,
              orderByDirection: undefined
            }
          : {
              orderBy: newSortArray[0].colId as keyof ReconExceptionDto,
              orderByDirection: newSortArray[0].sort as OrderByDirection
            }
      );
    },
    []
  );

  // memos

  const FiltersFormFormik = useMemo(
    () => (
      <Formik<ReconExceptionsFiltersFormValues>
        enableReinitialize // required to sync Assignee menu with "Assigned to me" switch
        initialValues={{
          ...props.searchParams,
          cusipOrSymbol: props.searchParams.cusip || '',
          dataType: props.searchParams.dataType || '',
          endDate: props.searchParams.endDate || dayjs().format('YYYY-MM-DD'),
          id: props.searchParams.id?.toString(),
          lockAccountFlag:
            props.searchParams.lockAccountFlag === true
              ? 'true'
              : props.searchParams.lockAccountFlag === false
                ? 'false'
                : '',
          parentAccountId: props.searchParams.parentAccountId || '',
          process: props.searchParams.process || '',
          status: props.searchParams.status || []
        }}
        onSubmit={handleFiltersFormSubmit}>
        <ReconExceptionsFiltersForm
          hideParentAccountInput={props.hideParentAccountIdInput}
        />
      </Formik>
    ),
    [props.hideParentAccountIdInput, props.searchParams]
  );

  const parentAccountColumnDefs = useMemo<ColDef[]>(
    // these columns will be displayed when the component
    // is embedded on a parent account details "recon" tab
    () => [
      {
        cellRenderer: (cellData: { data: ReconExceptionDto }) => {
          return (
            <Link
              data-testid={`recon-exception-link-${cellData.data.id}`}
              href={`/ops/recon-exceptions/${cellData.data.id}`}
              target='_blank'>
              {cellData.data.id}
              <Box
                component='span'
                sx={{
                  lineHeight: 1,
                  pl: 0.5
                }}>
                <OpenInNewIcon sx={{ fontSize: 14 }} />
              </Box>
            </Link>
          );
        },
        field: 'id',
        headerName: 'ID',
        minWidth: 75,
        pinned: 'left',
        sortable: true
      },
      {
        autoHeight: true,
        cellRenderer: (cellData: { data: ReconExceptionDto }) => {
          // fetch friendly display name for status
          const displayStatus = formatters.getValueKey(
            BreakageStatus,
            cellData.data.status
          );

          return (
            <DataTableBadgeCell
              color={
                BreakageStatusColorMap[cellData.data.status] as
                  | 'neutral'
                  | 'success'
              }>
              {formatters.displayCase(displayStatus)}
            </DataTableBadgeCell>
          );
        },
        field: 'status',
        headerName: 'Status',
        minWidth: 175,
        sortable: true
      },
      {
        autoHeight: true,
        cellRenderer: (cellData: { data: ReconExceptionDto }) => {
          // fetch friendly display name for data type
          const displayDataType = formatters.getValueKey(
            BreakageDataType,
            cellData.data.dataType
          );
          // fetch friendly display name for process
          const displayProcess = formatters.getValueKey(
            BreakageProcess,
            cellData.data.process
          );
          return (
            <DataTableStackCell
              primary={formatters.displayCase(displayDataType)}
              secondary={formatters.displayCase(displayProcess)}
            />
          );
        },
        field: 'dataType',
        headerName: 'Break Type',
        minWidth: 255,
        sortable: true
      },
      {
        cellRenderer: (cellData: { data: ReconExceptionDto }) => {
          return (
            <DataTableStackCell
              primary={
                formatters.formatSecurityName(
                  cellData.data.security?.symbol,
                  cellData.data.security?.cusip
                ) || '\u2014'
              }
            />
          );
        },
        field: 'cusip',
        headerName: 'Ticker',
        minWidth: 125,
        sortable: true
      },
      {
        field: 'exceptionDate',
        headerName: 'Exception Date',
        minWidth: 200,
        sortable: true,
        valueFormatter: ({ value }: { value: string }) =>
          formatters.formatFromIsoDateCustom(value, 'MM/DD/YYYY')
      },
      {
        autoHeight: true,
        cellRenderer: (cellData: { data: ReconExceptionDto }) => {
          const name =
            cellData.data.parentAccount?.accountType === 'SuperOmnibus' ||
            cellData.data.parentAccount?.accountType === 'House'
              ? cellData.data.parentAccount?.accountName
              : cellData.data.parentAccount?.plan?.name;

          return (
            <DataTableStackCell
              icon={
                cellData.data.lockAccountFlag ? (
                  <Stack
                    alignItems='center'
                    justifyContent='center'
                    sx={{
                      background: theme => theme.palette.warning.lightBg,
                      borderRadius: 100,
                      color: theme => theme.palette.warning.dark,
                      fontSize: 12,
                      p: 0.75
                    }}>
                    <AcUnitIcon fontSize='inherit' />
                  </Stack>
                ) : undefined
              }
              primary={name || ''}
              secondary={`ID: ${cellData.data.parentAccountId}`}
            />
          );
        },
        field: 'parentAccountId',
        headerName: 'Parent Account',
        minWidth: 255,
        sortable: true
      },
      {
        cellRenderer: (cellData: { data: ReconExceptionDto }) => {
          if (!cellData.data.assignee) {
            return null;
          }
          const matchedAssignee = getVestwellStaffQuery.data?.find(
            staffUser => staffUser.userId === cellData.data.assignee
          );
          return (
            <DataTableStackCell
              primary={matchedAssignee?.label || cellData.data.assignee}
            />
          );
        },
        field: 'assignee',
        headerName: 'Assignee',
        maxWidth: 160,
        sortable: false
      }
    ],
    []
  );

  const standAloneColumnDefs = useMemo<ColDef[]>(
    () => [
      {
        cellRenderer: (cellData: { data: ReconExceptionDto }) => {
          return (
            <Link
              component={RouterLink}
              state={{
                search: window.location.search
              }}
              to={`/ops/recon-exceptions/${cellData.data.id}`}>
              {cellData.data.id}
            </Link>
          );
        },
        field: 'id',
        headerName: 'ID',
        minWidth: 75,
        pinned: 'left',
        sortable: true
      },
      {
        autoHeight: true,
        cellRenderer: (cellData: { data: ReconExceptionDto }) => {
          // fetch friendly display name for status
          const displayStatus = formatters.getValueKey(
            BreakageStatus,
            cellData.data.status
          );

          return (
            <DataTableBadgeCell
              color={
                BreakageStatusColorMap[cellData.data.status] as
                  | 'neutral'
                  | 'success'
              }>
              {formatters.displayCase(displayStatus)}
            </DataTableBadgeCell>
          );
        },
        field: 'status',
        headerName: 'Status',
        minWidth: 175,
        sortable: true
      },
      {
        autoHeight: true,
        cellRenderer: (cellData: { data: ReconExceptionDto }) => {
          const name =
            cellData.data.parentAccount?.accountType === 'SuperOmnibus' ||
            cellData.data.parentAccount?.accountType === 'House'
              ? cellData.data.parentAccount?.accountName
              : cellData.data.parentAccount?.plan?.name;

          return (
            <DataTableStackCell
              icon={
                cellData.data.lockAccountFlag ? (
                  <Stack
                    alignItems='center'
                    justifyContent='center'
                    sx={{
                      background: theme => theme.palette.warning.lightBg,
                      borderRadius: 100,
                      color: theme => theme.palette.warning.dark,
                      fontSize: 12,
                      p: 0.75
                    }}>
                    <AcUnitIcon fontSize='inherit' />
                  </Stack>
                ) : undefined
              }
              primary={name || ''}
              secondary={
                cellData.data.parentAccount?.parentAccountId
                  ? `ID: ${cellData.data.parentAccount?.parentAccountId}`
                  : ''
              }
            />
          );
        },
        field: 'parentAccountId',
        headerName: 'Parent Account',
        minWidth: 255,
        sortable: true
      },
      {
        autoHeight: true,
        cellRenderer: (cellData: { data: ReconExceptionDto }) => {
          // fetch friendly display name for data type
          const displayDataType = formatters.getValueKey(
            BreakageDataType,
            cellData.data.dataType
          );
          // fetch friendly display name for process
          const displayProcess = formatters.getValueKey(
            BreakageProcess,
            cellData.data.process
          );
          return (
            <DataTableStackCell
              primary={formatters.displayCase(displayDataType)}
              secondary={formatters.displayCase(displayProcess)}
            />
          );
        },
        field: 'dataType',
        headerName: 'Break Type / Break Process',
        minWidth: 255,
        sortable: true
      },
      {
        cellRenderer: (cellData: { data: ReconExceptionDto }) => {
          return (
            <DataTableStackCell
              primary={
                formatters.formatSecurityName(
                  cellData.data.security?.symbol,
                  cellData.data.security?.cusip
                ) || '\u2014'
              }
              secondary={cellData.data.security?.description}
            />
          );
        },
        field: 'cusip',
        headerName: 'Security',
        minWidth: 125,
        sortable: true
      },
      {
        field: 'exceptionDate',
        headerName: 'Exception Date',
        minWidth: 200,
        sortable: true,
        valueFormatter: ({ value }: { value: string }) =>
          formatters.formatFromIsoDateCustom(value, 'MM/DD/YYYY')
      },
      {
        field: 'notes',
        headerName: 'Notes',
        maxWidth: 320,
        minWidth: 125,
        sortable: true,
        tooltipField: 'notes'
      },
      {
        comparator: numericComparator,
        field: 'parentValue',
        headerName: 'Parent Value',
        minWidth: 75,
        sortable: true,
        type: 'numericColumn',
        valueFormatter: ({ value }: { value: string }) =>
          value === null ? '' : formatters.formatDollars(value || 0, 3)
      },
      {
        comparator: numericComparator,
        field: 'comparisonValue',
        headerName: 'Comparison Value',
        minWidth: 75,
        sortable: true,
        type: 'numericColumn',
        valueFormatter: ({ value }: { value: string }) =>
          value === null ? '' : formatters.formatDollars(value || 0, 3)
      },
      {
        comparator: numericComparator,
        field: 'valueDifference',
        headerName: 'Value Difference',
        minWidth: 75,
        sortable: true,
        type: 'numericColumn',
        valueFormatter: ({ value }: { value: string }) =>
          value === null ? '' : formatters.formatDollars(value || 0, 3)
      },
      {
        comparator: numericComparator,
        field: 'parentUnits',
        headerName: 'Parent Units',
        minWidth: 75,
        sortable: true,
        type: 'numericColumn',
        valueFormatter: ({ value }: { value: string }) =>
          value === null ? '' : formatters.formatDecimal(value || 0, 3)
      },
      {
        comparator: numericComparator,
        field: 'comparisonUnits',
        headerName: 'Comparison Units',
        minWidth: 75,
        sortable: true,
        type: 'numericColumn',
        valueFormatter: ({ value }: { value: string }) =>
          value === null ? '' : formatters.formatDecimal(value || 0, 3)
      },
      {
        comparator: numericComparator,
        field: 'unitsDifference',
        headerName: 'Units Difference',
        minWidth: 75,
        sortable: true,
        type: 'numericColumn',
        valueFormatter: ({ value }: { value: string }) =>
          value === null ? '' : formatters.formatDecimal(value || 0, 3)
      },
      {
        cellRenderer: (cellData: { data: ReconExceptionDto }) => {
          if (!cellData.data.assignee) {
            return null;
          }
          const matchedAssignee = getVestwellStaffQuery.data?.find(
            staffUser => staffUser.userId === cellData.data.assignee
          );
          return (
            <DataTableStackCell
              primary={matchedAssignee?.label || cellData.data.assignee}
            />
          );
        },
        field: 'assignee',
        headerName: 'Assignee',
        maxWidth: 160,
        sortable: false
      }
    ],
    []
  );

  // consts

  const hasRequiredPermissions = userService.hasPermission(
    FeatureLevelPermissions.READ_SUBA_RECON
  );

  if (!hasRequiredPermissions) {
    return redirectToErrorPage(new Error('403'));
  }

  return (
    <Card>
      <CardHeader
        data-testid='exceptions-header'
        loading={
          !!searchReconExceptionsQuery.data?.results?.length &&
          searchReconExceptionsQuery.isFetching
        }
        title='All Exceptions'>
        <Stack direction='row' spacing={2}>
          <FormControlLabel
            control={
              <Switch
                checked={
                  props.searchParams.assignee &&
                  userService.getUser()?.sub === props.searchParams.assignee
                }
                data-testid='assigned-to-me-switch'
                disabled={userService.getUser()?.sub === undefined}
                inputProps={{ 'aria-label': 'assigned to me' }}
                onChange={handleAssigneeChange}
                size='small'
              />
            }
            label={
              <Typography sx={{ fontSize: 14 }}>Assigned to me</Typography>
            }
          />
          <DownloadCSVButton
            buttonProps={{
              sx: {
                fontSize: 'small'
              },
              variant: 'outlined'
            }}
            fileName={`Exceptions-${props.searchParams.parentAccountId || 'All'}.csv`}
            getInfo={() => exportReconExceptions(props.searchParams)}
            text='EXPORT CSV'
          />
        </Stack>
      </CardHeader>
      <CardContent
        disablePadding
        overlayLoading={getVestwellStaffQuery.isInitialLoading}>
        <DataTable
          columnDefs={
            props.searchParams.parentAccountId
              ? parentAccountColumnDefs
              : standAloneColumnDefs
          }
          data-testid='data-exceptions-table'
          emptyPlaceholderComponent={
            <Stack
              alignItems='center'
              data-testid='no-data-exceptions-table'
              justifyContent='center'
              sx={{ height: '100%' }}>
              <CardPlaceholder
                icon={<SearchIcon fontSize='inherit' />}
                subtitle={
                  !searchReconExceptionsQuery.data?.results
                    ? 'Search results will be displayed here.'
                    : 'No results found.'
                }
              />
            </Stack>
          }
          filterSidePanelComponent={FiltersFormFormik}
          gridRef={gridRef}
          onPageChanged={props.onPageChange}
          onPageSizeChanged={props.onPageSizeChange}
          onSortChanged={handleSortChanged}
          page={props.searchParams.page}
          pageSize={props.searchParams.pageSize}
          pagination
          paginationSource='server'
          paginationTotal={searchReconExceptionsQuery.data?.pagination?.total}
          rowData={searchReconExceptionsQuery.data?.results || []}
          sort={
            props.searchParams.orderBy
              ? [
                  {
                    colId: props.searchParams.orderBy,
                    sort: props.searchParams.orderByDirection
                  }
                ]
              : []
          }
        />
      </CardContent>
    </Card>
  );
};
