import useHasPermissions from '@/components/access-control/useHasPermissions.hook';
import {
  LoanDetailsResponseDto,
  LoanExpectedPayment,
  LoanStatus,
  LoanStatusEnum,
  LoanTransactionResponseDto,
  LoanValidationResult,
  UpdateLoanAttributes,
  UpdateLoanDto
} from '@/models/LoanDTO.model';
import { FeatureLevelPermissions } from '@/models/UserPermissions.model';
import ParticipantService from '@/services/Participant.service';
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Unstable_Grid2 as Grid,
  Modal,
  Stack,
  Tooltip,
  Typography
} from '@mui/material';
import { grey } from '@mui/material/colors';
import { useMutation, useQuery } from '@tanstack/react-query';

import { FC, useMemo, useState } from 'react';
import { useToggle } from 'react-use';

import { LoanReamortizationDialog } from './LoanReamortizationDialog.component';

type LoanActionsProps = {
  loanDetails: LoanDetailsResponseDto;
  loanExpectedPayments: LoanExpectedPayment[];
  missingParticipantAddressinfo: boolean;
  onSubmit: () => void;
  participantId: string;
  planBlackoutActive: boolean;
  sponsorPlanId: number;
  suspiciousActivity: boolean;
  tradeRequestStatus?: string;
};

export const LoanActions: FC<LoanActionsProps> = (props: LoanActionsProps) => {
  const [open, toggleOpen] = useToggle(false);
  const [openReamortize, toggleOpenReamortize] = useToggle(false);
  const [newStatus, setNewStatus] = useState<LoanStatus | undefined>();

  const fraudPerms = useHasPermissions({
    requires: [FeatureLevelPermissions.WRITE_FRAUD]
  });

  const loanPerms = useHasPermissions({
    requires: [FeatureLevelPermissions.WRITE_LOANS_ACTION]
  });

  const loanStatusMutation = useMutation(
    [
      'ParticipantService.updateLoan',
      props.participantId,
      props.loanDetails?.data?.id
    ],
    (attr: UpdateLoanAttributes) =>
      ParticipantService.updateLoan(
        +props.participantId,
        +props.loanDetails?.data?.id,
        {
          attributes: { ...attr },
          id: props.loanDetails?.data?.id
        } as UpdateLoanDto
      ),
    {
      onSuccess: () => {
        props.onSubmit();
      }
    }
  );

  const loanValidation = useQuery<LoanValidationResult>(
    ['ParticipantService.validateLoan', props.loanDetails?.data?.id],
    () => {
      return ParticipantService.validateLoan(props.loanDetails?.data?.id);
    },
    { enabled: open && newStatus === LoanStatusEnum.DisbursementPending }
  );

  const displayValidationErrors = useMemo(() => {
    return (
      newStatus === LoanStatusEnum.DisbursementPending &&
      loanValidation.data?.validationErrors.length > 0 &&
      !loanValidation.isFetching
    );
  }, [loanValidation, newStatus]);

  const transactions = useQuery<LoanTransactionResponseDto>(
    [
      'ParticipantService.getLoanTransactions',
      +props.participantId,
      +props.loanDetails?.data?.id
    ],
    () => {
      return ParticipantService.getLoanTransactions(
        +props.participantId,
        +props.loanDetails?.data?.id
      );
    },
    {
      staleTime: Infinity
    }
  );

  return (
    <>
      {!props.loanDetails?.data.attributes.isReamortizing && (
        <Stack alignItems='center' direction='row' spacing={2}>
          {props.loanDetails?.data.attributes.loanStatus ===
            LoanStatusEnum.FraudCheckComplete && (
            <Tooltip
              disableHoverListener={
                !props.planBlackoutActive &&
                !props.missingParticipantAddressinfo
              }
              title={
                props.planBlackoutActive
                  ? 'Plan is in blackout period.'
                  : props.missingParticipantAddressinfo
                    ? 'Participant is missing address info.'
                    : ''
              }>
              <span>
                <Button
                  color='primary'
                  data-testid='mark-as-approved'
                  disabled={
                    props.planBlackoutActive ||
                    props.suspiciousActivity ||
                    props.missingParticipantAddressinfo ||
                    !loanPerms.isAllowed
                  }
                  onClick={() => {
                    setNewStatus(LoanStatusEnum.DisbursementPending);
                    toggleOpen();
                  }}
                  variant='outlined'>
                  Approve
                </Button>
              </span>
            </Tooltip>
          )}
          {props.loanDetails?.data?.attributes?.loanStatus ===
            LoanStatusEnum.FraudCheckPending && (
            <Button
              color='primary'
              data-testid='fraud-check-complete'
              disabled={
                props.planBlackoutActive ||
                props.suspiciousActivity ||
                props.missingParticipantAddressinfo ||
                !fraudPerms.isAllowed
              }
              onClick={() => {
                setNewStatus(LoanStatusEnum.FraudCheckComplete);
                toggleOpen();
              }}
              variant='outlined'>
              Complete Fraud Check
            </Button>
          )}
          {[
            LoanStatusEnum.AwaitingParticipantAcceptance,
            LoanStatusEnum.FraudCheckPending,
            LoanStatusEnum.FraudCheckComplete
          ].includes(props.loanDetails?.data?.attributes?.loanStatus) && (
            <>
              <Button
                color='primary'
                data-testid='mark-as-rejected'
                disabled={!loanPerms.isAllowed && !fraudPerms.isAllowed}
                onClick={() => {
                  setNewStatus(LoanStatusEnum.Rejected);
                  toggleOpen();
                }}
                variant='outlined'>
                Reject
              </Button>
              <Button
                color='error'
                data-testid='mark-as-canceled'
                disabled={!loanPerms.isAllowed}
                onClick={() => {
                  setNewStatus(LoanStatusEnum.Canceled);
                  toggleOpen();
                }}
                variant='outlined'>
                Cancel
              </Button>
            </>
          )}
          {['Past Due', 'Deemed'].includes(
            props.loanDetails?.data?.attributes?.loanStatus
          ) && (
            <Button
              color='primary'
              data-testid='mark-as-offset'
              disabled={!loanPerms.isAllowed}
              onClick={() => {
                setNewStatus(LoanStatusEnum.Offset);
                toggleOpen();
              }}
              variant='outlined'>
              Offset
            </Button>
          )}
          {[
            LoanStatusEnum.Live,
            LoanStatusEnum.PastDue,
            LoanStatusEnum.Deemed
          ].includes(props.loanDetails?.data?.attributes?.loanStatus) && (
            <>
              <Button
                color='primary'
                data-testid='mark-as-paid'
                disabled={!loanPerms.isAllowed}
                onClick={() => {
                  setNewStatus(LoanStatusEnum.PaidInFull);
                  toggleOpen();
                }}
                variant='outlined'>
                Mark as Paid
              </Button>
              <Button
                color='primary'
                data-testid='mark-as-reamortize'
                disabled={!loanPerms.isAllowed}
                onClick={toggleOpenReamortize}
                variant='outlined'>
                Reamortize
              </Button>
            </>
          )}
          {props.loanDetails?.data?.attributes?.loanStatus ===
            LoanStatusEnum.Live && (
            <Button
              color='primary'
              data-testid='mark-as-past-due'
              disabled={!loanPerms.isAllowed}
              onClick={() => {
                setNewStatus(LoanStatusEnum.PastDue);
                toggleOpen();
              }}
              variant='outlined'>
              Mark as Past Due
            </Button>
          )}
          {props.loanDetails?.data?.attributes?.loanStatus ===
            LoanStatusEnum.PastDue && (
            <Button
              color='primary'
              data-testid='mark-as-deemed'
              disabled={!loanPerms.isAllowed}
              onClick={() => {
                setNewStatus(LoanStatusEnum.Deemed);
                toggleOpen();
              }}
              variant='outlined'>
              Deemed
            </Button>
          )}
          {props.loanDetails?.data?.attributes?.loanStatus ===
            LoanStatusEnum.Live &&
            transactions.data?.data.some(e => e.status == 'REVERSED') && (
              <Button
                color='error'
                data-testid='mark-as-canceled'
                disabled={!loanPerms.isAllowed}
                onClick={() => {
                  setNewStatus(LoanStatusEnum.Canceled);
                  toggleOpen();
                }}
                variant='outlined'>
                Cancel
              </Button>
            )}
          {LoanStatusEnum.DisbursementPending ===
            props.loanDetails?.data?.attributes?.loanStatus &&
            (props.tradeRequestStatus === undefined ||
              ['SUBA_ABORTED', 'SUBA_FAILED'].includes(
                props.tradeRequestStatus
              )) && (
              <Button
                color='error'
                data-testid='mark-as-canceled'
                disabled={!loanPerms.isAllowed}
                onClick={() => {
                  setNewStatus(LoanStatusEnum.Canceled);
                  toggleOpen();
                }}
                variant='outlined'>
                Cancel
              </Button>
            )}
          {LoanStatusEnum.Default ===
            props.loanDetails?.data?.attributes?.loanStatus && (
            <Button
              color='primary'
              data-testid='mark-as-reamortize'
              disabled={!loanPerms.isAllowed}
              onClick={toggleOpenReamortize}
              variant='outlined'>
              Reamortize
            </Button>
          )}
        </Stack>
      )}
      {displayValidationErrors ? (
        <Dialog fullWidth maxWidth='md' onClose={toggleOpen} open={open}>
          <DialogTitle>Validation errors were found</DialogTitle>
          <DialogContent>
            <Grid container spacing={4}>
              <Grid container spacing={2} xs={12}>
                {loanValidation.data?.validationErrors.map((error, index) => (
                  <Grid key={index} xs={12}>
                    <Alert severity='error'>{error.error}</Alert>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              data-testid='tpa-plans-modal-cancel-btn'
              onClick={toggleOpen}>
              OK
            </Button>
          </DialogActions>
        </Dialog>
      ) : (
        !loanValidation.isFetching && (
          <Modal onClose={toggleOpen} open={open}>
            <Box
              sx={{
                backgroundColor: grey[50],
                boxShadow: '24px',
                left: '50%',
                padding: theme => theme.spacing(4),
                position: 'absolute',
                top: '50%',
                transform: 'translate(-50%, -50%)'
              }}>
              <Typography component='div' variant='h5'>
                Update Loan
              </Typography>
              <Typography
                sx={{
                  marginBottom: theme => theme.spacing(4),
                  marginTop: theme => theme.spacing(4)
                }}
                variant='body1'>
                Are you sure you want to change the status to {newStatus}?
              </Typography>
              <Button
                disabled={!newStatus}
                onClick={() => {
                  if (newStatus)
                    loanStatusMutation.mutate({ status: newStatus });
                  toggleOpen();
                }}
                variant='contained'>
                CONFIRM
              </Button>
              <Button onClick={toggleOpen}>CANCEL</Button>
            </Box>
          </Modal>
        )
      )}
      <LoanReamortizationDialog
        onClose={toggleOpenReamortize}
        onSubmit={props.onSubmit}
        open={openReamortize}
        originalLoan={props.loanDetails}
        originalPayments={props.loanExpectedPayments}
        sponsorPlanId={props.sponsorPlanId}
      />
    </>
  );
};

LoanActions.displayName = 'LoanActions';
