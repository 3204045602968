import Card, {
  CardHeader,
  CardToggleProps
} from '@/components/card/Card.component';
import { redirectToErrorPage } from '@/components/error-detail/ErrorDetailPage.component';
import { SEARCH_PARENT_ACCOUNTS_QUERY_KEY } from '@/hooks/suba/useSearchParentAccountsQuery.hook';
import { SEARCH_SUB_ACCOUNTS_QUERY_KEY } from '@/hooks/suba/useSearchSubAccountsQuery.hook';
import { useTitle } from '@/hooks/useTitle';
import { useUrlState } from '@/hooks/useUrlState.hook';
import { SubAccountSearchRequest } from '@/models/suba/accounts/SubAccountSearchRequest.model';
import { FeatureLevelPermissions } from '@/models/UserPermissions.model';
import {
  ParentAccounts,
  ParentAccountsProps
} from '@/routes/suba/accounts/common/components/parent-accounts/ParentAccounts.component';
import { userService } from '@/services/User.service';
import { Typography } from '@mui/material';
import { useIsFetching } from '@tanstack/react-query';
import { AccountLevel } from '@vestwell-sub-accounting/models/accountsAndLedgers/AccountLevel';
import { CustodianId } from '@vestwell-sub-accounting/models/common/CustodianId';
import { ParentAccountType } from '@vestwell-sub-accounting/models/common/ParentAccountType';

import { FC, useCallback, useMemo } from 'react';

import {
  SubAccounts,
  SubAccountsProps
} from '../common/components/sub-accounts/SubAccounts.component';

export const SearchAccountsPage: FC = () => {
  useTitle('Accounts');

  // context

  const isFetchingParentAccounts = useIsFetching([
    SEARCH_PARENT_ACCOUNTS_QUERY_KEY
  ]);
  const isFetchingSubAccounts = useIsFetching([SEARCH_SUB_ACCOUNTS_QUERY_KEY]);

  // state

  const [urlState, setUrlState] = useUrlState<{
    accountLevel: AccountLevel;
    custodianId?: CustodianId;
    parentAccountType: ParentAccountType;
    subAccounts: Pick<
      SubAccountSearchRequest,
      | 'hasDebitSubAccounts'
      | 'orderBy'
      | 'orderByDirection'
      | 'page'
      | 'pageSize'
      | 'query'
    >;
    query?: string;
  }>(
    {
      accountLevel: AccountLevel.ParentAccount,
      parentAccountType: ParentAccountType.PlanLevel,
      subAccounts: {
        hasDebitSubAccounts: false,
        page: 1,
        pageSize: 25,
        query: ''
      }
    },
    {
      parsedValueTypes: {
        accountLevel: AccountLevel,
        custodianId: CustodianId,
        parentAccountType: ParentAccountType,
        query: 'string',
        subAccounts: 'json'
      }
    }
  );

  // callbacks

  const handleParentAccountsSearchFormSubmit = useCallback<
    ParentAccountsProps['onSearchFormSubmit']
  >(values => {
    setUrlState(prevUrlState => ({
      ...prevUrlState,
      custodianId: values.custodianId as CustodianId,
      page: 1,
      query: values.query
    }));
  }, []);

  const handleSubAccountsPageChange = useCallback<
    SubAccountsProps['onPageChange']
  >(newPage => {
    setUrlState(prevUrlState => ({
      ...prevUrlState,
      page: newPage
    }));
  }, []);

  const handleSubAccountsPageSizeChange = useCallback<
    SubAccountsProps['onPageChange']
  >(newPageSize => {
    setUrlState(prevUrlState => ({
      ...prevUrlState,
      pageSize: newPageSize
    }));
  }, []);

  const handleSubAccountsSortChange = useCallback<
    SubAccountsProps['onSortChange']
  >(newSort => {
    setUrlState(prevUrlState => ({
      ...prevUrlState,
      ...newSort,
      page: 1
    }));
  }, []);

  const handleSubAccountsSearchFormSubmit = useCallback<
    SubAccountsProps['onSearchFormSubmit']
  >(values => {
    setUrlState(prevUrlState => ({
      ...prevUrlState,
      subAccounts: {
        ...prevUrlState.subAccounts,
        ...values,
        page: 1
      }
    }));
  }, []);

  // memos

  const togglesOptions = useMemo<CardToggleProps[]>(
    () =>
      [
        {
          onChangeAction: (_, value: AccountLevel) => {
            setUrlState(prevUrlState => ({
              ...prevUrlState,
              accountLevel: value
            }));
          },
          options: [
            {
              label: 'Parent',
              value: AccountLevel.ParentAccount
            },
            { label: 'Sub', value: AccountLevel.SubAccount }
          ],
          value: urlState.accountLevel
        },
        urlState.accountLevel === AccountLevel.ParentAccount && {
          name: 'Parent Type',
          onChangeAction: (_, value: ParentAccountType) => {
            setUrlState(prevUrlState => ({
              ...prevUrlState,
              parentAccountType: value,
              query: undefined
            }));
          },
          options: [
            {
              label: 'Plan level',
              value: ParentAccountType.PlanLevel
            },
            { label: 'Super omni', value: ParentAccountType.SuperOmnibus },
            { label: 'House', value: ParentAccountType.House }
          ],
          value: urlState.parentAccountType
        }
      ].filter(Boolean),
    [urlState.accountLevel]
  );

  if (!userService.hasPermission(FeatureLevelPermissions.READ_SUBA_ACCOUNTS)) {
    return redirectToErrorPage(new Error('403'));
  }

  return (
    <>
      <Typography component='h2' mb={5} variant='h4'>
        Accounts
      </Typography>
      <Card>
        <CardHeader
          data-testid='search-accounts'
          loading={!!isFetchingParentAccounts || !!isFetchingSubAccounts}
          title='Search Accounts'
          toggles={togglesOptions}
        />
        {urlState.accountLevel === AccountLevel.ParentAccount && (
          <ParentAccounts
            onSearchFormSubmit={handleParentAccountsSearchFormSubmit}
            searchParams={{
              accountType: urlState.parentAccountType,
              custodianId: urlState.custodianId,
              query: urlState.query
            }}
          />
        )}
        {urlState.accountLevel === AccountLevel.SubAccount && (
          <SubAccounts
            onPageChange={handleSubAccountsPageChange}
            onPageSizeChange={handleSubAccountsPageSizeChange}
            onSearchFormSubmit={handleSubAccountsSearchFormSubmit}
            onSortChange={handleSubAccountsSortChange}
            searchParams={urlState.subAccounts}
          />
        )}
      </Card>
    </>
  );
};
