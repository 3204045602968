import { CONVERSION_TYPES } from '@/consts/uploads';
import { PlanV2Dto } from '@/models';
import { ConversionStatus } from '@/models/ConversionDTO.model';
import { PlanService } from '@/services/Plan.service';
import { Grid } from '@mui/material';
import { useQuery } from '@tanstack/react-query';

import React, { ReactElement, useMemo } from 'react';

import CashAllocation from '../CashAllocation.component';
import { DeferralRates } from '../DeferralRates';
import InKindConversion from '../InKindConversion.component';
import { InvestmentElectionMapping } from '../InvestmentElectionMapping';
import LoansConversion from '../LoansConversion.component';
import RothCostBasis from '../RothCostBasis.component';
import ConversionCard from './ConversionCard.component';

type PlanActionTableProps = {
  sponsorPlanId: number;
};

const PlanActionTable = ({
  sponsorPlanId
}: PlanActionTableProps): ReactElement => {
  const planQuery = useQuery<PlanV2Dto>(
    ['PlanService.getPlanById', sponsorPlanId?.toString()],
    () => {
      if (!sponsorPlanId) throw new Error(`keep the compiler happy`);

      return PlanService.getPlanById(sponsorPlanId);
    },
    {
      enabled: !!sponsorPlanId,
      staleTime: Infinity
    }
  );

  const conversions = useQuery(
    [`PlanService.getPlanConversions ${sponsorPlanId}`],
    () => PlanService.getPlanConversions(sponsorPlanId),
    {
      enabled: Boolean(sponsorPlanId)
    }
  );

  const isVestwellSubaccounting = useMemo(
    () =>
      planQuery.data?.data.attributes.recordkeeper ===
      'Vestwell Sub-Accounting Platform',
    [planQuery.data?.data.attributes.recordkeeper]
  );

  const showDeferralRates = ['Vestwell Sub-Accounting Platform', 'LT'].includes(
    planQuery.data?.data.attributes.recordkeeper ?? ''
  );

  return (
    <>
      <Grid
        columnSpacing={{ md: 3, sm: 2, xs: 1 }}
        container
        rowSpacing={1}
        sx={{ alignItems: 'center' }}>
        {isVestwellSubaccounting && (
          <Grid item xs={6}>
            <ConversionCard
              component={<CashAllocation sponsorPlanId={sponsorPlanId} />}
              conversionData={
                conversions?.data?.filter(
                  i => i.conversionType === CONVERSION_TYPES.CASH_ALLOCATION
                ) || []
              }
              sponsorPlanId={sponsorPlanId}
              title='Cash Allocation'
            />
          </Grid>
        )}
        {showDeferralRates && (
          <Grid item xs={6}>
            <ConversionCard
              component={<DeferralRates sponsorPlanId={sponsorPlanId} />}
              conversionData={
                conversions?.data?.filter(
                  i => i.conversionType === CONVERSION_TYPES.DEFERRAL_RATES
                ) || []
              }
              sponsorPlanId={sponsorPlanId}
              title='Deferral Rates'
            />
          </Grid>
        )}
        {isVestwellSubaccounting && (
          <Grid item xs={6}>
            <ConversionCard
              component={<RothCostBasis sponsorPlanId={sponsorPlanId} />}
              conversionData={
                conversions?.data?.filter(
                  i => i.conversionType === CONVERSION_TYPES.ROTH_COST_BASIS
                ) || []
              }
              sponsorPlanId={sponsorPlanId}
              title='Roth Cost Basis'
            />
          </Grid>
        )}
        {isVestwellSubaccounting && (
          <Grid item xs={6}>
            <ConversionCard
              component={<LoansConversion sponsorPlanId={sponsorPlanId} />}
              conversionData={
                conversions?.data?.filter(
                  i => i.conversionType === CONVERSION_TYPES.LOANS
                ) || []
              }
              sponsorPlanId={sponsorPlanId}
              title='Loan Conversions'
            />
          </Grid>
        )}
        <Grid item xs={6}>
          <ConversionCard
            component={
              <InvestmentElectionMapping
                isHasActiveConversion={
                  conversions?.data?.some(
                    conversion =>
                      conversion.conversionType ===
                        CONVERSION_TYPES.INVESTMENT_ELECTION_MAPPINGS &&
                      (conversion.status === ConversionStatus.Draft ||
                        conversion.status === ConversionStatus.PendingApproval)
                  ) || false
                }
                sponsorPlanId={sponsorPlanId}
              />
            }
            conversionData={
              conversions?.data?.filter(
                i =>
                  i.conversionType ===
                  CONVERSION_TYPES.INVESTMENT_ELECTION_MAPPINGS
              ) || []
            }
            sponsorPlanId={sponsorPlanId}
            title='Investment Election Mapping'
          />
        </Grid>
        {isVestwellSubaccounting && (
          <Grid item xs={6}>
            <ConversionCard
              component={<InKindConversion sponsorPlanId={sponsorPlanId} />}
              conversionData={
                conversions?.data?.filter(
                  i => i.conversionType === CONVERSION_TYPES.IN_KIND_CONVERSION
                ) || []
              }
              sponsorPlanId={sponsorPlanId}
              title='In-Kind Conversion'
            />
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default React.memo(PlanActionTable);
