import { CircularLoading } from '@/components/circular-loading';
import { useGetSecurityQuery } from '@/hooks/suba/useGetSecurity.hook';
import { useUrlState } from '@/hooks/useUrlState.hook';
import { DividendDeclarationDto } from '@/models/suba/dividend-declarations/DividendDeclarationDto.model';
import { useSecuritiesDetailRouteParams } from '@/routes/suba/securities/securities-detail/hooks/useSecurityDetailRouteParams.hook';
import { Box, Stack } from '@mui/material';

import dayjs from 'dayjs';
import { FC, useCallback, useState } from 'react';

import {
  AccrualDetailsCard,
  AccrualDetailsCardProps
} from './components/AccrualDetailsCard.component';
import { DividendDeclarationDetail } from './components/DividendDeclarationDetail.component';
import {
  DividendDeclarationsCard,
  DividendDeclarationsCardProps
} from './components/DividendDeclarationsCard.component';

export type DividendsTabUrlState = {
  accrualDetails: Omit<AccrualDetailsCardProps['searchParams'], 'cusip'>;
  dividendDeclarations: Omit<
    DividendDeclarationsCardProps['searchParams'],
    'cusip'
  >;
};

export const DividendsTab: FC = () => {
  // context

  const routeParams = useSecuritiesDetailRouteParams();

  // state

  const [urlState, setUrlState] = useUrlState<DividendsTabUrlState>(
    {
      accrualDetails: {
        page: 1,
        pageSize: 50
      },
      dividendDeclarations: {
        page: 1,
        pageSize: 50,
        paymentEndDate: dayjs().format('YYYY-MM-DD'),
        paymentStartDate: dayjs().subtract(30, 'days').format('YYYY-MM-DD')
      }
    },
    {
      parsedValueTypes: {
        accrualDetails: 'json',
        dividendDeclarations: 'json'
      }
    }
  );

  const [
    dividendDeclarationForDetailView,
    setDividendDeclarationForDetailView
  ] = useState<DividendDeclarationDto | undefined>();

  // apis

  const getSecurityQuery = useGetSecurityQuery(routeParams.cusipOrSymbol);

  // callbacks

  const handleAccrualDetailsClick = useCallback<
    DividendDeclarationsCardProps['onAccrualDetailsClick']
  >(values => {
    setUrlState(prevUrlState => ({
      ...prevUrlState,
      accrualDetails: {
        ...prevUrlState.accrualDetails,
        ...values
      }
    }));
  }, []);

  const handleDividendAccrualRatesFiltersFormSubmit = useCallback<
    AccrualDetailsCardProps['onFiltersFormSubmit']
  >(values => {
    setUrlState(prevUrlState => ({
      ...prevUrlState,
      accrualDetails: {
        ...prevUrlState.accrualDetails,
        ...values
      }
    }));
  }, []);

  const handleDividendAccrualRatesPageChange = useCallback<
    AccrualDetailsCardProps['onPageChange']
  >(newPage => {
    setUrlState(prevUrlState => ({
      ...prevUrlState,
      dividendAccrualRates: {
        ...prevUrlState.accrualDetails,
        page: newPage
      }
    }));
  }, []);

  const handleDividendAccrualRatesPageSizeChange = useCallback<
    AccrualDetailsCardProps['onPageChange']
  >(newPageSize => {
    setUrlState(prevUrlState => ({
      ...prevUrlState,
      dividendAccrualRates: {
        ...prevUrlState.accrualDetails,
        page: 1,
        pageSize: newPageSize
      }
    }));
  }, []);

  const handleDividendDeclarationsFiltersFormSubmit = useCallback<
    DividendDeclarationsCardProps['onFiltersFormSubmit']
  >(values => {
    setUrlState(prevUrlState => ({
      ...prevUrlState,
      dividendDeclarations: {
        ...prevUrlState.dividendDeclarations,
        ...values
      }
    }));
  }, []);

  const handleDividendDeclarationsPageChange = useCallback<
    DividendDeclarationsCardProps['onPageChange']
  >(newPage => {
    setUrlState(prevUrlState => ({
      ...prevUrlState,
      dividendDeclarations: {
        ...prevUrlState.dividendDeclarations,
        page: newPage
      }
    }));
  }, []);

  const handleDividendDeclarationsPageSizeChange = useCallback<
    DividendDeclarationsCardProps['onPageChange']
  >(newPageSize => {
    setUrlState(prevUrlState => ({
      ...prevUrlState,
      dividendDeclarations: {
        ...prevUrlState.dividendDeclarations,
        page: 1,
        pageSize: newPageSize
      }
    }));
  }, []);

  if (getSecurityQuery.isInitialLoading) return <CircularLoading />;

  return (
    <>
      {!dividendDeclarationForDetailView ? (
        <Stack direction={{ xl: 'row', xs: 'column' }} spacing={2}>
          <Box flex={8}>
            <DividendDeclarationsCard
              onAccrualDetailsClick={handleAccrualDetailsClick}
              onFiltersFormSubmit={handleDividendDeclarationsFiltersFormSubmit}
              onPageChange={handleDividendDeclarationsPageChange}
              onPageSizeChange={handleDividendDeclarationsPageSizeChange}
              searchParams={{
                cusip: getSecurityQuery.data?.cusip,
                ...urlState.dividendDeclarations
              }}
              setDividendDeclarationForDetailView={
                setDividendDeclarationForDetailView
              }
            />
          </Box>
          <Box flex={4}>
            <AccrualDetailsCard
              onFiltersFormSubmit={handleDividendAccrualRatesFiltersFormSubmit}
              onPageChange={handleDividendAccrualRatesPageChange}
              onPageSizeChange={handleDividendAccrualRatesPageSizeChange}
              searchParams={{
                cusip: getSecurityQuery.data?.cusip,
                ...urlState.accrualDetails
              }}
            />
          </Box>
        </Stack>
      ) : (
        <DividendDeclarationDetail
          dividendDeclaration={dividendDeclarationForDetailView}
          onClose={() => setDividendDeclarationForDetailView(undefined)}
        />
      )}
    </>
  );
};
