import CardInfoField from '@/components/card-info-field';
import FileUploadTable from '@/components/file-upload-table/FileUploadTable.component';
import LinearLoading from '@/components/linear-loading';
import { CONVERSION_TYPES, DOCUMENT_TYPES } from '@/consts/uploads';
import { Conversion, ConversionStatus } from '@/models/ConversionDTO.model';
import { FeatureLevelPermissions } from '@/models/UserPermissions.model';
import ActionTableForm from '@/routes/plans/plan-detail/PlanActionTableV2/ConversionMainComponents/ActionTableForm.component';
import { FundingSourceService } from '@/services/FundingSource.service';
import { PlanService } from '@/services/Plan.service';
import { userService } from '@/services/User.service';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Stack
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { formatSsn } from '@vestwell-frontend/helpers';

import React, { useMemo, useRef, useState } from 'react';

import ConversionCardButton from './ConversionMainComponents/ConversionCardButton.component';
import ConversionDialogHeader from './ConversionMainComponents/ConversionDialogHeader.component';
import {
  loansValidationSchema,
  useConversionAgGrid,
  useConversionFile,
  useUploadOnboardingAgGridFile
} from './hooks';

interface LoansConversionProps {
  sponsorPlanId: number;
  conversion?: Conversion;
}

const columnDefs = [
  {
    alternates: [
      'social',
      'SS #',
      'ss#',
      'social security number',
      'social_security_number',
      'social security',
      'ssn#',
      'Taxpayer ID (SSN or Fed ID)',
      'socialsecuritynumber',
      'employee ssn',
      'national_identifier',
      'employee social',
      'national identifier',
      'ssn number',
      'ss number',
      'ssn/fein',
      'Social Security Number'
    ],
    cellEditorParams: {
      showExcludeRowButton: true
    },
    editable: true,
    field: 'ssn',
    headerName: 'SSN',
    minWidth: 250,
    required: true,
    type: 'ssn',
    valueParser: (value: string) => {
      if (/^(?!(000))\d{3}(?!00)\d{2}(?!0000)\d{4}$/.test(value)) {
        return formatSsn(value);
      }
      return value;
    }
  },
  {
    editable: true,
    field: 'purpose',
    headerName: 'Purpose',
    minWidth: 250,
    required: true,
    resizable: true,
    type: 'text'
  },
  {
    editable: true,
    field: 'loan_origination_date',
    headerName: 'Loan Origination Date',
    minWidth: 250,
    placeholder: 'YYYY-MM-DD',
    required: false,
    resizable: true,
    type: 'text'
  },
  {
    editable: true,
    field: 'original_loan_amount',
    headerName: 'Original Loan Amount',
    minWidth: 250,
    required: false,
    resizable: true,
    type: 'number'
  },
  {
    editable: true,
    field: 'balance',
    headerName: 'Balance',
    minWidth: 250,
    required: true,
    resizable: true,
    type: 'number'
  },
  {
    editable: true,
    field: 'interest_rate',
    headerName: 'Interest Rate',
    minWidth: 250,
    required: true,
    resizable: true,
    type: 'number'
  },
  {
    editable: true,
    field: 'payment_amount',
    headerName: 'Payment Amount',
    minWidth: 250,
    required: true,
    resizable: true,
    type: 'number'
  },
  {
    editable: true,
    field: 'pay_frequency',
    headerName: 'Pay Frequency',
    minWidth: 250,
    required: false,
    resizable: true,
    type: 'text'
  },
  {
    editable: true,
    field: 'status',
    headerName: 'Status',
    minWidth: 250,
    options: ['Live', 'Default', 'Deemed'],
    required: true,
    resizable: true,
    type: 'select'
  },
  {
    editable: true,
    field: 'payroll_setup_id',
    headerName: 'Payroll setup id',
    minWidth: 250,
    required: false,
    resizable: true,
    type: 'number'
  },
  {
    editable: true,
    field: 'first_pay_date',
    headerName: 'First Pay Date',
    minWidth: 250,
    required: true,
    resizable: true,
    type: 'number'
  },
  {
    editable: true,
    field: 'parent_loan_id',
    headerName: 'Parent loan',
    minWidth: 250,
    required: true,
    resizable: true,
    type: 'number'
  },
  {
    editable: true,
    field: 'is_conversion',
    headerName: 'Is Conversion',
    minWidth: 250,
    required: false,
    resizable: true,
    type: 'text'
  },
  {
    editable: true,
    field: 'source1_type',
    headerName: 'Source1 Type',
    minWidth: 250,
    required: true,
    resizable: true,
    type: 'text'
  },
  {
    editable: true,
    field: 'source1_percent',
    headerName: 'Source1 Percent',
    minWidth: 250,
    required: true,
    resizable: true,
    type: 'number'
  },
  {
    editable: true,
    field: 'source2_type',
    headerName: 'Source2 Type',
    minWidth: 250,
    required: true,
    resizable: true,
    type: 'text'
  },
  {
    editable: true,
    field: 'source2_percent',
    headerName: 'Source2 Percent',
    minWidth: 250,
    required: true,
    resizable: true,
    type: 'number'
  },
  {
    editable: true,
    field: 'source3_type',
    headerName: 'Source3 Type',
    minWidth: 250,
    required: true,
    resizable: true,
    type: 'text'
  },
  {
    editable: true,
    field: 'source3_percent',
    headerName: 'Source3 Percent',
    minWidth: 250,
    required: true,
    resizable: true,
    type: 'number'
  },
  {
    editable: true,
    field: 'source4_type',
    headerName: 'Source4 Type',
    minWidth: 250,
    required: true,
    resizable: true,
    type: 'text'
  },
  {
    editable: true,
    field: 'source4_percent',
    headerName: 'Source4 Percent',
    minWidth: 250,
    required: true,
    resizable: true,
    type: 'number'
  },
  {
    editable: true,
    field: 'source5_type',
    headerName: 'Source5 Type',
    minWidth: 250,
    required: true,
    resizable: true,
    type: 'text'
  },
  {
    editable: true,
    field: 'source5_percent',
    headerName: 'Source5 Percent',
    minWidth: 250,
    required: true,
    resizable: true,
    type: 'number'
  },
  {
    editable: true,
    field: 'source6_type',
    headerName: 'Source6 Type',
    minWidth: 250,
    required: true,
    resizable: true,
    type: 'text'
  },
  {
    editable: true,
    field: 'source6_percent',
    headerName: 'Source6 Percent',
    minWidth: 250,
    required: true,
    resizable: true,
    type: 'number'
  },
  {
    editable: true,
    field: 'source7_type',
    headerName: 'Source7 Type',
    minWidth: 250,
    required: true,
    resizable: true,
    type: 'text'
  },
  {
    editable: true,
    field: 'source7_percent',
    headerName: 'Source7 Percent',
    minWidth: 250,
    required: true,
    resizable: true,
    type: 'number'
  },
  {
    editable: true,
    field: 'source8_type',
    headerName: 'Source8 Type',
    minWidth: 250,
    required: true,
    resizable: true,
    type: 'text'
  },
  {
    editable: true,
    field: 'source8_percent',
    headerName: 'Source8 Percent',
    minWidth: 250,
    required: true,
    resizable: true,
    type: 'number'
  },
  {
    editable: true,
    field: 'source9_type',
    headerName: 'Source9 Type',
    minWidth: 250,
    required: true,
    resizable: true,
    type: 'text'
  },
  {
    editable: true,
    field: 'source9_percent',
    headerName: 'Source9 Percent',
    minWidth: 250,
    required: true,
    resizable: true,
    type: 'number'
  },
  {
    editable: true,
    field: 'source10_type',
    headerName: 'Source10 Type',
    minWidth: 250,
    required: true,
    resizable: true,
    type: 'text'
  },
  {
    editable: true,
    field: 'source10_percent',
    headerName: 'Source10 Percent',
    minWidth: 250,
    required: true,
    resizable: true,
    type: 'number'
  },
  {
    editable: true,
    field: 'source11_type',
    headerName: 'Source11 Type',
    minWidth: 250,
    required: true,
    resizable: true,
    type: 'text'
  },
  {
    editable: true,
    field: 'source11_percent',
    headerName: 'Source11 Percent',
    minWidth: 250,
    required: true,
    resizable: true,
    type: 'number'
  },
  {
    editable: true,
    field: 'source12_type',
    headerName: 'Source12 Type',
    minWidth: 250,
    required: true,
    resizable: true,
    type: 'text'
  },
  {
    editable: true,
    field: 'source12_percent',
    headerName: 'Source12 Percent',
    minWidth: 250,
    required: true,
    resizable: true,
    type: 'number'
  },
  {
    editable: true,
    field: 'source13_type',
    headerName: 'Source13 Type',
    minWidth: 250,
    required: true,
    resizable: true,
    type: 'text'
  },
  {
    editable: true,
    field: 'source13_percent',
    headerName: 'Source13 Percent',
    minWidth: 250,
    required: true,
    resizable: true,
    type: 'number'
  },
  {
    editable: true,
    field: 'source14_type',
    headerName: 'Source14 Type',
    minWidth: 250,
    required: true,
    resizable: true,
    type: 'text'
  },
  {
    editable: true,
    field: 'source14_percent',
    headerName: 'Source14 Percent',
    minWidth: 250,
    required: true,
    resizable: true,
    type: 'number'
  },
  {
    editable: true,
    field: 'source15_type',
    headerName: 'Source15 Type',
    minWidth: 250,
    required: true,
    resizable: true,
    type: 'text'
  },
  {
    editable: true,
    field: 'source15_percent',
    headerName: 'Source15 Percent',
    minWidth: 250,
    required: true,
    resizable: true,
    type: 'number'
  }
];

const EMPTY_ROWS = 10;

const LoansConversion: React.FunctionComponent<LoansConversionProps> = ({
  sponsorPlanId,
  conversion
}: LoansConversionProps) => {
  const [openPlanActionDialog, setOpenPlanActionDialog] = useState(false);
  const conversionAgGrid = useConversionAgGrid(sponsorPlanId);
  const userFileNameRef = useRef('');
  const userInfo = userService.getUser();
  const isUploadingUser = userInfo?.nickname === conversion?.submittedBy?.id;
  const isDraftStage =
    conversion?.status === ConversionStatus.Draft || !conversion?.status;
  const isViewMode =
    conversion?.status && conversion.status !== ConversionStatus.Draft;

  const { mutateAsync: uploadLoans, isLoading } = useUploadOnboardingAgGridFile(
    sponsorPlanId as number,
    DOCUMENT_TYPES.LOANS,
    userFileNameRef.current
  );

  const { onClicked: editFileClicked, isLoading: isLoadingEditFile } =
    useConversionFile(
      conversionAgGrid.handleLoad,
      sponsorPlanId,
      CONVERSION_TYPES.LOANS,
      conversion?.documentId,
      columnDefs
    );

  const payrollSetupsQuery = useQuery(
    [`PlanService.getPlanPayrollSetups${sponsorPlanId}`],
    () => PlanService.getPlanPayrollSetups(sponsorPlanId),
    {
      cacheTime: 5000,
      enabled: Boolean(sponsorPlanId),
      staleTime: Infinity
    }
  );

  const fundingSourcesQuery = useQuery(
    ['FundingSourceService.getAllFundingSources'],
    FundingSourceService.getAllFundingSources,
    {
      staleTime: Infinity
    }
  );

  const parentLoanInfoQuery = useQuery(
    [`PlanService.getLoanBriefsPerPlan`, sponsorPlanId],
    () => PlanService.getLoanBriefsPerPlan(sponsorPlanId),
    {
      cacheTime: 5000,
      enabled: Boolean(sponsorPlanId),
      staleTime: Infinity
    }
  );

  const conversionFileExists = async (fileName: string): Promise<boolean> => {
    userFileNameRef.current = fileName;
    return PlanService.conversionFileExists(sponsorPlanId, fileName);
  };

  const uniqueParticipantsCount = useMemo(() => {
    const ssnList = new Set();
    conversionAgGrid.gridRows.forEach((row: any) => {
      if (row.ssn) ssnList.add(row.ssn);
    });
    return ssnList.size;
  }, [conversionAgGrid.gridRows]);

  const rowsToDto = () => {
    return conversionAgGrid.getDataForUpload({ plan_id: sponsorPlanId });
  };

  return (
    <>
      {conversion ? (
        <ConversionCardButton
          conversion={conversion}
          isLoading={isLoadingEditFile}
          onClick={() => {
            setOpenPlanActionDialog(true);
            editFileClicked();
          }}
          sponsorPlanId={sponsorPlanId}
        />
      ) : (
        <LoadingButton
          disabled={!sponsorPlanId || isLoading}
          loading={isLoading}
          onClick={() => {
            setOpenPlanActionDialog(true);
          }}
          startIcon={<FileUploadOutlinedIcon />}>
          Upload
        </LoadingButton>
      )}
      <Dialog
        fullWidth
        maxWidth='lg'
        onClose={() => {
          setOpenPlanActionDialog(false);
        }}
        open={openPlanActionDialog}>
        <DialogTitle>
          <ConversionDialogHeader
            checkFileNameExists={conversionFileExists}
            columnDefs={columnDefs}
            conversion={conversion}
            onUpload={conversionAgGrid.handleLoad}
            title='Loans Conversion'
            viewMode={isViewMode}
          />
        </DialogTitle>
        <DialogContent>
          <ActionTableForm
            emptyRowsCount={!conversion ? EMPTY_ROWS : undefined}
            initialValues={conversionAgGrid.gridRows}
            onChange={conversionAgGrid.setGridRows}
            onErrors={conversionAgGrid.handleErrors}
            onSubmit={conversionAgGrid.handleSubmit}
            ref={conversionAgGrid.form}
            validateOnChange
            validateOnMount
            validationSchema={loansValidationSchema(
              sponsorPlanId,
              fundingSourcesQuery.data
                ?.filter(i => !['AT', 'LN', 'SH'].includes(i.code))
                .map(i => i.code),
              payrollSetupsQuery.data,
              parentLoanInfoQuery.data
            )}>
            <FileUploadTable
              columnDefs={columnDefs}
              errors={conversionAgGrid.gridErrors}
              isViewMode={isViewMode}
              onCellChanged={conversionAgGrid.handleCellChange}
              onRowsChanged={conversionAgGrid.handleRowsChange}
              rowData={conversionAgGrid.gridRows}
            />
            {isLoadingEditFile && (
              <Box sx={{ mt: 2 }}>
                <LinearLoading />
              </Box>
            )}
          </ActionTableForm>
        </DialogContent>
        <DialogActions>
          {conversionAgGrid.gridRows.length > 0 && (
            <Grid container>
              <Stack
                direction='row'
                divider={<Divider flexItem orientation='vertical' />}
                spacing={1}>
                <CardInfoField
                  fieldName='Number of rows'
                  fieldValue={conversionAgGrid.rowCount}
                />
                <CardInfoField
                  fieldName='Unique participants'
                  fieldValue={`${uniqueParticipantsCount}`}
                />
              </Stack>
            </Grid>
          )}

          {((conversion?.status &&
            [ConversionStatus.PendingApproval, ConversionStatus.Draft].includes(
              conversion.status
            )) ||
            !conversion?.status) && (
            <>
              <LoadingButton
                disabled={conversionAgGrid.rowCount === 0 || isLoadingEditFile}
                loading={isLoading}
                onClick={async () => {
                  try {
                    await uploadLoans({
                      conversionDto: {
                        ...conversion,
                        documentId:
                          conversion?.status &&
                          conversion?.status !== ConversionStatus.Draft
                            ? conversion.documentId
                            : undefined,
                        status: ConversionStatus.Draft
                      },
                      results: rowsToDto()
                    });
                  } finally {
                    setOpenPlanActionDialog(false);
                    conversionAgGrid.form.current?.setValues([]);
                  }
                }}
                sx={{ width: 200 }}
                variant='text'>
                {isDraftStage ? 'Save as Draft' : 'Revert to Draft'}
              </LoadingButton>
              {((isUploadingUser && isDraftStage) ||
                (!isUploadingUser &&
                  userService.hasPermission(
                    FeatureLevelPermissions.WRITE_UPLOADS
                  ))) && (
                <LoadingButton
                  disabled={
                    conversionAgGrid.errorsCount > 0 ||
                    conversionAgGrid.rowCount === 0 ||
                    isLoadingEditFile
                  }
                  loading={isLoading}
                  onClick={async () => {
                    try {
                      await uploadLoans({
                        conversionDto: {
                          ...conversion,
                          documentId:
                            conversion?.status &&
                            conversion?.status !== ConversionStatus.Draft
                              ? conversion.documentId
                              : undefined,
                          status:
                            conversion?.status === ConversionStatus.Draft ||
                            !conversion?.status
                              ? ConversionStatus.PendingApproval
                              : ConversionStatus.Complete
                        },
                        results: rowsToDto()
                      });
                    } finally {
                      setOpenPlanActionDialog(false);
                      conversionAgGrid.form.current?.setValues([]);
                    }
                  }}
                  sx={{ width: 300 }}
                  variant='contained'>
                  {conversion?.status === ConversionStatus.Draft ||
                  !conversion?.status
                    ? 'Submit for approval'
                    : 'Approve & Submit'}
                </LoadingButton>
              )}
            </>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};

export default LoansConversion;
