import { CardContent } from '@/components/card';
import DataTable, {
  DataTableProps
} from '@/components/data-table/DataTable.component';
import { Link } from '@/components/link';
import { useSnackbar } from '@/contexts/SnackBarContext';
import { SubAccountDto } from '@/models/suba/SubAccountDTO.model';
import { SubAccountService } from '@/services/suba/accounts/SubAccount.service';
import VestwellTheme from '@/theme/Vestwell.theme';
import formatters from '@/utils/Formatters';
import { useQuery } from '@tanstack/react-query';
import { OperationalSubAccountList } from '@vestwell-sub-accounting/models/accountsAndLedgers/OperationalSubAccountList';
import { SubAccountApiIncludeOption } from '@vestwell-sub-accounting/models/accountsAndLedgers/SubAccountApiIncludeOption';

import { ColDef } from 'ag-grid-community';
import { AxiosError } from 'axios';
import Decimal from 'decimal.js';
import { isNil, omitBy } from 'lodash';
import { FC, useCallback, useMemo, useState } from 'react';

type OperationalSubAccountsProps = {
  hasPlanId?: boolean;
  pagination?: boolean;
  parentAccountId?: string;
  planId?: string;
  showPlan?: boolean;
  DataTableProps?: Partial<DataTableProps>;
};

export const OperationalSubAccounts: FC<
  OperationalSubAccountsProps
> = props => {
  const { showSnackbar } = useSnackbar();
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(25);
  const [orderBy, setOrderBy] = useState<keyof SubAccountDto | undefined>();
  const [orderByDirection, setOrderByDirection] = useState<
    'asc' | 'desc' | undefined
  >();

  const searchSubAccountsQuery = useQuery(
    [
      'SubAccountService.searchSubAccounts',
      {
        accountType: OperationalSubAccountList,
        hasPlanId: props.hasPlanId,
        include: [
          SubAccountApiIncludeOption.cashBalance,
          SubAccountApiIncludeOption.totalMarketValue
        ],
        orderBy,
        orderByDirection,
        page,
        pageSize,
        parentAccountId: props.parentAccountId,
        planId: props.planId
      }
    ],
    () =>
      SubAccountService.searchSubAccounts(
        omitBy(
          {
            accountType: OperationalSubAccountList,
            hasPlanId: props.hasPlanId,
            include: [
              SubAccountApiIncludeOption.cashBalance,
              SubAccountApiIncludeOption.totalMarketValue
            ],
            orderBy,
            orderByDirection,
            page,
            pageSize,
            parentAccountId: props.parentAccountId,
            planId: props.planId
          },
          isNil
        )
      ),
    {
      onError: (err: AxiosError) => {
        showSnackbar({
          message: `Failed to fetch operational sub accounts: ${err.response?.data ? err.response.data : err.message}`,
          severity: 'error'
        });
      }
    }
  );

  const columnDefs: ColDef[] = useMemo(
    () =>
      [
        {
          cellRenderer: ({ data }: { data: SubAccountDto }) => (
            <Link
              target='_blank'
              to={`/ops/accounts/${data.parentAccount?.parentAccountId}/sub-accounts/${data.subAccountId}`}>
              {formatters.displayCase(data.accountType)}
            </Link>
          ),
          field: 'accountType',
          headerName: 'Type',
          sortable: true,
          suppressMenu: true,
          tooltipValueGetter: ({ data }) =>
            formatters.displayCase(data.accountType)
        },
        props.showPlan && {
          field: 'planId',
          headerName: 'Plan ID',
          sortable: true,
          suppressMenu: true,
          type: 'numericColumn'
        },
        props.showPlan && {
          field: 'plan.name',
          headerName: 'Plan Name',
          maxWidth: 240,
          sortable: true,
          suppressMenu: true
        },
        {
          field: 'totalMarketValue',
          headerName: 'Market Value',
          suppressMenu: true,
          tooltipValueGetter: ({ value }) => formatters.formatDollars(value),
          type: 'numericColumn',
          valueFormatter: ({ value }) => formatters.formatDollars(value)
          // this is a calculated field, so we can't sort by it
        },
        {
          field: 'cashBalance',
          headerName: 'Cash Balance',
          suppressMenu: true,
          tooltipValueGetter: ({ value }) => formatters.formatDollars(value),
          type: 'numericColumn',
          valueFormatter: ({ value }) => formatters.formatDollars(value)
          // this is a calculated field, so we can't sort by it
        }
      ].filter(Boolean) as ColDef[],
    []
  );

  const handleSortChanged = useCallback(
    () => (newSort: { colId: string; sort?: 'asc' | 'desc' }[]) => {
      if (!newSort || newSort.length === 0) {
        setOrderBy(undefined);
        setOrderByDirection(undefined);
        setPage(1);
      } else {
        setOrderBy(newSort[0].colId as keyof SubAccountDto);
        setOrderByDirection(newSort[0].sort);
        setPage(1);
      }
    },
    []
  );

  const handlePageChanged = useCallback(
    (newPage: number) => setPage(newPage),
    []
  );

  const handlePageSizeChanged = useCallback((newPageSize: number) => {
    setPage(1);
    setPageSize(newPageSize);
  }, []);

  return (
    <CardContent
      disablePadding
      loading={searchSubAccountsQuery.isInitialLoading}>
      <DataTable
        columnDefs={columnDefs}
        columnSizing='auto'
        getRowStyle={params =>
          params.data.cashBalance &&
          new Decimal(params.data.cashBalance).lessThan(new Decimal(0)) && {
            backgroundColor: VestwellTheme.palette.warning.lightBg
          }
        }
        onPageChanged={handlePageChanged}
        onPageSizeChanged={handlePageSizeChanged}
        onSortChanged={handleSortChanged}
        page={page}
        pageSize={pageSize}
        pagination={props.pagination}
        paginationSource='server'
        paginationTotal={searchSubAccountsQuery.data?.pagination?.total}
        rowData={searchSubAccountsQuery.data?.results || []}
        sort={[{ colId: 'planId', sort: 'asc' }]}
        {...(props.DataTableProps || {})}
      />
    </CardContent>
  );
};
