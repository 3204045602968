import { useSnackbar } from '@/contexts/SnackBarContext';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography
} from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';

import React, { useEffect, useState } from 'react';

export type ConfirmationDialogInput = {
  name: string;
  type: 'select'; // only supporting select for now but others could be added as needs arise
  options?: { value: string | number; label: string }[];
  label: string;
  initialValue?: unknown;
};

export interface ActionConfirmationDialogProps {
  open: boolean;
  message?: string;
  inputs?: ConfirmationDialogInput[];
  onConfirm: (values: Record<string, unknown>) => Promise<void>;
  onCancel?: () => void;
  successMessage?: string;
}
export const ActionConfirmationDialog = ({
  open,
  message = 'Are you sure you wish to complete this action?',
  inputs = [],
  onConfirm,
  onCancel = () => {},
  successMessage = 'Action completed successfully'
}: ActionConfirmationDialogProps): JSX.Element => {
  const [loading, setLoading] = useState<boolean>(false);
  const queryClient = useQueryClient();
  const { showSnackbar } = useSnackbar();

  const [formValues, setFormValues] = useState<Record<string, unknown>>({});

  useEffect(() => {
    // setup the form values for the new set of confirmation inputs
    const initialValues = inputs.reduce(
      (acc, input) => {
        acc[input.name] = input.initialValue;
        return acc;
      },
      {} as Record<string, unknown>
    );

    setFormValues(initialValues);
  }, [inputs]);

  const handleConfirm = async () => {
    setLoading(true);

    try {
      await onConfirm(formValues);

      // Invalidate the WorkflowService get and search query so that we get fresh results after this update
      queryClient.invalidateQueries(['WorkflowService.getById']);
      queryClient.invalidateQueries(['WorkflowService.search']);

      showSnackbar({
        message: successMessage,
        severity: 'success'
      });
    } catch (err) {
      console.error(err);
      showSnackbar({
        message: `Failed to complete action`,
        severity: 'error'
      });

      // Invalidate the WorkflowService get and search query so that we get fresh results since when some of these actions fail, they actually modify the workflow
      queryClient.invalidateQueries(['WorkflowService.getById']);
      queryClient.invalidateQueries(['WorkflowService.search']);
    }

    setLoading(false);
  };

  return (
    <Dialog
      data-testid='workflow-detail-action-confirmation-dialog'
      onClose={() => {
        onCancel();
      }}
      open={open}>
      <DialogContent>
        {loading && (
          <Box sx={{ p: 4 }}>
            <CircularProgress />
          </Box>
        )}
        {!loading && (
          <>
            <Typography variant='body1'>{message}</Typography>
            {inputs?.length > 0 && (
              <>
                {inputs.map(
                  input =>
                    input.type === 'select' && (
                      <FormControl
                        fullWidth
                        key={input.name}
                        size='small'
                        sx={{ mt: 2 }}>
                        <InputLabel id={`${input.name}-label`}>
                          {input.label}
                        </InputLabel>
                        <Select
                          id={input.name}
                          label={input.label}
                          labelId={`${input.name}-label`}
                          name={input.name}
                          onChange={evt => {
                            setFormValues({
                              ...formValues,
                              [input.name]: evt.target.value
                            });
                          }}
                          value={formValues[input.name]}>
                          {input.options?.map(option => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    )
                )}
              </>
            )}
          </>
        )}
      </DialogContent>
      {!loading && (
        <DialogActions>
          <Button color='primary' onClick={handleConfirm} variant='contained'>
            Confirm
          </Button>
          <Button
            color='primary'
            onClick={() => {
              onCancel();
            }}
            variant='contained'>
            Cancel
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
};
