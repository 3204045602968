import Card, { CardHeader } from '@/components/card/Card.component';
import DownloadCSVButton from '@/components/download-csv-button/DownloadCSVButton.component';
import { useUrlState } from '@/hooks/useUrlState.hook';
import { SubAccountApiIncludeOption } from '@/models/suba/accounts/SubAccountApiIncludeOption.model';
import FundingSource from '@/models/suba/FundingSourceEnum.model';
import { OperationalSubAccounts } from '@/routes/suba/accounts/common/components/OperationalSubAccounts.component';
import {
  SubAccounts,
  SubAccountsProps
} from '@/routes/suba/accounts/common/components/sub-accounts/SubAccounts.component';
import { useSubaPlansDetailRouteParams } from '@/routes/suba/accounts/suba-plans-detail/hooks/useSubaPlansDetailRouteParams.hook';
import SubAccountService from '@/services/suba/accounts/SubAccount.service';
import formatters from '@/utils/Formatters';
import { Unstable_Grid2 as Grid } from '@mui/material';
import { SubAccountType } from '@vestwell-sub-accounting/models/common/SubAccountType';

import { FC, useCallback } from 'react';

const exportParticipantSubAccounts = async (planId: string) => {
  return (
    await SubAccountService.searchSubAccounts({
      hasPlanId: true,
      include: [
        SubAccountApiIncludeOption.cashBalance,
        SubAccountApiIncludeOption.totalMarketValue
      ],
      page: 1,
      pageSize: 0,
      planId
    })
  ).results?.map(subAccount => ({
    'Sub Account ID': subAccount.subAccountId,
    // eslint-disable-next-line sort-keys-plus/sort-keys
    'Part ID': subAccount.investorId,
    'Participant Name':
      subAccount.participant?.firstName || subAccount.participant?.lastName
        ? `${subAccount.participant?.firstName} ${subAccount.participant?.lastName}`
        : '',
    // eslint-disable-next-line sort-keys-plus/sort-keys
    'Funding Source': subAccount.fundingSource
      ? FundingSource[subAccount.fundingSource]
      : '',
    'Market Value': subAccount.totalMarketValue,
    // eslint-disable-next-line sort-keys-plus/sort-keys
    'Cash Balance': subAccount.cashBalance,
    'Created At': formatters.formatFromIsoDate(subAccount.createdAt)
  }));
};

export const SubAccountsTab: FC = () => {
  // context

  const routeParams = useSubaPlansDetailRouteParams();

  // state

  const [urlState, setUrlState] = useUrlState<
    Pick<
      SubAccountsProps['searchParams'],
      'page' | 'pageSize' | 'orderBy' | 'orderByDirection'
    >
  >(
    {
      page: 1,
      pageSize: 25
    },
    {
      parsedValueTypes: {
        orderBy: 'string',
        orderByDirection: 'string',
        page: 'number',
        pageSize: 'number'
      }
    }
  );

  // callbacks

  const handleSearchFormSubmit = useCallback<
    SubAccountsProps['onSearchFormSubmit']
  >(values => {
    setUrlState(prevUrlState => ({
      ...prevUrlState,
      ...values
    }));
  }, []);

  const handlePageChange = useCallback<SubAccountsProps['onPageChange']>(
    newPage => {
      setUrlState(prevUrlState => ({
        ...prevUrlState,
        page: newPage
      }));
    },
    []
  );

  const handlePageSizeChange = useCallback<SubAccountsProps['onPageChange']>(
    newPageSize => {
      setUrlState(prevUrlState => ({
        ...prevUrlState,
        page: 1,
        pageSize: newPageSize
      }));
    },
    []
  );

  const handleSortChange = useCallback<SubAccountsProps['onSortChange']>(
    newSort => {
      setUrlState(prevUrlState => ({
        ...prevUrlState,
        ...newSort,
        page: 1
      }));
    },
    []
  );

  return (
    <Grid container spacing={2}>
      <Grid lg={4} xs={12}>
        <Card>
          <CardHeader title='Operational Accounts' />
          <OperationalSubAccounts
            DataTableProps={{
              columnSizing: 'fit',
              ['data-testid']: 'data-operational-accounts'
            }}
            planId={routeParams.planId}
          />
        </Card>
      </Grid>
      <Grid lg={8} xs={12}>
        <Card>
          <CardHeader title='Participant Sub Accounts'>
            <DownloadCSVButton
              buttonProps={{
                sx: {
                  fontSize: 'small'
                },
                variant: 'outlined'
              }}
              fileName={`SubAccounts-Plan-${routeParams.planId}-Participants`}
              getInfo={() => exportParticipantSubAccounts(routeParams.planId)}
              text='Export CSV'
            />
          </CardHeader>
          <SubAccounts
            data-testid='data-participant-accounts'
            onPageChange={handlePageChange}
            onPageSizeChange={handlePageSizeChange}
            onSearchFormSubmit={handleSearchFormSubmit}
            onSortChange={handleSortChange}
            searchParams={{
              ...urlState,
              accountType: [
                SubAccountType.participantCash,
                SubAccountType.participantOutsideInvestment,
                SubAccountType.participantStandard
              ],
              hasPlanId: true,
              planId: routeParams.planId
            }}
          />
        </Card>
      </Grid>
    </Grid>
  );
};
