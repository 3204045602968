import ReactJson from '@microlink/react-json-view';

import React from 'react';

interface JSONViewerProps extends React.HTMLAttributes<HTMLBaseElement> {
  json: Record<string, any> | [] | string;
  displayDataTypes?: boolean;
  displayObjectSize?: boolean;
  collapseStringsAfterLength?: number;
}

export const JSONViewer: React.FunctionComponent<JSONViewerProps> = (
  props: JSONViewerProps
) => {
  const {
    json,
    displayDataTypes = false,
    displayObjectSize = false,
    collapseStringsAfterLength = 15
  } = props;

  let jsonObject = json as Record<string, any> | [];
  // if not a json object or array, try to parse it
  if (!Array.isArray(json) && typeof json !== 'object') {
    try {
      jsonObject = JSON.parse(json);
    } catch (e) {
      jsonObject = {};
    }
  }

  return (
    <ReactJson
      collapseStringsAfterLength={collapseStringsAfterLength}
      displayDataTypes={displayDataTypes}
      displayObjectSize={displayObjectSize}
      enableClipboard={false}
      indentWidth={2}
      name={false}
      src={jsonObject}
    />
  );
};

export default JSONViewer;
export type { JSONViewerProps };
