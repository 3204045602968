import DatePicker from '@/components/date-picker/DatePicker';
import { useSnackbar } from '@/contexts/SnackBarContext';
import { useGetSecurityQuery } from '@/hooks/suba/useGetSecurity.hook';
import { DividendAccrualRateDto } from '@/models/suba/dividend-accrual-rates/DividendAccrualRateDTO.model';
import { DividendAccrualRateUpdateRequest } from '@/models/suba/dividend-accrual-rates/DividendAccrualRateUpdateRequest.model';
import { useSecuritiesDetailRouteParams } from '@/routes/suba/securities/securities-detail/hooks/useSecurityDetailRouteParams.hook';
import { doesNotExceedPrecision } from '@/routes/suba/securities/securities-detail/utils';
import DividendAccrualRateService from '@/services/suba/dividend-accrual-rates/DividendAccrualRates.service';
import formatters from '@/utils/Formatters';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormHelperText,
  InputLabel,
  OutlinedInput,
  Stack,
  Typography
} from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { AxiosError } from 'axios';
import dayjs from 'dayjs';
import { Field, Form, Formik } from 'formik';
import * as yup from 'yup';

type EditAccrualRateRecordFormValues = {
  dailyDayCount: number;
  effectiveDate: string;
  rate: number;
};

const validationSchema = yup.object().shape(
  {
    dailyDayCount: yup.number().when('rate', {
      is: (rate: number) => !rate,
      then: yup
        .number()
        .required()
        .moreThan(0, 'Must be positive')
        .integer('Must be integer')
    }),
    rate: yup.number().when('dailyDayCount', {
      is: (dailyDayCount: number) => !dailyDayCount,
      then: yup
        .number()
        .required()
        .moreThan(0, 'Must be positive')
        .test(
          'is-decimal',
          'Must not exceed 8 decimal places',
          doesNotExceedPrecision(8)
        )
    })
  },
  [['dailyDayCount', 'rate']]
);

type EditAccrualDetailsDialogProps = {
  onClose: () => void;
  dividendAccrual: DividendAccrualRateDto;
  onEdit?: () => void;
};

export const EditAccrualDetailsDialog = (
  props: EditAccrualDetailsDialogProps
) => {
  const routeParams = useSecuritiesDetailRouteParams();
  const { showSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const getSecurityQuery = useGetSecurityQuery(routeParams.cusipOrSymbol);

  const editAccrualRateRecordMutation = useMutation(
    ['DividendAccrualRateService.update'],
    (params: DividendAccrualRateUpdateRequest) => {
      return DividendAccrualRateService.update(params);
    },
    {
      onError: (error: AxiosError) => {
        showSnackbar({
          message: error.message,
          severity: 'error'
        });
      },
      onSuccess: () => {
        showSnackbar({
          message: 'Dividend Accrual updated',
          severity: 'success'
        });

        queryClient.invalidateQueries(['DividendAccrualRateService.search']);
        props.onClose();
        if (typeof props.onEdit === 'function') {
          props.onEdit();
        }
      }
    }
  );

  const handleSubmit = async (values: EditAccrualRateRecordFormValues) => {
    editAccrualRateRecordMutation.mutate({
      cusip: getSecurityQuery.data?.cusip,
      dailyDayCount: values.dailyDayCount,
      effectiveDate: props.dividendAccrual.effectiveDate,
      rate: values.rate.toFixed(8)
    });
  };

  return (
    <Dialog
      fullWidth
      maxWidth='md'
      onClose={() => {
        props.onClose();
      }}
      open>
      <Formik
        initialValues={{
          dailyDayCount: props.dividendAccrual.dailyDayCount,
          effectiveDate: dayjs(props.dividendAccrual.effectiveDate).format(
            'YYYY-MM-DD'
          ),
          rate: Number(props.dividendAccrual.rate)
        }}
        onSubmit={(values: EditAccrualRateRecordFormValues) =>
          handleSubmit(values)
        }
        validationSchema={validationSchema}>
        {({ handleSubmit: handleFormSubmit, errors, touched }) => (
          <>
            <DialogTitle
              sx={{
                pb: 1 // there is an unknown rule somewhere setting 8px important padding to the top of DialogContent so we have to compensate here
              }}>
              Edit Accrual Rate Record
            </DialogTitle>
            <DialogContent
              sx={{
                p: 0
              }}>
              <Box
                data-testid='dividend-accrual-edit-summary'
                sx={{
                  bgcolor: theme => theme.palette.primary.light,
                  px: 3.25,
                  py: 2
                }}>
                <Typography>
                  {getSecurityQuery.data?.description || '\u2014'}
                </Typography>
                <Typography
                  sx={{
                    color: theme => theme.palette.grey[600],
                    fontSize: 14
                  }}>
                  {formatters.formatSecurityName(
                    getSecurityQuery.data?.symbol,
                    getSecurityQuery.data?.cusip
                  ) || '\u2014'}
                </Typography>
              </Box>
              <Box sx={{ p: 3 }}>
                <Form data-testid='edit-accrual-detail-form'>
                  <Stack
                    alignItems='start'
                    direction='row'
                    spacing={2}
                    sx={{ mt: 3 }}>
                    <FormControl sx={{ alignSelf: 'flex-start' }}>
                      <Field
                        as={DatePicker}
                        autoComplete='off'
                        data-testid='edit-accrual-effective-date'
                        disabled
                        label='Payment Date'
                        name='effectiveDate'
                        size='small' // FormControl doesn't pass to our DatePicker
                        sx={{ width: 213 }}
                        variant='outlined'
                      />
                    </FormControl>
                    <FormControl
                      error={touched.rate && Boolean(errors.rate)}
                      fullWidth
                      size='small'
                      sx={{ maxWidth: 213 }}>
                      <InputLabel htmlFor='rate-input'>Rate</InputLabel>
                      <Field
                        as={OutlinedInput}
                        autoComplete='off'
                        data-testid='edit-accrual-rate'
                        label='Accrual Rate'
                        name='rate'
                        type='number'
                      />
                      <FormHelperText>
                        {(touched.rate && errors.rate) || ' '}
                      </FormHelperText>
                    </FormControl>
                    <FormControl
                      error={
                        touched.dailyDayCount && Boolean(errors.dailyDayCount)
                      }
                      fullWidth
                      size='small'
                      sx={{ maxWidth: 213 }}>
                      <InputLabel htmlFor='dailyDayCount-input'>
                        Effective Day Count
                      </InputLabel>
                      <Field
                        as={OutlinedInput}
                        autoComplete='off'
                        data-testid='edit-accrual-daily-day-count'
                        label='Effective Day Count'
                        name='dailyDayCount'
                        type='number'
                      />
                      <FormHelperText>
                        {(touched.dailyDayCount && errors.dailyDayCount) || ' '}
                      </FormHelperText>
                    </FormControl>
                  </Stack>
                </Form>
              </Box>
            </DialogContent>
            <Divider />
            <DialogActions
              sx={{
                px: 3,
                py: 2.25
              }}>
              <Button
                data-testid='cancel-button'
                disabled={editAccrualRateRecordMutation.isLoading}
                onClick={() => {
                  props.onClose();
                }}>
                Cancel
              </Button>

              <LoadingButton
                data-testid='confirm-button'
                loading={editAccrualRateRecordMutation.isLoading}
                onClick={() => handleFormSubmit()}
                variant='contained'>
                Confirm
              </LoadingButton>
            </DialogActions>
          </>
        )}
      </Formik>
    </Dialog>
  );
};
