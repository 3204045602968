import DatePickerForm from '@/components/date-picker/DatePickerForm';
import SimpleDropdown from '@/components/simple-dropdown';
import SimpleUpload from '@/components/simple-upload';
import { useDialog } from '@/contexts/DialogContext';
import { useSnackbar } from '@/contexts/SnackBarContext';
import { CreateDocumentGroupingWithDetailsPayload } from '@/models/CreateDocumentGroupingWithDetailsPayload.model';
import {
  UploadDocumentData,
  UploadedDocData
} from '@/models/DocumentDTO.model';
import { PlanService } from '@/services/Plan.service';
import { getBulkUploadPlanDocValidationSchema } from '@/utils/validations/BulkUploadPlanDocValidationSchea.schema';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Theme,
  Typography
} from '@mui/material';
import { grey } from '@mui/material/colors';
import { makeStyles } from '@mui/styles';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import clsx from 'clsx';
import dayjs from 'dayjs';
import { Form, Formik } from 'formik';
import { FC, useMemo, useRef, useState } from 'react';

import { GroupsWithDates } from './PlanDocumentsGroup.component';

const useStyles = makeStyles((theme: Theme) => ({
  datePicker: {
    width: '43%'
  },
  dialogContent: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  docName: {
    color: theme.palette.text.secondary
  },
  errorMsg: {
    color: theme.palette.error.main
  },
  selectAreaButtonHover: {
    backgroundColor: theme.palette.primary.dark
  },
  selectAreaHover: {
    borderColor: theme.palette.primary.dark,
    cursor: 'pointer'
  }
}));

type BulkUploadPlanDialogProps = {
  documentGroups: GroupsWithDates[];
  planId: number;
  categoryId: number;
  isNewGroup?: boolean;
  categoryName: string;
  groupName?: string;
  groupingId?: number;
  requiredDocumentKey?: string;
};

const BulkUploadPlanDialog: FC<BulkUploadPlanDialogProps> = props => {
  const fileExtensions = {
    'application/msword': ['.doc'],
    'application/pdf': ['.pdf'],
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document': [
      '.docx'
    ],
    'text/plain': ['.txt']
  };

  const queryClient = useQueryClient();
  const { closeDialog } = useDialog();
  const { showSnackbar } = useSnackbar();
  const classes = useStyles();
  const [isHovering, setIsHovering] = useState(false);
  const buttonRef = useRef<HTMLButtonElement>(null);

  const handleMouseEnter = () => {
    setIsHovering(true);
  };

  const handleMouseLeave = () => {
    setIsHovering(false);
  };

  let effectiveDate: string;

  const createDocumentGroupingWithDetails = useMutation(
    ['PlanService.createDocumentGroupingWithDetails'],
    (data: CreateDocumentGroupingWithDetailsPayload) => {
      return PlanService.createDocumentGroupingWithDetails(props.planId, data);
    },
    {
      onError: () => {
        showSnackbar({
          message: 'Failed!',
          severity: 'error'
        });
      }
    }
  );

  const deleteDuplicatedInvoiceMutation = useMutation(
    ['PlanService.deleteUploadedDoc'],
    (data: { groupId: number; docId: number }) =>
      PlanService.deleteUploadedDoc(props.planId, data.docId, data.groupId),
    {
      onError: () =>
        showSnackbar({
          message: `Replacing previous invoice failed!`,
          severity: 'error'
        }),
      onSuccess: async () => {
        showSnackbar({
          message: `Success! Invoice replaced`,
          severity: 'success'
        });

        await queryClient.invalidateQueries([
          'PlanService.getDocumentCategoryGroupings',
          props.planId,
          props.categoryId
        ]);
      }
    }
  );

  const documentKeysByCategoryQuery = useQuery<string[]>(
    ['PlanService.getDocumentKeysByCategory', props.categoryId],
    () => PlanService.getDocumentKeysByCategory(props.categoryId),
    {
      enabled: props.isNewGroup,
      staleTime: Infinity
    }
  );

  const validDocumentKeys = useMemo(() => {
    const documentKeys = props.isNewGroup
      ? documentKeysByCategoryQuery.data
      : props.documentGroups
          .find(group => group.groupingId === props.groupingId)
          ?.documents.map(doc => doc.documentKey);

    return documentKeys?.sort() ?? [];
  }, [
    props.documentGroups,
    props.groupingId,
    documentKeysByCategoryQuery.data
  ]);

  const uploadPlanDocument = useMutation(
    ['PlanService.uploadPlanDocument'],
    (data: UploadDocumentData) => {
      return PlanService.uploadPlanDocument(
        data.planId,
        data.documentData,
        data.effectiveDate,
        data.groupingId || props.groupingId
      );
    }
  );

  const onSubmit = async (values: {
    documentKeys: string[];
    documentNames: string[];
    effectiveDate: string;
    files: File[];
  }) => {
    const newGroupingId = props.isNewGroup
      ? (
          await createDocumentGroupingWithDetails.mutateAsync({
            categoryId: props.categoryId,
            entityId: props.planId,
            entityType: 'plan',
            name: props.categoryName
          })
        ).groupId
      : undefined;

    const uploadDocumentsPromises: Promise<UploadedDocData>[] = [];
    for (let i = 0; i < values.files.length; i++) {
      const uploadDocumentFormData = new FormData();
      uploadDocumentFormData.append(
        'file',
        values.files[i],
        values.documentNames[i]
      );
      uploadDocumentFormData.append('documentKey', values.documentKeys[i]);
      uploadDocumentFormData.append('effectiveDate', values.effectiveDate);
      const docInfo: UploadDocumentData = {
        documentData: uploadDocumentFormData,
        effectiveDate: values.effectiveDate,
        groupingId: newGroupingId,
        planId: props.planId
      };
      uploadDocumentsPromises.push(uploadPlanDocument.mutateAsync(docInfo));
    }

    try {
      const results = await Promise.all(uploadDocumentsPromises);

      showSnackbar({
        message: props.isNewGroup
          ? 'Success! New group created'
          : 'Successfully uploaded documents!',
        severity: 'success'
      });

      for (const doc of results) {
        if (
          [
            'Sponsor Notices/Q1 Invoice',
            'Sponsor Notices/Q2 Invoice',
            'Sponsor Notices/Q3 Invoice',
            'Sponsor Notices/Q4 Invoice'
          ].includes(doc.documentKey)
        ) {
          const matchingGroup = props.documentGroups.find(
            group => props.groupingId === group.groupingId
          );

          const matchingDoc = matchingGroup?.documents?.find(
            oldDocument =>
              oldDocument.documentKey === doc.documentKey &&
              dayjs(oldDocument.effectiveDate).year() ===
                dayjs(effectiveDate).year()
          );

          if (matchingDoc?.uploadHistoryId) {
            deleteDuplicatedInvoiceMutation.mutate({
              docId: matchingDoc.uploadHistoryId,
              groupId: props.groupingId
            });
          }
        }
      }

      await queryClient.invalidateQueries([
        'PlanService.getAllPlanDocuments',
        props.planId
      ]);
      await queryClient.invalidateQueries([
        'PlanService.getDocumentCategoryGroupings',
        props.planId,
        props.categoryId
      ]);
      for (const doc of results) {
        queryClient.invalidateQueries([
          'PlanService.getPlanDocUploadHist',
          props.planId,
          doc.documentKey
        ]);
      }
      queryClient.invalidateQueries([
        'PlanService.getDocumentCategoryLegacy',
        props.planId,
        props.categoryId
      ]);
    } catch (error) {
      showSnackbar({
        message: `Failed!`,
        severity: 'error'
      });
    }

    closeDialog();
  };

  return (
    <>
      <Formik
        initialValues={{
          documentKeys: [],
          documentNames: [],
          effectiveDate: dayjs(new Date()).format('MM/DD/YYYY'),
          files: []
        }}
        onSubmit={onSubmit}
        validationSchema={getBulkUploadPlanDocValidationSchema(
          props.isNewGroup ? props.documentGroups : [],
          props.requiredDocumentKey
        )}>
        {({
          setFieldValue,
          values,
          errors,
          isValid,
          isSubmitting,
          setFieldError
        }) => {
          return (
            <Form data-testid='upload-plan-dialog-form'>
              <DialogTitle>{`Upload ${props.groupName || props.categoryName} ${props.isNewGroup ? 'for the new group' : ''}`}</DialogTitle>
              <DialogContent className={classes.dialogContent}>
                <SimpleUpload
                  accept={fileExtensions}
                  multiple
                  onSelect={files => {
                    if (Array.isArray(files)) {
                      setFieldValue('files', files);
                      const names = files.map(file => file.name);
                      setFieldValue('documentNames', names);
                      setFieldValue('documentKeys', []);
                    }
                  }}
                  selectRawFiles
                  showFileTypes>
                  <Box
                    alignItems='center'
                    border={`1px solid ${grey[300]}`}
                    borderRadius={0.5}
                    className={clsx(isHovering && classes.selectAreaHover)}
                    display='flex'
                    flexDirection='column'
                    gap={1}
                    justifyContent='center'
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                    padding={5}>
                    <Button
                      className={clsx(
                        isHovering && classes.selectAreaButtonHover
                      )}
                      ref={buttonRef}
                      startIcon={<FileUploadOutlinedIcon />}
                      variant='contained'>
                      Select Files
                    </Button>
                    <Typography
                      className={clsx(
                        values.documentNames?.length && classes.docName,
                        errors.documentNames && classes.errorMsg
                      )}
                      variant='caption'>
                      {values.documentNames?.join(', ') || 'No files chosen'}
                    </Typography>
                  </Box>
                </SimpleUpload>
                <Divider color={grey[200]} />
                {!!values.documentNames?.length &&
                  values.documentNames.map((docName: string, index: number) => {
                    return (
                      <Grid
                        alignItems='center'
                        container
                        direction='row'
                        key={docName}
                        spacing={1}>
                        <Grid item xs={6}>
                          <Typography overflow='hidden' textOverflow='ellipsis'>
                            {docName}
                          </Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <SimpleDropdown
                            errorMessage={
                              Array.isArray(errors.documentKeys)
                                ? errors.documentKeys[index]
                                : errors.documentKeys
                            }
                            fieldId={`documentKeys.${index}`}
                            fieldName='Document key'
                            fieldValues={validDocumentKeys}
                            onChange={event => {
                              setFieldValue(
                                `documentKeys.${index}`,
                                event.target.value
                              );
                            }}
                            required
                          />
                        </Grid>
                      </Grid>
                    );
                  })}
                <DatePickerForm
                  className={classes.datePicker}
                  data-testid='upload-doc-effective-date'
                  format='MM/DD/YYYY'
                  handleError={error => {
                    if (error === 'invalidDate') {
                      setFieldError('effectiveDate', '');
                    }
                  }}
                  inputProps={{
                    autoComplete: 'off'
                  }}
                  label='Effective date'
                  name='effectiveDate'
                  value={values.effectiveDate}
                  variant='outlined'
                />
              </DialogContent>
              <DialogActions>
                <Button
                  data-testid='upload-plan-dialog-cancel'
                  disabled={uploadPlanDocument.isLoading || isSubmitting}
                  onClick={() => {
                    closeDialog();
                  }}>
                  Cancel
                </Button>
                <LoadingButton
                  data-testid='upload-plan-dialog-submit'
                  disabled={
                    !isValid ||
                    !values.files?.length ||
                    uploadPlanDocument.isLoading ||
                    isSubmitting
                  }
                  loading={isSubmitting}
                  type='submit'>
                  Upload
                </LoadingButton>
              </DialogActions>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default BulkUploadPlanDialog;
