import { SubaValidationError } from '@/models/WithdrawalsDTO.model';

import {
  FraudrankerResultData,
  UpdatedFraudranker
} from './FraudrankerResult.model';

export interface LoanDto {
  id: number;
  attributes: {
    originalLoanAmount: number;
    outstandingPrincipalDue: number;
    overdueInterest: number;
    loanStatus: string;
    startDate?: string;
    firstPaymentDueDate?: string;
    createdAt: string;
    originationDate: string;
    participantFirstName?: string;
    participantLastName?: string;
  };
  relationships?: {
    participant?: {
      data: {
        type: string;
        id: number;
      };
      links: Record<string, unknown>;
    };
  };
}

export interface LoanResponseDto {
  data: LoanDto[];
}

export type FormattedLoan = {
  id: number;
  originalAmount: number;
  outstandingBalance: number;
  status: string;
  startDate?: string;
  firstPaymentDueDate?: string;
  createdAt: string;
  participantId: number;
  participantFirstName?: string;
  participantLastName?: string;
};

export interface LoanDetailsResponseDto {
  data: {
    id: number;
    attributes: {
      loanStatus: LoanStatusEnum;
      loanPurpose: string;
      loanAmount: number;
      outstandingPrincipalDue: number;
      overdueInterest: number;
      repaymentYears?: number;
      interestRate: number;
      isReamortizing: boolean;
      originationFee?: number;
      maintenanceFee: number;
      extraFee?: number;
      remainingPaymentsCount: number;
      deliveryMethod?: string;
      deliveryAddress?: Address;
      bankAccountInfo?: {
        financialInstitution: string;
        routingNumber: string;
        accountNumber: string;
        plaidMask?: string;
      };
      notes?: string;
      fraudNotes?: string;
      fraudRankResult?: FraudrankerResultData;
      forCreditTo?: string;
      forFurtherCreditTo?: string;
      originationDate?: string;
      addressUpdate?: LoanAddressUpdate;
      calloutToParticipant: boolean;
      updatedFraudRanking?: UpdatedFraudranker;
      createdAt: string;
    };
  };
  included?: LoanExpectedPayment[];
}

export type LoanExpectedPayment = {
  type?: 'loan-expected-payment';
  id?: number;
  attributes?: {
    paymentSequenceNumber: number;
    dueDate: string;
    total: number;
    interest: number;
    interestPaid: number;
    principal: number;
    principalPaid: number;
    paymentStatus:
      | 'Expected'
      | 'Pending'
      | 'Paid'
      | 'Paid (Partial)'
      | 'Past Due'
      | 'Default'
      | 'Offset'
      | 'Declined By Participant'
      | 'Expired';
    latestPaymentDate?: string;
    updatedAt: string;
  };
};

export type LoanPaymentscheduleTable = {
  id: number;
  dueDate: string;
  total: number;
  interest: number;
  principal: number;
  paymentStatus: string;
  totalPaid?: number;
  latestPaymentDate?: string;
  updatedAt?: string;
};

export type UpdateLoanAttributes = {
  loanAmount?: number;
  loanPurpose?: string;
  repaymentYears?: number;
  paymentFrequency?: string;
  status?: LoanStatus;
  isSignedAgreement?: boolean;
  ltLoanId?: string;
  originationFee?: number;
  maintenanceFee?: number;
  extraFee?: number;
  notes?: string;
  fraudNotes?: string;
  calloutToParticipant?: boolean;
  latestFraudRank?: string;
};

export interface UpdateLoanDto {
  id: number;
  attributes: UpdateLoanAttributes;
}

interface Address {
  address1: string;
  address2: string;
  city: string;
  state: string;
  zip: string;
}

type LoanTransactionDto = {
  id: number;
  tradeDate: string;
  effectiveDate: string;
  fundingSource: string;
  transactionSubtype: string;
  ticker: string;
  amount: number;
  status: string;
  tracerId: string;
};

export type LoanTransactionResponseDto = {
  data: LoanTransactionDto[];
};

export interface LoanTradeRequestDto {
  actionType: string;
  createdAt: string;
  id: number;
  status: string;
  workflowStatus?: string;
  updatedAt: string;
  tracerId: string;
  subaResult?: any;
}

export interface LoanTradeRequestTable {
  createdAt: string;
  id: number;
  status: string;
  workflowStatus?: string;
  type: string;
  updatedAt: string;
  tracerId: string;
  subaResult?: any;
}

export type LoanStatus =
  | 'Awaiting Participant Acceptance'
  | 'Declined by Participant'
  | 'Expired'
  | 'Disbursement Pending'
  | 'Disbursement Complete'
  | 'Fraud Check Pending'
  | 'Fraud Check Complete'
  | 'Live'
  | 'Paid in Full'
  | 'Default'
  | 'Deemed'
  | 'Offset'
  | 'Approved'
  | 'Requested'
  | 'Canceled'
  | 'Rejected'
  | 'Past Due'
  | 'Canceled - Migration'
  | 'Reamortized';

export enum LoanStatusEnum {
  AwaitingParticipantAcceptance = 'Awaiting Participant Acceptance',
  DeclinedByParticipant = 'Declined by Participant',
  Expired = 'Expired',
  DisbursementPending = 'Disbursement Pending',
  DisbursementComplete = 'Disbursement Complete',
  FraudCheckPending = 'Fraud Check Pending',
  FraudCheckComplete = 'Fraud Check Complete',
  Live = 'Live',
  PaidInFull = 'Paid in Full',
  Default = 'Default',
  Deemed = 'Deemed',
  Offset = 'Offset',
  Approved = 'Approved',
  Requested = 'Requested',
  Canceled = 'Canceled',
  Rejected = 'Rejected',
  PastDue = 'Past Due',
  CanceledMigration = 'Canceled - Migration',
  Reamortized = 'Reamortized'
}

type ParentLoanDto = {
  ssn: string;
  loanId: number;
};

export type LoanBriefInfo = {
  data: ParentLoanDto[];
};

export type LoanIdsPerPlanDto = {
  data: number[];
};

export type LoanAddressUpdate = {
  approvedBy?: any;
  address1?: string;
  address2?: string;
  city?: string;
  state?: string;
  status: string;
  zip?: string;
  submittedBy: any;
  updatedAt?: string;
};

export type LoanAddressUpdateRequest = {
  address1?: string;
  address2?: string;
  city?: string;
  state?: string;
  status: 'Approved' | 'Canceled' | 'Pending' | 'Rejected';
  zip?: string;
  loanId: number;
};

export const PAY_SCHEDULE_DETAILED = {
  B: 'Every 2 Weeks',
  M: 'Monthly',
  ME: 'Monthly',
  S: 'Twice a Month',
  SC: 'Twice a Month',
  W: 'Weekly'
};

export type LoanReamortizeRequest = {
  firstPaymentDate?: string;
  paymentAmount: number;
  payrollId: string;
  waiveOverdueInterest: boolean;
};

export type LoanExpectedPaymentAttributes = {
  dueDate: string;
  interest: number;
  interestPaid: number;
  latestPaymentDate?: string;
  paymentSequenceNumber: number;
  paymentStatus: string;
  principal: number;
  principalPaid: number;
  total: number;
  updatedAt: string;
};

export type LoanValidationResult = {
  validationErrors: SubaValidationError[];
};
