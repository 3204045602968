import { DataTableStackCell } from '@/components/data-table/DataTable.component';
import { styled } from '@mui/material';

const StyledDataTableStackCell = styled(DataTableStackCell)(({ theme }) => ({
  minHeight: theme.spacing(7)
}));

type MappingStackCellProps = {
  broadAssetClass?: string;
  cusip?: string;
  expenseRatio?: number;
  modelId?: number;
  name: string;
  symbol?: string;
  modelType: string;
};

export const MappingStackCell = (props: MappingStackCellProps) => {
  const primary = props.symbol ? `${props.symbol} | ${props.name}` : props.name;

  const secondary =
    props.expenseRatio && props.broadAssetClass
      ? `ER: ${props.expenseRatio} | Class: ${props.broadAssetClass}`
      : `${props.modelType === 'goal' ? 'Portfolio ' : ''}ID: ${props.cusip || props.modelId}`;

  return (
    <StyledDataTableStackCell
      primary={primary}
      secondary={
        props.modelType !== 'default' &&
        props.modelType !== 'ms_rp' &&
        props.modelType !== 'ms_ma'
          ? secondary
          : undefined
      }
    />
  );
};

MappingStackCell.displayName = 'MappingStackCell';
