import { useGetSecurityQuery } from '@/hooks/suba/useGetSecurity.hook';
import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import {
  Box,
  CircularProgress,
  FormControl,
  FormControlProps as FormControlPropsType,
  FormHelperText,
  FormHelperTextProps as FormHelperTextPropsType,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  OutlinedInputProps as OutlinedInputPropsType,
  Stack,
  SvgIcon
} from '@mui/material';
import { unstable_useId as useId } from '@mui/utils';

import React, { useEffect, useState } from 'react';
import { useDebounce, useToggle } from 'react-use';

import TextStack, { TextStackItem, TextValue } from '../text-stack';

type CusipTickerSearchProps = {
  'data-testid'?: string;
  helperTextPlaceholder?: boolean;
  summary?: boolean;
  disabled?: boolean;
  /** @deprecated Use `value` instead */
  initialValue?: string;
  name?: string;
  value?: string;
  FormControlProps?: FormControlPropsType;
  FormHelperTextProps?: FormHelperTextPropsType;
  OutlinedInputProps?: OutlinedInputPropsType;
  onChange?: (
    confirmedRawValue: string,
    cusipTickerValues: { cusip: string; symbol: string }
  ) => void;
  onError?: (error: any) => void;
  onValidating?: (value: boolean) => void;
};

export const CusipTickerSearch = React.forwardRef<
  HTMLDivElement,
  CusipTickerSearchProps
>(
  (
    {
      'data-testid': testId = 'cusip-ticker-search-input',
      helperTextPlaceholder = false,
      summary = false,
      disabled = false,
      ...props
    },
    ref
  ) => {
    const [errorMessage, setErrorMessage] = useState<null | string>(null);
    const [value, setValue] = useState(props.initialValue || props.value || ''); // empty value avoids controlled/uncontrolled warning while external data is initializing
    const [searchValue, setSearchValue] = useState(
      props.initialValue || props.value
    );
    const [canClearValue, toggleCanClearValue] = useToggle(false);
    const inputId = useId();
    const summaryId = useId();

    useDebounce(
      () => {
        props.onValidating?.(false);
        if (value !== searchValue && (value.length >= 2 || value === '')) {
          setSearchValue(value.toUpperCase());
        }
      },
      300,
      [value]
    );

    const getSecurityQuery = useGetSecurityQuery(searchValue, {
      enabled: !!searchValue
    });

    useEffect(() => {
      props.onValidating?.(getSecurityQuery.isFetching);
    }, [getSecurityQuery.isFetching, props.onValidating]);

    useEffect(() => {
      // data is undefined when there is no cached data and the query has been disabled (e.g. for '' search)
      if (getSecurityQuery.data !== undefined) toggleCanClearValue(true);

      if (
        typeof props.onChange === 'function' &&
        getSecurityQuery.data?.cusip
      ) {
        props.onChange(value, {
          cusip: getSecurityQuery.data.cusip,
          symbol: getSecurityQuery.data.symbol
        });
      }
      // we only want to run this when data changes;
      // don't include onChange to avoid render loops when non-memoized functions are provided
    }, [getSecurityQuery.data]);

    useEffect(() => {
      if (getSecurityQuery.error) {
        if (
          getSecurityQuery.error.isAxiosError &&
          getSecurityQuery.error.response?.status === 404
        ) {
          setErrorMessage('Ticker doesn’t exist');
        } else {
          setErrorMessage(getSecurityQuery.error.message);
        }
      } else {
        setErrorMessage(null);
      }
    }, [getSecurityQuery.error]);

    useEffect(() => {
      if (typeof props.onError === 'function' && errorMessage !== null) {
        props.onError(new Error(errorMessage));
      }
      // we only want to run this when an error occurs;
      // don't include onError to avoid render loops when non-memoized functions are provided
    }, [errorMessage]);

    const handleKeyDown = (
      e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
      if (e.key === 'Enter') {
        if (getSecurityQuery.isFetching) {
          e.preventDefault();
        }
        setSearchValue(value);
      }
    };

    const getEndAdornment = () => {
      if (getSecurityQuery.isFetching) {
        return (
          <InputAdornment position='end'>
            <CircularProgress size='20px' />
          </InputAdornment>
        );
      }
      if (disabled) {
        return (
          <InputAdornment position='end'>
            <IconButton disabled>
              <SvgIcon />
            </IconButton>
          </InputAdornment>
        );
      }
      if (canClearValue) {
        return (
          <InputAdornment position='end'>
            <IconButton
              aria-label='clear'
              onClick={() => {
                setValue('');
                setSearchValue('');
                toggleCanClearValue();

                if (typeof props.onChange === 'function') {
                  props.onChange('', { cusip: '', symbol: '' });
                }
              }}>
              <ClearIcon />
            </IconButton>
          </InputAdornment>
        );
      }
      return (
        <InputAdornment position='end'>
          <IconButton aria-label='search' onClick={() => setSearchValue(value)}>
            <SearchIcon />
          </IconButton>
        </InputAdornment>
      );
    };

    const helperText =
      props.FormHelperTextProps?.children || errorMessage || ' ';

    return (
      <Stack ref={ref} spacing={2} width='100%'>
        <FormControl
          error={!!getSecurityQuery.error}
          size='small'
          {...(props.FormControlProps || {})}
          onBlur={() => setSearchValue(value)}>
          <InputLabel htmlFor={inputId}>CUSIP / Ticker</InputLabel>
          <OutlinedInput
            autoComplete='off'
            disabled={disabled}
            endAdornment={getEndAdornment()}
            id={inputId}
            inputProps={{
              'aria-busy': getSecurityQuery.isFetching,
              'aria-describedby': summaryId,
              'data-testid': testId // for value input only, the hidden input should be accessed via name or jest-dom toHaveFormValues
            }}
            label='CUSIP / Ticker'
            onChange={event => {
              const currentValue = event.target.value;

              toggleCanClearValue();
              setValue(currentValue.toUpperCase());

              if (typeof props.onChange === 'function' && currentValue === '') {
                props.onChange('', { cusip: '', symbol: '' });
              }
            }}
            onKeyDown={handleKeyDown}
            sx={{
              // zero padding when buttons are adornments to avoid excessive padding due to their hit areas
              pr: getSecurityQuery.isFetching ? undefined : 0
            }}
            type='text'
            value={value}
            {...(props.OutlinedInputProps || {})}
          />
          {(props.FormHelperTextProps?.children ||
            errorMessage ||
            helperTextPlaceholder === true) && (
            <FormHelperText error {...(props.FormHelperTextProps || {})}>
              {helperText}
            </FormHelperText>
          )}
          {/* separate hidden input for form submission of matched CUSIP */}
          <input
            name={props.name}
            type='hidden'
            value={getSecurityQuery.data?.cusip || ''}
          />
        </FormControl>
        {summary && getSecurityQuery.data && (
          <Box
            id={summaryId}
            sx={{
              bgcolor: theme => theme.palette.primary.light,
              border: '1px solid rgba(33, 150, 243, 0.3)',
              borderRadius: '4px',
              px: 2,
              py: 1.5
            }}>
            <TextStack
              divider
              id='account-detail-header-fields'
              rowColumnWidth='dynamic'>
              <>
                <TextStackItem>
                  <TextValue
                    detail={`CUSIP: ${getSecurityQuery.data.cusip}`}
                    sx={{ whiteSpace: 'nowrap' }}>
                    {getSecurityQuery.data.symbol} &#8226;{' '}
                    {getSecurityQuery.data.description}
                  </TextValue>
                </TextStackItem>
              </>
            </TextStack>
          </Box>
        )}
      </Stack>
    );
  }
);

CusipTickerSearch.displayName = 'CusipTickerSearch';

export default CusipTickerSearch;
