import { useSnackbar } from '@/contexts/SnackBarContext';
import { useUserToken } from '@/contexts/UserTokenContext';
import { HoldingsGetRequest } from '@/models/suba/holdings/HoldingsGetRequest.model';
import { HoldingsService } from '@/services/suba/holdings/Holdings.service';
import { useQuery } from '@tanstack/react-query';

import { AxiosError } from 'axios';

export const useHoldingsQuery = (params: HoldingsGetRequest) => {
  const { showSnackbar } = useSnackbar();
  const { userHasValidToken } = useUserToken();

  return useQuery(
    ['HoldingsService.get', ...Object.values(params)],
    () => HoldingsService.get(params),
    {
      enabled:
        userHasValidToken &&
        !!(params.parentAccountId || params.planId || params.subAccountId),
      keepPreviousData: true,
      onError: (err: AxiosError) => {
        showSnackbar({
          message: `Failed to get holdings: ${err.response?.data ?? err.message}`,
          severity: 'error'
        });
      }
    }
  );
};
