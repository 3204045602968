import { Badge } from '@/components/badge';
import { CusipTickerSearch } from '@/components/cusip-ticker-search/CusipTickerSearch';
import { DatePicker } from '@/components/date-picker';
import { orderStatusColorMap } from '@/models/suba/common/OrderStatusColorMap.model';
import formatters from '@/utils/Formatters';
import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  Stack
} from '@mui/material';
import { TradeType } from '@vestwell-sub-accounting/models/common/TradeType';
import { OrderStatus } from '@vestwell-sub-accounting/models/orderManagement/OrderStatus';

import { Field, Form, useFormikContext } from 'formik';
import { FC } from 'react';
import { useToggle } from 'react-use';

export type ParentAccountOrdersFiltersFormValues = {
  cusip: string;
  endDate: string;
  omsBatchId: string;
  orderStatus: OrderStatus[];
  ourParentOrderId: string;
  startDate: string;
  tradeType: TradeType[];
};

export const ParentAccountOrdersFiltersForm: FC = () => {
  // context

  const formikContext =
    useFormikContext<ParentAccountOrdersFiltersFormValues>();

  // state

  const [isValidating, toggleIsValidating] = useToggle(false);
  const [isCusipValid, toggleIsCusipValid] = useToggle(true);

  return (
    <Form data-testid='filter-form'>
      <Stack
        alignItems='flex-start'
        justifyContent='flex-start'
        spacing={2}
        width={256}>
        <Field
          as={CusipTickerSearch}
          data-testid='cusip-symbol-input'
          helperTextPlaceholder
          name='cusip'
          onChange={async value => {
            await formikContext.setFieldValue('cusip', value);
            toggleIsCusipValid(true);
            if (value === '') formikContext.submitForm();
          }}
          onError={err => {
            formikContext.setFieldError('cusip', err.message);
            toggleIsCusipValid();
          }}
          onValidating={value => toggleIsValidating(value)}
        />
        <FormControl fullWidth size='small'>
          <InputLabel id='tradeType-label' shrink>
            Trade Type
          </InputLabel>
          <Field
            MenuProps={{
              'data-testid': 'menu-tradeType'
            }}
            as={Select}
            displayEmpty
            label='Trade Type'
            labelId='tradeType-label'
            multiple
            name='tradeType'
            renderValue={(selected: TradeType[]) => {
              if (!selected?.length) {
                return <>Any</>;
              }
              return selected
                .map(value => formatters.getValueKey(TradeType, value))
                .join(', ');
            }}>
            {Object.values(TradeType).map(value => {
              const displayTradeType = formatters.getValueKey(TradeType, value);
              return (
                <MenuItem key={value} value={value}>
                  <Checkbox
                    checked={formikContext.values.tradeType?.includes(value)}
                    sx={{ py: 0 }}
                  />
                  <ListItemText>
                    {formatters.displayCase(displayTradeType)}
                  </ListItemText>
                </MenuItem>
              );
            })}
          </Field>
        </FormControl>
        <FormControl fullWidth size='small'>
          <InputLabel id='orderStatus-label' shrink>
            Status
          </InputLabel>
          <Field
            MenuProps={{
              'data-testid': 'menu-orderStatus'
            }}
            as={Select}
            displayEmpty
            label='Status'
            labelId='orderStatus-label'
            multiple
            name='orderStatus'
            renderValue={(selected: OrderStatus[]) => {
              if (!selected?.length) {
                return <>Any</>;
              }
              return (
                <Box
                  sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    gap: 0.5
                  }}>
                  {selected.map(value => {
                    const displayOrderStatus = formatters.getValueKey(
                      OrderStatus,
                      value
                    );
                    return (
                      <Badge
                        color={orderStatusColorMap[value]}
                        key={value}
                        size='small'>
                        {formatters.displayCase(displayOrderStatus)}
                      </Badge>
                    );
                  })}
                </Box>
              );
            }}>
            {Object.values(OrderStatus)
              .sort() // close enough to sorting by key so lets call it good
              .map(value => {
                const displayOrderStatus = formatters.getValueKey(
                  OrderStatus,
                  value
                );
                return (
                  <MenuItem key={value} value={value}>
                    <Checkbox
                      checked={formikContext.values.orderStatus?.includes(
                        value
                      )}
                      sx={{ py: 0 }}
                    />
                    <ListItemText>
                      {formatters.displayCase(displayOrderStatus)}
                    </ListItemText>
                  </MenuItem>
                );
              })}
          </Field>
        </FormControl>

        <Divider flexItem textAlign='left'>
          Trade Date
        </Divider>

        <FormControl fullWidth>
          <Field
            as={DatePicker}
            autoComplete='off'
            data-testid='startDate'
            disableFuture
            label='From'
            name='startDate'
            size='small' // FormControl doesn't pass to our DatePicker
            variant='outlined'
          />
        </FormControl>
        <FormControl fullWidth>
          <Field
            as={DatePicker}
            autoComplete='off'
            data-testid='endDate'
            disableFuture
            label='To'
            name='endDate'
            size='small' // FormControl doesn't pass to our DatePicker
            variant='outlined'
          />
        </FormControl>
        <Divider flexItem textAlign='left'>
          Other
        </Divider>
        <FormControl fullWidth size='small'>
          <InputLabel htmlFor='ourParentOrderId-input'>Order ID</InputLabel>
          <Field
            as={OutlinedInput}
            id='ourParentOrderId-input'
            inputProps={{
              'data-testid': 'ourParentOrderId-input'
            }}
            label='Order ID'
            name='ourParentOrderId'
          />
        </FormControl>
        <FormControl fullWidth size='small'>
          <InputLabel htmlFor='omsBatchId-input'>OMS Batch ID</InputLabel>
          <Field
            as={OutlinedInput}
            id='omsBatchId-input'
            inputProps={{
              'data-testid': 'omsBatchId-input'
            }}
            label='OMS Batch ID'
            name='omsBatchId'
          />
        </FormControl>
        <Button
          data-testid='submit'
          disabled={!formikContext.isValid || isValidating || !isCusipValid}
          type='submit'
          variant='outlined'>
          Apply
        </Button>
      </Stack>
    </Form>
  );
};
