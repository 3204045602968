import { useSnackbar } from '@/contexts/SnackBarContext';
import { UpdateConversionDto } from '@/models/ConversionDTO.model';
import { PlanService } from '@/services/Plan.service';
import {
  useMutation,
  UseMutationResult,
  useQueryClient
} from '@tanstack/react-query';

import { startCase } from 'lodash';
import kebabCase from 'lodash/kebabCase';

import { FundMappings } from '../InvestmentElectionMapping/InvestmentElectionMapping.component';

interface UploadOnboardingFileProps {
  results: Record<string, any>[];
  conversionDto?: UpdateConversionDto;
  fundMappings?: FundMappings;
}

const useUploadOnboardingAgGridFile = (
  sponsorPlanId: number,
  documentType: string,
  userFileName = '',
  onSettled?: () => void
): UseMutationResult<unknown, Error, UploadOnboardingFileProps> => {
  const queryClient = useQueryClient();
  const snackbar = useSnackbar();

  return useMutation<unknown, Error, UploadOnboardingFileProps>(
    props =>
      PlanService.uploadOnboardingFileV2({
        conversionDto: props.conversionDto,
        documentData: props.results,
        documentType: kebabCase(documentType),
        fundMappings: props.fundMappings,
        planId: sponsorPlanId as number,
        userFileName
      }),
    {
      onError: async (error: any) => {
        snackbar.showSnackbar({
          autoHideDuration: 10000,
          message:
            JSON.stringify(error?.data, null, 2) ||
            error.message ||
            'Something went wrong!',
          severity: 'error'
        });
      },
      onSettled: async () => {
        try {
          await queryClient.fetchQuery([
            `PlanService.getPlanConversions ${sponsorPlanId}`
          ]);
        } catch (_) {
          /* empty */
        } finally {
          if (onSettled) {
            onSettled();
          }
        }
      },
      onSuccess: async () => {
        snackbar.showSnackbar({
          message: `Successfully uploaded new ${startCase(documentType)}`,
          severity: 'success'
        });
      }
    }
  );
};

export default useUploadOnboardingAgGridFile;
