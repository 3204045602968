import { useSnackbar } from '@/contexts/SnackBarContext';
import { useUserToken } from '@/contexts/UserTokenContext';
import { DividendAccrualRateService } from '@/services/suba/dividend-accrual-rates/DividendAccrualRates.service';
import { useQuery } from '@tanstack/react-query';

import { AxiosError } from 'axios';

export const SEARCH_DIVIDEND_ACCRUAL_RATES_QUERY_KEY =
  'DividendAccrualRateService.search';

export const useSearchDividendAccrualRatesQuery = (
  params: Parameters<typeof DividendAccrualRateService.search>[0]
) => {
  const { showSnackbar } = useSnackbar();
  const { userHasValidToken } = useUserToken();

  return useQuery(
    [SEARCH_DIVIDEND_ACCRUAL_RATES_QUERY_KEY, params],
    () => DividendAccrualRateService.search(params),
    {
      enabled: userHasValidToken,
      keepPreviousData: true,
      onError: (err: AxiosError) => {
        showSnackbar({
          message: `Failed to get dividend accrual rates: ${err.response?.data ?? err.message}`,
          severity: 'error'
        });
      }
    }
  );
};
