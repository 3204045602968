import { useSnackbar } from '@/contexts/SnackBarContext';
import { useUserToken } from '@/contexts/UserTokenContext';
import { PendingTransactionSearchRequest } from '@/models/suba/transactions/PendingTransactionSearchRequest.model';
import { TransactionService } from '@/services/suba/transactions/Transaction.service';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';

import { AxiosError } from 'axios';

export const useSearchPendingTransactionsQuery = (
  params: PendingTransactionSearchRequest,
  options?: UseQueryOptions<
    Awaited<ReturnType<typeof TransactionService.searchPending>>,
    AxiosError
  >
) => {
  const { showSnackbar } = useSnackbar();
  const { userHasValidToken } = useUserToken();

  return useQuery(
    ['TransactionService.searchPending', ...Object.values(params)],
    () => TransactionService.searchPending(params),
    {
      ...(options || {}),
      enabled: (options?.enabled && userHasValidToken) ?? userHasValidToken,
      keepPreviousData: options?.keepPreviousData ?? true,
      onError:
        options?.onError ??
        ((err: AxiosError) => {
          showSnackbar({
            message: `Failed to search pending transactions: ${err.response?.data || err.message}`,
            severity: 'error'
          });
        })
    }
  );
};
