import { CardContent, CardPlaceholder } from '@/components/card/Card.component';
import DataTable, {
  DataTableStackCell
} from '@/components/data-table/DataTable.component';
import { useSearchParentAccountsQuery } from '@/hooks/suba/useSearchParentAccountsQuery.hook';
import { ParentAccountDto } from '@/models/suba/ParentAccountDTO.model';
import ParentAccountService from '@/services/suba/accounts/ParentAccount.service';
import formatters from '@/utils/Formatters';
import SearchIcon from '@mui/icons-material/Search';
import { Stack } from '@mui/material';
import { CustodianId } from '@vestwell-sub-accounting/models/common/CustodianId';
import { ParentAccountType } from '@vestwell-sub-accounting/models/common/ParentAccountType';

import { Formik } from 'formik';
import { FC } from 'react';

import {
  ParentAccountsSearchForm,
  ParentAccountsSearchFormValues
} from './components/ParentAccountsSearchForm.component';

type SearchFormValues = {
  custodianId?: CustodianId | '';
  query?: string;
};

type SearchParams = Parameters<
  typeof ParentAccountService.searchParentAccounts
>[0];

export type ParentAccountsProps = {
  searchParams: SearchParams;
  onSearchFormSubmit: (values: SearchFormValues) => void;
};

export const ParentAccounts: FC<ParentAccountsProps> = props => {
  // api

  const searchParentAccountsQuery = useSearchParentAccountsQuery(
    props.searchParams,
    {
      enabled:
        props.searchParams.accountType !== ParentAccountType.PlanLevel ||
        !!(props.searchParams.custodianId || props.searchParams.query),
      initialData:
        !props.searchParams.custodianId || !props.searchParams.query
          ? []
          : undefined
    }
  );

  return (
    <>
      <CardContent>
        <Formik<ParentAccountsSearchFormValues>
          enableReinitialize
          initialValues={{
            custodianId: props.searchParams.custodianId || '',
            query: props.searchParams.query || ''
          }}
          onSubmit={props.onSearchFormSubmit}>
          <ParentAccountsSearchForm
            accountType={props.searchParams.accountType}
          />
        </Formik>
      </CardContent>
      <CardContent
        disablePadding
        loading={searchParentAccountsQuery.isInitialLoading}>
        <DataTable
          columnDefs={[
            {
              autoHeight: true,
              cellRenderer: (props: { data: ParentAccountDto }) => {
                const name =
                  props.data?.accountType === 'SuperOmnibus' ||
                  props.data?.accountType === 'House'
                    ? props.data?.accountName
                    : props.data?.plan?.name;
                return (
                  <DataTableStackCell
                    primary={name || ''}
                    primaryLinkProps={{
                      to: `/ops/accounts/${props.data.parentAccountId}`
                    }}
                    secondary={`ID: ${props.data.parentAccountId}`}
                  />
                );
              },
              flex: 2,
              headerName: 'Parent Account'
            },
            {
              autoHeight: true,
              cellRenderer: (props: { data: ParentAccountDto }) => (
                <DataTableStackCell
                  primary={formatters.displayCase(props.data.custodianId)}
                  secondary={`ID: ${props.data.custodianAccountNumber}`}
                />
              ),
              flex: 2,
              headerName: 'Custodian'
            },
            {
              field: 'createdAt',
              flex: 5,
              valueFormatter: ({ value }: { value: string }) =>
                formatters.formatFromIsoDate(value)
            }
          ]}
          columnSizing='flex'
          data-testid='results-table'
          emptyPlaceholderComponent={
            <Stack
              alignItems='center'
              data-testid={
                !props.searchParams.query
                  ? 'results-placeholder'
                  : 'no-results-message'
              }
              justifyContent='center'
              sx={{ height: '100%' }}>
              <CardPlaceholder
                icon={<SearchIcon fontSize='inherit' />}
                subtitle={
                  props.searchParams.query === ''
                    ? 'Search results will be displayed here.'
                    : 'No results found.'
                }
              />
            </Stack>
          }
          rowData={searchParentAccountsQuery.data || []}
        />
      </CardContent>
    </>
  );
};
