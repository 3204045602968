import Card, { CardContent, CardPlaceholder } from '@/components/card';
import {
  DataTable,
  DataTableBadgeCell,
  DataTableProps,
  DataTableStackCell
} from '@/components/data-table/DataTable.component';
import MyTabs from '@/components/my-tabs/MyTabs.component';
import { SierraWorkflowStatus } from '@/components/sierra-workflow-status';
import { useSnackbar } from '@/contexts/SnackBarContext';
import { useGetVestwellStaffQuery } from '@/hooks/suba/useGetVestwellStaffQuery.hook';
import { useSearchAlertTypeTotals } from '@/hooks/suba/useSearchAlertTypeTotals.hook';
import { AlertDto } from '@/models/suba/alerts/AlertDTO.model';
import { AlertStatusColorMap } from '@/models/suba/alerts/AlertStatusColorMap.model';
import {
  DepositType,
  depositTypeNames
} from '@/models/suba/alerts/DepositType.model';
import { OrderByDirection } from '@/models/suba/common/OrderByDirection.model';
import { FeatureLevelPermissions } from '@/models/UserPermissions.model';
import { AlertDetails } from '@/routes/suba/common/components/alert-details';
import { AlertDetailsTabs } from '@/routes/suba/common/components/AlertDetailsTabs.component';
import { UpdateAlertForm } from '@/routes/suba/common/components/UpdateAlertForm.component';
import AlertService from '@/services/suba/alerts/Alert.service';
import { userService } from '@/services/User.service';
import formatters from '@/utils/Formatters';
import SearchIcon from '@mui/icons-material/Search';
import {
  Box,
  Divider,
  FormControlLabel,
  Stack,
  Switch,
  SwitchProps,
  Typography
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { AlertStatus } from '@vestwell-sub-accounting/models/common/AlertStatus';
import { AlertSubType } from '@vestwell-sub-accounting/models/common/AlertSubType';
import { AlertType } from '@vestwell-sub-accounting/models/common/AlertType';
import { SecurityOrderType } from '@vestwell-sub-accounting/models/common/SecurityOrderType';
import { WorkflowName } from '@vestwell-sub-accounting/models/conductor/WorkflowName.model';

import { ColDef } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { Formik } from 'formik';
import { FC, useCallback, useMemo, useRef, useState } from 'react';

import { AlertDetailsDrawer } from '../AlertDetailsDrawer.component';
import {
  AlertsFiltersForm,
  AlertsFiltersFormValues
} from './components/AlertsFiltersForm.component';

type SearchParams = Parameters<typeof AlertService.search>[0];

export type AlertsProps = {
  onAlertOpen?: (alert: AlertDto) => void;
  onAlertTypeChange: (alertType: AlertType | null) => void;
  onAssigneeChange: (assignee: string | null) => void;
  onDrawerClose?: () => void;
  onFiltersFormSubmit: (values: AlertsFiltersFormValues) => void;
  onPageChange: DataTableProps['onPageChanged'];
  onPageSizeChange: DataTableProps['onPageSizeChanged'];
  onSortChange: (
    newSort: Pick<SearchParams, 'orderBy' | 'orderByDirection'>
  ) => void;
  searchParams: SearchParams;
};

export const Alerts: FC<AlertsProps> = props => {
  // context

  const { showSnackbar } = useSnackbar();

  // refs

  const gridRef = useRef<AgGridReact>(null);

  // state

  const [isAlertDetailDrawerOpen, setIsAlertDetailDrawerOpen] = useState(false);
  const [selectedAlertId, setSelectedAlertId] = useState<number | undefined>();

  // api

  const getVestwellStaffQuery = useGetVestwellStaffQuery();

  const searchTypeTotalsQuery = useSearchAlertTypeTotals({
    ...props.searchParams,
    orderBy: undefined,
    orderByDirection: undefined,
    page: undefined,
    pageSize: undefined
  });

  const searchAlertWithPaginationQuery = useQuery(
    ['AlertService.search', props.searchParams],
    () => AlertService.search(props.searchParams),
    {
      keepPreviousData: true,
      onError: (err: any) => {
        const message = err.response?.data ? err.response.data : err.message;
        showSnackbar({
          message: `Alert search failed: ${message}`,
          severity: 'error'
        });
      }
    }
  );

  // callbacks

  const handleAlertTypeChange = useCallback(maybeNewAlertType => {
    if (!props.onAlertTypeChange) return;
    if (maybeNewAlertType === 'All') return props.onAlertTypeChange(null);
    if (!Object.values(AlertType).includes(maybeNewAlertType)) return;

    props.onAlertTypeChange(maybeNewAlertType);
  }, []);

  const handleAssigneeChange = useCallback<SwitchProps['onChange']>(event => {
    props.onAssigneeChange(
      event.target.checked ? userService.getUser()?.sub : null
    );
  }, []);

  const handleSortChanged = useCallback<DataTableProps['onSortChanged']>(
    newSortArray => {
      props.onSortChange(
        !newSortArray || newSortArray.length === 0
          ? {
              orderBy: undefined,
              orderByDirection: undefined
            }
          : {
              orderBy: newSortArray[0].colId as keyof AlertDto,
              orderByDirection: newSortArray[0].sort as OrderByDirection
            }
      );
    },
    []
  );

  const selectCustodianAccount = useCallback((cellData: { data: AlertDto }) => {
    switch (cellData.data.alertType) {
      case AlertType.Trading:
      case AlertType.MoneyIn:
        switch (cellData.data.alertSubType) {
          case AlertSubType.CashTransferRejected:
            return {
              custodianAccountNumber:
                cellData.data.details?.fromParentAccount
                  ?.custodianAccountNumber,
              parentAccountId: cellData.data.details?.fromParentAccount?.id
            };
          default:
            return {
              custodianAccountNumber:
                cellData.data.details?.trade?.custodianAccountNumber,
              parentAccountId:
                cellData.data.parentAccountId ||
                cellData.data.details?.parentAccountId
            };
        }

      default:
        return {
          custodianAccountNumber:
            cellData.data.custodianAccountNumber ||
            cellData.data.details?.custodianAccountNumber,
          parentAccountId:
            cellData.data.parentAccountId ||
            cellData.data.details?.parentAccountId
        };
    }
  }, []);

  // memos

  const columnDefs = useMemo(() => {
    const columnDefOptions: ColDef[] = [
      {
        autoHeight: true,
        cellRenderer: (cellData: { data: AlertDto }) => {
          // fetch friendly display name for alert sub type
          const displaySubType = formatters.getValueKey(
            AlertSubType,
            cellData.data.alertSubType
          );
          return (
            <DataTableStackCell
              primary={`${cellData.data.id} - ${cellData.data.alertName}`}
              primaryLinkProps={{
                onClick: () => {
                  setSelectedAlertId(cellData.data.id);
                  setIsAlertDetailDrawerOpen(true);
                  if (props.onAlertOpen) props.onAlertOpen(cellData.data);
                },
                to: null
              }}
              secondary={formatters.displayCase(displaySubType)}
            />
          );
        },
        field: 'id',
        headerName: 'Alert',
        minWidth: 255,
        sortable: true
      },
      {
        autoHeight: true,
        cellRenderer: (cellData: { data: AlertDto }) => {
          const workflowName =
            cellData.data?.details?.workflowExecution?.workflowName;

          return (
            <DataTableStackCell
              primary={
                formatters.displayCase(
                  workflowName === WorkflowName.tradeRequest
                    ? cellData.data?.details?.tradeRequestQueue
                        ?.tradeCalculatorMethod
                    : workflowName
                ) || '\u2014'
              }
            />
          );
        },
        field: 'flowType',
        headerName: 'Flow Type',
        minWidth: 180,
        sortable: false
      },
      {
        autoHeight: true,
        cellRenderer: (cellData: { data: AlertDto }) => {
          const planId =
            cellData.data.planId || cellData.data.details?.plan?.id || '\u2014';
          const planName = cellData.data.plan?.name || '\u2014';

          return (
            <DataTableStackCell
              primary={planId}
              primaryLinkProps={{
                to: planId !== '\u2014' ? `/plans/${planId}/plan` : undefined
              }}
              secondary={planName}
            />
          );
        },
        field: 'planId',
        headerName: 'Plan',
        minWidth: 180,
        sortable: false
      },
      {
        autoHeight: true,
        cellRenderer: (cellData: { data: AlertDto }) => {
          const custodianAccount = selectCustodianAccount(cellData);
          return (
            <DataTableStackCell
              primary={custodianAccount.custodianAccountNumber}
              secondary={`Parent Acct. ID: ${custodianAccount.parentAccountId}`}
              secondaryLinkProps={{
                to: custodianAccount.parentAccountId
                  ? `/ops/accounts/${custodianAccount.parentAccountId}`
                  : undefined
              }}
            />
          );
        },
        field: 'custodianAccountNumber',
        headerName: 'Custodian Account',
        minWidth: 180,
        sortable: false
      },
      {
        autoHeight: true,
        cellRenderer: (cellData: { data: AlertDto }) => {
          return cellData.data.alertSubType === AlertSubType.CashTransfer ? (
            <DataTableStackCell
              primary={
                cellData.data.details?.toParentAccount?.plan?.setupType ||
                cellData.data.details?.toParentAccount?.accountName ||
                '\u2014'
              }
              secondary={cellData.data.details?.toParentAccount?.subAccountId}
            />
          ) : null;
        },
        field: 'toAccount',
        headerName: 'To Account',
        minWidth: 180,
        sortable: false
      },
      {
        autoHeight: true,
        cellRenderer: (cellData: { data: AlertDto }) => {
          return (
            <DataTableStackCell
              primary={
                cellData.data?.details?.event?.body?.[0]?.amount
                  ? formatters.formatDollars(
                      cellData.data.details.event.body[0].amount
                    )
                  : '\u2014'
              }
              secondary={
                depositTypeNames[
                  cellData.data?.details?.event?.body?.[0]
                    ?.depositType as DepositType
                ] || '\u2014'
              }
            />
          );
        },
        field: 'depositInfo',
        headerName: 'Deposit Info',
        minWidth: 180,
        sortable: false
      },
      {
        autoHeight: true,
        cellRenderer: (cellData: { data: AlertDto }) => (
          <SierraWorkflowStatus
            executionStatus={
              cellData.data?.details?.workflowExecution?.executionStatus ??
              'Execution status not found'
            }
            workflowStatus={
              cellData.data?.details?.workflowStatus?.workflowStatus ??
              'Workflow status not found'
            }
          />
        ),
        field: 'currentStatus',
        headerName: 'Execution/Workflow Statuses',
        minWidth: 175,
        sortable: true
      },
      {
        autoHeight: true,
        cellRenderer: (cellData: { data: AlertDto }) => {
          // fetch friendly display name for status
          const displayStatus = formatters.getValueKey(
            AlertStatus,
            cellData.data.alertStatus
          );

          return (
            <DataTableBadgeCell
              color={AlertStatusColorMap[cellData.data.alertStatus]}>
              {formatters.displayCase(displayStatus)}
            </DataTableBadgeCell>
          );
        },
        field: 'alertStatus',
        headerName: 'Status',
        minWidth: 175,
        sortable: true
      },
      {
        autoHeight: true,
        cellRenderer: (cellData: { data: AlertDto }) => {
          const security = cellData.data.details?.security ?? {};
          return (
            <DataTableStackCell
              primary={
                (security.symbol || '\u2014') +
                ' | ' +
                (security.cusip || '\u2014')
              }
              secondary={security.description || '\u2014'}
            />
          );
        },
        field: 'security',
        headerName: 'Security',
        minWidth: 200,
        sortable: false
      },
      {
        autoHeight: true,
        cellRenderer: (cellData: { data: AlertDto }) => {
          return (
            <DataTableStackCell
              primary={
                formatters.displayCase(
                  cellData.data.details?.trade?.status || ''
                ) || '\u2014'
              }
              secondary={cellData.data.details?.trade?.statusReason || '\u2014'}
            />
          );
        },
        field: 'alertTradingStatus',
        headerName: 'Status',
        minWidth: 175,
        sortable: true
      },
      {
        autoHeight: true,
        cellRenderer: (cellData: { data: AlertDto }) => {
          return (
            <DataTableStackCell
              primary={
                formatters.formatFromIsoDateCustom(
                  cellData.data.details?.trade?.tradeDate,
                  'MM/DD/YYYY'
                ) || '\u2014'
              }
              secondary={
                SecurityOrderType.DOLLAR_ORDER ===
                cellData.data.details?.trade?.tradeOrderType
                  ? formatters.formatDollars(
                      cellData.data.details?.trade?.tradeAmount
                    )
                  : formatters.formatDecimal(
                      cellData.data.details?.trade?.tradeUnits,
                      3
                    ) || '\u2014'
              }
            />
          );
        },
        field: 'tradeDetails',
        headerName: 'Trade Details',
        minWidth: 200,
        sortable: false
      },
      {
        cellRenderer: (cellData: { data: AlertDto }) => {
          if (!cellData.data.assignee) {
            return null;
          }
          const matchedAssignee = getVestwellStaffQuery.data?.find(
            staffUser => staffUser.userId === cellData.data.assignee
          );
          return (
            <DataTableStackCell
              primary={matchedAssignee?.label || cellData.data.assignee}
            />
          );
        },
        field: 'assignee',
        headerName: 'Assignee',
        maxWidth: 160,
        sortable: false
      },
      {
        field: 'priority',
        headerName: 'Priority',
        minWidth: 125,
        sortable: true,
        valueFormatter: ({ value }: { value: string }) =>
          formatters.displayCase(value)
      },
      {
        autoHeight: true,
        cellRenderer: (cellData: { data: AlertDto }) => (
          <Typography
            maxWidth={200}
            sx={{ wordBreak: 'break-word' }}
            variant='body1'>
            {formatters.displayCase(
              cellData.data?.details?.workflowExecution?.failureCause?.message
            )}
          </Typography>
        ),
        field: 'reason',
        maxWidth: 200,
        sortable: true,
        wrapText: true
      },
      {
        field: 'createdAt',
        headerName: 'Created At',
        maxWidth: 200,
        sortable: true,
        valueFormatter: ({ value }: { value: string }) =>
          formatters.formatFromIsoDate(value)
      }
    ];

    const unmappedTypeColumns = [
      'id',
      'alertStatus',
      'assignee',
      'priority',
      'createdAt'
    ];

    const columnTypeMap: Partial<Record<AlertType, string[]>> = {
      [AlertType.Conversion]: [
        'id',
        'planId',
        'custodianAccountNumber',
        'depositInfo',
        'alertStatus',
        'assignee',
        'priority',
        'createdAt'
      ],
      [AlertType.Rollovers]: [
        'id',
        'planId',
        'custodianAccountNumber',
        'depositInfo',
        'alertStatus',
        'assignee',
        'priority',
        'createdAt'
      ],
      [AlertType.Conductor]: [
        'id',
        'flowType',
        'currentStatus',
        'alertStatus',
        'assignee',
        'priority',
        'reason',
        'createdAt'
      ],
      [AlertType.Trading]: [
        'id',
        'planId',
        'custodianAccountNumber',
        'security',
        'alertTradingStatus',
        'tradeDetails',
        'assignee',
        'priority',
        'createdAt'
      ],
      [AlertType.MoneyIn]: [
        'id',
        'planId',
        'custodianAccountNumber',
        'toAccount',
        'depositInfo',
        'alertStatus',
        'assignee',
        'priority',
        'createdAt'
      ]
    };

    return columnDefOptions.filter(colDef => {
      if (!colDef.field) return true;

      if (
        !props.searchParams.alertType ||
        !Array.isArray(columnTypeMap[props.searchParams.alertType])
      ) {
        // if type is not set or if this type has not been mapped then show the default unmapped type columns
        return unmappedTypeColumns.includes(colDef.field);
      }

      return (columnTypeMap[props.searchParams.alertType] as string[]).includes(
        colDef.field
      ); // have to assert because TS doesn't know that we've already checked that this is an array
    });
  }, [props.searchParams, AlertStatusColorMap, getVestwellStaffQuery.data]);

  const FiltersFormFormik = useMemo(
    () => (
      <Formik<AlertsFiltersFormValues>
        enableReinitialize // required to sync Assignee menu with "Assigned to me" switch
        initialValues={{
          ...props.searchParams,
          alertStatus: Array.isArray(props.searchParams.alertStatus)
            ? props.searchParams.alertStatus
            : props.searchParams.alertStatus
              ? [props.searchParams.alertStatus]
              : Object.values(AlertStatus).filter(
                  status => status !== AlertStatus.Closed
                ),
          alertSubType: props.searchParams.alertSubType || '',
          priority: props.searchParams.priority || '',
          query: props.searchParams.query || ''
        }}
        onSubmit={props.onFiltersFormSubmit}>
        <AlertsFiltersForm />
      </Formik>
    ),
    [props.searchParams]
  );

  // consts

  const hasWritePermissions = userService.hasPermission(
    FeatureLevelPermissions.WRITE_SUBA_ALERTS
  );

  return (
    <>
      <AlertDetailsDrawer
        alertId={selectedAlertId}
        hasWritePermissions={hasWritePermissions}
        onClose={() => {
          setIsAlertDetailDrawerOpen(false);
          setSelectedAlertId(undefined);
          if (props.onDrawerClose) props.onDrawerClose();
        }}
        open={isAlertDetailDrawerOpen}>
        <Box px={3}>
          <Stack spacing={2}>
            <UpdateAlertForm
              hasWritePermissions={hasWritePermissions}
              vestwellStaff={getVestwellStaffQuery.data}
            />
            <AlertDetails />
            <AlertDetailsTabs
              disableStateInUrl={true}
              hasWritePermissions={hasWritePermissions}
            />
          </Stack>
        </Box>
      </AlertDetailsDrawer>
      <Card>
        <MyTabs
          actions={
            <FormControlLabel
              control={
                <Switch
                  checked={
                    userService.getUser()?.sub === props.searchParams.assignee
                  }
                  data-testid='assigned-to-me-switch'
                  inputProps={{ 'aria-label': 'assigned to me' }}
                  onChange={handleAssigneeChange}
                  size='small'
                />
              }
              label={
                <Typography sx={{ fontSize: 14 }}>Assigned to me</Typography>
              }
            />
          }
          defaultDisplayTabs={[
            AlertType.MoneyOut,
            AlertType.MoneyIn,
            AlertType.Rollovers,
            AlertType.Conversion
          ]}
          loading={
            (searchAlertWithPaginationQuery.data?.results &&
              searchAlertWithPaginationQuery.isInitialLoading) ||
            searchTypeTotalsQuery.isInitialLoading
          }
          name='alert-type'
          onChange={handleAlertTypeChange}
          tabLabelFormatter={tab =>
            formatters.displayCase(formatters.getValueKey(AlertType, tab))
          }
          tabOptions={Object.values(AlertType)}
          totals={searchTypeTotalsQuery.data}
          value={props.searchParams.alertType}
        />
        <Divider sx={{ borderColor: theme => theme.palette.grey[300] }} />
        <CardContent
          disablePadding
          overlayLoading={getVestwellStaffQuery.isInitialLoading}>
          <DataTable
            columnDefs={columnDefs}
            data-testid='data-alerts-table'
            emptyPlaceholderComponent={
              <Stack
                alignItems='center'
                data-testid='no-data-alerts-table'
                justifyContent='center'
                sx={{ height: '100%' }}>
                <CardPlaceholder
                  icon={<SearchIcon fontSize='inherit' />}
                  subtitle={
                    !searchAlertWithPaginationQuery.data?.results
                      ? 'Search results will be displayed here.'
                      : 'No results found.'
                  }
                />
              </Stack>
            }
            filterSidePanelComponent={FiltersFormFormik}
            gridRef={gridRef}
            onPageChanged={props.onPageChange}
            onPageSizeChanged={props.onPageSizeChange}
            onSortChanged={handleSortChanged}
            page={props.searchParams.page}
            pageSize={props.searchParams.pageSize}
            pagination
            paginationSource='server'
            paginationTotal={
              searchAlertWithPaginationQuery.data?.pagination?.total
            }
            rowData={
              searchAlertWithPaginationQuery.data?.results
                ? searchAlertWithPaginationQuery.data.results
                : []
            }
            sort={
              props.searchParams.orderBy
                ? [
                    {
                      colId: props.searchParams.orderBy,
                      sort: props.searchParams.orderByDirection
                    }
                  ]
                : []
            }
          />
        </CardContent>
      </Card>
    </>
  );
};
