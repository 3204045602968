import { CopyToClipboard } from '@/components/copy-to-clipboard';
import { redirectToErrorPage } from '@/components/error-detail/ErrorDetailPage.component';
import LinearLoading from '@/components/linear-loading';
import NavigationBreadcrumbs from '@/components/navigation-breadcrumbs';
import { useTitle } from '@/hooks/useTitle';
import { FeatureLevelPermissions } from '@/models/UserPermissions.model';
import { AlertDetails } from '@/routes/suba/common/components/alert-details';
import { getExpandedAlertDetailsComponent } from '@/routes/suba/common/components/alert-expanded-details/getExpandedAlertDetailsComponent';
import { AlertDetailsTabs } from '@/routes/suba/common/components/AlertDetailsTabs.component';
import { UpdateAlertForm } from '@/routes/suba/common/components/UpdateAlertForm.component';
import { AlertContext } from '@/routes/suba/common/contexts/AlertContext';
import AuthZService from '@/services/AuthZ.service';
import AlertService from '@/services/suba/alerts/Alert.service';
import { userService } from '@/services/User.service';
import { Box, Stack, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';

import React, { FC, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';

type AlertsDetailPageProps = {
  alertId: string;
};

export const AlertsDetailPage: FC = () => {
  const location = useLocation();
  const [passedStateSearch] = useState(location.state?.search); // stash in state to avoid later state changes from wiping the original passed search
  const { alertId } = useParams<AlertsDetailPageProps>();
  const [errorStatus, setErrorStatus] = React.useState<number | null>(null);

  const { data: alertQueryData } = useQuery(
    ['AlertService.getById', Number(alertId)],
    () => AlertService.getById(Number(alertId)),
    {
      onError: (err: any) => {
        console.error(err.response?.data ? err.response.data : err.message);
        if (err?.response?.status) setErrorStatus(err.response.status);
      },
      onSuccess: () => {
        setErrorStatus(null);
      }
    }
  );

  useTitle(`Alert ${alertQueryData?.id} ${alertQueryData?.alertName}`);

  const getVestwellStaffQuery = useQuery(
    ['AuthZService.getVestwellStaff'],
    async () => {
      const staff = await AuthZService.getVestwellStaff();
      return AuthZService.formatVestwellStaffList(staff);
    },
    {
      onError: (err: any) => {
        console.error(err.response?.data ? err.response.data : err.message);
        if (err?.response?.status) setErrorStatus(err.response.status);
      },
      onSuccess: () => {
        setErrorStatus(null);
      }
    }
  );

  const hasWritePermissions = userService.hasPermission(
    FeatureLevelPermissions.WRITE_SUBA_ALERTS
  );

  if (errorStatus)
    return redirectToErrorPage(new Error(errorStatus.toString()));

  // breadcrumb items
  const paths = [{ name: 'All Alerts', to: '/ops/alerts' }];
  if (passedStateSearch) {
    paths.push({
      name: 'Search Results',
      to: `/ops/alerts${passedStateSearch}`
    });
  }

  const ExpandedAlertDetails = getExpandedAlertDetailsComponent(alertQueryData);

  const showExpandedAlertDetails = hasWritePermissions && ExpandedAlertDetails;

  return (
    <AlertContext.Provider value={alertQueryData}>
      {(alertQueryData === undefined ||
        getVestwellStaffQuery.isInitialLoading) && <LinearLoading />}
      {alertQueryData && !getVestwellStaffQuery.isInitialLoading && (
        <Box display='grid' gap={2} gridTemplateColumns='repeat(11, 1fr)'>
          <Box
            gridColumn={showExpandedAlertDetails ? 'span 5' : 'span 12'}
            gridRow='1'>
            <Box data-testid='alert-detail-header' mb={3}>
              <NavigationBreadcrumbs
                data-testid='alert-detail-breadcrumbs'
                paths={paths}
              />
              <Typography role='heading' variant='h5'>
                {alertQueryData.alertName || 'Alert'}
              </Typography>
              <Box alignItems='center' display='flex' justifyContent='left'>
                <Typography data-testid='detail_id' variant='subtitle1'>
                  ID: {alertQueryData.id}
                </Typography>
                <CopyToClipboard
                  copyName='Alert ID'
                  copyValue={String(alertQueryData.id)}
                />
              </Box>
            </Box>
            <Stack spacing={2} useFlexGap>
              <UpdateAlertForm
                hasWritePermissions={hasWritePermissions}
                vestwellStaff={getVestwellStaffQuery.data}
              />
              <AlertDetails />
              <AlertDetailsTabs hasWritePermissions={hasWritePermissions} />
            </Stack>
          </Box>
          {/* CSS grid is used to achieve shaded sidebar that terminates with longest content in either column */}
          {showExpandedAlertDetails && (
            <Box
              borderRadius={2}
              gridColumn='6 / 12'
              gridRow='1'
              p={2}
              sx={{
                backgroundColor: theme => theme.palette.grey[100]
              }}>
              <ExpandedAlertDetails />
            </Box>
          )}
        </Box>
      )}
    </AlertContext.Provider>
  );
};
