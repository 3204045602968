import { CardContent, CardPlaceholder } from '@/components/card';
import {
  DataTable,
  DataTableProps
} from '@/components/data-table/DataTable.component';
import { useSearchPendingTransactionsQuery } from '@/hooks/suba/useSearchPendingTransactionsQuery.hook';
import { PaginatedApiResponse } from '@/models/PaginatedApiResponse.model';
import { OrderByDirection } from '@/models/suba/common/OrderByDirection.model';
import { PendingTransactionSearchRequest } from '@/models/suba/transactions/PendingTransactionSearchRequest.model';
import { TransactionDto } from '@/models/suba/transactions/TransactionDTO.model';
import { TransactionSortKey } from '@/models/suba/transactions/TransactionSortKey.enum';
import { TransactionStatus } from '@/models/suba/transactions/TransactionStatus.model';
import SearchIcon from '@mui/icons-material/Search';
import { Stack } from '@mui/material';
import { UseQueryResult } from '@tanstack/react-query';
import { useToggle } from '@vestwell-frontend/hooks';
import { AccountLevel } from '@vestwell-sub-accounting/models/accountsAndLedgers/AccountLevel';
import { PositionDateType } from '@vestwell-sub-accounting/models/accountsAndLedgers/PositionDateType';
import { PendingTransactionStatus } from '@vestwell-sub-accounting/models/common/PendingTransactionStatus';

import { ColDef } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import dayjs from 'dayjs';
import { Formik, FormikConfig } from 'formik';
import {
  FC,
  MutableRefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';

import { TransactionsProps } from '../models/TransactionsProps.model';
import { exportPendingTransactions } from '../utils/exportTransactions';
import { getColDefs } from '../utils/getColDefs';
import { CancelTransactionDialog } from './dialogs/CancelTransactionDialog.component';
import { ConfirmTransactionDialog } from './dialogs/ConfirmTransactionDialog.component';
import { TransactionDetailCellRenderer } from './TransactionDetailCellRenderer.component';
import {
  TransactionsFiltersForm,
  TransactionsFiltersFormValues
} from './TransactionsFiltersForm.component';

export type PendingTransactionsProps = TransactionsProps & {
  exportTransactionsRef: MutableRefObject<() => Promise<void>>;
  onFiltersFormSubmit: (values: TransactionsFiltersFormValues) => void;
  onPageChange: (page: number) => void;
  onPageSizeChange: (page: number) => void;
  onSortChange: (
    newSort: Pick<
      PendingTransactionSearchRequest,
      'orderBy' | 'orderByDirection'
    >
  ) => void;
  queryRef: MutableRefObject<UseQueryResult<PaginatedApiResponse<unknown>>>;
  searchParams: PendingTransactionSearchRequest;
  toggleIsQueryFetching: ReturnType<typeof useToggle>[1];
};

export const PendingTransactions: FC<PendingTransactionsProps> = props => {
  // refs

  const gridRef = useRef<AgGridReact>(null);

  // state

  const [openCancelDialog, toggleOpenCancelDialog] = useToggle(false);
  const [openConfirmationDialog, toggleOpenConfirmationDialog] =
    useToggle(false);
  const [selectedTransactions, setSelectedTransactions] = useState<
    TransactionDto[]
  >([]);

  // effects

  useEffect(() => {
    if (!props.exportTransactionsRef) return;

    const commonParams = {
      accountId: props.accountId,
      gridRef,
      searchParams: props.searchParams
    };

    props.exportTransactionsRef.current = () =>
      exportPendingTransactions(
        'planId' in props
          ? {
              ...commonParams,
              accountLevel: AccountLevel.SubAccount,
              planId: props.planId
            }
          : {
              ...commonParams,
              accountId: props.accountId,
              accountLevel: props.accountLevel
            }
      );
  }, [props.accountId, props.accountLevel, props.planId, props.searchParams]);

  // api

  const pendingTransactionsQuery = useSearchPendingTransactionsQuery(
    {
      ...props.searchParams,
      accountLevel: props.accountLevel,
      ...(props.planId
        ? {
            accountLevel: AccountLevel.SubAccount,
            planId: props.planId
          }
        : {
            accountId: props.accountId,
            accountLevel: props.accountLevel
          })
    },
    {
      // avoid abrupt transitions when changing filters that result in a new query
      keepPreviousData: true
    }
  );

  // callbacks

  const handlePageChanged = useCallback<DataTableProps['onPageChanged']>(
    page => props.onPageChange(page),
    []
  );

  const handlePageSizeChanged = useCallback<
    DataTableProps['onPageSizeChanged']
  >(pageSize => {
    props.onPageChange(1);
    props.onPageSizeChange(pageSize);
  }, []);

  const handleSortChanged = useCallback<DataTableProps['onSortChanged']>(
    newSortArray => {
      props.onSortChange(
        !newSortArray || newSortArray.length === 0
          ? {
              orderBy: undefined,
              orderByDirection: undefined
            }
          : {
              orderBy: newSortArray[0].colId as TransactionSortKey,
              orderByDirection: newSortArray[0].sort as OrderByDirection
            }
      );
    },
    []
  );

  const handleSubmit = useCallback<
    FormikConfig<TransactionsFiltersFormValues>['onSubmit']
  >(values => {
    props.onFiltersFormSubmit(values);
  }, []);

  // memos

  const columnDefs = useMemo<ColDef[]>(
    () =>
      getColDefs({
        accountLevel: props.accountLevel,
        hideActions: props.hideActions,
        hideColumns: props.hideColumns,
        hideDetailCell: props.hideDetailCell,
        setSelectedTransactions,
        toggleOpenCancelDialog,
        toggleOpenConfirmationDialog,
        transactionStatus: TransactionStatus.Pending
      }),
    [
      props.accountLevel,
      props.hideActions,
      props.hideColumns,
      props.hideDetailCell
    ]
  );

  const DetailCellRenderer = useMemo(() => TransactionDetailCellRenderer, []);

  const FiltersFormFormik = useMemo(
    () => (
      <Formik<TransactionsFiltersFormValues>
        enableReinitialize
        initialValues={{
          amountFrom: props.searchParams.amountFrom || '',
          amountTo: props.searchParams.amountTo || '',
          beginningDate: props.searchParams.beginningDate || '',
          cusip: props.searchParams.cusip || '',
          dateType: props.searchParams.dateType || PositionDateType.Trade,
          endingDate:
            props.searchParams.endingDate || dayjs().format('YYYY-MM-DD'),
          sourceTransactionId: props.searchParams.sourceTransactionId || '',
          statuses: props.searchParams.statuses || [
            PendingTransactionStatus.Pending
          ],
          tracerId: props.searchParams.tracerId || '',
          transactionBaseTypes: props.searchParams.transactionBaseTypes || [],
          transactionTypeCode: props.searchParams.transactionTypeCode || '',
          transferSubAccountId: props.searchParams.transferSubAccountId || '',
          unitsFrom: props.searchParams.unitsFrom || '',
          unitsTo: props.searchParams.unitsTo || ''
        }}
        onSubmit={handleSubmit}>
        <TransactionsFiltersForm
          accountLevel={props.accountLevel}
          transactionStatus={TransactionStatus.Pending}
        />
      </Formik>
    ),
    [props.searchParams]
  );

  // effects

  useEffect(() => {
    props.queryRef.current = pendingTransactionsQuery;
  }, [pendingTransactionsQuery]);

  useEffect(() => {
    props.toggleIsQueryFetching(pendingTransactionsQuery.isFetching);
  }, [pendingTransactionsQuery.isFetching]);

  return (
    <>
      <CancelTransactionDialog
        accountId={props.accountId}
        accountLevel={
          // ensure account summary is aligned with current page to avoid redundancy with table of selected transactions
          (props.hideAccountLevelToggle && props.accountLevel) ||
          AccountLevel.ParentAccount
        }
        onClose={() => {
          toggleOpenCancelDialog(false);
          setSelectedTransactions([]);
        }}
        open={openCancelDialog}
        transactions={selectedTransactions}
      />
      <ConfirmTransactionDialog
        accountId={props.accountId}
        accountLevel={
          // ensure account summary is aligned with current page to avoid redundancy with table of selected transactions
          (props.hideAccountLevelToggle && props.accountLevel) ||
          AccountLevel.ParentAccount
        }
        onClose={() => {
          toggleOpenConfirmationDialog(false);
          setSelectedTransactions([]);
        }}
        open={openConfirmationDialog}
        transaction={selectedTransactions[0]}
      />
      <CardContent
        data-testid='pending-transactions-card-content'
        disablePadding
        overlayLoading={pendingTransactionsQuery.isInitialLoading}>
        <DataTable
          columnDefs={columnDefs}
          data-testid='pending-transactions-data-table'
          detailCellRenderer={!props.hideDetailCell && DetailCellRenderer}
          emptyPlaceholderComponent={
            <Stack
              alignItems='center'
              data-testid='pending-transactions-data-table-placeholder'
              justifyContent='center'
              sx={{ height: '100%' }}>
              <CardPlaceholder
                icon={<SearchIcon fontSize='inherit' />}
                subtitle={
                  !pendingTransactionsQuery.data
                    ? 'Search results will be displayed here.'
                    : 'No results found.'
                }
              />
            </Stack>
          }
          filterSidePanelComponent={!props.hideFilters && FiltersFormFormik}
          gridRef={gridRef}
          onPageChanged={handlePageChanged}
          onPageSizeChanged={handlePageSizeChanged}
          onSortChanged={handleSortChanged}
          page={props.searchParams.page}
          pageSize={props.searchParams.pageSize}
          pagination
          paginationSource='server'
          paginationTotal={pendingTransactionsQuery.data?.pagination?.total}
          rowData={pendingTransactionsQuery.data?.results || []}
          sort={
            props.searchParams?.orderBy
              ? [
                  {
                    colId: props.searchParams.orderBy,
                    sort: props.searchParams.orderByDirection
                  }
                ]
              : []
          }
        />
      </CardContent>
    </>
  );
};
