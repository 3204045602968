import { CardPlaceholder } from '@/components/card';
import CircularLoading from '@/components/circular-loading';
import CollapsibleTable, {
  CellComponentProps,
  Order
} from '@/components/collapsible-table';
import { FormattedPendingTransaction } from '@/models/PendingTransactionsDTO';
import ParticipantService from '@/services/Participant.service';
import formatters from '@/utils/Formatters';
import { Box, Paper, Stack, TableCell, Theme, Typography } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { useQuery } from '@tanstack/react-query';

import dayjs from 'dayjs';
import { orderBy as orderData } from 'lodash';
import { FC, useMemo, useState } from 'react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    amountCellWidth: {
      width: '15%'
    },
    body: {
      ...theme.typography.caption,
      color: theme.palette.grey[700],
      fontSize: theme.spacing(2),
      height: theme.spacing(7.8),
      padding: '0',
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2)
    },
    emptyCell: {
      width: theme.spacing(7.7)
    },
    headerTableTitle: {
      padding: theme.spacing(2)
    },
    inititatedDateCellWidth: {
      width: '22%'
    },
    paper: {
      marginBottom: theme.spacing(2),
      width: '100%'
    },
    transactionTypeCellWidth: {
      width: '32%'
    }
  })
);

const PendingTransactionsTableCell: FC<CellComponentProps> = props => {
  const classes = useStyles();

  return (
    <TableCell className={classes.body} component='th' scope='row'>
      <Box>
        {props.column.field === 'amount'
          ? formatters.formatDollars(props.row[props.column.field])
          : props.column.field === 'requestedDate'
            ? dayjs(props.row[props.column.field]).format('MM/DD/YYYY')
            : props.row[props.column.field]}
      </Box>
    </TableCell>
  );
};

type PendingTransactionsProps = {
  participantId: string;
};

const PendingTransactions: FC<PendingTransactionsProps> = props => {
  const classes = useStyles();
  const [order, setOrder] = useState<Order>('desc');
  const [orderBy, setOrderBy] = useState('requestedDate');

  const headerColumns = useMemo(
    () => [
      {
        cellClassName: classes.emptyCell,
        field: 'emptyCell',
        headerName: '',
        sortable: false
      },
      {
        cellClassName: classes.inititatedDateCellWidth,
        field: 'requestedDate',
        headerName: 'Initiated Date'
      },
      {
        cellClassName: classes.transactionTypeCellWidth,
        field: 'transactionType',
        headerName: 'Transaction Type'
      },
      {
        cellClassName: classes.amountCellWidth,
        field: 'amount',
        headerName: 'Amount'
      },
      { field: 'mappedStatus', headerName: 'Status' }
    ],
    [classes]
  );

  const pendingTransactionsQuery = useQuery<FormattedPendingTransaction[]>(
    ['ParticipantService.getPendingTransactions', props.participantId],
    () => {
      return ParticipantService.getPendingTransactions(props.participantId);
    },
    {
      enabled: Boolean(props.participantId),
      select: pendingTransactions =>
        pendingTransactions.map(pendingTransaction => ({
          ...pendingTransaction,
          mappedStatus: formatters.formatPendingTransactionStatus(
            pendingTransaction.status,
            pendingTransaction
          )
        })),
      staleTime: Infinity
    }
  );

  const sortedData = useMemo(
    () => orderData(pendingTransactionsQuery.data || [], orderBy, order),
    [pendingTransactionsQuery.data, order, orderBy]
  );

  return (
    <Paper className={classes.paper} elevation={0} variant='outlined'>
      <Typography
        className={classes.headerTableTitle}
        component='div'
        id='tableTitle'
        variant='h5'>
        Pending Transactions
      </Typography>
      {pendingTransactionsQuery.isFetching ? (
        <Stack alignItems='center'>
          <CircularLoading />
        </Stack>
      ) : (
        <CollapsibleTable
          cellComponent={PendingTransactionsTableCell}
          columns={headerColumns}
          disablePagination
          noDataPlaceholderComponent={
            <CardPlaceholder
              data-testid='no-data-pending-transactions-participant'
              subtitle={`${
                pendingTransactionsQuery.isError
                  ? 'Error'
                  : 'No data for this participant'
              }`}
            />
          }
          sorter={{
            onSortOrderChanged: (newOrderBy: string, newOrder: Order) => {
              setOrderBy(newOrderBy);
              setOrder(newOrder);
            },
            order,
            orderBy
          }}
          tableData={sortedData}
          useDivAsBackground
        />
      )}
    </Paper>
  );
};

export default PendingTransactions;
