import DatePickerForm from '@/components/date-picker/DatePickerForm';
import { NumberFormatForm } from '@/components/number-format-form/NumberFormatForm.component';
import TextStack, {
  TextLabel,
  TextStackItem,
  TextValue
} from '@/components/text-stack';
import { EMPTY_FIELD_PLACEHOLDER } from '@/consts/formatting';
import { useSnackbar } from '@/contexts/SnackBarContext';
import { PlanPayrollSetupInfo } from '@/models';
import {
  LoanDetailsResponseDto,
  LoanExpectedPayment,
  LoanExpectedPaymentAttributes,
  LoanReamortizeRequest,
  PAY_SCHEDULE_DETAILED
} from '@/models/LoanDTO.model';
import ParticipantService from '@/services/Participant.service';
import { PlanService } from '@/services/Plan.service';
import formatters from '@/utils/Formatters';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { LoadingButton } from '@mui/lab';
import {
  Alert,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  styled,
  Typography
} from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';
import { useMutation, useQuery } from '@tanstack/react-query';

import dayjs from 'dayjs';
import { Field, Form, Formik } from 'formik';
import { FC, useCallback, useState } from 'react';
import { useToggle } from 'react-use';
import * as yup from 'yup';

const validationSchema = yup.object({
  firstPaymentDate: yup.string().required('First Payment Date is required'),
  paymentAmount: yup
    .number()
    .required('Payment Amount is required')
    .typeError('must be only digits')
    .moreThan(0, 'must be > 0'),
  payrollId: yup.string().required('Pay Frequency is required')
});

type LoanReamortizationDialogProps = {
  onClose: () => void;
  onSubmit: () => void;
  originalLoan: LoanDetailsResponseDto;
  originalPayments: LoanExpectedPayment[];
  open: boolean;
  sponsorPlanId: number;
};

type LoanReamortizationFormValues = {
  firstPaymentDate: string;
  interestRate: number;
  outstandingBalance: number;
  paymentAmount: number;
  payrollFrequency?: string;
  payrollId: string;
  waiveOverdueInterest: boolean;
};

const DialogActionsContainer = styled(Stack, {
  shouldForwardProp: () => true
})({
  width: '100%'
});

export const LoanReamortizationDialog: FC<
  LoanReamortizationDialogProps
> = props => {
  const snackbar = useSnackbar();
  const [formValues, setFormValues] = useState<LoanReamortizationFormValues>();
  const [previewError, setPreviewError] = useState<string>('');
  const [showPreview, togglePreview] = useToggle(false);
  const payrollSetupsQuery = useQuery<PlanPayrollSetupInfo[]>(
    ['PlanService.getPlanPayrollSetups', props.sponsorPlanId],
    () => {
      return PlanService.getPlanPayrollSetups(props.sponsorPlanId);
    },
    {
      enabled: !!props.sponsorPlanId
    }
  );

  const previewPaymentsQuery = useQuery<LoanExpectedPaymentAttributes[]>(
    [
      'ParticipantService.getReamortizationLoanPreviewPayments',
      props.originalLoan?.data.id,
      formValues
    ],
    () => {
      return ParticipantService.getReamortizationLoanPreviewPayments(
        props.originalLoan?.data.id,
        formValues
      );
    },
    {
      enabled: !!props.sponsorPlanId && !!formValues?.paymentAmount,
      onError: (err: any) => {
        setPreviewError(
          err.response?.data?.details ? err.response.data.details : err.message
        );
      }
    }
  );

  const loanReamortizationMutation = useMutation(
    ['ParticipantService.postReamortizeLoan', props.originalLoan?.data.id],
    (attr: LoanReamortizeRequest) =>
      ParticipantService.postReamortizeLoan(props.originalLoan?.data.id, attr),
    {
      onError: () => {
        snackbar.showSnackbar({
          message: 'Error submitting request',
          severity: 'error'
        });
      },
      onSuccess: () => {
        snackbar.showSnackbar({
          message: 'Your request has been submitted',
          severity: 'success'
        });
        props.onSubmit();
      }
    }
  );

  const initialValues: LoanReamortizationFormValues = {
    firstPaymentDate: dayjs(new Date()).format('MM/DD/YYYY'),
    interestRate: props.originalLoan.data.attributes.interestRate,
    outstandingBalance:
      props.originalLoan.data.attributes.outstandingPrincipalDue +
      props.originalLoan.data.attributes.overdueInterest,
    paymentAmount: props.originalPayments?.[0]?.attributes?.total,
    payrollFrequency: '',
    payrollId: '',
    waiveOverdueInterest: false
  };

  const onCancel = useCallback(
    formik => {
      formik.resetForm();
      togglePreview(false);
      props.onClose();
    },
    [props.onClose]
  );

  const onSubmit = (values: LoanReamortizationFormValues, formik) => {
    loanReamortizationMutation.mutateAsync(values);
    onCancel(formik);
  };

  const payFrequencyChange = (formik, value: number) => {
    formik.setFieldValue('payrollId', value);
    formik.setFieldValue(
      'payrollFrequency',
      PAY_SCHEDULE_DETAILED[
        payrollSetupsQuery.data?.find(payroll => payroll.id === value).frequency
      ]
    );
  };

  return (
    <Dialog fullWidth maxWidth='sm' onClose={props.onClose} open={props.open}>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validateOnMount
        validationSchema={validationSchema}>
        {formik => {
          return (
            <>
              <DialogTitle>Reamortize Loan</DialogTitle>
              <DialogContent dividers>
                <Form>
                  {showPreview ? (
                    <>
                      <Typography mb={4} variant='body2'>
                        Review Changes
                      </Typography>
                      <Grid2 container mb={1}>
                        <Grid2 xs={5}>
                          <Typography variant='subtitle1'>
                            Outstanding Balance
                          </Typography>
                        </Grid2>
                        <Grid2 xs={3}>
                          <Typography variant='subtitle1'>
                            {formatters.formatDollars(
                              initialValues.outstandingBalance
                            )}
                          </Typography>
                        </Grid2>
                        {initialValues.outstandingBalance !==
                          formik.values.outstandingBalance && (
                          <>
                            <Grid2 xs={1}>
                              <ArrowForwardIcon />
                            </Grid2>
                            <Grid2 xs={3}>
                              <Typography variant='subtitle1'>
                                {formatters.formatDollars(
                                  formik.values.outstandingBalance
                                )}
                              </Typography>
                            </Grid2>
                          </>
                        )}
                      </Grid2>
                      <Grid2 container mb={1}>
                        <Grid2 xs={5}>
                          <Typography variant='subtitle1'>
                            Interest Rate
                          </Typography>
                        </Grid2>
                        <Grid2 xs={3}>
                          <Typography variant='subtitle1'>
                            {formik.values.interestRate}%
                          </Typography>
                        </Grid2>
                      </Grid2>
                      <Grid2 container mb={1}>
                        <Grid2 xs={5}>
                          <Typography variant='subtitle1'>
                            Payment Amount (per period)
                          </Typography>
                        </Grid2>
                        <Grid2 xs={3}>
                          <Typography variant='subtitle1'>
                            {formatters.formatDollars(
                              initialValues.paymentAmount
                            )}
                          </Typography>
                        </Grid2>
                        {initialValues.paymentAmount !==
                          formik.values.paymentAmount && (
                          <>
                            <Grid2 xs={1}>
                              <ArrowForwardIcon />
                            </Grid2>
                            <Grid2 xs={3}>
                              <Typography variant='subtitle1'>
                                {formatters.formatDollars(
                                  formik.values.paymentAmount
                                )}
                              </Typography>
                            </Grid2>
                          </>
                        )}
                      </Grid2>
                      <Grid2 container mb={1}>
                        <Grid2 xs={5}>
                          <Typography variant='subtitle1'>Frequency</Typography>
                        </Grid2>
                        <Grid2 xs={3}>
                          <Typography variant='subtitle1'>
                            {formik.values.payrollFrequency}
                          </Typography>
                        </Grid2>
                      </Grid2>
                      <Grid2 container mb={1}>
                        <Grid2 xs={5}>
                          <Typography variant='subtitle1'>
                            First Pay Date
                          </Typography>
                        </Grid2>
                        <Grid2 xs={3}>
                          <Typography variant='subtitle1'>
                            {formik.values.firstPaymentDate}
                          </Typography>
                        </Grid2>
                      </Grid2>
                      <Grid2 container mb={1}>
                        <Grid2 xs={5}>
                          <Typography variant='subtitle1'>
                            Installments
                          </Typography>
                        </Grid2>
                        <Grid2 xs={3}>
                          <Typography variant='subtitle1'>
                            {props.originalPayments?.length}
                          </Typography>
                        </Grid2>
                        {props.originalPayments?.length !==
                          previewPaymentsQuery.data?.length && (
                          <>
                            <Grid2 xs={1}>
                              <ArrowForwardIcon />
                            </Grid2>
                            <Grid2 xs={3}>
                              <Typography variant='subtitle1'>
                                {previewPaymentsQuery.data?.length}
                              </Typography>
                            </Grid2>
                          </>
                        )}
                      </Grid2>
                      {previewPaymentsQuery.error && (
                        <Grid2 container mb={1}>
                          <Alert severity='error'>{previewError}</Alert>
                        </Grid2>
                      )}
                    </>
                  ) : (
                    <>
                      <Typography mb={4} variant='body2'>
                        Update loan conditions below.
                      </Typography>
                      <TextStack direction='column'>
                        <TextStackItem>
                          <TextLabel>Outstanding Balance</TextLabel>
                          <TextValue>
                            <Stack
                              alignItems='center'
                              direction='row'
                              spacing={2}>
                              {formatters.formatDollars(
                                formik.values.outstandingBalance
                              )}
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={formik.values.waiveOverdueInterest}
                                    name='waiveOverdueInterest'
                                    onChange={(_, checked) => {
                                      formik.setFieldValue(
                                        'waiveOverdueInterest',
                                        checked
                                      );
                                      formik.setFieldValue(
                                        'outstandingBalance',
                                        checked
                                          ? props.originalLoan.data.attributes
                                              .outstandingPrincipalDue
                                          : initialValues.outstandingBalance
                                      );
                                    }}
                                    size='small'
                                    value={formik.values.waiveOverdueInterest}
                                  />
                                }
                                label='Waive Overdue Interest'
                              />
                            </Stack>
                          </TextValue>
                        </TextStackItem>
                        <TextStackItem>
                          <TextLabel>Interest Rate</TextLabel>
                          <TextValue>{formik.values.interestRate}%</TextValue>
                        </TextStackItem>
                        <TextStackItem>
                          <TextLabel>Payment Amount</TextLabel>
                          <TextValue>
                            <NumberFormatForm
                              name='paymentAmount'
                              numericFormatProps={{ prefix: '$' }}
                              size='small'
                            />
                          </TextValue>
                        </TextStackItem>
                        <TextStackItem>
                          <TextLabel>Pay Frequency</TextLabel>
                          <TextValue>
                            <FormControl>
                              <Field
                                as={Select}
                                displayEmpty
                                error={
                                  formik.touched.payrollId &&
                                  !!formik.errors.payrollId
                                }
                                input={<OutlinedInput />}
                                name='payrollId'
                                onChange={event =>
                                  payFrequencyChange(formik, event.target.value)
                                }
                                size='small'>
                                {payrollSetupsQuery.data?.map(payrollSetup => (
                                  <MenuItem
                                    key={payrollSetup.id}
                                    value={payrollSetup.id}>
                                    <Stack>
                                      <Typography variant='body1'>
                                        {
                                          PAY_SCHEDULE_DETAILED[
                                            payrollSetup.frequency
                                          ]
                                        }
                                      </Typography>
                                      <Stack
                                        direction='row'
                                        divider={
                                          <Divider
                                            flexItem
                                            orientation='vertical'
                                          />
                                        }
                                        spacing={1}>
                                        <Typography variant='caption'>
                                          Pay Group ID: {payrollSetup.id}
                                        </Typography>
                                        <Typography variant='caption'>
                                          {payrollSetup.payGroupName ??
                                            EMPTY_FIELD_PLACEHOLDER}
                                        </Typography>
                                      </Stack>
                                    </Stack>
                                  </MenuItem>
                                ))}
                              </Field>
                              <FormHelperText error>
                                {formik.touched.payrollId &&
                                  formik.errors.payrollId}
                              </FormHelperText>
                            </FormControl>
                          </TextValue>
                        </TextStackItem>
                        <TextStackItem>
                          <TextLabel>First Pay Date</TextLabel>
                          <TextValue detail='Nearest scheduled pay date on or after the date you have chosen will be the first date of the loan'>
                            <DatePickerForm
                              data-testid='first-pay-date-picker'
                              disablePast
                              format='MM/DD/YYYY'
                              fullWidth
                              inputProps={{
                                autoComplete: 'off'
                              }}
                              name='firstPaymentDate'
                              size='small'
                              value={formik.values.firstPaymentDate}
                              variant='outlined'
                            />
                          </TextValue>
                        </TextStackItem>
                      </TextStack>
                    </>
                  )}
                </Form>
              </DialogContent>
              <DialogActions>
                <DialogActionsContainer
                  direction='row'
                  justifyContent='space-between'>
                  <Button onClick={() => onCancel(formik)}>Cancel</Button>
                  <Stack direction='row'>
                    {!showPreview && (
                      <>
                        <Button onClick={formik.handleReset}>Reset</Button>
                        <Button
                          disabled={!formik.isValid}
                          onClick={() => {
                            setFormValues(formik.values);
                            togglePreview();
                          }}>
                          Preview
                        </Button>
                      </>
                    )}
                    {showPreview && (
                      <>
                        <Button onClick={togglePreview}>Back to Edit</Button>
                        <LoadingButton
                          disabled={
                            previewPaymentsQuery.isLoading ||
                            loanReamortizationMutation.isLoading ||
                            previewPaymentsQuery.isError
                          }
                          loading={
                            previewPaymentsQuery.isLoading ||
                            loanReamortizationMutation.isLoading
                          }
                          onClick={formik.submitForm}>
                          Confirm
                        </LoadingButton>
                      </>
                    )}
                  </Stack>
                </DialogActionsContainer>
              </DialogActions>
            </>
          );
        }}
      </Formik>
    </Dialog>
  );
};

LoanReamortizationDialog.displayName = 'LoanReamortizationDialog';
