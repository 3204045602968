import Card, {
  CardContent,
  CardHeader,
  CardPlaceholder
} from '@/components/card/Card.component';
import DataTable, {
  DataTableProps
} from '@/components/data-table/DataTable.component';
import DatePicker from '@/components/date-picker';
import { useGetSecurityQuery } from '@/hooks/suba/useGetSecurity.hook';
import { useSearchPricesQuery } from '@/hooks/suba/useSearchPrices.hook';
import { useUrlState } from '@/hooks/useUrlState.hook';
import { useSecuritiesDetailRouteParams } from '@/routes/suba/securities/securities-detail/hooks/useSecurityDetailRouteParams.hook';
import formatters from '@/utils/Formatters';
import { Search as SearchIcon } from '@mui/icons-material';
import { Button, FormControl, Stack } from '@mui/material';
import { DataProvider } from '@vestwell-sub-accounting/models/securitiesAndPricing/DataProvider';

import { ColDef } from 'ag-grid-community';
import dayjs from 'dayjs';
import { Field, Form, Formik } from 'formik';
import { FC, useCallback, useMemo } from 'react';

const displayDataProvider = {
  [DataProvider.bloomberg]: 'Bloomberg',
  [DataProvider.matrix]: 'Matrix',
  [DataProvider.morningstar]: 'Morningstar',
  [DataProvider.nasd]: 'NASD',
  [DataProvider.nscc]: 'NSCC',
  [DataProvider.xignite]: 'Xignite'
};

export type PricesTabUrlState = Pick<
  SearchParams,
  'page' | 'pageSize' | keyof FiltersFormValues
>;

type FiltersFormValues = {
  effectiveEndDate: string;
  effectiveStartDate: string;
};

type SearchParams = Parameters<typeof useSearchPricesQuery>[0];

export const PricesTab: FC = () => {
  // context

  const routeParams = useSecuritiesDetailRouteParams();

  // state

  const [urlState, setUrlState] = useUrlState<PricesTabUrlState>(
    {
      effectiveEndDate: dayjs().format('YYYY-MM-DD'),
      effectiveStartDate: dayjs().subtract(30, 'days').format('YYYY-MM-DD'),
      page: 1,
      pageSize: 50
    },
    {
      parsedValueTypes: {
        effectiveEndDate: 'string',
        effectiveStartDate: 'string',
        page: 'number',
        pageSize: 'number'
      }
    }
  );

  // api

  const getSecurityQuery = useGetSecurityQuery(routeParams.cusipOrSymbol);

  const searchPricesQuery = useSearchPricesQuery(
    {
      ...urlState,
      cusip: getSecurityQuery.data.cusip
    },
    {
      enabled: !!getSecurityQuery.data.cusip
    }
  );

  // callbacks

  const handleFiltersFormSubmit = useCallback((values: FiltersFormValues) => {
    setUrlState(prevUrlState => ({
      ...prevUrlState,
      ...values,
      effectiveEndDate: values.effectiveEndDate || null,
      effectiveStartDate: values.effectiveStartDate || null
    }));
  }, []);

  const handlePageChange = useCallback<DataTableProps['onPageChanged']>(
    newPage => {
      setUrlState(prevUrlState => ({
        ...prevUrlState,
        page: newPage
      }));
    },
    []
  );

  const handlePageSizeChange = useCallback<DataTableProps['onPageChanged']>(
    newPageSize => {
      setUrlState(prevUrlState => ({
        ...prevUrlState,
        page: 1,
        pageSize: newPageSize
      }));
    },
    []
  );

  // memos

  const columnDefs = useMemo<ColDef[]>(
    () => [
      {
        field: 'effectiveDate',
        valueFormatter: ({ value }) =>
          formatters.formatFromIsoDateCustom(value, 'MM/DD/YY')
      },
      {
        field: 'closePrice',
        headerName: 'Price',
        type: 'numericColumn',
        valueFormatter: ({ value }) => formatters.formatDollars(value, 2)
      },
      {
        field: 'dataProvider',
        headerName: 'Source',
        valueFormatter: ({ value }) =>
          displayDataProvider[value as keyof typeof displayDataProvider] ||
          value
      }
    ],
    []
  );

  const FiltersFormFormik = useMemo(
    () => (
      <Formik<FiltersFormValues>
        initialValues={{
          effectiveEndDate: urlState.effectiveEndDate || '',
          effectiveStartDate: urlState.effectiveStartDate || ''
        }}
        onSubmit={handleFiltersFormSubmit}>
        <Form data-testid='prices-search-form'>
          <Stack spacing={2}>
            <FormControl fullWidth size='small' variant='outlined'>
              <Field
                as={DatePicker}
                autoComplete='off'
                data-testid='effective-start-date-input'
                disableFuture
                label='Effective Date Start'
                name='effectiveStartDate'
              />
            </FormControl>
            <FormControl fullWidth size='small' variant='outlined'>
              <Field
                as={DatePicker}
                autoComplete='off'
                data-testid='effective-end-date-input'
                disableFuture
                label='Effective Date End'
                name='effectiveEndDate'
              />
            </FormControl>
            <Button data-testid='apply-button' type='submit' variant='outlined'>
              Apply
            </Button>
          </Stack>
        </Form>
      </Formik>
    ),
    []
  );

  return (
    <Card data-testid='prices-card'>
      <CardHeader
        loading={
          searchPricesQuery.data?.results && searchPricesQuery.isFetching
        }
        title='Prices'
      />
      <CardContent
        disablePadding
        overlayLoading={searchPricesQuery.isInitialLoading}>
        <DataTable
          columnDefs={columnDefs}
          emptyPlaceholderComponent={
            <Stack
              alignItems='center'
              data-testid='no-prices-message'
              justifyContent='center'
              sx={{ height: '100%' }}>
              <CardPlaceholder
                icon={<SearchIcon fontSize='inherit' />}
                subtitle='No prices found'
              />
            </Stack>
          }
          filterSidePanelComponent={FiltersFormFormik}
          onPageChanged={handlePageChange}
          onPageSizeChanged={handlePageSizeChange}
          page={urlState.page}
          pageSize={urlState.pageSize}
          pagination
          paginationSource='server'
          paginationTotal={searchPricesQuery.data?.pagination?.total}
          rowData={searchPricesQuery.data?.results}
        />
      </CardContent>
    </Card>
  );
};
