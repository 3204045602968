import { useSnackbar } from '@/contexts/SnackBarContext';
import { useUserToken } from '@/contexts/UserTokenContext';
import { ConfirmedTransactionSearchRequest } from '@/models/suba/transactions/ConfirmedTransactionSearchRequest.model';
import { TransactionService } from '@/services/suba/transactions/Transaction.service';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';

import { AxiosError } from 'axios';

export const useSearchConfirmedTransactionsQuery = (
  params: ConfirmedTransactionSearchRequest,
  options?: UseQueryOptions<
    Awaited<ReturnType<typeof TransactionService.searchConfirmed>>,
    AxiosError
  >
) => {
  const { showSnackbar } = useSnackbar();
  const { userHasValidToken } = useUserToken();

  return useQuery(
    ['TransactionService.searchConfirmed', ...Object.values(params)],
    () => TransactionService.searchConfirmed(params),
    {
      ...(options || {}),
      enabled: (options?.enabled && userHasValidToken) ?? userHasValidToken,
      keepPreviousData: options?.keepPreviousData ?? true,
      onError:
        options?.onError ??
        ((err: AxiosError) => {
          showSnackbar({
            message: `Failed to search confirmed transactions: ${err.response?.data || err.message}`,
            severity: 'error'
          });
        })
    }
  );
};
