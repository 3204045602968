import Card, {
  CardContent,
  CardHeader,
  CardPlaceholder
} from '@/components/card';
import DataTable, {
  DataTableBadgeCell
} from '@/components/data-table/DataTable.component';
import { useSnackbar } from '@/contexts/SnackBarContext';
import { OrderByDirection } from '@/models/suba/common/OrderByDirection.model';
import { PendingRequestDto } from '@/models/suba/pending-requests/PendingRequestDTO.model';
import { PendingRequestSearchRequest } from '@/models/suba/pending-requests/PendingRequestSearchRequest.model';
import PendingRequestService from '@/services/suba/pending-requests/PendingRequest.service';
import formatters from '@/utils/Formatters';
import SearchIcon from '@mui/icons-material/Search';
import { Button, FormControl, OutlinedInput, Stack } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { CustodianId } from '@vestwell-sub-accounting/models/common/CustodianId';
import { Actions } from '@vestwell-sub-accounting/models/integrationsGateway/Actions';
import { PendingRequestStatuses } from '@vestwell-sub-accounting/models/integrationsGateway/PendingRequestStatuses';

import { ColDef } from 'ag-grid-community';
import { Field, Form, Formik } from 'formik';
import { FC, useMemo, useState } from 'react';

import { CustodianRequestsDetailCellRenderer } from './CustodianRequestsDetailCellRenderer.component';

type CustodianRequestsTabProps = {
  hideFilters?: boolean;
  hideHeader?: boolean;
  searchParams?: Partial<PendingRequestSearchRequest>;
};

export const CustodianRequestsTab: FC<CustodianRequestsTabProps> = ({
  hideFilters = false,
  hideHeader = false,
  searchParams
}) => {
  const { showSnackbar } = useSnackbar();
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(25);
  const [orderBy, setOrderBy] = useState<keyof PendingRequestDto | undefined>();
  const [orderByDirection, setOrderByDirection] = useState<OrderByDirection>();

  const defaultQuery: PendingRequestSearchRequest = {
    ...searchParams // overwrites the above defaults
  };

  const [query, setQuery] = useState<PendingRequestSearchRequest>(defaultQuery);

  const { data, isFetching } = useQuery(
    [
      'PendingRequestService.search',
      query,
      page,
      pageSize,
      orderBy,
      orderByDirection
    ],
    () => {
      const queryWithPagination = {
        ...query,
        // if blank then don't pass it
        page,
        pageSize,
        tracerId: query.tracerId || undefined
      };
      if (orderBy) {
        queryWithPagination.orderBy = orderBy;
      }
      if (orderByDirection) {
        queryWithPagination.orderByDirection = orderByDirection;
      }
      return PendingRequestService.search(queryWithPagination);
    },
    {
      keepPreviousData: true,
      onError: (err: any) => {
        const message = err.response?.data ? err.response.data : err.message;
        showSnackbar({
          message: `Pending request search failed: ${message}`,
          severity: 'error'
        });
      }
    }
  );

  const pendingRequestStatusColorMap: Partial<
    Record<PendingRequestStatuses, 'info' | 'neutral' | 'success' | 'warning'>
  > = {
    [PendingRequestStatuses.pending]: 'neutral',
    [PendingRequestStatuses.processed]: 'success'
  };

  const columnDefs: ColDef[] = [
    {
      autoHeight: true,
      cellRenderer: (cellData: { data: PendingRequestDto }) => {
        // first column in the table needs to use a cellRenderer for the expansion icon to render
        const displayCustodianId = formatters.getValueKey(
          CustodianId,
          cellData.data.custodianId
        );
        return <>{formatters.displayCase(displayCustodianId)}</>;
      },
      field: 'custodianId',
      headerName: 'Custodian',
      sortable: true
    },
    {
      autoHeight: true,
      cellRenderer: (cellData: { data: PendingRequestDto }) => {
        const displayStatus = formatters.getValueKey(
          PendingRequestStatuses,
          cellData.data.status
        );
        return (
          <>
            {cellData.data.status && (
              <DataTableBadgeCell
                color={pendingRequestStatusColorMap[cellData.data.status]}>
                {formatters.displayCase(displayStatus)}
              </DataTableBadgeCell>
            )}
          </>
        );
      },
      field: 'status',
      headerName: 'Status',
      sortable: true
    },
    {
      autoHeight: true,
      field: 'id',
      headerName: 'ID',
      sortable: true
    },
    {
      autoHeight: true,
      field: 'action',
      headerName: 'Action',
      sortable: true,
      valueFormatter: ({ value }: { value: string }) => {
        // fetch friendly display name
        const displayActions = formatters.getValueKey(Actions, value);
        return formatters.displayCase(displayActions);
      }
    }
  ];

  const handleSubmit = (formData: PendingRequestSearchRequest) => {
    setQuery(formData);
  };

  const handleSortChanged = (
    newSort: { colId: string; sort?: 'asc' | 'desc' }[]
  ) => {
    if (!newSort || newSort.length === 0) {
      setOrderBy(undefined);
      setOrderByDirection(undefined);
      setPage(1);
    } else {
      setOrderBy(newSort[0].colId as keyof PendingRequestDto);
      setOrderByDirection(newSort[0].sort as OrderByDirection);
      setPage(1);
    }
  };

  const handlePageChanged = (newPage: number) => {
    setPage(newPage);
  };

  const handlePageSizeChanged = (newPageSize: number) => {
    setPage(1);
    setPageSize(newPageSize);
  };

  const detailCellRenderer = useMemo(() => {
    return CustodianRequestsDetailCellRenderer;
  }, []);

  return (
    <Card>
      {!hideHeader && (
        <CardHeader
          data-testid='pending-request-header'
          title='Pending Requests'
        />
      )}
      <CardContent disablePadding overlayLoading={isFetching}>
        <DataTable
          columnDefs={columnDefs}
          data-testid='data-pending-requests-table'
          detailCellRenderer={detailCellRenderer}
          emptyPlaceholderComponent={
            <Stack
              alignItems='center'
              data-testid='no-data-pending-requests-table'
              justifyContent='center'
              sx={{ height: '100%' }}>
              <CardPlaceholder
                icon={<SearchIcon fontSize='inherit' />}
                subtitle='No results found.'
              />
            </Stack>
          }
          filterSidePanelComponent={
            !hideFilters ? (
              <Formik<Partial<PendingRequestSearchRequest>>
                initialValues={{
                  tracerId: ''
                }}
                onSubmit={(values: PendingRequestSearchRequest) =>
                  handleSubmit(values)
                }>
                <Form data-testid='filter-form'>
                  <Stack
                    alignItems='flex-start'
                    justifyContent='flex-start'
                    spacing={2}>
                    <FormControl fullWidth size='small'>
                      <Field
                        InputProps={{
                          endAdornment: <SearchIcon />
                        }}
                        as={OutlinedInput}
                        inputProps={{
                          'data-testid': 'tracerId-input'
                        }}
                        name='tracerId'
                        placeholder='Enter a Tracer ID'
                      />
                    </FormControl>

                    <Button
                      data-testid='submit'
                      type='submit'
                      variant='outlined'>
                      Apply
                    </Button>
                  </Stack>
                </Form>
              </Formik>
            ) : undefined
          }
          onPageChanged={handlePageChanged}
          onPageSizeChanged={handlePageSizeChanged}
          onSortChanged={handleSortChanged}
          page={page}
          pageSize={pageSize}
          pagination
          paginationSource='server'
          paginationTotal={data?.pagination?.total}
          rowData={data?.results || []}
          sort={
            orderBy
              ? [
                  {
                    colId: orderBy,
                    sort: orderByDirection
                  }
                ]
              : []
          }
        />
      </CardContent>
    </Card>
  );
};
