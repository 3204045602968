import { useSnackbar } from '@/contexts/SnackBarContext';
import { useUserToken } from '@/contexts/UserTokenContext';
import { SubAccountSearchRequest } from '@/models/suba/accounts/SubAccountSearchRequest.model';
import { SubAccountService } from '@/services/suba/accounts/SubAccount.service';
import { useQuery } from '@tanstack/react-query';

import { AxiosError } from 'axios';

export const SEARCH_SUB_ACCOUNTS_QUERY_KEY =
  'SubAccountService.searchSubAccounts';

export const useSearchSubAccountsQuery = (params: SubAccountSearchRequest) => {
  const { showSnackbar } = useSnackbar();
  const { userHasValidToken } = useUserToken();

  return useQuery(
    [SEARCH_SUB_ACCOUNTS_QUERY_KEY, params],
    () => SubAccountService.searchSubAccounts(params),
    {
      enabled: userHasValidToken,
      keepPreviousData: true,
      onError: (err: AxiosError) => {
        showSnackbar({
          message: `Failed to get sub accounts: ${err.response?.data ?? err.message}`,
          severity: 'error'
        });
      }
    }
  );
};
