import { CopyToClipboard } from '@/components/copy-to-clipboard';
import { redirectToErrorPage } from '@/components/error-detail/ErrorDetailPage.component';
import LinearLoading from '@/components/linear-loading';
import NavigationBreadcrumbs from '@/components/navigation-breadcrumbs';
import SimpleTabs, { SimpleTabsProps, TabData } from '@/components/simple-tabs';
import TextStack, {
  TextLabel,
  TextStackItem,
  TextValue
} from '@/components/text-stack';
import { useTitle } from '@/hooks/useTitle';
import FundingSource from '@/models/suba/FundingSourceEnum.model';
import { FeatureLevelPermissions } from '@/models/UserPermissions.model';
import {
  CreateMenu,
  CreateMenuItem,
  HeadingMenuItem
} from '@/routes/suba/accounts/common/components/CreateMenu.component';
import { ConfirmFlatteningTradesDialog } from '@/routes/suba/accounts/common/components/dialogs/ConfirmFlatteningTradesDialog.component';
import { CreateTransactionDialog } from '@/routes/suba/accounts/common/components/dialogs/CreateTransactionDialog.component';
import { ConductorTab } from '@/routes/suba/accounts/common/components/tabs/conductor';
import { HoldingsTab } from '@/routes/suba/accounts/common/components/tabs/holdings';
import { SubAccountOrdersTab } from '@/routes/suba/accounts/common/components/tabs/sub-account-orders';
import { TransferCashDrawer } from '@/routes/suba/accounts/common/components/TransferCashDrawer.component';
import { TransactionsTab } from '@/routes/suba/common/components/tabs/transactions';
import SubAccountsService from '@/services/suba/accounts/SubAccount.service';
import { userService } from '@/services/User.service';
import formatters from '@/utils/Formatters';
import { Box, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { AccountLevel } from '@vestwell-sub-accounting/models/accountsAndLedgers/AccountLevel';
import { isOperationalSubAccount } from '@vestwell-sub-accounting/models/common/SubAccountType';

import { FC, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

enum CreateAction {
  CashTransfer = 'CashTransfer',
  FlatteningTrades = 'FlatteningTrades',
  Transaction = 'Transaction'
}

type SubAccountsDetailPageProps = {
  subAccountId: string;
  parentAccountId: string;
};

export const SubAccountsDetailPage: FC = () => {
  const { parentAccountId, subAccountId } =
    useParams<SubAccountsDetailPageProps>();
  const [action, setAction] = useState<CreateAction | null>(null);

  const { data: subAccountQueryData, isLoading } = useQuery(
    ['SubAccountService.getById', subAccountId],
    () => SubAccountsService.getById(subAccountId),
    {
      enabled: userService.hasPermission(
        FeatureLevelPermissions.READ_SUBA_ACCOUNTS
      )
    }
  );

  const title = useTitle();

  const tabElements: TabData[] = useMemo(
    // memo to prevent tabs being unloaded and reloaded when this component state changes
    () => [
      {
        component: <HoldingsTab subAccountId={subAccountId} />,
        label: 'Holdings',
        path: `/ops/accounts/${parentAccountId}/sub-accounts/${subAccountId}/holdings`
      },
      {
        component: <SubAccountOrdersTab searchParams={{ subAccountId }} />,
        label: 'Trading',
        path: `/ops/accounts/${parentAccountId}/sub-accounts/${subAccountId}/trading`
      },
      {
        component: (
          <TransactionsTab
            accountId={subAccountId}
            accountLevel={AccountLevel.SubAccount}
            hideAccountLevelToggle
            hideColumns={['accountId']}
          />
        ),
        label: 'Transactions',
        path: `/ops/accounts/${parentAccountId}/sub-accounts/${subAccountId}/transactions`
      },
      {
        component: (
          <ConductorTab
            accountId={subAccountId}
            accountLevel={AccountLevel.SubAccount}
            subAccountType={subAccountQueryData?.accountType}
          />
        ),
        label: 'Conductor',
        path: `/ops/accounts/${parentAccountId}/sub-accounts/${subAccountId}/conductor`
      }
    ],
    [parentAccountId, subAccountId, subAccountQueryData]
  );

  if (!userService.hasPermission(FeatureLevelPermissions.READ_SUBA_ACCOUNTS)) {
    return redirectToErrorPage(new Error('403'));
  }

  const tabs: SimpleTabsProps = {
    onChange: index => {
      title.setPageName(
        `${name} ${subAccountQueryData?.subAccountId} ${tabElements[index].label}`
      );
    },
    tabs: tabElements,
    tabsAriaLabel: 'accounts-tabs'
  };

  const parentName =
    subAccountQueryData?.parentAccount?.accountType === 'SuperOmnibus' ||
    subAccountQueryData?.parentAccount?.accountType === 'House'
      ? subAccountQueryData?.parentAccount?.accountName
      : subAccountQueryData?.plan?.name;

  const name =
    subAccountQueryData?.accountType === 'participantStandard' ||
    subAccountQueryData?.accountType === 'participantOutsideInvestment' ||
    subAccountQueryData?.accountType === 'participantCash'
      ? `${formatters.displayCase(subAccountQueryData?.accountType)} - ${
          subAccountQueryData?.fundingSource
            ? FundingSource[
                subAccountQueryData?.fundingSource as keyof typeof FundingSource
              ]
            : ''
        }`
      : formatters.displayCase(subAccountQueryData?.accountType);

  const paths = [
    { name: 'Search Accounts', to: '/ops/accounts' },
    {
      name: parentName || '',
      to: `/ops/accounts/${parentAccountId}`
    }
  ];

  return (
    <>
      {isLoading && <LinearLoading />}
      {action === CreateAction.Transaction && subAccountQueryData && (
        <CreateTransactionDialog
          accountId={subAccountQueryData?.subAccountId}
          accountLevel={AccountLevel.SubAccount}
          custodianId={subAccountQueryData?.parentAccount?.custodianId}
          onClose={() => setAction(null)}
          open
          parentAccountId={subAccountQueryData?.parentAccount?.parentAccountId}
          parentAccountType={subAccountQueryData?.parentAccount?.accountType}
        />
      )}
      {action === CreateAction.FlatteningTrades && subAccountQueryData && (
        <ConfirmFlatteningTradesDialog
          onClose={() => setAction(null)}
          open
          subAccountId={subAccountId}
          subAccountName={name}
        />
      )}
      {action === CreateAction.CashTransfer && subAccountQueryData && (
        <TransferCashDrawer
          onClose={() => setAction(null)}
          onSubmit={() => setAction(null)}
          open
          parentAccountId={subAccountQueryData?.parentAccount?.parentAccountId}
          planId={subAccountQueryData?.planId}
          subAccount={subAccountQueryData}
        />
      )}
      {!isLoading && (
        <Box position='relative'>
          <Box
            data-testid='sub-account-detail-header'
            display='flex'
            justifyContent='space-between'>
            <NavigationBreadcrumbs
              data-testid='sub-account-detail-breadcrumbs'
              paths={paths}
            />
          </Box>
          <Typography role='heading' variant='h4'>
            {name}
          </Typography>
          <Box alignItems='center' display='flex' justifyContent='left'>
            <Typography data-testid='detail_id' variant='subtitle1'>
              ID: {subAccountQueryData?.subAccountId}
            </Typography>

            <CopyToClipboard
              copyName={`Sub Account ID `}
              copyValue={String(subAccountQueryData?.subAccountId)}
            />
          </Box>
          <TextStack
            divider
            id='sub-account-detail-header-fields'
            rowColumnWidth='dynamic'
            sx={{ m: '30px 0', p: 0 }}>
            <>
              <TextStackItem>
                <TextLabel>VW Core Account ID</TextLabel>
                <TextValue>{subAccountQueryData?.coreAccountId}</TextValue>
              </TextStackItem>

              <TextStackItem>
                <TextLabel>Plan</TextLabel>
                <TextValue
                  links={
                    subAccountQueryData?.parentAccount?.accountType !==
                      'SuperOmnibus' &&
                    subAccountQueryData?.parentAccount?.accountType !==
                      'House' && [
                      {
                        target: '_blank',
                        to: `/plans/${subAccountQueryData?.planId}/plan`
                      }
                    ]
                  }>
                  {subAccountQueryData?.plan?.name}
                </TextValue>
              </TextStackItem>

              {subAccountQueryData?.investorId && (
                <TextStackItem>
                  <TextLabel>Participant</TextLabel>
                  <TextValue
                    copyLabel={`ID: ${subAccountQueryData?.investorId}`}
                    copyValue={String(subAccountQueryData?.investorId)}
                    copyable
                    links={[
                      {
                        target: '_blank',
                        to: `/participants/${subAccountQueryData?.investorId}/personal`
                      }
                    ]}>
                    {`${subAccountQueryData?.participant?.firstName} ${subAccountQueryData?.participant?.lastName}`}
                  </TextValue>
                </TextStackItem>
              )}
              <TextStackItem>
                <TextLabel>Funding Source</TextLabel>
                <TextValue>
                  {subAccountQueryData?.fundingSource
                    ? FundingSource[
                        subAccountQueryData?.fundingSource as keyof typeof FundingSource
                      ]
                    : null}
                </TextValue>
              </TextStackItem>

              <TextStackItem>
                <TextLabel>Created</TextLabel>
                <TextValue>
                  {subAccountQueryData?.createdAt
                    ? formatters.formatFromIsoDate(
                        subAccountQueryData?.createdAt
                      )
                    : null}
                </TextValue>
              </TextStackItem>
            </>
          </TextStack>
          <CreateMenu>
            <HeadingMenuItem>For Sub Account</HeadingMenuItem>
            <CreateMenuItem
              data-testid='transaction-menu-item'
              onClick={() => setAction(CreateAction.Transaction)}>
              Transaction
            </CreateMenuItem>
            {userService.hasPermission(
              FeatureLevelPermissions.WRITE_TRADE_REQUEST_EDIT
            ) &&
              isOperationalSubAccount(subAccountQueryData.accountType) && (
                <CreateMenuItem
                  data-testid='flattening-trades-menu-item'
                  onClick={() => setAction(CreateAction.FlatteningTrades)}>
                  Flattening Trades
                </CreateMenuItem>
              )}
            {userService.hasPermission(
              FeatureLevelPermissions.WRITE_SUBA_TRANSFER_CASH
            ) && (
              <CreateMenuItem
                data-testid='cash-transfer-menu-item'
                onClick={() => setAction(CreateAction.CashTransfer)}>
                Cash Transfer
              </CreateMenuItem>
            )}
          </CreateMenu>
          <SimpleTabs data-testid='sub-accounts-detail-tabs' {...tabs} />
        </Box>
      )}
    </>
  );
};
