import { CardContent, CardPlaceholder } from '@/components/card';
import DataTable, {
  DataTableProps,
  DataTableStackCell
} from '@/components/data-table/DataTable.component';
import { Link } from '@/components/link';
import { useParentAccountById } from '@/hooks/suba/useParentAccountById.hook';
import { useSearchSubAccountsQuery } from '@/hooks/suba/useSearchSubAccountsQuery.hook';
import { SubAccountApiIncludeOption } from '@/models/suba/accounts/SubAccountApiIncludeOption.model';
import { OrderByDirection } from '@/models/suba/common/OrderByDirection.model';
import FundingSource from '@/models/suba/FundingSourceEnum.model';
import { SubAccountDto } from '@/models/suba/SubAccountDTO.model';
import VestwellTheme from '@/theme/Vestwell.theme';
import formatters from '@/utils/Formatters';
import { MergeExclusive } from '@/utils/types/MergeExclusive';
import SearchIcon from '@mui/icons-material/Search';
import { Divider, Stack } from '@mui/material';
import { ParentAccountType } from '@vestwell-sub-accounting/models/common/ParentAccountType';

import Decimal from 'decimal.js';
import { Formik } from 'formik';
import { ComponentProps, FC, useCallback, useMemo } from 'react';

import {
  SubAccountsSearchForm,
  SubAccountsSearchFormValues
} from './components/SubAccountsSearchForm.component';

type SearchParams = Parameters<typeof useSearchSubAccountsQuery>[0];

type CommonProps = {
  onPageChange: DataTableProps['onPageChanged'];
  onPageSizeChange: DataTableProps['onPageSizeChanged'];
  onSortChange: (
    newSort: Pick<SearchParams, 'orderBy' | 'orderByDirection'>
  ) => void;
  searchParams: SearchParams;
};

type SubAccountsPropsWithFilters = {
  hideFilters?: false;
  onSearchFormSubmit: (values: SubAccountsSearchFormValues) => void;
};

type SubAccountsPropsWithoutFilters = {
  hideFilters: true;
};

export type SubAccountsProps = CommonProps &
  MergeExclusive<SubAccountsPropsWithFilters, SubAccountsPropsWithoutFilters>;

export const SubAccounts: FC<SubAccountsProps> = props => {
  // api

  const parentAccountQuery = useParentAccountById({
    accountId: props.searchParams.parentAccountId
  });

  const searchSubAccountsQuery = useSearchSubAccountsQuery({
    ...(props.searchParams || {}),
    include: [
      SubAccountApiIncludeOption.cashBalance,
      SubAccountApiIncludeOption.totalMarketValue
    ]
  });

  // callbacks

  const handleSortChanged = useCallback<DataTableProps['onSortChanged']>(
    newSortArray => {
      props.onSortChange(
        !newSortArray || newSortArray.length === 0
          ? {
              orderBy: undefined,
              orderByDirection: undefined
            }
          : {
              orderBy: newSortArray[0].colId as keyof SubAccountDto,
              orderByDirection: newSortArray[0].sort as OrderByDirection
            }
      );
    },
    []
  );

  // memos

  const columnDefs: ComponentProps<typeof DataTable>['columnDefs'] = useMemo(
    () =>
      [
        {
          cellRenderer: (cellData: { data: SubAccountDto }) => (
            <Link
              target='_blank'
              to={`/ops/accounts/${cellData.data.parentAccountId}/sub-accounts/${cellData.data.subAccountId}`}>
              {cellData.data.subAccountId}
            </Link>
          ),
          field: 'subAccountId',
          headerName: 'Sub Account ID',
          minWidth: 180,
          resizable: true,
          sortable: true
        },
        parentAccountQuery.data?.accountType ===
          ParentAccountType.SuperOmnibus &&
          props.searchParams.planId === undefined && {
            field: 'planId',
            headerName: 'Plan ID',
            minWidth: 120,
            sortable: true
          },
        {
          field: 'investorId',
          headerName: 'Part ID',
          minWidth: 120,
          sortable: true
        },
        props.searchParams.parentAccountId === undefined &&
          props.searchParams.planId === undefined && {
            autoHeight: true,
            cellRenderer: (cellData: { data: SubAccountDto }) => {
              const name =
                cellData.data.parentAccount?.accountType ===
                  ParentAccountType.SuperOmnibus ||
                cellData.data.parentAccount?.accountType ===
                  ParentAccountType.House
                  ? cellData.data.parentAccount.accountName
                  : cellData.data.plan?.name;

              return (
                <DataTableStackCell
                  primary={name || ''}
                  secondary={`ID: ${cellData.data.parentAccount?.parentAccountId}`}
                />
              );
            },
            field: 'parentAccountId',
            headerName: 'Parent Account',
            maxWidth: 330,
            minWidth: 330,
            sortable: true
          },
        {
          cellRenderer: (cellData: { data: SubAccountDto }) => {
            const participantName =
              cellData.data.participant?.firstName ||
              cellData.data.participant?.lastName
                ? `${cellData.data.participant?.firstName} ${cellData.data.participant?.lastName}`
                : '';
            return (
              <Link
                target='_blank'
                to={`/participants/${cellData.data?.investorId}/personal`}>
                {participantName || '\u2014'}
              </Link>
            );
          },
          headerName: 'Participant Name',
          maxWidth: 260,
          minWidth: 260
          // this data is available only after paginating, so we need to keep the sorting disabled
        },
        {
          cellRenderer: (cellData: { data: SubAccountDto }) => {
            return cellData?.data?.fundingSource
              ? FundingSource[
                  cellData?.data?.fundingSource as keyof typeof FundingSource
                ]
              : '\u2014';
          },
          field: 'fundingSource',
          headerName: 'Funding Source',
          maxWidth: 170,
          minWidth: 170
          // this data is available only after paginating, so we need to keep the sorting disabled
        },
        {
          field: 'totalMarketValue',
          headerName: 'Market Value',
          maxWidth: 260,
          type: 'numericColumn',
          valueFormatter: ({ value }) => formatters.formatDollars(value)
          // this is a calculated field, so we can't sort by it
        },
        {
          field: 'cashBalance',
          headerName: 'Cash Balance',
          maxWidth: 260,
          type: 'numericColumn',
          valueFormatter: ({ value }) => formatters.formatDollars(value)
          // this is a calculated field, so we can't sort by it
        },
        {
          field: 'createdAt',
          headerName: 'Created At',
          maxWidth: 190,
          minWidth: 190,
          sortable: true,
          valueFormatter: ({ value }: { value: string }) =>
            formatters.formatFromIsoDate(value)
        }
      ].filter(Boolean),
    []
  );

  return (
    <>
      {!props.hideFilters && (
        <CardContent>
          <Formik<SubAccountsSearchFormValues>
            initialValues={{
              hasDebitSubAccounts:
                props.searchParams?.hasDebitSubAccounts || false,
              query: props.searchParams?.query || ''
            }}
            onSubmit={props.onSearchFormSubmit}>
            <SubAccountsSearchForm />
          </Formik>
        </CardContent>
      )}
      <Divider />
      <CardContent
        disablePadding
        loading={searchSubAccountsQuery.isInitialLoading}>
        <DataTable
          columnDefs={columnDefs}
          data-testid='data-sub-accounts'
          emptyPlaceholderComponent={
            <Stack
              alignItems='center'
              data-testid='no-data-sub-accounts'
              justifyContent='center'
              sx={{ height: '100%' }}>
              <CardPlaceholder
                icon={<SearchIcon fontSize='inherit' />}
                subtitle={
                  searchSubAccountsQuery.data?.results === undefined
                    ? 'Search results will be displayed here.'
                    : 'No results found.'
                }
              />
            </Stack>
          }
          getRowStyle={params =>
            params.data.cashBalance &&
            new Decimal(params.data.cashBalance).lessThan(new Decimal(0)) && {
              backgroundColor: VestwellTheme.palette.warning.lightBg
            }
          }
          onPageChanged={props.onPageChange}
          onPageSizeChanged={props.onPageSizeChange}
          onSortChanged={handleSortChanged}
          page={props.searchParams?.page}
          pageSize={props.searchParams?.pageSize}
          pagination
          paginationSource='server'
          paginationTotal={searchSubAccountsQuery.data?.pagination?.total}
          primaryKey='subAccountId'
          rowData={searchSubAccountsQuery.data?.results || []}
          sort={
            props.searchParams?.orderBy
              ? [
                  {
                    colId: props.searchParams.orderBy,
                    sort: props.searchParams.orderByDirection
                  }
                ]
              : []
          }
        />
      </CardContent>
    </>
  );
};
