import Card, {
  CardContent,
  CardHeader,
  CardPlaceholder
} from '@/components/card';
import DataTable, {
  DataTableBadgeCell,
  DataTableMenuCell,
  DataTableProps,
  DataTableStackCell
} from '@/components/data-table/DataTable.component';
import ViewParentIcon from '@/components/icon/ViewParent';
import { useSnackbar } from '@/contexts/SnackBarContext';
import { useUrlState } from '@/hooks/useUrlState.hook';
import { OrderByDirection } from '@/models/suba/common/OrderByDirection.model';
import { orderStatusColorMap } from '@/models/suba/common/OrderStatusColorMap.model';
import { SubAccountOrderDto } from '@/models/suba/sub-account-orders/SubAccountOrderDTO.model';
import { SubAccountOrderSearchRequest } from '@/models/suba/sub-account-orders/SubAccountOrderSearchRequest.model';
import { FeatureLevelPermissions } from '@/models/UserPermissions.model';
import { DeferSubAccountOrderDialog } from '@/routes/suba/common/components/dialogs/DeferSubAccountOrderDialog.component';
import SubAccountOrderService from '@/services/suba/sub-account-orders/SubAccountOrder.service';
import { userService } from '@/services/User.service';
import formatters from '@/utils/Formatters';
import SearchIcon from '@mui/icons-material/Search';
import { IconButton, MenuItem, Stack, Tooltip } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { SecurityOrderType } from '@vestwell-sub-accounting/models/common/SecurityOrderType';
import { TradeType } from '@vestwell-sub-accounting/models/common/TradeType';
import { OrderStatus } from '@vestwell-sub-accounting/models/orderManagement/OrderStatus';

import { ColDef } from 'ag-grid-community';
import dayjs from 'dayjs';
import { Formik } from 'formik';
import { FC, useCallback, useMemo, useState } from 'react';

import { SubAccountOrderDetailCellRenderer } from './components/SubAccountOrderDetailCellRenderer.component';
import {
  SubAccountOrdersFiltersForm,
  SubAccountOrdersFiltersFormValues
} from './components/SubAccountOrdersFiltersForm.component';

export type SubAccountOrdersTabUrlState = Pick<
  SubAccountOrderSearchRequest,
  | 'orderBy'
  | 'orderByDirection'
  | 'page'
  | 'pageSize'
  | keyof SubAccountOrdersFiltersFormValues
>;

type SubAccountOrdersTabProps = {
  hideFilters?: boolean;
  hideHeader?: boolean;
  hideActions?: boolean;
  searchParams?: Partial<SubAccountOrderSearchRequest>;
  includeColumns?: string[];
};

export const SubAccountOrdersTab: FC<SubAccountOrdersTabProps> = props => {
  // context

  const { showSnackbar } = useSnackbar();

  // state

  const [urlState, setUrlState] = useUrlState<SubAccountOrdersTabUrlState>(
    {
      ...(props.searchParams || {}),
      endDate: props.hideFilters
        ? undefined
        : props.searchParams?.endDate || dayjs().format('YYYY-MM-DD'),
      page: 1,
      pageSize: 25,
      startDate: props.hideFilters
        ? undefined
        : props.searchParams?.startDate ||
          dayjs().subtract(1, 'month').format('YYYY-MM-DD')
    },
    {
      arrayParamKeys: ['orderStatus', 'tradeType'],
      parsedValueTypes: {
        cusip: 'string',
        endDate: 'string',
        id: 'string',
        omsBatchId: 'string',
        orderBy: 'string',
        orderByDirection: 'string',
        orderStatus: OrderStatus,
        page: 'number',
        pageSize: 'number',
        startDate: 'string',
        tradeType: TradeType
      }
    }
  );

  const [selectedSubAccountOrder, setSelectedSubAccountOrder] =
    useState<SubAccountOrderDto>();
  const [openDeferDialog, setOpenDeferDialog] = useState(false);

  // api

  const subAccountOrderQuery = useQuery(
    ['SubAccountOrderService.search', urlState],
    () => SubAccountOrderService.search(urlState),
    {
      keepPreviousData: true,
      onError: (err: any) => {
        showSnackbar({
          message: `Sub Account Order search failed: ${err.response?.data ? err.response.data : err.message}`,
          severity: 'error'
        });
      }
    }
  );

  // callbacks

  const handlePageChanged = useCallback<DataTableProps['onPageChanged']>(
    newPage => {
      setUrlState(prevUrlState => ({
        ...prevUrlState,
        page: newPage
      }));
    },
    []
  );

  const handlePageSizeChanged = useCallback<
    DataTableProps['onPageSizeChanged']
  >(newPageSize => {
    setUrlState(prevUrlState => ({
      ...prevUrlState,
      page: 1,
      pageSize: newPageSize
    }));
  }, []);

  const handleSortChanged = useCallback<DataTableProps['onSortChanged']>(
    newSort => {
      setUrlState(prevUrlState =>
        !newSort || newSort.length === 0
          ? {
              ...prevUrlState,
              orderBy: undefined,
              orderByDirection: undefined
            }
          : {
              ...prevUrlState,
              orderBy: newSort[0].colId as keyof SubAccountOrderDto,
              orderByDirection: newSort[0].sort as OrderByDirection
            }
      );
    },
    []
  );

  // memos

  const columnDefs = useMemo<ColDef[]>(
    () =>
      [
        props.includeColumns?.includes('subAccount') && {
          autoHeight: true,
          cellRenderer: (cellData: { data: SubAccountOrderDto }) => {
            const displaySubAccountName = formatters.formatSubAccountName(
              cellData.data.subAccount
            );
            return (
              <DataTableStackCell
                primary={displaySubAccountName}
                primaryLinkProps={{
                  target: '_blank',
                  to: `/ops/accounts/${cellData.data.parentAccountId}/sub-accounts/${cellData.data.subAccount?.subAccountId}`
                }}
                secondary={cellData.data.subAccount?.subAccountId}
              />
            );
          },
          field: 'subAccount',
          headerName: 'Sub Account',
          sortable: false
        },
        {
          autoHeight: true,
          cellRenderer: (cellData: { data: SubAccountOrderDto }) => {
            return (
              <Tooltip title='View Parent Account Order'>
                <IconButton
                  href={`/ops/parent-account-orders/${cellData.data.parentAccountOrderId}`}
                  size='small'
                  target='_blank'>
                  <ViewParentIcon sx={{ fontSize: 24 }} />
                </IconButton>
              </Tooltip>
            );
          },
          cellStyle: { paddingRight: 0 },
          field: 'id',
          headerName: '',
          suppressColumnsToolPanel: true,
          width: 85
        },
        {
          autoHeight: true,
          cellRenderer: (cellData: { data: SubAccountOrderDto }) => {
            return (
              <>
                {!props.hideActions &&
                userService.hasPermission(
                  FeatureLevelPermissions.WRITE_SUBA_TRANSACTIONS
                ) &&
                [OrderStatus.Rejected, OrderStatus.Open].includes(
                  cellData.data.orderStatus
                ) ? (
                  <DataTableMenuCell>
                    {[OrderStatus.Rejected, OrderStatus.Open].includes(
                      cellData.data.orderStatus
                    ) && (
                      <MenuItem
                        onClick={() => {
                          setSelectedSubAccountOrder(cellData.data);
                          setOpenDeferDialog(true);
                        }}>
                        Defer
                      </MenuItem>
                    )}
                  </DataTableMenuCell>
                ) : (
                  ' '
                )}
              </>
            );
          },
          cellStyle: { paddingLeft: 0, paddingRight: 0 },
          field: 'id',
          headerName: '',
          suppressColumnsToolPanel: true,
          width: 45
        },
        {
          autoHeight: true,
          cellRenderer: (cellData: { data: SubAccountOrderDto }) => {
            return (
              <DataTableStackCell
                primary={formatters.formatSecurityName(
                  cellData.data.security?.symbol,
                  cellData.data.security?.cusip
                )}
                secondary={cellData.data.security?.description}
              />
            );
          },
          field: 'security.symbol',
          headerName: 'Security'
        },
        {
          autoHeight: true,
          field: 'tradeType',
          headerName: 'Trade Type',
          sortable: true,
          valueFormatter: ({ value }: { value: string }) => {
            // fetch friendly display name
            const displayTradeType = formatters.getValueKey(TradeType, value);
            return formatters.displayCase(displayTradeType);
          }
        },
        {
          autoHeight: true,
          cellRenderer: (cellData: { data: SubAccountOrderDto }) => {
            const displayOrderStatus = formatters.getValueKey(
              OrderStatus,
              cellData.data.orderStatus
            );
            return (
              <>
                {cellData.data.orderStatus && (
                  <DataTableBadgeCell
                    color={orderStatusColorMap[cellData.data.orderStatus]}>
                    {formatters.displayCase(displayOrderStatus)}
                  </DataTableBadgeCell>
                )}
              </>
            );
          },
          field: 'orderStatus',
          headerName: 'Status',
          sortable: true
        },
        {
          autoHeight: true,
          field: 'securityOrderType',
          headerName: 'Order Type',
          sortable: true,
          valueFormatter: ({ value }: { value: string }) => {
            // fetch friendly display name
            const displaySecurityOrderType = formatters.snakeToCamelCase(
              formatters.getValueKey(SecurityOrderType, value)
            ); // snake case to camel case is necessary until the SecurityOrderType enum has its keys updated to camel case to match the convention
            return formatters.displayCase(displaySecurityOrderType);
          }
        },
        {
          autoHeight: true,
          cellRenderer: (cellData: { data: SubAccountOrderDto }) => {
            let displayUnitsAmount = '';
            if (
              [
                SecurityOrderType.DOLLAR_ALL,
                SecurityOrderType.DOLLAR_ORDER
              ].includes(cellData.data.securityOrderType)
            ) {
              displayUnitsAmount = cellData.data.orderAmount
                ? formatters.formatDollars(cellData.data.orderAmount)
                : '';
            } else {
              displayUnitsAmount = cellData.data.orderUnits
                ? formatters.formatDecimal(cellData.data.orderUnits, 3)
                : '';
            }
            return displayUnitsAmount;
          },
          field: 'orderUnits',
          headerName: 'Units/Amount'
        },
        {
          field: 'tradeDate',
          headerName: 'Trade Date',
          sortable: true,
          valueFormatter: ({ value }: { value: string }) =>
            value ? formatters.formatFromIsoDateCustom(value, 'MM/DD/YYYY') : ''
        }
      ].filter(
        // include columns with no field or if a column include list was provided, include columns that are in the list
        col =>
          !!col &&
          (!props.includeColumns || props.includeColumns.includes(col.field))
      ),
    []
  );

  const DetailCellRenderer = useMemo(
    () => SubAccountOrderDetailCellRenderer,
    []
  );

  const FiltersFormFormik = useMemo(
    () => (
      <Formik<SubAccountOrdersFiltersFormValues>
        enableReinitialize
        initialValues={{
          cusip: urlState.cusip || '',
          endDate: urlState.endDate || '',
          id: urlState.id?.toString() || '',
          omsBatchId: urlState.omsBatchId || '',
          orderStatus: urlState.orderStatus || [],
          startDate: urlState.startDate || '',
          tradeType: urlState.tradeType || []
        }}
        onSubmit={values => {
          setUrlState(prevUrlState => ({
            ...prevUrlState,
            ...values,
            endDate: values.startDate || null,
            id: values.id ? Number(values.id) : undefined,
            page: 1,
            startDate: values.startDate || null
          }));
        }}>
        <SubAccountOrdersFiltersForm />
      </Formik>
    ),
    [urlState]
  );

  return (
    <>
      {selectedSubAccountOrder && (
        <DeferSubAccountOrderDialog
          onClose={() => {
            setOpenDeferDialog(false);
            setSelectedSubAccountOrder(undefined);
          }}
          open={openDeferDialog}
          subAccountOrder={selectedSubAccountOrder}
        />
      )}
      <Card>
        {!props.hideHeader && (
          <CardHeader
            data-testid='sub-account-order-header'
            loading={
              subAccountOrderQuery.data?.results &&
              subAccountOrderQuery.isFetching
            }
            title='Sub Account Orders'
          />
        )}
        <CardContent
          disablePadding
          overlayLoading={subAccountOrderQuery.isInitialLoading}>
          <DataTable
            columnDefs={columnDefs}
            columnSizing='flex'
            data-testid='data-sub-account-orders-table'
            detailCellRenderer={DetailCellRenderer}
            emptyPlaceholderComponent={
              <Stack
                alignItems='center'
                data-testid='no-data-sub-account-orders-table'
                justifyContent='center'
                sx={{ height: '100%' }}>
                <CardPlaceholder
                  icon={<SearchIcon fontSize='inherit' />}
                  subtitle='No results found.'
                />
              </Stack>
            }
            filterSidePanelComponent={!props.hideFilters && FiltersFormFormik}
            onPageChanged={handlePageChanged}
            onPageSizeChanged={handlePageSizeChanged}
            onSortChanged={handleSortChanged}
            page={urlState.page}
            pageSize={urlState.pageSize}
            pagination
            paginationSource='server'
            paginationTotal={subAccountOrderQuery.data?.pagination?.total}
            rowData={subAccountOrderQuery.data?.results || []}
            sort={
              urlState.orderBy
                ? [
                    {
                      colId: urlState.orderBy,
                      sort: urlState.orderByDirection
                    }
                  ]
                : []
            }
          />
        </CardContent>
      </Card>
    </>
  );
};
