import { PaginatedApiResponse } from '@/models/PaginatedApiResponse.model';
import {
  ReconExceptionApiQueryServiceRequest,
  ReconExceptionDto,
  ReconExceptionsGetTotalsResponse
} from '@/models/suba/recon/ReconException.model';
import { UpdateReconExceptionRequest } from '@/models/suba/recon/UpdateReconExceptionRequest.model';
import ApiService from '@/services/Api.service';

import { isNil, omitBy } from 'lodash';

class ReconExceptionService {
  static search(params: ReconExceptionApiQueryServiceRequest) {
    return ApiService.getJson<PaginatedApiResponse<ReconExceptionDto[]>>(
      `recon-exceptions/`,
      omitBy(params, value => value === '' || isNil(value))
    );
  }

  static searchRelated(params: ReconExceptionApiQueryServiceRequest) {
    return ApiService.getJson<PaginatedApiResponse<ReconExceptionDto[]>>(
      `recon-exceptions/related`,
      omitBy(params, value => value === '' || isNil(value))
    );
  }

  static getById(id: number) {
    return ApiService.getJson<ReconExceptionDto>(`recon-exceptions/${id}`);
  }

  static update(id: number, params: UpdateReconExceptionRequest) {
    return ApiService.patchJson<UpdateReconExceptionRequest, ReconExceptionDto>(
      `recon-exceptions/${id}`,
      { ...params }
    );
  }

  static getTotalCounts(
    params:
      | {
          parentAccountId: string;
        }
      | {
          planId: string;
        }
  ) {
    return ApiService.getJson<ReconExceptionsGetTotalsResponse>(
      `recon-exceptions/total-counts`,
      params
    );
  }
}

export default ReconExceptionService;
