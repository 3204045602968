import Card, {
  CardContent,
  CardHeader
} from '@/components/card/Card.component';
import DataTable from '@/components/data-table/DataTable.component';
import TextStack, {
  TextLabel,
  TextStackItem,
  TextValue
} from '@/components/text-stack/TextStack.component';
import { useGetSecurityQuery } from '@/hooks/suba/useGetSecurity.hook';
import { useSecuritiesDetailRouteParams } from '@/routes/suba/securities/securities-detail/hooks/useSecurityDetailRouteParams.hook';
import { Box, Divider, Grid, Stack, Typography } from '@mui/material';
import { AccrualRateRule } from '@vestwell-sub-accounting/models/securitiesAndPricing/AccrualRateRule';
import { FundDividendFrequency } from '@vestwell-sub-accounting/models/securitiesAndPricing/FundDividendFrequency';
import { MultiDayAccrualRule } from '@vestwell-sub-accounting/models/securitiesAndPricing/MultiDayAccrualRule';

import { FC } from 'react';

const displayDividendAccrualRateRule = {
  [AccrualRateRule.perDayRates]: 'Per Day Rate',
  [AccrualRateRule.totalValueRates]: 'Total Value Rate'
};

const displayDividendFrequency = {
  [FundDividendFrequency.annual]: 'Annually',
  [FundDividendFrequency.monthly]: 'Monthly',
  [FundDividendFrequency.quarterly]: 'Quarterly',
  [FundDividendFrequency.semiannual]: 'Semi-Annually'
};

const displayMultiDayAccrualRule = {
  [MultiDayAccrualRule.noBulkRate]: 'No Bulk Rate',
  [MultiDayAccrualRule.prevBusDay]: 'Previous Business Day',
  [MultiDayAccrualRule.nextBusDay]: 'Next Business Day'
};

export const FundRulesTab: FC = () => {
  const routeParams = useSecuritiesDetailRouteParams();
  const getSecurityQuery = useGetSecurityQuery(routeParams.cusipOrSymbol);

  return (
    <Card>
      <CardHeader title='Fund Rules' />
      <CardContent disablePadding>
        <Grid container spacing={0}>
          <Grid item xs={true}>
            <Box padding={2}>
              <Stack spacing={4}>
                <div>
                  <Typography display='inline-block' mb={1} variant='overline'>
                    General
                  </Typography>
                  <TextStack direction='column' textLabelColumnWidth='50%'>
                    <TextStackItem>
                      <TextLabel>Dividend Type</TextLabel>
                      <TextValue data-testid='dividend-type'>
                        {getSecurityQuery.data?.fundRules
                          ? getSecurityQuery.data?.fundRules?.divType ||
                            'Periodic'
                          : undefined}
                      </TextValue>
                    </TextStackItem>
                    <TextStackItem>
                      <TextLabel>Dividend Frequency</TextLabel>
                      <TextValue data-testid='dividend-frequency'>
                        {getSecurityQuery.data?.fundRules?.divFrequency
                          ? displayDividendFrequency[
                              getSecurityQuery.data?.fundRules
                                ?.divFrequency as keyof typeof displayDividendFrequency
                            ]
                          : undefined}
                      </TextValue>
                    </TextStackItem>
                  </TextStack>
                </div>
                <div>
                  <Typography display='inline-block' mb={1} variant='overline'>
                    Minimums
                  </Typography>
                  <TextStack direction='column' textLabelColumnWidth='50%'>
                    <TextStackItem>
                      <TextLabel>Minimum Initial Purchase Amount</TextLabel>
                      <TextValue data-testid='minimum-initial-purchase-amount'>
                        {getSecurityQuery.data?.fundRules?.qpMinInitialPurchase}
                      </TextValue>
                    </TextStackItem>
                    <TextStackItem>
                      <TextLabel>Minimum Subsequent Purchase Amount</TextLabel>
                      <TextValue data-testid='minimum-subsequent-purchase-amount'>
                        {
                          getSecurityQuery.data?.fundRules
                            ?.qpMinSubsequentPurchase
                        }
                      </TextValue>
                    </TextStackItem>
                  </TextStack>
                </div>
              </Stack>
            </Box>
          </Grid>
          <Grid display='flex' item justifyContent='flex-end' xs='auto'>
            <Divider orientation='vertical' />
          </Grid>
          <Grid item xs={true}>
            <Stack spacing={4}>
              <Box pt={2} px={4}>
                <Typography display='inline-block' mb={1} variant='overline'>
                  Accrual Info
                </Typography>
                <TextStack direction='column' textLabelColumnWidth='50%'>
                  <TextStackItem>
                    <TextLabel>Accrual Weekend Rule</TextLabel>
                    <TextValue data-testid='accrual-weekend-rule'>
                      {getSecurityQuery.data?.fundRules?.accrualWeekEndRule
                        ? displayMultiDayAccrualRule[
                            getSecurityQuery.data?.fundRules
                              ?.accrualWeekEndRule as keyof typeof displayMultiDayAccrualRule
                          ]
                        : undefined}
                    </TextValue>
                  </TextStackItem>
                  <TextStackItem>
                    <TextLabel>Accrual Month End Rule</TextLabel>
                    <TextValue data-testid='accrual-month-end-rule'>
                      {getSecurityQuery.data?.fundRules?.accrualMonthEndRule
                        ? displayMultiDayAccrualRule[
                            getSecurityQuery.data?.fundRules
                              ?.accrualMonthEndRule as keyof typeof displayMultiDayAccrualRule
                          ]
                        : undefined}
                    </TextValue>
                  </TextStackItem>
                  <TextStackItem>
                    <TextLabel>Dividend Accrual Rate Rule</TextLabel>
                    <TextValue data-testid='dividend-accrual-rate-rule'>
                      {getSecurityQuery.data?.divAccrualRateRule
                        ? displayDividendAccrualRateRule[
                            getSecurityQuery.data
                              ?.divAccrualRateRule as keyof typeof displayDividendAccrualRateRule
                          ]
                        : undefined}
                    </TextValue>
                  </TextStackItem>
                </TextStack>
              </Box>
              <DataTable
                columnDefs={[
                  {
                    field: 'rule',
                    headerName: 'Accrual Rules'
                  },
                  {
                    field: 'value'
                  }
                ]}
                disableLastRowBorder
                rowData={[
                  {
                    rule: 'Purchase Start',
                    value:
                      getSecurityQuery.data?.fundRules?.divAccrualPurchaseStart
                  },
                  {
                    rule: 'Redemption Stop',
                    value:
                      getSecurityQuery.data?.fundRules?.divAccrualRedemptionStop
                  },
                  {
                    rule: 'Partial Exchange In Start',
                    value:
                      getSecurityQuery.data?.fundRules
                        ?.divAccrualPartialExchInStart
                  },
                  {
                    rule: 'Partial Exchange Out Stop',
                    value:
                      getSecurityQuery.data?.fundRules
                        ?.divAccrualPartialExchOutStop
                  },
                  {
                    rule: 'Partial Transfer In Start',
                    value:
                      getSecurityQuery.data?.fundRules
                        ?.divAccrualPartialTransferInStart
                  },
                  {
                    rule: 'Partial Transfer Out Stop',
                    value:
                      getSecurityQuery.data?.fundRules
                        ?.divAccrualPartialTransferOutStop
                  },
                  {
                    rule: 'ACAT Transfer In Start',
                    value:
                      getSecurityQuery.data?.fundRules
                        ?.divAccrualAcatTransferInStart
                  },
                  {
                    rule: 'ACAT Transfer Out Stop',
                    value:
                      getSecurityQuery.data?.fundRules
                        ?.divAccrualAcatTransferOutStop
                  },
                  {
                    rule: 'Dividend DCCS Accrual Redemption Stop Rule',
                    value:
                      getSecurityQuery.data?.fundRules
                        ?.divDccsAccrualRedemptionStop
                  }
                ]}
              />
            </Stack>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};
