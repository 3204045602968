import { Badge } from '@/components/badge';
import { CusipTickerSearch } from '@/components/cusip-ticker-search/CusipTickerSearch';
import { DatePicker } from '@/components/date-picker/DatePicker';
import { BreakageStatusColorMap } from '@/models/suba/recon/BreakageStatusColorMap.model';
import { AssigneeAutocomplete } from '@/routes/suba/common/components/forms/AssigneeAutocomplete.component';
import formatters from '@/utils/Formatters';
import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  Stack
} from '@mui/material';
import { BreakageDataType } from '@vestwell-sub-accounting/models/recon/BreakageDataType';
import { BreakageProcess } from '@vestwell-sub-accounting/models/recon/BreakageProcess';
import { BreakageStatus } from '@vestwell-sub-accounting/models/recon/BreakageStatus';

import { Field, Form, useFormikContext } from 'formik';
import { FC, useState } from 'react';

export type ReconExceptionsFiltersFormValues = {
  assignee?: string;
  cusipOrSymbol: string;
  id: string;
  parentAccountId: string;
  status: BreakageStatus[];
  dataType: BreakageDataType | '';
  process: BreakageProcess | '';
  lockAccountFlag: 'true' | 'false' | '';
  startDate?: string;
  endDate: string;
};

type ReconExceptionsFiltersFormProps = {
  hideParentAccountInput?: boolean;
};

export const ReconExceptionsFiltersForm: FC<
  ReconExceptionsFiltersFormProps
> = props => {
  const formikContext = useFormikContext<ReconExceptionsFiltersFormValues>();
  const [isCusipValid, setIsCusipValid] = useState(true);
  const [isValidating, setIsValidating] = useState(false);

  return (
    <Form data-testid='filter-form'>
      <Stack
        alignItems='flex-start'
        justifyContent='flex-start'
        spacing={2}
        width={256}>
        <Field
          as={CusipTickerSearch}
          data-testid='cusip-symbol-input'
          helperTextPlaceholder
          name='cusipOrSymbol'
          onChange={async value => {
            await formikContext.setFieldValue('cusipOrSymbol', value);
            setIsCusipValid(true);
            if (value === '') formikContext.submitForm();
          }}
          onError={err => {
            formikContext.setFieldError('cusipOrSymbol', err.message);
            setIsCusipValid(false);
          }}
          onValidating={setIsValidating}
        />
        <FormControl fullWidth size='small'>
          <InputLabel htmlFor='exception-id-input'>Exception ID</InputLabel>
          <Field
            as={OutlinedInput}
            id='exception-id-input'
            inputProps={{
              'data-testid': 'exception-id-input'
            }}
            label='Exception ID'
            name='id'
            placeholder='Any'
          />
        </FormControl>
        {props.hideParentAccountInput ? null : (
          <FormControl fullWidth size='small'>
            <InputLabel htmlFor='parent-account-id-input'>
              Parent Account ID
            </InputLabel>
            <Field
              as={OutlinedInput}
              id='parent-account-id-input'
              inputProps={{
                'data-testid': 'parent-account-id-input'
              }}
              label='Parent Account ID'
              name='parentAccountId'
              placeholder='Any'
            />
          </FormControl>
        )}
        <FormControl fullWidth size='small'>
          <InputLabel id='menu-status-label' shrink>
            Status
          </InputLabel>
          <Field
            MenuProps={{
              'data-testid': 'menu-status'
            }}
            as={Select}
            data-testid='query-status'
            displayEmpty
            label='Status'
            labelId='menu-status-label'
            multiple
            name='status'
            renderValue={(selected: BreakageStatus[]) => {
              if (selected.length === 0) {
                return <>Any</>;
              }
              return (
                <Box
                  sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    gap: 0.5
                  }}>
                  {selected.map(value => {
                    return (
                      <Badge
                        color={BreakageStatusColorMap[value]}
                        key={value}
                        size='small'>
                        {formatters.displayCase(
                          formatters.getValueKey(BreakageStatus, value)
                        )}
                      </Badge>
                    );
                  })}
                </Box>
              );
            }}>
            {Object.values(BreakageStatus).map(value => {
              const displayStatus = formatters.getValueKey(
                BreakageStatus,
                value
              );
              return (
                <MenuItem key={value} value={value}>
                  <Checkbox
                    checked={(formikContext.values.status || []).includes(
                      value
                    )}
                    sx={{ py: 0 }}
                  />
                  <ListItemText>
                    {formatters.displayCase(displayStatus)}
                  </ListItemText>
                </MenuItem>
              );
            })}
          </Field>
        </FormControl>
        <FormControl fullWidth size='small'>
          <InputLabel id='menu-data-type-label' shrink>
            Break Type
          </InputLabel>
          <Field
            MenuProps={{
              'data-testid': 'menu-data-type'
            }}
            as={Select}
            data-testid='query-data-type'
            displayEmpty
            label='Break Type'
            labelId='menu-data-type-label'
            name='dataType'>
            <MenuItem value=''>Any</MenuItem>
            {Object.values(BreakageDataType).map(value => {
              const displayDataType = formatters.getValueKey(
                BreakageDataType,
                value
              );
              return (
                <MenuItem key={value} value={value}>
                  {formatters.displayCase(displayDataType)}
                </MenuItem>
              );
            })}
          </Field>
        </FormControl>
        <FormControl fullWidth size='small'>
          <InputLabel id='menu-process-label' shrink>
            Break Process
          </InputLabel>
          <Field
            MenuProps={{
              'data-testid': 'menu-process'
            }}
            as={Select}
            data-testid='query-process'
            displayEmpty
            label='Break Process'
            labelId='menu-process-label'
            name='process'>
            <MenuItem value=''>Any</MenuItem>
            {Object.values(BreakageProcess).map(value => {
              const displayProcess = formatters.getValueKey(
                BreakageProcess,
                value
              );
              return (
                <MenuItem key={value} value={value}>
                  {formatters.displayCase(displayProcess)}
                </MenuItem>
              );
            })}
          </Field>
        </FormControl>
        <FormControl fullWidth size='small'>
          <InputLabel id='menu-lock-account-flag-label' shrink>
            Frozen
          </InputLabel>
          <Field
            MenuProps={{
              'data-testid': 'menu-lock-account-flag'
            }}
            as={Select}
            data-testid='query-lock-account-flag'
            displayEmpty
            label='Frozen'
            labelId='menu-lock-account-flag-label'
            name='lockAccountFlag'>
            <MenuItem value=''>Any</MenuItem>
            <MenuItem value='true'>Frozen</MenuItem>
            <MenuItem value='false'>Not Frozen</MenuItem>
          </Field>
        </FormControl>
        <FormControl fullWidth size='small'>
          <Field as={AssigneeAutocomplete} name='assignee' />
        </FormControl>

        <Divider flexItem textAlign='left'>
          Exception Date
        </Divider>

        <FormControl fullWidth size='small' variant='outlined'>
          <Field
            as={DatePicker}
            data-testid='start-date-input'
            label='From'
            name='startDate'
          />
        </FormControl>
        <FormControl fullWidth size='small' variant='outlined'>
          <Field
            as={DatePicker}
            data-testid='end-date-input'
            label='To'
            name='endDate'
          />
        </FormControl>

        <Button
          disabled={!formikContext.isValid || isValidating || !isCusipValid}
          type='submit'
          variant='outlined'>
          Apply
        </Button>
      </Stack>
    </Form>
  );
};
