import { useUrlState } from '@/hooks/useUrlState.hook';
import { OrderByDirection } from '@/models/suba/common/OrderByDirection.model';
import { ReconExceptionApiQueryServiceRequest } from '@/models/suba/recon/ReconException.model';
import {
  ReconExceptions,
  ReconExceptionsFiltersFormValues,
  ReconExceptionsProps
} from '@/routes/suba/common/components/recon-exceptions';
import { MergeExclusive } from '@/utils/types/MergeExclusive';
import { BreakageDataType } from '@vestwell-sub-accounting/models/recon/BreakageDataType';
import { BreakageProcess } from '@vestwell-sub-accounting/models/recon/BreakageProcess';
import { BreakageStatus } from '@vestwell-sub-accounting/models/recon/BreakageStatus';

import dayjs from 'dayjs';
import { FC, useCallback } from 'react';

export type ReconExceptionsTabUrlState = Pick<
  ReconExceptionApiQueryServiceRequest,
  | 'cusip'
  | 'orderBy'
  | 'orderByDirection'
  | 'page'
  | 'pageSize'
  | keyof Omit<ReconExceptionsFiltersFormValues, 'cusipOrSymbol'>
>;

type ReconExceptionsByAccountIdProps = {
  parentAccountId?: string;
};

type ReconExceptionsByPlanIdProps = {
  planId?: string;
};

type ReconExceptionsTabProps = MergeExclusive<
  ReconExceptionsByAccountIdProps,
  ReconExceptionsByPlanIdProps
>;

export const ReconExceptionsTab: FC<ReconExceptionsTabProps> = props => {
  // state

  const [urlState, setUrlState] = useUrlState<ReconExceptionsTabUrlState>(
    {
      endDate: dayjs().format('YYYY-MM-DD'),
      page: 1,
      pageSize: 25,
      status: [BreakageStatus.Open, BreakageStatus.InProcess]
    },
    {
      arrayParamKeys: ['status'],
      parsedValueTypes: {
        assignee: 'string',
        cusip: 'string',
        dataType: BreakageDataType,
        endDate: 'string',
        id: 'number',
        lockAccountFlag: 'boolean',
        orderBy: 'string',
        orderByDirection: OrderByDirection,
        page: 'number',
        pageSize: 'number',
        parentAccountId: 'string',
        process: BreakageProcess,
        startDate: 'string',
        status: BreakageStatus
      }
    }
  );

  // callbacks

  const handleAssigneeChange = useCallback<
    ReconExceptionsProps['onAssigneeChange']
  >(newAssignee => {
    setUrlState(prevUrlState => ({
      ...prevUrlState,
      assignee: newAssignee,
      page: 1
    }));
  }, []);

  const handleFiltersFormSubmit = useCallback<
    ReconExceptionsProps['onFiltersFormSubmit']
  >(values => {
    setUrlState(prevUrlState => ({
      ...prevUrlState,
      ...values,
      cusip: values.cusipOrSymbol,
      dataType: values.dataType || undefined,
      endDate: values.endDate || null,
      id: values.id ? Number(values.id) : undefined,
      lockAccountFlag:
        values.lockAccountFlag === ''
          ? undefined
          : values.lockAccountFlag === 'true'
            ? true
            : false,
      page: 1,
      process: values.process || undefined
    }));
  }, []);

  const handlePageChange = useCallback<ReconExceptionsProps['onPageChange']>(
    newPage => {
      setUrlState(prevUrlState => ({
        ...prevUrlState,
        page: newPage
      }));
    },
    []
  );

  const handlePageSizeChange = useCallback<
    ReconExceptionsProps['onPageChange']
  >(newPageSize => {
    setUrlState(prevUrlState => ({
      ...prevUrlState,
      page: 1,
      pageSize: newPageSize
    }));
  }, []);

  const handleSortChange = useCallback<ReconExceptionsProps['onSortChange']>(
    newSort => {
      setUrlState(prevUrlState => ({
        ...prevUrlState,
        ...newSort,
        page: 1
      }));
    },
    []
  );

  return (
    <ReconExceptions
      hideParentAccountIdInput={!!props.parentAccountId}
      onAssigneeChange={handleAssigneeChange}
      onFiltersFormSubmit={handleFiltersFormSubmit}
      onPageChange={handlePageChange}
      onPageSizeChange={handlePageSizeChange}
      onSortChange={handleSortChange}
      searchParams={{
        ...urlState,
        parentAccountId: props.parentAccountId || urlState.parentAccountId, // fallback to form value if prop doesn't override it
        planId: props.planId
      }}
    />
  );
};
