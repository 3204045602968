import CollapsibleTable from '@/components/collapsible-table';
import { alpha, Box, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { useMemo } from 'react';

import ConversionRequestsCollapsible from './ConversionRequestsCollapsible.component';
import ConversionRequestsTableCell from './ConversionRequestsTableCell.component';

interface ConversionRequestsTabProps {
  data: any;
  isPlanRequests: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  headerCell: {
    color: alpha(theme.palette.common.black, 0.6),
    fontSize: theme.spacing(1.75),
    fontWeight: 500,
    paddingBottom: theme.spacing(1.25),
    paddingTop: theme.spacing(1.25)
  }
}));

const ConversionRequestsTab: React.FunctionComponent<
  ConversionRequestsTabProps
> = (props: ConversionRequestsTabProps) => {
  const { data } = props;

  const classes = useStyles();

  const conversionRequestColumns = useMemo(
    () =>
      [
        {
          cellClassName: classes.headerCell,
          field: 'status',
          headerName: 'Status',
          width: 130
        },
        {
          cellClassName: classes.headerCell,
          field: 'depositCode',
          headerName: 'Deposit Code',
          width: 130
        },
        !props.isPlanRequests && {
          cellClassName: classes.headerCell,
          field: 'participant',
          headerName: 'Participant',
          width: 260
        },
        {
          cellClassName: classes.headerCell,
          field: 'amount',
          headerName: 'Amount',
          width: 130
        },
        {
          cellClassName: classes.headerCell,
          field: 'tracerId',
          headerName: 'Tracer ID',
          width: 130
        },
        {
          cellClassName: classes.headerCell,
          field: 'createdAt',
          headerName: 'Create At',
          width: 130
        }
      ].filter(i => i),
    [classes]
  );

  return (
    <Box sx={{ marginTop: (theme: Theme) => theme.spacing(-3) }}>
      <CollapsibleTable
        backgroundPaperElevation={0}
        cellComponent={ConversionRequestsTableCell}
        collapsibleComponent={ConversionRequestsCollapsible}
        columns={conversionRequestColumns}
        headerPagination
        rootPaperElevation={0}
        tableData={data}
        tablePaginationSx={{
          borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
          borderTop: '1px solid rgba(0, 0, 0, 0.12)'
        }}
      />
    </Box>
  );
};

export default ConversionRequestsTab;
