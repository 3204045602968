import { CONVERSION_TYPES, ConversionValues } from '@/consts/uploads';
import { useSnackbar } from '@/contexts/SnackBarContext';
import { Conversion, ConversionStatus } from '@/models/ConversionDTO.model';
import { FeatureLevelPermissions } from '@/models/UserPermissions.model';
import { PlanService } from '@/services/Plan.service';
import { userService } from '@/services/User.service';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { LoadingButton } from '@mui/lab';
import { Button, IconButton } from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import React from 'react';

interface ConversionCardButtonProps {
  sponsorPlanId: number;
  conversion: Conversion;
  isLoading: boolean;
  onClick: () => void;
  setTabIndex?: (index: number) => void;
  disableRequestsBtn?: boolean;
}

const ConversionCardButton: React.FunctionComponent<
  ConversionCardButtonProps
> = ({
  conversion,
  sponsorPlanId,
  isLoading,
  onClick,
  setTabIndex,
  disableRequestsBtn = true
}: ConversionCardButtonProps) => {
  const { showSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const userInfo = userService.getUser();
  const isUploadingUser = userInfo?.nickname === conversion?.submittedBy?.id;
  const getBtnName = () => {
    if (conversion.id) {
      switch (conversion.conversionType) {
        case CONVERSION_TYPES.CASH_ALLOCATION:
        case CONVERSION_TYPES.LOANS:
        case CONVERSION_TYPES.IN_KIND_CONVERSION:
          if (
            conversion.status === ConversionStatus.Draft &&
            userService.hasPermission(FeatureLevelPermissions.WRITE_UPLOADS) &&
            isUploadingUser
          )
            return 'EDIT';
          else if (conversion.status === ConversionStatus.PendingApproval) {
            if (isUploadingUser) return 'VIEW';
            else if (
              userService.hasPermission(FeatureLevelPermissions.WRITE_UPLOADS)
            )
              return 'VIEW & APPROVE';
            else return null;
          } else return 'VIEW FILE';
        case CONVERSION_TYPES.ROTH_COST_BASIS:
        case CONVERSION_TYPES.DEFERRAL_RATES:
        case CONVERSION_TYPES.HISTORICAL_YEARS_OF_SERVICE:
          return 'VIEW FILE';
        case CONVERSION_TYPES.INVESTMENT_ELECTION_MAPPINGS:
          if (isUploadingUser) {
            return userService.hasPermission(
              FeatureLevelPermissions.WRITE_CONVERSION_ASSET_MAPPING_SUBMIT
            )
              ? 'VIEW'
              : null;
          } else {
            if (conversion.status === ConversionStatus.Complete) return 'VIEW';
            if (conversion.status === ConversionStatus.Draft) return null;
            if (
              conversion.status === ConversionStatus.PendingApproval &&
              userService.hasPermission(
                FeatureLevelPermissions.WRITE_CONVERSION_ASSET_MAPPING_APPROVE
              )
            ) {
              return 'VIEW & APPROVE';
            }

            return null;
          }
      }
    } else return null;
  };

  const deleteConversionMutation = useMutation(
    ['PlanService.deleteConversion', conversion.id, sponsorPlanId],
    () => PlanService.deleteConversion(conversion.id, sponsorPlanId),
    {
      onError: () => {
        showSnackbar({
          message: `Error deleting conversion`,
          severity: 'error'
        });
      },
      onSuccess: () => {
        showSnackbar({
          message: `Success!`,
          severity: 'success'
        });
        queryClient.refetchQueries([
          `PlanService.getPlanConversions ${sponsorPlanId}`
        ]);
      }
    }
  );

  const onClickDelete = () => deleteConversionMutation.mutate();

  return (
    <>
      {getBtnName() && (
        <LoadingButton
          disabled={!sponsorPlanId || isLoading}
          loading={isLoading}
          onClick={onClick}>
          {getBtnName()}
        </LoadingButton>
      )}
      {!disableRequestsBtn && (
        <Button onClick={() => setTabIndex && setTabIndex(1)}>
          VIEW REQUESTS
        </Button>
      )}
      {conversion.status === ConversionStatus.Draft &&
        isUploadingUser &&
        (
          [
            CONVERSION_TYPES.CASH_ALLOCATION,
            CONVERSION_TYPES.LOANS
          ] as ConversionValues[]
        ).includes(conversion.conversionType) && (
          <IconButton
            aria-label='delete'
            disabled={deleteConversionMutation.isLoading}
            onClick={onClickDelete}>
            <DeleteOutlineIcon />
          </IconButton>
        )}
    </>
  );
};

export default ConversionCardButton;
