import FileUploadTable from '@/components/file-upload-table/FileUploadTable.component';
import LinearLoading from '@/components/linear-loading';
import { CONVERSION_TYPES, DOCUMENT_TYPES } from '@/consts/uploads';
import { Conversion } from '@/models/ConversionDTO.model';
import ActionTableForm from '@/routes/plans/plan-detail/PlanActionTableV2/ConversionMainComponents/ActionTableForm.component';
import yearsOfServiceValidationSchema from '@/utils/validations/YearsOfService.schema';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack
} from '@mui/material';
import { formatSsn } from '@vestwell-frontend/helpers';

import { useToggle } from 'react-use';

import ConversionDialog from '../PlanActionTable/ConversionDialog';
import ConversionCardButton from '../PlanActionTableV2/ConversionMainComponents/ConversionCardButton.component';
import ConversionDialogHeader from '../PlanActionTableV2/ConversionMainComponents/ConversionDialogHeader.component';
import {
  useConversionAgGrid,
  useConversionFile,
  useUploadOnboardingAgGridFile
} from '../PlanActionTableV2/hooks';

interface YearsOfServiceButtonProps {
  sponsorPlanId: number;
  conversion?: Conversion;
}

const columnDefs = [
  {
    alternates: [
      'social',
      'SS #',
      'ss#',
      'social security number',
      'social_security_number',
      'social security',
      'ssn#',
      'Taxpayer ID (SSN or Fed ID)',
      'socialsecuritynumber',
      'employee ssn',
      'national_identifier',
      'employee social',
      'national identifier',
      'ssn number',
      'ss number',
      'ssn/fein',
      'Social Security Number'
    ],
    cellEditorParams: {
      showExcludeRowButton: true
    },
    editable: true,
    field: 'ssn',
    headerName: 'SSN',
    minWidth: 250,
    required: true,
    suppressMenu: true,
    type: 'ssn',
    valueParser: (value: string) => {
      if (/^(?!(000))\d{3}(?!00)\d{2}(?!0000)\d{4}$/.test(value)) {
        return formatSsn(value);
      }
      return value;
    }
  },
  {
    alternates: ['Years of Service'],
    editable: true,
    field: 'yearsOfService',
    headerName: 'Years of Service',
    minWidth: 250,
    required: true,
    resizable: true,
    suppressMenu: true,
    type: 'number'
  }
];
const EMPTY_ROWS = 10;

export const YearsOfService: React.FunctionComponent<
  YearsOfServiceButtonProps
> = (props: YearsOfServiceButtonProps) => {
  const [openPlanActionDialog, togglePlanActionDialog] = useToggle(false);
  const [openConfirmationDialog, toggleOpenConfirmationDialog] =
    useToggle(false);
  const conversionAgGrid = useConversionAgGrid(props.sponsorPlanId);

  const onUploadCallback = () => {
    toggleOpenConfirmationDialog();
    togglePlanActionDialog();
    if (isSuccess) {
      conversionAgGrid.form.current?.setValues([]);
    }
  };

  const {
    mutate: uploadYearsOfService,
    isLoading,
    isSuccess
  } = useUploadOnboardingAgGridFile(
    props.sponsorPlanId as number,
    DOCUMENT_TYPES.HISTORICAL_YEARS_OF_SERVICE,
    '', // props.uploadedDocument.name,
    onUploadCallback
  );

  const { onClicked: editFileClicked, isLoading: isLoadingEditFile } =
    useConversionFile(
      conversionAgGrid.handleLoad,
      props.sponsorPlanId,
      CONVERSION_TYPES.HISTORICAL_YEARS_OF_SERVICE,
      props.conversion?.documentId,
      columnDefs
    );

  const validationSchema = yearsOfServiceValidationSchema(props.sponsorPlanId);

  return (
    <>
      {props.conversion ? (
        <ConversionCardButton
          conversion={props.conversion}
          isLoading={isLoadingEditFile}
          onClick={() => {
            togglePlanActionDialog();
            editFileClicked();
          }}
          sponsorPlanId={props.sponsorPlanId}
        />
      ) : (
        <LoadingButton
          disabled={!props.sponsorPlanId || isLoading}
          loading={isLoading}
          onClick={togglePlanActionDialog}
          startIcon={<FileUploadOutlinedIcon />}>
          Upload
        </LoadingButton>
      )}
      <ConversionDialog
        fullWidth
        maxWidth='lg'
        onClose={togglePlanActionDialog}
        open={openPlanActionDialog}>
        <DialogTitle>
          <ConversionDialogHeader
            columnDefs={columnDefs}
            onUpload={conversionAgGrid.handleLoad}
            title='Historical Years Of Service'
          />
        </DialogTitle>
        <Stack direction='row' justifyContent='flex-end' mb={1}>
          <Stack direction='row' mr={2}>
            <WarningAmberIcon
              color='error'
              sx={{ marginRight: theme => theme.spacing(1) }}
            />
            {conversionAgGrid.errorsCount} Errors
          </Stack>
        </Stack>
        <DialogContent>
          <ActionTableForm
            emptyRowsCount={!props.conversion ? EMPTY_ROWS : undefined}
            initialValues={conversionAgGrid.gridRows}
            onChange={conversionAgGrid.setGridRows}
            onErrors={conversionAgGrid.handleErrors}
            onSubmit={conversionAgGrid.handleSubmit}
            ref={conversionAgGrid.form}
            validateOnChange
            validateOnMount
            validationSchema={validationSchema}>
            <FileUploadTable
              columnDefs={columnDefs}
              errors={conversionAgGrid.gridErrors}
              onCellChanged={conversionAgGrid.handleCellChange}
              onRowsChanged={conversionAgGrid.handleRowsChange}
              rowData={conversionAgGrid.gridRows}
            />
            {isLoadingEditFile && (
              <Box mt={2}>
                <LinearLoading />
              </Box>
            )}
          </ActionTableForm>
        </DialogContent>
        <DialogActions>
          <Button onClick={togglePlanActionDialog} variant='text'>
            Cancel
          </Button>
          {!props.conversion && (
            <LoadingButton
              disabled={
                conversionAgGrid.errorsCount > 0 ||
                conversionAgGrid.rowCount === 0
              }
              loading={isLoading}
              onClick={toggleOpenConfirmationDialog}
              variant='contained'>
              Submit
            </LoadingButton>
          )}
        </DialogActions>
      </ConversionDialog>

      <Dialog
        onClose={toggleOpenConfirmationDialog}
        open={openConfirmationDialog}>
        <DialogTitle>Submit & overwrite existing years of service</DialogTitle>
        <DialogContent>
          <DialogContentText
            sx={{ color: theme => theme.palette.text.primary }}>
            Continuing will overwrite any existing years of service stored for
            participants. Are you sure you want to proceed?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={toggleOpenConfirmationDialog}>BACK TO EDIT</Button>
          <LoadingButton
            loading={isLoading}
            onClick={() => {
              uploadYearsOfService({
                results: conversionAgGrid.getDataForUpload({
                  planId: props.sponsorPlanId
                })
              });
            }}>
            SUBMIT
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
};
