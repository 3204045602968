import Card, {
  CardContent,
  CardHeader,
  CardPlaceholder
} from '@/components/card';
import {
  DataTable,
  DataTableBadgeCell,
  DataTableMenuCell,
  DataTableProps,
  DataTableStackCell
} from '@/components/data-table/DataTable.component';
import ViewChildrenIcon from '@/components/icon/ViewChildren';
import { useSnackbar } from '@/contexts/SnackBarContext';
import { useUrlState } from '@/hooks/useUrlState.hook';
import { OrderByDirection } from '@/models/suba/common/OrderByDirection.model';
import { orderStatusColorMap } from '@/models/suba/common/OrderStatusColorMap.model';
import { ParentAccountOrderDto } from '@/models/suba/parent-account-orders/ParentAccountOrderDTO.model';
import { ParentAccountOrderSearchRequest } from '@/models/suba/parent-account-orders/ParentAccountOrderSearchRequest.model';
import { FeatureLevelPermissions } from '@/models/UserPermissions.model';
import { ParentAccountOrderDetailCellRenderer } from '@/routes/suba/accounts/common/components/ParentAccountOrderDetailCellRenderer.component';
import { DeferParentAccountOrderDialog } from '@/routes/suba/common/components/dialogs/DeferParentAccountOrderDialog.component';
import { ExecuteParentAccountOrderDialog } from '@/routes/suba/common/components/dialogs/ExecuteParentAccountOrderDialog.component';
import { ResubmitParentAccountOrderDialog } from '@/routes/suba/common/components/dialogs/ResubmitParentAccountOrderDialog.component';
import ParentAccountOrderService from '@/services/suba/parent-account-orders/ParentAccountOrder.service';
import { userService } from '@/services/User.service';
import formatters from '@/utils/Formatters';
import SearchIcon from '@mui/icons-material/Search';
import { IconButton, MenuItem, Stack, Tooltip } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { SecurityOrderType } from '@vestwell-sub-accounting/models/common/SecurityOrderType';
import { TradeType } from '@vestwell-sub-accounting/models/common/TradeType';
import { OrderStatus } from '@vestwell-sub-accounting/models/orderManagement/OrderStatus';

import { ColDef } from 'ag-grid-community';
import dayjs from 'dayjs';
import { Formik } from 'formik';
import { FC, useCallback, useMemo, useState } from 'react';

import {
  ParentAccountOrdersFiltersForm,
  ParentAccountOrdersFiltersFormValues
} from './ParentAccountOrdersFiltersForm.component';

export type ParentAccountOrdersTabUrlState = Pick<
  ParentAccountOrderSearchRequest,
  | 'orderBy'
  | 'orderByDirection'
  | 'page'
  | 'pageSize'
  | keyof ParentAccountOrdersFiltersFormValues
>;

type ParentAccountOrdersTabProps = {
  parentAccountId?: string;
  hideFilters?: boolean;
  hideHeader?: boolean;
  hideActions?: boolean;
  searchParams?: Partial<ParentAccountOrderSearchRequest>;
};

export const ParentAccountOrdersTab: FC<ParentAccountOrdersTabProps> = ({
  hideFilters = false,
  hideHeader = false,
  hideActions = false,
  ...props
}) => {
  // context

  const { showSnackbar } = useSnackbar();

  // state

  const [selectedParentAccountOrder, setSelectedParentAccountOrder] =
    useState<ParentAccountOrderDto>();
  const [openDeferDialog, setOpenDeferDialog] = useState(false);
  const [openResubmitDialog, setOpenResubmitDialog] = useState(false);
  const [openExecuteDialog, setOpenExecuteDialog] = useState(false);

  const [urlState, setUrlState] = useUrlState<ParentAccountOrdersTabUrlState>(
    {
      endDate: hideFilters ? undefined : dayjs().format('YYYY-MM-DD'),
      page: 1,
      pageSize: 25,
      startDate: hideFilters
        ? undefined
        : dayjs().subtract(1, 'month').format('YYYY-MM-DD'),
      ...(props.searchParams || {}) // overwrites the above defaults
    },
    {
      arrayParamKeys: ['orderStatus', 'tradeType'],
      parsedValueTypes: {
        cusip: 'string',
        endDate: 'string',
        omsBatchId: 'string',
        orderBy: 'string',
        orderByDirection: 'string',
        orderStatus: OrderStatus,
        ourParentOrderId: 'string',
        page: 'number',
        pageSize: 'number',
        startDate: 'string',
        tradeType: TradeType
      }
    }
  );

  // api

  const searchParentAccountOrdersQuery = useQuery(
    ['ParentAccountOrderService.search', props.parentAccountId, urlState],
    () => {
      return ParentAccountOrderService.search({
        ...urlState,
        parentAccountId: props.parentAccountId
      });
    },
    {
      keepPreviousData: true,
      onError: (err: any) => {
        showSnackbar({
          message: `Parent Account Order search failed: ${err?.message}`,
          severity: 'error'
        });
      }
    }
  );

  // callbacks

  const handlePageChanged = useCallback<DataTableProps['onPageChanged']>(
    newPage => {
      setUrlState(prevUrlState => ({
        ...prevUrlState,
        page: newPage
      }));
    },
    []
  );

  const handlePageSizeChanged = useCallback<
    DataTableProps['onPageSizeChanged']
  >(newPageSize => {
    setUrlState(prevUrlState => ({
      ...prevUrlState,
      page: 1,
      pageSize: newPageSize
    }));
  }, []);

  const handleSortChanged = useCallback<DataTableProps['onSortChanged']>(
    newSort => {
      setUrlState(prevUrlState =>
        !newSort || newSort.length === 0
          ? {
              ...prevUrlState,
              orderBy: undefined,
              orderByDirection: undefined
            }
          : {
              ...prevUrlState,
              orderBy: newSort[0].colId as keyof ParentAccountOrderDto,
              orderByDirection: newSort[0].sort as OrderByDirection
            }
      );
    },
    []
  );

  // memos

  const columnDefs = useMemo<ColDef[]>(
    () => [
      {
        autoHeight: true,
        cellRenderer: (cellData: { data: ParentAccountOrderDto }) => {
          return (
            <Tooltip title='View Sub Account Orders'>
              <IconButton
                href={`/ops/parent-account-orders/${cellData.data.id}`}
                size='small'
                target='_blank'>
                <ViewChildrenIcon sx={{ fontSize: 24 }} />
              </IconButton>
            </Tooltip>
          );
        },
        cellStyle: { paddingRight: 0 },
        field: 'id',
        headerName: '',
        suppressColumnsToolPanel: true,
        width: 85
      },
      {
        autoHeight: true,
        cellRenderer: (cellData: { data: ParentAccountOrderDto }) => (
          <>
            {!hideActions &&
              userService.hasPermission(
                FeatureLevelPermissions.WRITE_SUBA_TRANSACTIONS
              ) && (
                <DataTableMenuCell>
                  {cellData.data.orderStatus === OrderStatus.Rejected && (
                    <MenuItem
                      onClick={() => {
                        setSelectedParentAccountOrder(cellData.data);
                        setOpenDeferDialog(true);
                      }}>
                      Defer
                    </MenuItem>
                  )}
                  {cellData.data.orderStatus === OrderStatus.Rejected && (
                    <MenuItem
                      onClick={() => {
                        setSelectedParentAccountOrder(cellData.data);
                        setOpenResubmitDialog(true);
                      }}>
                      Resubmit Order
                    </MenuItem>
                  )}
                  {[
                    OrderStatus.Rejected,
                    OrderStatus.Accepted,
                    OrderStatus.Submitted
                  ].includes(cellData.data.orderStatus) && (
                    <MenuItem
                      onClick={() => {
                        setSelectedParentAccountOrder(cellData.data);
                        setOpenExecuteDialog(true);
                      }}>
                      Manually Execute
                    </MenuItem>
                  )}
                </DataTableMenuCell>
              )}
          </>
        ),
        cellStyle: { paddingLeft: 0, paddingRight: 0 },
        field: 'id',
        headerName: '',
        suppressColumnsToolPanel: true,
        width: 45
      },
      {
        autoHeight: true,
        cellRenderer: (cellData: { data: ParentAccountOrderDto }) => {
          return (
            <DataTableStackCell
              primary={formatters.formatSecurityName(
                cellData.data.security?.symbol,
                cellData.data.security?.cusip
              )}
              secondary={cellData.data.security?.description}
            />
          );
        },
        field: 'security.symbol',
        headerName: 'Security'
      },
      {
        autoHeight: true,
        field: 'tradeType',
        headerName: 'Trade Type',
        sortable: true,
        valueFormatter: ({ value }: { value: string }) => {
          // fetch friendly display name
          const displayTradeType = formatters.getValueKey(TradeType, value);
          return formatters.displayCase(displayTradeType);
        }
      },
      {
        autoHeight: true,
        cellRenderer: (cellData: { data: ParentAccountOrderDto }) => {
          const displayOrderStatus = formatters.getValueKey(
            OrderStatus,
            cellData.data.orderStatus
          );
          return (
            <>
              {cellData.data.orderStatus && (
                <DataTableBadgeCell
                  color={orderStatusColorMap[cellData.data.orderStatus]}>
                  {formatters.displayCase(displayOrderStatus)}
                </DataTableBadgeCell>
              )}
            </>
          );
        },
        field: 'orderStatus',
        headerName: 'Status',
        sortable: true
      },
      {
        autoHeight: true,
        field: 'securityOrderType',
        headerName: 'Order Type',
        sortable: true,
        valueFormatter: ({ value }: { value: string }) => {
          // fetch friendly display name
          const displaySecurityOrderType = formatters.snakeToCamelCase(
            formatters.getValueKey(SecurityOrderType, value)
          ); // snake case to camel case is necessary until the SecurityOrderType enum has its keys updated to camel case to match the convention
          return formatters.displayCase(displaySecurityOrderType);
        }
      },
      {
        autoHeight: true,
        cellRenderer: (cellData: { data: ParentAccountOrderDto }) => {
          let displayUnitsAmount = '';
          if (
            [
              SecurityOrderType.DOLLAR_ALL,
              SecurityOrderType.DOLLAR_ORDER
            ].includes(cellData.data.securityOrderType)
          ) {
            displayUnitsAmount = cellData.data.orderAmount
              ? formatters.formatDollars(cellData.data.orderAmount)
              : '';
          } else {
            displayUnitsAmount = cellData.data.orderUnits
              ? formatters.formatDecimal(cellData.data.orderUnits, 3)
              : '';
          }
          return displayUnitsAmount;
        },
        field: 'orderUnits',
        headerName: 'Units/Amount'
      },
      {
        field: 'tradeDate',
        headerName: 'Trade Date',
        sortable: true,
        valueFormatter: ({ value }: { value: string }) =>
          value ? formatters.formatFromIsoDateCustom(value, 'MM/DD/YYYY') : ''
      }
    ],
    []
  );

  const DetailCellRenderer = useMemo(
    () => ParentAccountOrderDetailCellRenderer,
    []
  );

  const FiltersFormFormik = useMemo(
    () => (
      <Formik<ParentAccountOrdersFiltersFormValues>
        initialValues={{
          cusip: urlState.cusip,
          endDate: urlState.endDate,
          omsBatchId: urlState.omsBatchId || '',
          orderStatus: urlState.orderStatus || [],
          ourParentOrderId: urlState.ourParentOrderId || '',
          startDate: urlState.startDate,
          tradeType: urlState.tradeType || []
        }}
        onSubmit={values => {
          setUrlState(prevUrlState => ({
            ...prevUrlState,
            ...values,
            endDate: values.endDate || null,
            page: 1,
            startDate: values.startDate || null
          }));
        }}>
        <ParentAccountOrdersFiltersForm />
      </Formik>
    ),
    []
  );

  return (
    <>
      {selectedParentAccountOrder && (
        <DeferParentAccountOrderDialog
          onClose={() => {
            setOpenDeferDialog(false);
            setSelectedParentAccountOrder(undefined);
          }}
          open={openDeferDialog}
          parentAccountOrder={selectedParentAccountOrder}
        />
      )}
      {selectedParentAccountOrder && (
        <ResubmitParentAccountOrderDialog
          onClose={() => {
            setOpenResubmitDialog(false);
            setSelectedParentAccountOrder(undefined);
          }}
          open={openResubmitDialog}
          parentAccountOrder={selectedParentAccountOrder}
        />
      )}
      {selectedParentAccountOrder && (
        <ExecuteParentAccountOrderDialog
          onClose={() => {
            setOpenExecuteDialog(false);
            setSelectedParentAccountOrder(undefined);
          }}
          open={openExecuteDialog}
          parentAccountOrder={selectedParentAccountOrder}
        />
      )}
      <Card>
        {!hideHeader && (
          <CardHeader
            data-testid='parent-account-order-header'
            loading={
              !!searchParentAccountOrdersQuery.data?.results?.length &&
              searchParentAccountOrdersQuery.isFetching
            }
            title='Parent Account Orders'
          />
        )}
        <CardContent
          disablePadding
          overlayLoading={searchParentAccountOrdersQuery.isInitialLoading}>
          <DataTable
            columnDefs={columnDefs}
            columnSizing='flex'
            data-testid='data-parent-account-orders-table'
            detailCellRenderer={DetailCellRenderer}
            emptyPlaceholderComponent={
              <Stack
                alignItems='center'
                data-testid='no-data-parent-account-orders-table'
                justifyContent='center'
                sx={{ height: '100%' }}>
                <CardPlaceholder
                  icon={<SearchIcon fontSize='inherit' />}
                  subtitle='No results found.'
                />
              </Stack>
            }
            filterSidePanelComponent={!hideFilters && FiltersFormFormik}
            onPageChanged={handlePageChanged}
            onPageSizeChanged={handlePageSizeChanged}
            onSortChanged={handleSortChanged}
            page={urlState.page}
            pageSize={urlState.pageSize}
            pagination
            paginationSource='server'
            paginationTotal={
              searchParentAccountOrdersQuery.data?.pagination?.total
            }
            rowData={searchParentAccountOrdersQuery.data?.results}
            sort={
              urlState.orderBy
                ? [
                    {
                      colId: urlState.orderBy,
                      sort: urlState.orderByDirection
                    }
                  ]
                : []
            }
          />
        </CardContent>
      </Card>
    </>
  );
};
