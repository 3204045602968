import DataTable, {
  DataTableProps
} from '@/components/data-table/DataTable.component';
import { PlanFunds, Program } from '@/services/Investment.service';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { IconButton, Stack, styled, Typography, useTheme } from '@mui/material';

import { FC, useMemo } from 'react';

import { InvestmentAutocomplete } from './InvestmentAutocomplete.component';
import { MappingStackCell } from './MappingStackCell.component';

const MappingCellRenderer = (props: {
  value: { modelId: number; name: string; modelType: string };
}) => {
  if (!props.value?.name) {
    return null;
  }

  return <MappingStackCell {...props.value} />;
};

const StyledArrowForwardIcon = styled(ArrowForwardIcon)(({ theme }) => ({
  alignSelf: 'center',
  color: 'rgba(0,0,0,0.26)',
  display: 'flex',
  fontSize: theme.spacing(3),
  justifySelf: 'center'
}));

const StyledIconButton = styled(IconButton)(() => ({
  color: 'rgba(0,0,0,0.54)'
}));

type ProgramHeaderCellProps = {
  id: number;
  name: string;
};

const ProgramHeaderCell = (props: ProgramHeaderCellProps) => {
  const theme = useTheme();
  return (
    <Stack py={1}>
      <Typography
        color='rgba(0,0,0,0.87)'
        fontWeight='bold'
        lineHeight={theme.spacing(3)}
        variant='body2'>
        {props.name}
      </Typography>
      <Typography color='rgba(0,0,0,0.87)' variant='body2'>
        ID: {props.id}
      </Typography>
    </Stack>
  );
};

const formatOptions = (
  program: Program
): {
  cusip?: string;
  group?: string;
  id: string;
  modelId?: number;
  modelType?: string;
}[] => {
  const ms_type = program.externalProviders.find(
    i => 'ms_rp' === i.investmentType || 'ms_ma' === i.investmentType
  )?.investmentType;

  return [
    ...(program.targetSeries
      ? [
          {
            hierarchy: ['Target Date Series'],
            id: 'target',
            name: program.targetSeries.name
          },
          ...program.targetModels.map(targetDateFund => ({
            group: program.targetSeries?.name,
            hierarchy: ['Target Date Series', targetDateFund.targetModelId],
            id: `target-${targetDateFund.targetModelId}`,
            modelId: targetDateFund.targetModelId,
            modelType: 'target',
            name: targetDateFund.modelName
          }))
        ]
      : []),
    ...(program.riskSeries
      ? [
          { hierarchy: ['Risk Model'], id: 'risk' },
          ...program.riskModels.map(riskModel => ({
            group: program.riskSeries.name,
            hierarchy: ['Risk Model', riskModel.riskModelId],
            id: `risk-${riskModel.riskModelId}`,
            modelId: riskModel.riskModelId,
            modelType: 'risk',
            name: riskModel.modelName
          }))
        ]
      : []),
    ...(program.nliSeries
      ? [
          {
            hierarchy: ['TIAA NLI'],
            id: 'nli',
            name: program.nliSeries.name
          },
          ...program.nliModels.map(targetDateFund => ({
            group: program.nliSeries?.name,
            hierarchy: ['TIAA NLI', targetDateFund.targetModelId],
            id: `nli-${targetDateFund.targetModelId}`,
            modelId: targetDateFund.targetModelId,
            modelType: 'nli',
            name: targetDateFund.modelName
          }))
        ]
      : []),
    (program.goalSeries || ms_type) && {
      hierarchy: ['Managed Accounts'],
      id: 'goal'
    },
    ms_type && {
      group: 'Managed Accounts',
      hierarchy: ['Managed Accounts', ms_type],
      id: ms_type,
      modelType: ms_type,
      name: '401(k) Manager'
    },
    ...(program.goalSeries
      ? program.goalSeries.goePortfolioIds.map(goePortfolioId => {
          return {
            group: 'Managed Accounts',
            hierarchy: ['Managed Accounts', goePortfolioId],
            id: `goal-${goePortfolioId}`,
            modelId: goePortfolioId,
            modelType: 'goal',
            name: program.goalSeries.name
          };
        })
      : []),
    ...(program.fundLineup
      ? [
          { hierarchy: ['Fund Lineup'], id: 'pmenu' },
          ...program.fundLineup.fundLineupFunds.map(fundLineupFund => {
            return {
              broadAssetClass: fundLineupFund.broadAssetClass,
              cusip: fundLineupFund.cusip,
              expenseRatio: fundLineupFund.expenseRatio,
              group: program.fundLineup.name,
              hierarchy: ['Fund Lineup', fundLineupFund.cusip],
              id: `pmenu-${fundLineupFund.cusip}`,
              modelType: 'pmenu',
              name: fundLineupFund.fundName,
              symbol: fundLineupFund.symbol
            };
          })
        ]
      : [])
  ].filter(Boolean);
};

const findOption = (
  modelId: number,
  options,
  modelType?: string,
  cusip?: string,
  current?: boolean
) => {
  return options.find(option => {
    if (cusip) {
      return option.cusip === cusip;
    }
    switch (modelType) {
      case 'ms_ma':
        return option[current ? 'oldType' : 'modelType'] === 'ms_ma';
      case 'ms_rp':
        return option[current ? 'oldType' : 'modelType'] === 'ms_rp';
      case 'goal':
        return (
          option[current ? 'oldType' : 'modelType'] === 'goal' &&
          modelId === option[current ? 'oldPortfolioId' : 'modelId']
        );
      case 'pmenu':
        return option.oldType === 'pmenu';
      default:
        return (
          modelId === option[current ? 'oldModelId' : 'modelId'] &&
          modelType === option[current ? 'oldType' : 'modelType']
        );
    }
  });
};

type ReenrollMappingTableProps = Omit<PlanFunds, 'name'> &
  Pick<DataTableProps, 'context'> & {
    isConfirming: boolean;
  };

export const ReenrollMappingTable: FC<ReenrollMappingTableProps> = props => {
  const theme = useTheme();

  const autoGroupColumnDef = useMemo(() => {
    return {
      autoHeaderHeight: true,
      autoHeight: true,
      cellRendererParams: {
        suppressCount: true
      },
      cellRendererSelector: params => {
        return {
          component: !params.node.parent.key
            ? 'agGroupCellRenderer'
            : () => <MappingStackCell {...params.node.data} />
        };
      },
      cellStyle: params => {
        return !params.node.parent.key
          ? { fontSize: theme.spacing(1.75) }
          : null;
      },
      flex: 1,
      headerComponent: () => {
        return (
          <ProgramHeaderCell
            id={props.currentProgram.programId}
            name={props.currentProgram.name}
          />
        );
      },
      headerName: props.currentProgram.name
    };
  }, [props.currentProgram]);

  const newProgramOptions = useMemo(() => {
    return formatOptions(props.newProgram);
  }, [props.newProgram]);

  const rowData = useMemo(() => {
    let data = formatOptions(props.currentProgram).map(item => {
      if (item.cusip) {
        const mappings =
          props.context.changes.find(({ oldType }) => oldType === 'pmenu')
            ?.mappings || [];

        const found = mappings.find(({ oldCusip }) => oldCusip === item.cusip);

        if (!found) {
          return { ...item, newProgram: null };
        }

        return {
          ...item,
          newProgram: newProgramOptions.find(({ cusip }) => {
            return cusip === found.newCusip;
          })
        };
      }

      const change = findOption(
        item.modelId,
        props.context.changes,
        item.modelType,
        null,
        true
      );

      if (change) {
        if (change.newType === 'default') {
          return {
            ...item,
            newProgram: {
              id: 'default',
              modelType: 'default',
              name: 'Default Election'
            }
          };
        }

        const found = findOption(
          change.newPortfolioId || change.newModelId,
          newProgramOptions,
          change.newType,
          change.newFund,
          false
        );

        return {
          ...item,
          newProgram: found
        };
      }
      return { ...item, newProgram: null };
    });

    if (props.isConfirming) {
      data = data.filter(item => {
        return ['target', 'risk', 'goal', 'pmenu'].includes(item.id)
          ? data.some(
              ({ modelType }) =>
                (item.id === 'goal'
                  ? modelType === item.id ||
                    modelType === 'ms_rp' ||
                    modelType === 'ms_ma'
                  : modelType === item.id) && item.newProgram
            )
          : !!item.newProgram;
      });
    }

    return data;
  }, [
    newProgramOptions,
    props.isConfirming,
    props.currentProgram,
    props.context.changes
  ]);

  const columnDefs = useMemo(() => {
    const cols = [
      {
        cellRendererSelector: params => {
          return {
            component: () =>
              !params.node.parent.key ? <div /> : <StyledArrowForwardIcon />
          };
        },
        headerComponent: () => <StyledArrowForwardIcon />,
        width: 61
      },
      {
        cellEditor: InvestmentAutocomplete,
        cellEditorParams: {
          refData: newProgramOptions.filter(data => {
            return !['target', 'risk', 'goal', 'pmenu', 'nli'].includes(
              data.id
            );
          })
        },
        cellRenderer: MappingCellRenderer,
        cellRendererSelector: params => {
          return {
            component: !params.node.parent.key
              ? () => <div />
              : params.value
                ? MappingCellRenderer
                : () => (
                    <Typography color='rgba(0,0,0,0.38)' variant='body1'>
                      Select...
                    </Typography>
                  )
          };
        },
        editable: params => !!params.node.parent?.key && !props.isConfirming,
        field: 'newProgram',
        flex: 1,
        headerComponent: () => {
          return (
            <ProgramHeaderCell
              id={props.newProgram.programId}
              name={props.newProgram.name}
            />
          );
        },
        headerName: props.newProgram.name
      },
      {
        cellRendererSelector: params => {
          if (!params.node.parent.key || !params.data.newProgram) {
            return { component: 'agGroupCellRenderer' };
          }

          const removeChange = () => {
            if (
              params.data.modelType === 'ms_rp' ||
              params.data.modelType === 'ms_ma'
            ) {
              return props.context.setChanges([
                ...props.context.changes.filter(
                  prevChange =>
                    prevChange.oldType !== 'ms_rp' &&
                    prevChange.oldType !== 'ms_ma'
                )
              ]);
            }

            if (params.data.modelType === 'goal') {
              return props.context.setChanges([
                ...props.context.changes.filter(
                  prevChange =>
                    prevChange.oldPortfolioId !== params.data.modelId
                )
              ]);
            }

            if (params.data.modelType === 'pmenu') {
              const pmenuChanges =
                props.context.changes.find(change => change.oldType === 'pmenu')
                  ?.mappings || [];

              if (pmenuChanges.length > 1) {
                return props.context.setChanges([
                  ...props.context.changes.map(change => {
                    if (change.oldType === 'pmenu') {
                      return {
                        ...change,
                        mappings: change.mappings.filter(
                          cusip => cusip.oldCusip !== params.data.cusip
                        )
                      };
                    }

                    return change;
                  })
                ]);
              }

              return props.context.setChanges([
                ...props.context.changes.filter(
                  prevChange => prevChange.oldType !== 'pmenu'
                )
              ]);
            }

            return props.context.setChanges([
              ...props.context.changes.filter(prevChange =>
                prevChange.oldType === params.data.modelType
                  ? prevChange.oldModelId !== params.data.modelId
                  : true
              )
            ]);
          };

          return {
            component: () => (
              <StyledIconButton
                aria-label={`Remove ${params.data.newProgram.name}`}
                onClick={removeChange}
                size='small'>
                <CloseOutlinedIcon />
              </StyledIconButton>
            )
          };
        },
        width: 66
      }
    ];

    return props.isConfirming ? cols.filter((col, index) => index !== 2) : cols;
  }, [props.context, props.isConfirming, props.newProgram, rowData]);

  return (
    <DataTable
      autoGroupColumnDef={autoGroupColumnDef}
      columnDefs={columnDefs}
      columnSizing='fit'
      context={props.context}
      defaultColDef={{
        resizable: false,
        suppressMenu: true,
        suppressMovable: true
      }}
      getDataPath={getDataPathData => getDataPathData.hierarchy}
      groupDefaultExpanded={-1}
      primaryKey='id'
      readOnlyEdit
      rowData={rowData}
      singleClickEdit
      stopEditingWhenCellsLoseFocus
    />
  );
};

ReenrollMappingTable.displayName = 'ReenrollMappingTable';
