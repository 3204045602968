import DOMInteraction from '@/utils/DOMInteraction';
import CheckIcon from '@mui/icons-material/Check';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { LoadingButton, LoadingButtonProps } from '@mui/lab';
import {
  Avatar,
  Badge,
  BadgeProps,
  Box,
  Button,
  Divider,
  IconButton,
  LinearProgress,
  Menu,
  MenuItem,
  Stack,
  SxProps,
  Theme,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
  useTheme
} from '@mui/material';
import MUICard from '@mui/material/Card';

import { kebabCase, omit } from 'lodash';
import _uniqueId from 'lodash/uniqueId';
import {
  createContext,
  CSSProperties,
  FunctionComponent,
  HTMLAttributes,
  MouseEvent,
  ReactElement,
  ReactNode,
  useState
} from 'react';

import CircularLoading from '../circular-loading';

/**
 * This context allows for the size to be passed down to the Card subcomponents.
 */
const CardSizeContext = createContext<'small' | 'medium'>('medium');

/** Internal use within CardHeader for displaying an optional toggle next to the title */
export interface CardToggleProps extends HTMLAttributes<HTMLBaseElement> {
  name?: string;
  'data-testid'?: string;
  options: {
    BadgeProps?: BadgeProps;
    'data-testid'?: string;
    icon?: ReactElement;
    value: any;
    label: string;
  }[];
  value: any;
  onChangeAction: (event: MouseEvent<HTMLElement>, value: any) => void;
}

const CardToggle: FunctionComponent<CardToggleProps> = (
  props: CardToggleProps
) => {
  const {
    'data-testid': testId,
    options = [],
    value,
    onChangeAction,
    name
  } = props;

  const [internalValue, setInternalValue] = useState(value);

  const internalOnChangeAction = (
    event: MouseEvent<HTMLElement>,
    newInternalValue: string
  ) => {
    if (newInternalValue !== null) {
      // non-null check to prevent entering into a state where no value is selected
      setInternalValue(newInternalValue);
      onChangeAction(event, newInternalValue);
    }
  };

  return (
    <Stack alignItems='center' direction='row' spacing={1}>
      {name && <Typography>{name}</Typography>}
      <ToggleButtonGroup
        color='primary'
        data-testid={testId}
        exclusive
        onChange={internalOnChangeAction}
        size='small'
        value={internalValue}>
        {options.map(option => {
          const label = (
            <Typography
              sx={{
                fontSize: 14,
                fontWeight: 500,
                lineHeight: '24px'
              }}>
              {option.label}
            </Typography>
          );

          const toggleButton = (
            <ToggleButton
              {...omit(option, ['BadgeProps'])}
              data-testid={
                testId
                  ? `${testId}-${kebabCase(option.label)}-toggle-button`
                  : `${kebabCase(option.label)}-toggle-button`
              }
              key={option.value}
              sx={{
                // to account for the 1px border
                px: 1.5,
                py: '5px'
              }}>
              {internalValue === option.value && (
                <Stack
                  sx={{
                    fontSize: 20,
                    lineHeight: '24px'
                  }}>
                  <CheckIcon fontSize='inherit' />
                </Stack>
              )}
              {option.icon ? (
                <Stack direction='row' spacing={1}>
                  {label}
                  {option.icon}
                  <Typography variant='overline'>5</Typography>
                </Stack>
              ) : (
                label
              )}
            </ToggleButton>
          );

          if (!option.BadgeProps) return toggleButton;

          return (
            <Badge
              color='primary'
              key={option.value}
              showZero
              slotProps={{
                badge: {
                  'data-testid': testId
                    ? `${testId}-${kebabCase(option.label)}-toggle-button-badge`
                    : `${kebabCase(option.label)}-toggle-button-badge`
                } as BadgeProps // override because data-testid is unrecognized but only propagated when defined like this
              }}
              {...option.BadgeProps}>
              {toggleButton}
            </Badge>
          );
        })}
      </ToggleButtonGroup>
    </Stack>
  );
};

/** Internal use within CardHeader for displaying an optional main action button on the right side of the header */
export interface CardMainActionButtonProps extends LoadingButtonProps {
  tooltipTitle?: string;
  'data-testid'?: string;
  label: string | ReactNode;
  onClick?: (event: MouseEvent<HTMLElement>) => void;
  disabled?: boolean;
  visible?: boolean;
  variant?: 'text' | 'outlined' | 'contained';
}

const CardMainActionButton: FunctionComponent<CardMainActionButtonProps> = (
  props: CardMainActionButtonProps
) => {
  const {
    tooltipTitle,
    'data-testid': testId,
    label,
    loading,
    onClick,
    disabled,
    visible = true,
    variant = 'outlined',
    ...restProps
  } = props;

  const button = (
    <LoadingButton
      data-testid={testId}
      disabled={disabled}
      loading={props.loading}
      loadingPosition='start'
      onClick={onClick}
      variant={variant}
      {...restProps}>
      {label}
    </LoadingButton>
  );

  return visible ? (
    tooltipTitle ? (
      <Tooltip title={tooltipTitle}>{button}</Tooltip>
    ) : (
      button
    )
  ) : (
    <></>
  );
};

/** Internal use within CardHeader for displaying an optional menu popover action button on the right side of the header */
interface CardMenuActionButtonProps extends HTMLAttributes<HTMLBaseElement> {
  'data-testid'?: string;
  options: { value: any; label: string }[];
  onClick: (value: any) => void;
  onOpen?: (event: MouseEvent<HTMLButtonElement>) => void;
  onClose?: () => void;
}

const CardMenuActionButton: FunctionComponent<CardMenuActionButtonProps> = (
  props: CardMenuActionButtonProps
) => {
  const {
    'data-testid': testId,
    options = [],
    onClick,
    onOpen,
    onClose
  } = props;

  const [id] = useState<string>(_uniqueId('card-menu-action-button-'));
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const internalOnOpen = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    if (typeof onOpen === 'function') {
      onOpen(event);
    }
  };
  const internalOnClose = () => {
    setAnchorEl(null);
    if (typeof onClose === 'function') {
      onClose();
    }
  };
  const internalOnClick = (value: any) => {
    if (typeof onClick === 'function') {
      onClick(value);
    }
  };

  return (
    <>
      <IconButton
        aria-controls={open ? 'basic-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup='true'
        aria-label='settings'
        data-testid={testId}
        id={id}
        onClick={internalOnOpen}
        sx={{ p: 0.75 }}>
        <MoreVertIcon />
      </IconButton>
      <Menu
        MenuListProps={{
          'aria-labelledby': id
        }}
        anchorEl={anchorEl}
        anchorOrigin={{
          horizontal: 'right',
          vertical: 'bottom'
        }}
        id='basic-menu'
        onClose={internalOnClose}
        open={open}
        sx={{
          '& .MuiPaper-root': {
            minWidth: 180
          }
        }}
        transformOrigin={{
          horizontal: 'right',
          vertical: 'top'
        }}>
        {options.map(option => (
          <MenuItem
            key={option.value}
            onClick={() => {
              internalOnClick(option.value);
              internalOnClose();
            }}>
            {option.label}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

interface CardHeaderProps extends HTMLAttributes<HTMLBaseElement> {
  'data-testid'?: string;
  title?: string;
  titleDecoration?: ReactElement;
  titleStyle?: CSSProperties;
  subtitle?: string;
  /**
   * Any icon component, prepended to the title.
   * Be sure to add fontSize="inherit" to the icon element for proper sizing and set desired color.
   * We can't add that for you because the icon component is memoized.
   */
  icon?: ReactElement;
  /**
   * While loading, overlay a linear progress bar at the bottom of the header.
   */
  loading?: boolean;
  /**
   * A CardToggle component to display to the right of the title.
   * @deprecated Use `toggles` instead.
   */
  toggle?: CardToggleProps;
  /**
   * Render one or more CardToggle components to display to the right of the title.
   * Badges may optionally be added.
   */
  toggles?: CardToggleProps[];
  /**
   * Optionally displays CardMainActionButton components on the right side of the header.
   * If a menuAction is provided, these buttons will be added to the left of that menu.
   */
  actionButtonsProps?: CardMainActionButtonProps[];
  /**
   * Optionally displays a CardMenuActionButton component on the right side of the header.
   */
  menuProps?: CardMenuActionButtonProps;
  /** TODO: To be used for popover display */
  // details?: ReactNode;

  className?: string;
  disableDivider?: boolean;
  justifyContent?: string;
}
export type { CardHeaderProps };

export const CardHeader: FunctionComponent<CardHeaderProps> = (
  props: CardHeaderProps
) => {
  return (
    <CardSizeContext.Consumer>
      {size => (
        <Box position='relative'>
          <Stack
            alignItems='center'
            className={props.className}
            data-testid={props['data-testid']}
            direction='row'
            justifyContent={props.justifyContent ?? 'space-between'}
            spacing={2}
            sx={{
              minHeight: {
                medium: '72px',
                small: '64px'
              }[size],
              pl: props.title ? 2 : 0,
              pr: props.menuProps ? 1 : 2,
              py: {
                medium: 1,
                small: 0.75
              }[size]
            }}>
            <Stack alignItems='center' direction='row' spacing={2}>
              {props.icon && (
                <Avatar
                  sx={{
                    fontSize: 40,
                    lineHeight: 1
                  }}>
                  {props.icon}
                </Avatar>
              )}
              <Stack>
                <Box display='flex' flexDirection='row' gap='15px'>
                  <Typography
                    component='h5'
                    id={`${kebabCase(props.title)}-heading`}
                    style={props.titleStyle}
                    sx={{
                      fontSize: { medium: 24, small: 20 }[size],
                      lineHeight: '32px'
                    }}>
                    {props.title}
                  </Typography>
                  {props.titleDecoration}
                </Box>

                {props.subtitle && (
                  <Typography
                    component='h6'
                    sx={{
                      fontSize: 14,
                      lineHeight: '20px'
                    }}>
                    {props.subtitle}
                  </Typography>
                )}
              </Stack>
              {props.toggle && (
                <CardToggle
                  {...props.toggle}
                  data-testid={
                    props['data-testid']
                      ? `${props['data-testid']}-toggle`
                      : undefined
                  }
                />
              )}
              {(props.toggles || []).map((toggle, index) => (
                <CardToggle
                  {...toggle}
                  data-testid={
                    props['data-testid'] || toggle['data-testid']
                      ? [props['data-testid'], toggle['data-testid'], 'toggle']
                          .filter(Boolean)
                          .join('-')
                      : undefined
                  }
                  key={index}
                />
              ))}
            </Stack>

            {(props.actionButtonsProps ||
              props.menuProps ||
              props.children) && (
              <Stack alignItems='center' direction='row' spacing={1}>
                {props.children}
                {props.actionButtonsProps &&
                  props.actionButtonsProps.map(actionButtonProps => {
                    return (
                      <CardMainActionButton
                        data-testid={
                          props['data-testid']
                            ? `${props['data-testid']}-main-action`
                            : undefined
                        }
                        key={
                          typeof actionButtonProps.label === 'string'
                            ? actionButtonProps.label
                            : DOMInteraction.JSXToTextContent(
                                actionButtonProps.label
                              )
                        }
                        {...actionButtonProps}
                      />
                    );
                  })}
                {props.menuProps && (
                  <CardMenuActionButton
                    data-testid={
                      props['data-testid']
                        ? `${props['data-testid']}-menu-action`
                        : undefined
                    }
                    {...props.menuProps}
                  />
                )}
              </Stack>
            )}
          </Stack>
          {props.loading && (
            <LinearProgress
              sx={{ bottom: 0, left: 0, position: 'absolute', right: 0 }}
            />
          )}
          {!props.disableDivider && (
            <Divider sx={{ borderColor: theme => theme.palette.grey[300] }} />
          )}
        </Box>
      )}
    </CardSizeContext.Consumer>
  );
};

interface CardPlaceholderProps extends HTMLAttributes<HTMLBaseElement> {
  'data-testid'?: string;
  /**
   * Any icon component.
   * Be sure to add fontSize="inherit" to the icon element for proper sizing and set desired color.
   * We can't add that for you because the icon component is memoized.
   */
  icon?: ReactElement;
  title?: string;
  subtitle?: string;
  /**
   * Content of the button shown below the possible icon, title, and/or subtitle.
   * You must also provide an onClickAction property for this to be rendered.
   */
  actionLabel?: ReactNode;
  /**
   * Action to be performed when the optional button is clicked.
   * You must also provide an actionLabel property for this to trigger the render of a button.
   */
  onClickAction?: () => void;
}
export type { CardPlaceholderProps };

export const CardPlaceholder: FunctionComponent<CardPlaceholderProps> = (
  props: CardPlaceholderProps
) => {
  const {
    'data-testid': testId,
    icon,
    title,
    subtitle,
    onClickAction,
    actionLabel,
    style
  } = props;

  return (
    <Stack
      alignItems='center'
      data-testid={testId}
      justifyContent='center'
      style={style}
      sx={{ minHeight: '119px', py: 4 }}>
      {icon && (
        <Stack
          sx={{
            color: theme => theme.palette.grey[300],
            fontSize: 42,
            mb: 1
          }}>
          {icon}
        </Stack>
      )}
      {title && <Typography variant='body1'>{title}</Typography>}
      {subtitle && (
        <Typography
          sx={{ color: theme => theme.palette.grey[700], mt: 0.25 }}
          variant='body2'>
          {subtitle}
        </Typography>
      )}
      {onClickAction && actionLabel && (
        <Button
          onClick={onClickAction}
          sx={{
            fontSize: 13,
            fontWeight: 500,
            lineHeight: '22px',
            mt: 1
          }}>
          {actionLabel}
        </Button>
      )}
    </Stack>
  );
};

interface CardContentProps extends HTMLAttributes<HTMLBaseElement> {
  children?: ReactNode;
  'data-testid'?: string;
  disablePadding?: boolean;
  /**
   * While loading, the content of the card will be unloaded and replaced with an overlay indicator.
   */
  loading?: boolean;
  /**
   * Optional alternative to loading prop to display the loading indicator as an overlay on top of the content.
   */
  overlayLoading?: boolean;
  sx?: SxProps<Theme>;
}
export type { CardContentProps };

export const CardContent: FunctionComponent<CardContentProps> = (
  props: CardContentProps
) => {
  const {
    'data-testid': testId,
    children,
    disablePadding = false,
    loading,
    overlayLoading,
    sx
  } = props;

  const theme = useTheme();

  return (
    <CardSizeContext.Consumer>
      {size => (
        <Box
          aria-busy={loading || overlayLoading}
          data-testid={testId}
          sx={{
            minHeight: overlayLoading ? '233px' : undefined,
            p: disablePadding === true ? 0 : 2,
            position: 'relative',
            zIndex: overlayLoading ? 1 : undefined, // ensures the overlay does not overlap parent or sibling elements of this card
            ...(typeof sx === 'function' ? sx(theme) : sx)
          }}>
          {(loading || overlayLoading) && (
            <Box
              sx={{
                background: overlayLoading ? '#fff' : undefined,
                bottom: overlayLoading ? 0 : undefined,
                left: overlayLoading ? 0 : undefined,
                position: overlayLoading ? 'absolute' : undefined,
                right: overlayLoading ? 0 : undefined,
                top: overlayLoading ? 0 : undefined,
                zIndex: overlayLoading ? 9999 : undefined
              }}>
              <Stack
                alignItems='center'
                justifyContent='center'
                sx={{
                  minHeight: '233px'
                }}>
                <CircularLoading size={size === 'small' ? '36px' : '40px'} />
              </Stack>
            </Box>
          )}
          {(!loading || overlayLoading) && children}
        </Box>
      )}
    </CardSizeContext.Consumer>
  );
};

interface CardProps extends HTMLAttributes<HTMLBaseElement> {
  children: ReactNode;
  'data-testid'?: string;
  minHeight?: string | number;
  minWidth?: string | number;
  size?: 'small' | 'medium';
  sx?: SxProps<Theme>;
}

export const Card: FunctionComponent<CardProps> = (props: CardProps) => {
  const { children, 'data-testid': testId, size = 'medium', sx } = props;

  const theme = useTheme();

  return (
    <CardSizeContext.Provider value={size}>
      <MUICard
        data-testid={testId}
        sx={{
          borderColor: theme.palette.grey[300],
          minHeight: props.minHeight,
          minWidth: {
            sm: props.minWidth || '360px'
          },
          width: {
            sm: 'auto',
            xs: '100%'
          },
          ...(typeof sx === 'function' ? sx(theme) : sx)
        }}
        variant='outlined'>
        {children}
      </MUICard>
    </CardSizeContext.Provider>
  );
};

export default Card;
export type { CardProps };
