import { useSnackbar } from '@/contexts/SnackBarContext';
import { useUserToken } from '@/contexts/UserTokenContext';
import { AlertSearchRequest } from '@/models/suba/alerts/AlertSearchRequest.model';
import AlertService from '@/services/suba/alerts/Alert.service';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';

import { AxiosError } from 'axios';

export const useSearchAlertTypeTotals = (
  query: AlertSearchRequest,
  options?: UseQueryOptions<
    Awaited<ReturnType<typeof AlertService.searchTypeTotals>>,
    AxiosError
  >
) => {
  const { showSnackbar } = useSnackbar();
  const { userHasValidToken } = useUserToken();

  return useQuery(
    ['AlertService.searchTypeTotals', { ...query, alertType: undefined }],
    () => AlertService.searchTypeTotals({ ...query, alertType: undefined }),
    {
      ...(options || {}),
      enabled: (options?.enabled && userHasValidToken) ?? userHasValidToken,
      keepPreviousData: options?.keepPreviousData ?? true,
      onError:
        options?.onError ??
        ((err: AxiosError) => {
          const message = err.response?.data ? err.response.data : err.message;
          showSnackbar({
            message: `Alert search type totals failed: ${message}`,
            severity: 'error'
          });
        })
    }
  );
};
