import AccessControl from '@/components/access-control/AccessControl.component';
import TextStack, {
  TextLabel,
  TextStackItem,
  TextValue
} from '@/components/text-stack';
import { useDialog } from '@/contexts/DialogContext';
import { useSnackbar } from '@/contexts/SnackBarContext';
import { LoanDetailsResponseDto } from '@/models';
import { LoanStatusEnum, UpdateLoanDto } from '@/models/LoanDTO.model';
import { FeatureLevelPermissions } from '@/models/UserPermissions.model';
import ParticipantService from '@/services/Participant.service';
import formatters from '@/utils/Formatters';
import { Box, Button, styled, Tooltip, Typography } from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { FC, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import * as yup from 'yup';

interface LoanFeeCardProps {
  loanDetails: LoanDetailsResponseDto;
}

type LoanFeeCardParams = {
  participantId: string;
  loanId: string;
};

const StyledTitle = styled(Typography)({
  fontSize: 15,
  fontWeight: 500
});

const LOAN_STATUSES = [
  LoanStatusEnum.DisbursementPending,
  LoanStatusEnum.Default,
  LoanStatusEnum.FraudCheckPending,
  LoanStatusEnum.FraudCheckComplete,
  LoanStatusEnum.Live,
  LoanStatusEnum.PastDue
];

export const FeeInfoStack: FC<LoanFeeCardProps> = (props: LoanFeeCardProps) => {
  const { participantId, loanId } = useParams<LoanFeeCardParams>();
  const { openDialog } = useDialog();
  const { showSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const updateLoanDetails = useMutation(
    ['ParticipantService.updateLoanFees', participantId, loanId],
    (dto: UpdateLoanDto) =>
      ParticipantService.updateLoan(+participantId, +loanId, dto),
    {
      onError: () => {
        showSnackbar({
          message: 'Error updating loan fees',
          severity: 'error'
        });
      },
      onSuccess: () => {
        queryClient.refetchQueries([
          'ParticipantService.getLoan',
          participantId,
          loanId,
          true
        ]);
        showSnackbar({
          message: 'Success!',
          severity: 'success'
        });
      }
    }
  );

  const loanStatus = useMemo(() => {
    return (
      Object.values(LoanStatusEnum).find(
        value => value === props.loanDetails.data.attributes.loanStatus
      ) ?? undefined
    );
  }, [props.loanDetails.data.attributes.loanStatus]);

  return (
    <TextStack direction='column'>
      <StyledTitle>Fees</StyledTitle>
      <TextStackItem>
        <TextLabel>Origination Fee</TextLabel>
        <TextValue data-testid='origination-fee'>
          {formatters.formatDollars(
            props.loanDetails.data.attributes.originationFee
          )}
        </TextValue>
      </TextStackItem>
      <TextStackItem>
        <TextLabel>Maintenance Fee</TextLabel>
        <TextValue data-testid='maintenance-fee'>
          {formatters.formatDollars(
            props.loanDetails.data.attributes.maintenanceFee
          )}
        </TextValue>
      </TextStackItem>
      {props.loanDetails.data.attributes.extraFee !== undefined && (
        <TextStackItem>
          <TextLabel>Delivery Fee</TextLabel>
          <TextValue data-testid='delivery-fee'>
            {formatters.formatDollars(
              props.loanDetails.data.attributes.extraFee
            )}
          </TextValue>
        </TextStackItem>
      )}

      {LOAN_STATUSES.includes(loanStatus) && (
        <AccessControl requires={[FeatureLevelPermissions.WRITE_LOANS_FEES]}>
          <Box>
            <Tooltip
              disableHoverListener={!!props.loanDetails.data.attributes.notes}
              title='A note is required before fees can be edited'>
              <span>
                <Button
                  disabled={!props.loanDetails.data.attributes.notes}
                  onClick={() => {
                    openDialog({
                      actionButtons: {
                        cancelButton: {
                          children: 'Cancel'
                        },
                        submitButton: {
                          children: 'Save'
                        }
                      },
                      onSubmit: async rawInputValues => {
                        updateLoanDetails.mutate({
                          attributes: {
                            extraFee: +rawInputValues.extraFee,
                            maintenanceFee: +rawInputValues.maintenanceFee,
                            originationFee: +rawInputValues.originationFee
                          },
                          id: +loanId
                        } as UpdateLoanDto);
                      },
                      steps: [
                        {
                          fields: {
                            ...(props.loanDetails.data.attributes
                              .maintenanceFee !== undefined
                              ? {
                                  maintenanceFee: {
                                    initialValue:
                                      props.loanDetails.data.attributes
                                        .maintenanceFee,
                                    label: 'Maintenance Fee'
                                  }
                                }
                              : {}),
                            ...(props.loanDetails.data.attributes
                              .originationFee !== undefined &&
                            [
                              LoanStatusEnum.FraudCheckPending,
                              LoanStatusEnum.FraudCheckComplete
                            ].includes(loanStatus)
                              ? {
                                  originationFee: {
                                    initialValue:
                                      props.loanDetails.data.attributes
                                        .originationFee,
                                    label: 'Origination Fee'
                                  }
                                }
                              : {}),
                            ...(props.loanDetails.data.attributes.extraFee !==
                            undefined
                              ? {
                                  extraFee: {
                                    initialValue:
                                      props.loanDetails.data.attributes
                                        .extraFee,
                                    label: 'Delivery Fee'
                                  }
                                }
                              : {})
                          },
                          title: 'Edit Fees'
                        }
                      ],
                      subcomponentProps: {
                        dialogContentProps: {
                          dividers: true
                        }
                      },
                      validationSchema: yup.object({
                        ...(props.loanDetails.data.attributes.maintenanceFee !==
                        undefined
                          ? {
                              maintenanceFee: yup
                                .number()
                                .typeError('Maintenance Fee must be numeric')
                                .min(
                                  0,
                                  'Maintenance Fee must be a positive number'
                                )
                                .max(100, 'Maximum Maintenance Fee is $100')
                                .required('Required')
                            }
                          : {}),
                        ...(props.loanDetails.data.attributes.originationFee !==
                          undefined &&
                        [
                          LoanStatusEnum.FraudCheckPending,
                          LoanStatusEnum.FraudCheckComplete
                        ].includes(loanStatus)
                          ? {
                              originationFee: yup
                                .number()
                                .typeError('Origination Fee must be numeric')
                                .min(
                                  0,
                                  'Origination Fee must be a positive number'
                                )
                                .max(200, 'Maximum Origination Fee is $200')
                                .required('Required')
                            }
                          : {}),
                        ...(props.loanDetails.data.attributes.extraFee !==
                        undefined
                          ? {
                              extraFee: yup
                                .number()
                                .typeError('Delivery Fee must be numeric')
                                .min(
                                  0,
                                  'Delivery Fee must be a positive number'
                                )
                                .max(100, 'Maximum Delivery Fee is $100')
                                .required('Required')
                            }
                          : {})
                      })
                    });
                  }}
                  variant='text'>
                  EDIT
                </Button>
              </span>
            </Tooltip>
          </Box>
        </AccessControl>
      )}
    </TextStack>
  );
};
