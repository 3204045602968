import { useSnackbar } from '@/contexts/SnackBarContext';
import { useUserToken } from '@/contexts/UserTokenContext';
import { SecurityService } from '@/services/suba/security/Security.service';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';

import { AxiosError } from 'axios';

export const GET_SECURITY_QUERY_KEY = 'SecurityService.get';

export const useGetSecurityQuery = (
  params: Parameters<typeof SecurityService.get>[0],
  options?: UseQueryOptions<
    Awaited<ReturnType<typeof SecurityService.get>>,
    AxiosError
  >
) => {
  const { showSnackbar } = useSnackbar();
  const { userHasValidToken } = useUserToken();

  return useQuery(
    [GET_SECURITY_QUERY_KEY, params],
    () => SecurityService.get(params),
    {
      ...(options || {}),
      enabled: (options?.enabled && userHasValidToken) ?? userHasValidToken,
      onError:
        options?.onError ??
        ((err: AxiosError) => {
          showSnackbar({
            message: `Failed to get security: ${err.response?.data ?? err.message}`,
            severity: 'error'
          });
        })
    }
  );
};
