import { EMPTY_FIELD_PLACEHOLDER } from '@/consts/formatting';
import {
  PLAN_DATES_TYPES,
  UNVERIFIED_PRIOR_PROVIDER_ID
} from '@/consts/plan.constants';
import {
  POOLED_PLAN_SUBTYPES,
  POOLED_PLAN_TYPES
} from '@/consts/pooled-plan.constants';
import { DocumentCategoryIds } from '@/consts/sponsor.constants';
import {
  ContributionCorrectionDto,
  ContributionDetailsDto,
  ContributionDto,
  ContributionFileContents,
  ContributionForTable,
  DocumentDto,
  InvestmentDto,
  LTPlan,
  PayrollFundingInfoDto,
  PayrollProviderDto,
  PayrollProviderInfo,
  PlanDate,
  PlanDesignDto,
  PlanPayrollSetupDto,
  PlanSalesforceInfo,
  PlanV2Dto,
  SubAccountingPlan,
  UpdatePlanDto,
  UploadedDocument
} from '@/models';
import { AuditLogsDto } from '@/models/AuditLogs.model';
import CashBalanceDto from '@/models/CashBalanceDTO.model';
import { ClientSuccessManagerDto } from '@/models/ClientSuccessManager.model';
import {
  Conversion,
  ConversionInvestmentsResponse,
  PageConversionResponse,
  RothDepositReportDto,
  UpdateConversionDto
} from '@/models/ConversionDTO.model';
import { CreateDocumentGroupingResponse } from '@/models/CreateDocumentGroupingResponse';
import { CreateDocumentGroupingWithDetailsPayload } from '@/models/CreateDocumentGroupingWithDetailsPayload.model';
import { UploadedDocData } from '@/models/DocumentDTO.model';
import {
  DocumentMetadataDto,
  DocumentMetadataDtoV2,
  DocumentMetadataListDtoV2
} from '@/models/DocumentMetadataDTO.model';
import { SendEmailResponseDto } from '@/models/EmailManagement.model';
import ForfeitureEventDto, {
  ForfeitureEvent
} from '@/models/ForfeitureEventsDTO.model';
import { ForfeiturePreferencesDto } from '@/models/ForfeiturePreferencesDTO.model';
import ForfeitureTrackerDto from '@/models/ForfeitureTrackerDTO.model';
import {
  LoanBriefInfo,
  LoanIdsPerPlanDto,
  LoanResponseDto
} from '@/models/LoanDTO.model';
import { DuplicateParticipantsDto } from '@/models/MergeParticipantsDto.model';
import {
  BillingFeeDto,
  BillingPeriod,
  BillingPeriodDetails,
  BillingValidationResponse,
  CreateBillingPeriodDto,
  UpdateBillingPeriodDto
} from '@/models/ops/fees/BillingDTO.model';
import { DeferralRatesParticipants } from '@/models/ParticipantDTO.model';
import { ParticipantEligibilityReportData } from '@/models/ParticipantEligibilityReportData.model';
import { PartnerSystemMappingDto } from '@/models/PartnerSystem.model';
import {
  GetCommunicationsParams,
  GetPlanParticipantsMailerLettersParams,
  PlanCommunications,
  PlanParticipantsMailerLetters
} from '@/models/PlanCommunicationsDTO.model';
import {
  DeconversionDestinationResponse,
  DeconversionRequestDto,
  DisbursementInstructionsDto,
  PlanDeconversionResponse,
  UpdateDeconversionDto
} from '@/models/PlanDeconversionDTO.model';
import { PlanDesign } from '@/models/PlanDesign.model';
import PlanDocumentCategoryGroup, {
  UpdatePlanDetail
} from '@/models/PlanDocumentCategoryGroupDTO.model';
import { PlanFeaturesDto } from '@/models/PlanFeaturesDto.model';
import {
  ParticipantWithWelcomeEmail,
  PlanParticipantsDto,
  PlanParticipantsInfo,
  PlanParticipantsSimpleDto
} from '@/models/PlanParticipantsDTO.model';
import { PlanStat } from '@/models/PlanStats.model';
import { AllPlanStatsDto } from '@/models/PlanStatsDTO.model';
import {
  SponsorPlanInfoDto,
  StatefulSchemaTrackingState,
  StatefulSchemaUpdateResponse
} from '@/models/PlanV2DTO.model';
import { PooledPlan, PooledPlanDto } from '@/models/PooledPlanDTO.model';
import {
  FormattedRolloverDto,
  RolloverListDto
} from '@/models/RolloversDTO.model';
import {
  ScheduledChange,
  ScheduledChangesDTO
} from '@/models/ScheduledChangesDTO.model';
import { SubaDepositTransactionResponse } from '@/models/suba/deposits/SubaDepositTransactionResponse.model';
import { UpdatePlanPayload } from '@/models/UpdatePlanPayload.model';
import { FormattedWithdrawalDto } from '@/models/WithdrawalsDTO.model';
import {
  DocumentTestResultResponse,
  PlanTestResultDto,
  PlanYearConfiguration,
  PostPlanYearConfigResponse,
  ProcessTransitionResponse,
  TestExecutionResultsDto,
  YearEndContributionResultsDto,
  YearEndTestingDto
} from '@/models/YearEndTestingDTO.model';
import { FundMappings } from '@/routes/plans/plan-detail/PlanActionTableV2/InvestmentElectionMapping';
import { DocumentDefinitionConfig } from '@/routes/plans/plan-detail/PlanDocumentsTab/UploadPlanDialog.component';
import { PlanFilterType } from '@/routes/plans/plans/consts';
import ApiService from '@/services/Api.service';
import { ProgramPlansListDto } from '@/services/ops/investments/Program.service';
import PayrollIntegrationsService from '@/services/payroll-integrations.service';
import formatters from '@/utils/Formatters';
import { DocumentBodyDto } from '@doc/api';
import type { PartnerSystem } from '@vestwell-api/scala';
import { Dates, Payroll, Plans } from '@vestwell-api/scala';

import dayjs from 'dayjs';
import {
  PatchTpaContact,
  PayrollSetupCreateRequest,
  PayrollSetupResponse,
  PostTpaContact,
  SubaDetailsTransactionsResponse,
  SubaPlanDetailsResponse,
  TpaContact
} from 'scala-sdk';
import * as yup from 'yup';

// TODO(mbildner): what should this type be?
interface Plan {
  id: number;
  name: string;
  relationship: string;
  alert: string;
  nextMilestone: string;
  generalPlanInfo: { planName: string };

  // TODO(mbildner): make this field required and move to SponsorPlan type
  // that matches the incoming DTO.
  planName?: string;
}

const EditPlanSchema = yup.object({
  advisorId: yup.number().required(),
  companyName: yup.string().required(),
  newTpaId: yup.number().when('oldTpaId', {
    is: true,
    otherwise: yup.number().optional(),
    then: yup.number().required()
  }),
  oldTpaId: yup.number().optional(),
  planConversionType: yup.string().required(),
  planId: yup.number().required(),
  planTier: yup.number().required(),
  planType: yup
    .string()
    .oneOf(['401k', 'Solo 401k', '403b', 'Starter 401k'])
    .required(),
  priorProviderId: yup
    .mixed()
    .test('valid-number-or-empty', 'Must be a number or empty', value => {
      if (value === undefined || value === '') {
        return true;
      }

      return yup
        .number()
        .required('Prior Provider ID must be a valid number')
        .isValidSync(value);
    }),
  priorProviderUnverified: yup.string().when('priorProviderId', {
    is: UNVERIFIED_PRIOR_PROVIDER_ID,
    otherwise: schema => schema.optional(),
    then: schema => schema.required('Required')
  })
});

const CreatePlanSchema = yup.object({
  advisorId: yup.number().required(),
  companyName: yup.string().required(),
  isConvertedPlan: yup.string().oneOf(['true', 'false']).required(),
  opportunityId: yup.string().required(),
  planTier: yup.number().required(),
  planType: yup
    .string()
    .oneOf(['401k', 'Solo 401k', '403b', 'Starter 401k'])
    .required(),
  // moshe: consolidate this logic
  priorProviderId: yup.number().when('isConvertedPlan', {
    is: 'true',
    otherwise: schema => schema.optional(),
    then: schema =>
      schema.required('Prior Provider is required for conversion plans')
  }),
  tpaId: yup.number(),
  tpaSubtypeId: yup.number()
});

const CreateAdopterPlanSchema = CreatePlanSchema.shape({
  advisorId: CreatePlanSchema.fields.advisorId.optional(),
  pooledPlanId: yup.number().required()
}).omit(['tpaId']);

const CreatePooledPlanSchema = yup.object({
  opportunityId: yup.string().required(),
  pooledPlanDefault: yup
    .object({
      advisorId: yup.string().required(),
      thirdPartyAdministratorId: yup.number().required()
    })
    .required(),
  pooledPlanName: yup.string().required(),
  pooledPlanSubType: yup.string().when('pooledPlanType', {
    is: 'mep',
    then: schema => schema.oneOf(POOLED_PLAN_SUBTYPES).required()
  }),
  pooledPlanType: yup.string().oneOf(POOLED_PLAN_TYPES).required()
});

type CreatePlanDto = yup.InferType<typeof CreatePlanSchema>;

type CreateAdopterPlanDto = yup.InferType<typeof CreateAdopterPlanSchema>;

type EditPlanDto = yup.InferType<typeof EditPlanSchema>;

type PlanCreatedDto = {
  sponsorPlanId: number;
  sponsorId: number;
};

type PlanEditedDto = PlanCreatedDto;

type CreatePooledPlanDto = yup.InferType<typeof CreatePooledPlanSchema>;

type GetParticipantsWithLatestWelcomeEmailsParams = {
  sponsorPlanId: string | number;
  params: {
    pageNumber?: number;
    pageSize?: number;
    search?: string;
    registrationStatus?: string;
    welcomeEmailStatus?: string;
  };
};

// TODO(mbildner): this service has no unit tests
class PlanService {
  static pageSize = 100;

  static async getOnboardingPlans(): Promise<Plan[]> {
    return ApiService.getJson(`/plan/onboarding/list`) as any;
  }

  static async updatePooledPlanDesignDefaults(
    pooledPlanId: number | string,
    pooledPlanDesign: PlanDesign,
    isConfirmed: boolean
  ): Promise<PlanDesign> {
    return ApiService.patchJson(
      `pooled-plan/${pooledPlanId}/plan-design?confirmed=${isConfirmed}`,
      pooledPlanDesign
    );
  }

  static async createOnboardingPlan(
    createPlanDto: CreatePlanDto
  ): Promise<PlanCreatedDto> {
    return ApiService.postJson('/plan/onboarding', createPlanDto);
  }

  static async createAdopterOnboardingPlan(
    createAdopterOnboardingPlan: CreateAdopterPlanDto
  ): Promise<PlanCreatedDto> {
    return ApiService.postJson(
      '/plan/onboard/adopter',
      createAdopterOnboardingPlan
    );
  }

  static async getPlansPage(params: {
    pageNumber?: number;
    pageSize?: number;
    search?: string;
    filterType?: PlanFilterType;
    firmIds?: number[];
    recordkeepersIds?: number[];
    adminStatuses?: string[];
    planTypes?: string[];
    pooledPlanId?: number;
    sortBy?: string;
    sortDirection?: 'asc' | 'desc';
  }): Promise<SponsorPlanInfoDto> {
    return ApiService.getJson('/plan', {
      adminStatuses: params.adminStatuses ?? [],
      filterType: params.filterType ?? PlanFilterType.PLAN_ID,
      firmIds: params.firmIds ?? [],
      pageNumber: params.pageNumber ?? 0,
      pageSize: params.pageSize ?? this.pageSize,
      planTypes: params.planTypes ?? [],
      pooledPlanId: params.pooledPlanId,
      recordkeepersIds: params.recordkeepersIds ?? [],
      search: params.search ?? '',
      sortBy: params.sortBy,
      sortDirection: params.sortDirection
    });
  }

  static async getContributions(
    planId: number
  ): Promise<ContributionForTable[]> {
    const contributionsResponse: ContributionDto[] = (await ApiService.getJson(
      `/plan/${planId}/contributions`
    )) as ContributionDto[];

    const contributions: ContributionForTable[] = contributionsResponse.map(
      c => {
        return {
          actualPayrollDate: c.key.actualPayrollDate,
          correctionsTotal: c.contributionMeta.correctionsTotal,
          division: c.contributionMeta.division,
          expectedPayrollDate: c.key.expectedPayrollDate,
          flowSubtype: c.contributionMeta.flowSubtype,
          hasCorrections: c.contributionMeta.hasCorrections,
          hasLostGains: c.contributionMeta.hasLostGains,
          isOffCycle: c.contributionMeta.isOffCycle,
          parentUcid: c.contributionMeta.parentUcid,
          payGroupName:
            c.contributionMeta.payGroupName ?? EMPTY_FIELD_PLACEHOLDER,
          payrollDate: c.contributionMeta.payrollDate,
          payrollDueStatus: c.contributionMeta.payrollDueStatus,
          payrollStartedDate:
            c.contributionMeta.payrollStartedDate ?? EMPTY_FIELD_PLACEHOLDER,
          processingStatus: c.contributionMeta.processingStatus,
          recordkeeper: c.contributionMeta.recordkeeper,
          sponsorPlanId: c.key.sponsorPlanId,
          total: c.totals ? c.totals.total : 0,
          totalAfterTax: c.totals ? c.totals.totalAt : 0,
          totalEmployerDiscretionaryMatch: c.totals ? c.totals.totalEm : 0,
          totalEsaEmployeeDeferral: c.totals?.totalEsaEmployeeDeferral || 0,
          totalEsaEmployerMatch: c.totals?.totalEsaEmployerMatch || 0,
          totalEsaInitialDepositBonus:
            c.totals?.totalEsaInitialDepositBonus || 0,
          totalEsaMilestoneBonus: c.totals?.totalEsaMilestoneBonus || 0,
          totalLoan: c.totals ? c.totals.totalLn : 0,
          totalPreTax: c.totals ? c.totals.totalSd : 0,
          totalProfitShare: c.totals ? c.totals.totalPs : 0,
          totalPw: c.totals?.totalPw || 0,
          totalRoth: c.totals ? c.totals.totalRc : 0,
          totalSafeHarbour: c.totals ? c.totals.totalSh : 0,
          ucid: c.contributionMeta.ucid
        };
      }
    );
    return contributions;
  }

  static async updateStatefulSchema(
    id: number,
    state: StatefulSchemaTrackingState['attributes']['state'],
    status: string
  ): Promise<StatefulSchemaUpdateResponse> {
    return ApiService.putJson(`/state-management/${id}`, {
      state,
      status
    }) as Promise<StatefulSchemaUpdateResponse>;
  }

  static async getRawContributionsFileContents(
    planId: number | string,
    ucid: string,
    documentCategoryId: DocumentCategoryIds
  ): Promise<ArrayBuffer> {
    return ApiService.getArrayBuffer<ArrayBuffer>(
      `/plan/${planId}/contributions/${ucid}/s3`,
      {
        documentCategoryId
      }
    );
  }

  static async getNormalizedContributionsFileContents(
    planId: number | string,
    ucid: string,
    documentCategoryId: DocumentCategoryIds
  ): Promise<ContributionFileContents> {
    return ApiService.getJson<ContributionFileContents>(
      `/plan/${planId}/contributions/${ucid}/normalization`,
      {
        documentCategoryId
      }
    );
  }

  static async getContributionsDetails(
    planId: number | string,
    ucid: string
  ): Promise<ContributionDetailsDto> {
    const dto = (await ApiService.getJson(
      `/plan/${planId}/contributions/${ucid}/details`
    )) as ContributionDetailsDto;

    if (!dto) {
      throw new Error(
        `invalid JSON received from backend for planId=${planId}`
      );
    }

    return dto;
  }

  static async getContributionsCorrection(
    planId: number | string,
    ucid: string
  ): Promise<ContributionCorrectionDto[]> {
    const dto = (await ApiService.getJson(
      `/plan/${planId}/contributions/${ucid}/correction`
    )) as ContributionCorrectionDto[];

    if (!dto) {
      throw new Error(
        `invalid JSON received from backend for planId=${planId}`
      );
    }
    return dto;
  }

  static async getPlanById(planId: string | number): Promise<PlanV2Dto> {
    return ApiService.getJson<PlanV2Dto>(`/plan/${planId}`);
  }

  static async getPlanSalesforceInfo(planId: string | number) {
    return ApiService.getJson<PlanSalesforceInfo>(
      `/plan/${planId}/salesforce-info`
    );
  }

  static async getOnboardedPlanById(planId: string | number): Promise<
    | {
        thirdPartyPlan: LTPlan | SubAccountingPlan;
        dateSubmittedToRecordkeeper: string;
      }
    | Record<string, unknown>
  > {
    return ApiService.getJson(`/onboarding/plans/${planId}`) as any;
  }

  static async uploadOnboardingFile(
    planId: string | number,
    documentData: Array<unknown>,
    documentType: string,
    uploadedDocument: UploadedDocument,
    userFileName = ''
  ): Promise<unknown> {
    const documentName = `plan-${planId}-${documentType}-${dayjs().format(
      'YYYY-MM-DD'
    )}.json`;

    return ApiService.postJson(
      `/plan/onboarding/${planId}/${documentType}/upload`,
      {
        documentData,
        documentName,
        uploadedDocument,
        userFileName
      }
    ) as any;
  }

  static async uploadOnboardingFileV2({
    planId,
    documentData,
    documentType,
    fundMappings,
    conversionDto,
    userFileName
  }: {
    planId: string | number;
    documentData: Array<unknown>;
    documentType: string;
    fundMappings: FundMappings;
    conversionDto?: UpdateConversionDto;
    userFileName?: string;
  }): Promise<unknown> {
    const documentName = `plan-${planId}-${documentType}-${dayjs().format(
      'YYYY-MM-DD'
    )}.json`;

    return ApiService.postJson(
      `/plan/onboarding/v2/${planId}/${documentType}/upload`,
      {
        conversionDto,
        documentData,
        documentName,
        fundMappings,
        userFileName
      }
    ) as any;
  }

  static async deleteConversion(
    conversionId: number,
    planId: number
  ): Promise<unknown> {
    return ApiService.deleteJson(`/plan/${planId}/conversion/${conversionId}`);
  }

  static async uploadAMCFile(
    planId: string | number,
    documentData: Array<unknown>,
    documentType: string
  ): Promise<unknown> {
    const documentName = `plan-${planId}-${documentType}-${dayjs().format(
      'YYYY-MM-DD'
    )}.csv`;

    return ApiService.postJson(
      `/plan/testing/${planId}/amc/${documentType}/upload`,
      {
        documentData,
        documentName
      }
    );
  }

  static async uploadPlanDocument(
    planId: number,
    documentData: FormData,
    effectiveDate?: string,
    groupingId?: number
  ): Promise<UploadedDocData> {
    return ApiService.postFormData(
      `/plan/${planId}/document?effectiveDate=${effectiveDate || ''}&groupingId=${
        groupingId || ''
      }`,
      documentData
    );
  }

  static async bulkUploadPlanDocument(
    planId: number,
    documentData: FormData,
    effectiveDate: string,
    groupingId: number
  ): Promise<UploadedDocData[]> {
    return ApiService.postFormData(
      `/plan/${planId}/documents?effectiveDate=${effectiveDate}&groupingId=${groupingId}`,
      documentData
    );
  }

  static async generateAMCFiles(planId: string | number): Promise<unknown> {
    return ApiService.postJson(
      `/plan/testing/${planId}/amc/generate`,
      {}
    ) as any;
  }

  static async getParticipantsBasicDetails(
    planId?: string | number,
    query?: {
      masked?: boolean;
    }
  ): Promise<PlanParticipantsSimpleDto> {
    return ApiService.getJson(`/plan/${planId}/participants-simple`, query);
  }

  static async getParticipantsByPlanId(params: {
    planId: string | number;
    pageNumber: number;
    pageSize: number;
    sort: string[];
    searchTerm?: string;
    abortSignal?: AbortSignal;
    filter?: string;
    esaGroupId?: number;
    statuses?: string[];
    eligibility?: string[];
    preTaxDeferralStart?: number;
    preTaxDeferralEnd?: number;
    rothDeferralStart?: number;
    rothDeferralEnd?: number;
  }): Promise<PlanParticipantsInfo> {
    const query: Record<string, any> = {
      pageNumber: params.pageNumber,
      pageSize: params.pageSize
    };

    if (params.filter) {
      query.filter = params.filter;
    }

    if (params.searchTerm) {
      query.search = params.searchTerm;
    }

    if (params.esaGroupId) {
      query.esaGroupId = params.esaGroupId;
    }

    if (params.statuses) {
      query.statuses = params.statuses;
    }

    if (params.eligibility) {
      query.eligibility = params.eligibility;
    }

    if (params.preTaxDeferralStart) {
      query.preTaxDeferralStart = params.preTaxDeferralStart;
    }

    if (params.preTaxDeferralEnd) {
      query.preTaxDeferralEnd = params.preTaxDeferralEnd;
    }

    if (params.rothDeferralStart) {
      query.rothDeferralStart = params.rothDeferralStart;
    }

    if (params.rothDeferralEnd) {
      query.rothDeferralEnd = params.rothDeferralEnd;
    }

    query.sort = params.sort.join(',');

    const dto = await ApiService.getJson<PlanParticipantsDto>(
      `/plan/${params.planId}/participants`,
      query,
      params.abortSignal
    );

    const participants = dto.data.map(participant => {
      const participantWithoutEligibility = {
        birthDate: participant.attributes.birthDate,
        createdAt: participant.attributes.createdAt,
        deferralRate: `${formatters.formatDeferral(
          participant.attributes.deferralElections.afterTaxType,
          participant.attributes.deferralElections.afterTaxAmt ?? 0
        )}`,
        elStatus: participant.attributes.eligibility
          ? participant.attributes.eligibility.status
          : '--',
        email: participant.attributes.contactInfo.personalEmail,
        employeeClass: participant.attributes?.employeeClass,
        esaGroupId: participant.attributes.esaGroupId,
        historicalYears:
          participant.attributes.vestingAmounts &&
          participant.attributes.vestingAmounts.length > 0
            ? participant.attributes.vestingAmounts[0].yearsOfVesting
            : '--',
        id: participant.id,
        isExcludedEmployee: participant.attributes?.isExcludedEmployee,
        isLtpt: participant.attributes?.eligibility?.isLtpt,
        name: `${participant.attributes.lastName}, ${participant.attributes.firstName}`,
        overrideYears:
          participant.attributes.vestingAmounts &&
          participant.attributes.vestingAmounts.length > 0
            ? participant.attributes.vestingAmounts[0].yearsOfVestingOverride ||
              '--'
            : '--',
        preTaxDeferral: `${formatters.formatDeferral(
          participant.attributes.deferralElections.preTaxType,
          participant.attributes.deferralElections.preTaxAmt ?? 0
        )}`,
        registeredAt: participant.attributes.registeredOn,
        rothDeferral: `${formatters.formatDeferral(
          participant.attributes.deferralElections.rothType,
          participant.attributes.deferralElections.rothAmt ?? 0
        )}`,
        ssn: participant.attributes.ssn.slice(-5),
        stateIraAccountStatus: formatters.capitalizeFirstChar(
          participant.attributes.stateIraAccountStatus
        ),
        stateIraCipStatus: formatters.capitalizeFirstChar(
          participant.attributes.stateIraCipStatus
        ),
        stateIraPerEmployerStatus: formatters.capitalizeFirstChar(
          participant.attributes.stateIraPerEmployerStatus
        ),
        waitingPeriod: formatters.calculateWaitingPeriod(
          participant.attributes.createdAt
        ),
        workEmail: participant.attributes.contactInfo.workEmail
      };

      let participantWithEligibility = {
        ageRequirement: '--',
        entryDate: '--',
        serviceRequirement: '--'
      };

      if (participant.attributes.eligibility) {
        participantWithEligibility = {
          ageRequirement: participant.attributes.eligibility
            .ageRequirementDetail
            ? formatters.formatMetUnmet(
                participant.attributes.eligibility.ageRequirementDetail.isMet
              )
            : '--',
          entryDate: participant.attributes.eligibility.entryDate || '--',
          serviceRequirement: participant.attributes.eligibility
            .serviceRequirementDetail
            ? formatters.formatMetUnmet(
                participant.attributes.eligibility.serviceRequirementDetail
                  .isMet
              )
            : '--'
        };

        if (
          participant.attributes.stateIraCipStatus &&
          participant.attributes.stateIraAccountStatus &&
          participant.attributes.stateIraPerEmployerStatus
        ) {
          participantWithoutEligibility.elStatus =
            participant.attributes.employeeStatus;
        }
      }

      const matchEligibility = participant.attributes?.matchEligibility
        ? {
            matchEligibilityEntryDate:
              participant.attributes?.matchEligibility?.entryDate,
            matchEligibilityStatus:
              participant.attributes?.matchEligibility?.status
          }
        : {
            matchEligibilityEntryDate:
              participant.attributes?.eligibility?.entryDate,
            matchEligibilityStatus: participant.attributes?.eligibility?.status
          };

      const profitSharingEligibility = participant.attributes
        ?.profitSharingEligibility
        ? {
            profitSharingEligibilityEntryDate:
              participant.attributes?.profitSharingEligibility?.entryDate,
            profitSharingEligibilityStatus:
              participant.attributes?.profitSharingEligibility?.status
          }
        : {
            profitSharingEligibilityEntryDate:
              participant.attributes?.eligibility?.entryDate,
            profitSharingEligibilityStatus:
              participant.attributes?.eligibility?.status
          };

      return {
        ...participantWithoutEligibility,
        ...participantWithEligibility,
        ...matchEligibility,
        ...profitSharingEligibility
      };
    });

    return {
      count: dto.meta.count,
      participants: participants.sort((participant1, participant2) =>
        participant1.name < participant2.name ? -1 : 1
      )
    };
  }

  static async getParticipantsWithLatestWelcomeEmails({
    sponsorPlanId,
    params
  }: GetParticipantsWithLatestWelcomeEmailsParams): Promise<PlanParticipantsInfo> {
    const participants = await ApiService.getJson<{
      total: number;
      data: ParticipantWithWelcomeEmail[];
    }>(`/plan/${sponsorPlanId}/participants-with-welcome-emails`, params);

    return {
      count: participants.total,
      participants: participants.data.map(participant => {
        return {
          eligibilityStatus: participant.eligibilityStatus,
          email: participant.userEmail,
          id: participant.participantId,
          name: `${participant.lastName}, ${participant.firstName}`,
          registeredAt: participant.activationDate
            ? new Date(parseInt(participant.activationDate as string, 10))
            : null,
          welcomeEmailSentAt: participant.welcomeEmailSentAt
            ? new Date(parseInt(participant.welcomeEmailSentAt as string, 10))
            : null,
          welcomeEmailStatus: participant.welcomeEmailStatus,
          workEmail: participant.workEmail
        };
      })
    };
  }

  static async getParticipantsEligibilityReportByPlan(
    planId: string | number,
    pageNumber?: number,
    pageSize?: number,
    eligibility?:
      | 'Eligible'
      | 'Ineligible'
      | 'Terminated'
      | 'Awaiting entry date'
      | 'Terminated with balance',
    sort?: 'name' | 'balance' | 'investment' | 'lastName',
    searchTerm?: string
  ): Promise<ParticipantEligibilityReportData[]> {
    return ApiService.getJson<ParticipantEligibilityReportData[]>(
      `/plan/${planId}/participants-eligibility-report`,
      { eligibility, pageNumber, pageSize, search: searchTerm, sort }
    );
  }

  static async getParticipantsSimpleByPlanId(
    planId: string | number,
    pageNumber: number,
    pageSize: number,
    sort: string[],
    masked?: boolean
  ): Promise<PlanParticipantsInfo> {
    const query: Record<string, any> = {
      pageNumber,
      pageSize
    };

    query.sort = sort.join(',');

    query.masked = masked ?? true;

    const dto = await ApiService.getJson<PlanParticipantsSimpleDto>(
      `/plan/${planId}/participants-simple`,
      query
    );

    const participants = dto.data.map(participant => {
      return {
        birthDate: participant.attributes.birthDate,
        email: participant.attributes.contactInfo.personalEmail,
        id: participant.id,
        name: `${participant.attributes.lastName}, ${participant.attributes.firstName}`,
        preTaxDeferral: `${formatters.formatDeferral(
          participant.attributes.deferralElections.preTaxType,
          participant.attributes.deferralElections.preTaxAmt ?? 0
        )}`,
        rothDeferral: `${formatters.formatDeferral(
          participant.attributes.deferralElections.rothType,
          participant.attributes.deferralElections.rothAmt ?? 0
        )}`,
        ssn: masked
          ? participant.attributes.ssn.slice(-5)
          : participant.attributes.ssn,
        stateIraAccountStatus: formatters.capitalizeFirstChar(
          participant.attributes.stateIraAccountStatus
        ),
        stateIraCipStatus: formatters.capitalizeFirstChar(
          participant.attributes.stateIraCipStatus
        ),
        stateIraPerEmployerStatus: formatters.capitalizeFirstChar(
          participant.attributes.stateIraPerEmployerStatus
        ),
        workEmail: participant.attributes.contactInfo.workEmail
      };
    });

    return {
      count: dto.meta.count,
      participants: participants.sort((participant1, participant2) =>
        participant1.name < participant2.name ? -1 : 1
      )
    };
  }

  static async getParticipantsForDeferralConversion(
    planId: string | number,
    query: { year: number; type: string }
  ): Promise<DeferralRatesParticipants[]> {
    const dto = await ApiService.getJson<DeferralRatesParticipants[]>(
      `/plan/${planId}/participants-deferral`,
      query
    );

    if (!dto) {
      throw new Error(
        `invalid JSON received from backend for planId=${planId}`
      );
    }
    return dto;
  }

  static async getPlanPayrollSetups(
    planId: string | number
  ): Promise<PlanPayrollSetupDto[]> {
    const dto: PlanPayrollSetupDto[] = await ApiService.getJson<
      PlanPayrollSetupDto[]
    >(`/plans/${planId}/setup`);

    if (!dto || !Array.isArray(dto)) {
      throw new Error(
        `invalid JSON received from backend for planId=${planId}`
      );
    }
    return dto;
  }

  static async getLatestPlanPayrollSetupByName(
    planId: string | number,
    name: string
  ): Promise<PlanPayrollSetupDto> {
    const dto: PlanPayrollSetupDto[] = await ApiService.getJson<
      PlanPayrollSetupDto[]
    >(`/plans/${planId}/setup`);

    if (!dto || !Array.isArray(dto)) {
      throw new Error(
        `invalid JSON received from backend for planId=${planId}`
      );
    }
    return dto.sort((a, b) => b.id - a.id).find(a => a.payGroupName === name);
  }

  static async getPayrollProviderById(
    payrollProviderId: string | number
  ): Promise<PayrollProviderInfo> {
    const dto: PayrollProviderDto = (await ApiService.getJson(
      `/plan/payroll-provider/${payrollProviderId}`
    )) as PayrollProviderDto;

    if (!dto || !dto.id || !dto.name) {
      throw new Error(
        `invalid JSON received from backend for payrollProviderId=${payrollProviderId}`
      );
    }
    return {
      id: dto.id,
      name: dto.name
    } as PayrollProviderInfo;
  }

  static async getPlanConversionDocuments(
    planId: string | number
  ): Promise<unknown> {
    return ApiService.getJson(`/plan/${planId}/documents/conversion`) as any;
  }

  static async getAllPlanDocuments(planId: string | number): Promise<
    {
      document_key: string;
      latest: DocumentMetadataDto;
    }[]
  > {
    return ApiService.getJson(`/plan/${planId}/documents/all`);
  }

  static async getDocumentDefinition(
    entity: string,
    documentKey: string
  ): Promise<DocumentDefinitionConfig> {
    return ApiService.getJson(`/plan/documents/definition`, {
      documentKey,
      entity
    });
  }

  static async getDocumentForPlanId(
    planId: string | number,
    documentId?: string | number
  ): Promise<DocumentDto> {
    return ApiService.getJson(`/plan/${planId}/document/${documentId}`);
  }

  static async getBulkDocumentsForPlanId(params: {
    planId: number;
    documentIds: number[];
  }): Promise<DocumentDto> {
    return ApiService.getJson(`/plan/${params.planId}/documents`, {
      documentIds: params.documentIds.join(',')
    });
  }

  static async deleteOnboardingDocumentById(args: {
    planId: number;
    documentId: number;
  }): Promise<DocumentMetadataDtoV2> {
    return ApiService.deleteJson(
      `/plan/${args.planId}/onboarding-questionnaire-documents/${args.documentId}`
    );
  }
  static async deleteUploadedDoc(
    planId: number,
    documentId: number,
    groupId: number
  ): Promise<any> {
    return ApiService.deleteJson(
      `/plan/${planId}/document/${documentId}/group/${groupId}`
    );
  }

  static async getPlanDocUploadHist(
    planId: number,
    documentKey: string
  ): Promise<DocumentMetadataListDtoV2> {
    return ApiService.getJson(`/plan/${planId}/document-history`, {
      documentKey
    });
  }

  static async validate(
    planId: string | number
  ): Promise<{ errors: string[] }> {
    const response = await ApiService.postJson(
      `/onboarding/plans/${planId}/validate`,
      {}
    );
    return response as { errors: string[] };
  }

  static async onboard(planId: string | number): Promise<void> {
    await ApiService.postJson(`/onboarding/onboard-plan-by-id`, { planId });
  }

  static async getPayrollFundingInfo(
    planId: string | number
  ): Promise<PayrollFundingInfoDto> {
    const dto = await ApiService.getJson<PayrollFundingInfoDto>(
      `/plans/${planId}/payroll/setup/funding`
    );

    if (!dto) {
      throw new Error(
        `invalid JSON received from backend for planId=${planId}`
      );
    }
    return dto;
  }

  static async updatePlan(
    planId: string | number,
    body: UpdatePlanDto
  ): Promise<UpdatePlanPayload> {
    return ApiService.patchJson<UpdatePlanDto, UpdatePlanPayload>(
      `/plans/${planId}`,
      body
    );
  }

  static async getPlanDesignById(
    planId: string | number
  ): Promise<PlanDesignDto> {
    return ApiService.getJson(`plan/plan-design/${planId}`);
  }

  static async checkSsnWithinPlan(
    planId: string | number,
    ssn: string
  ): Promise<{ data: boolean }> {
    return ApiService.getJson(`/plans/${planId}/ssn/${ssn}`);
  }

  static async updatePlanDesign(
    planId: string | number,
    data: PlanDesign
  ): Promise<PlanDesign> {
    return ApiService.patchJson(`plan/plan-design/${planId}`, data);
  }

  static async getInvestments(
    planId: number | string,
    ucid: string
  ): Promise<InvestmentDto[]> {
    const contributionsResponse: InvestmentDto[] = (await ApiService.getJson(
      `/plan/${planId}/contributions/${ucid}/tradeRequests`
    )) as InvestmentDto[];

    return contributionsResponse;
  }

  static async getAdvancedCorrections(
    planId: number | string,
    ucid: string
  ): Promise<any> {
    return await ApiService.getJson(
      `/plan/${planId}/contributions/${ucid}/advanced-corrections`
    );
  }

  static async retryAch(planId: number, ucid: string): Promise<unknown> {
    return ApiService.postJson(`/plan/${planId}/retry/ach`, { ucid });
  }

  static async retryTradeRequests(
    planId: number,
    ucid: string
  ): Promise<unknown> {
    return ApiService.postJson(`/plan/${planId}/retry/tradeRequests`, { ucid });
  }

  static async retryAdvancedCorrectionRequests(
    planId: number,
    ucid: string
  ): Promise<unknown> {
    return ApiService.postJson(`/plan/${planId}/retry/advancedCorrection`, {
      ucid
    });
  }

  static async getRollovers(planId: number): Promise<FormattedRolloverDto[]> {
    const rolloverDto: RolloverListDto = await ApiService.getJson(
      `/plan/${planId}/rollovers`
    );

    if (!rolloverDto) {
      throw new Error(
        `invalid JSON received from backend for planId=${planId}`
      );
    }

    return rolloverDto?.data?.map(rawRollover => {
      return {
        accountProvider: rawRollover.attributes.accountProvider,
        accountType: rawRollover.attributes.accountType,
        createdAt: formatters.formatFromIsoDateCustom(
          rawRollover.attributes.createdAt,
          'YYYY-MM-DD'
        ),
        id: +rawRollover.id,
        participantId: rawRollover.attributes.participantId,
        participantName: `${rawRollover.attributes.participantLastName}, ${rawRollover.attributes.participantFirstName}`,
        pretaxAmount: formatters.formatDollars(
          rawRollover.attributes.preTaxAmount
        ),
        rothAmount: formatters.formatDollars(rawRollover.attributes.rothAmount),
        status: rawRollover.attributes.status,
        updatedAt: formatters.formatFromIsoDateCustom(
          rawRollover.attributes.updatedAt,
          'YYYY-MM-DD'
        )
      } as FormattedRolloverDto;
    });
  }

  static async getUnmatchedDeposits(
    planId?: number
  ): Promise<SubaDepositTransactionResponse> {
    return (await ApiService.getJson(
      `/plan/${planId}/unmatchedDeposits`
    )) as SubaDepositTransactionResponse;
  }

  static async updateForfeiturePref(
    planId: string | number,
    dto: ForfeiturePreferencesDto
  ): Promise<unknown> {
    return ApiService.putJson(`/plan/${planId}/forfeiture-preference`, dto);
  }

  static async getNullProgramPlans(
    search: string
  ): Promise<ProgramPlansListDto> {
    return ApiService.getJson(`/plans?programId`, { search });
  }

  static async updatePlanProgram(
    planId: number,
    programId: number
  ): Promise<{ data: string }> {
    return ApiService.patchJson(`/plans/${planId}/program`, {
      programId: programId
    });
  }

  static async getCashBalance(
    planId: string | number
  ): Promise<CashBalanceDto[]> {
    return ApiService.getJson(`/plan/${planId}/cash-balance`);
  }

  static async getForfeitureEvents(
    planId: number | string
  ): Promise<ForfeitureEventDto> {
    return (await ApiService.getJson(
      `/plan/${planId}/forfeiture-events`
    )) as ForfeitureEventDto;
  }

  static async getForfeitureTrackerData(
    planId: number | string
  ): Promise<ForfeitureTrackerDto> {
    return (await ApiService.getJson(
      `/plan/${planId}/forfeiture-statuses`
    )) as ForfeitureTrackerDto;
  }

  static async saveForfeitureEvent(
    planId: number,
    data: ForfeitureEvent
  ): Promise<unknown> {
    return ApiService.postJson(`plan/${planId}/forfeiture-events`, data);
  }

  static async updateForfeitureEvent(
    planId: number,
    data: ForfeitureEvent
  ): Promise<unknown> {
    return ApiService.putJson(`plan/${planId}/forfeiture-events`, data);
  }

  static async getSponsorPlanPizzaTracker(
    planId: string | number
  ): Promise<Record<string, string>> {
    return ApiService.getJson<Record<string, string>>(
      `/plans/${planId}/pizza-tracker`
    );
  }

  static async sendUnderfundedContributionsCalcReport(
    planId: number
  ): Promise<SendEmailResponseDto> {
    return ApiService.postJson(
      `plan/${planId}/send/underfunded-contribution`,
      {}
    );
  }

  static async sendOverfundedContributionsCalcReport(
    planId: number
  ): Promise<SendEmailResponseDto> {
    return ApiService.postJson(
      `plan/${planId}/send/overfunded-contribution`,
      {}
    );
  }

  static async sendAmendmentNotifications(
    planId: string | number,
    isSafeHarborOrAutoEnroll: boolean,
    planName: string
  ): Promise<any> {
    return ApiService.postJson(`/plan/${planId}/send/amendment-notifications`, {
      isSafeHarborOrAutoEnroll,
      planName
    });
  }

  static async getCsms(): Promise<ClientSuccessManagerDto> {
    return ApiService.getJson('plan/plan-design/csms');
  }

  static async getDocumentCategoryGroupings(
    planId: string | number,
    categoryId: string | number
  ): Promise<PlanDocumentCategoryGroup[]> {
    return ApiService.getJson(
      `/plan/${planId}/documents/category/${categoryId}/all`
    );
  }

  static async updateDocGroupDetails(
    planId: number,
    uploadHistoryId: number,
    groupId: number,
    data: UpdatePlanDetail
  ): Promise<Record<string, never>> {
    return ApiService.patchJson(
      `/plan/${planId}/documents/${uploadHistoryId}/group/${groupId}`,
      data
    );
  }

  static async conversionFileExists(
    planId: number,
    fileName: string
  ): Promise<boolean> {
    return ApiService.getJson(`/plan/${planId}/conversion/file-exists`, {
      fileName
    });
  }

  static async refreshPlanEligibility(sponsorPlanId: string): Promise<any> {
    return ApiService.postJson(`/plans/eligibility/${sponsorPlanId}`, {});
  }

  // this is for another Scala endpoint to get Payroll Setups and not the same as the one above getPlanPayrollSetups
  static async getPayrollSetups(
    sponsorPlanId: string | number
  ): Promise<Payroll.GetSetup.ResponseBody[]> {
    const dto: Payroll.GetSetup.ResponseBody[] = await ApiService.getJson<
      Payroll.GetSetup.ResponseBody[]
    >(`/plans/${sponsorPlanId}/payroll-setups`, {});

    if (!dto || !Array.isArray(dto)) {
      throw new Error(
        `invalid JSON received from backend for planId=${sponsorPlanId}`
      );
    }
    return dto;
  }

  static async getPartnerSystems(
    planId: number
  ): Promise<PartnerSystem.GetAll.ResponseBody> {
    return ApiService.getJson<PartnerSystem.GetAll.ResponseBody>(
      `/plan/${planId}/partner-systems`
    );
  }

  static async postPartnerSystemMapping(
    planId: number,
    partnerSystemId: number,
    externalId: string
  ): Promise<PartnerSystem.PostMapping.ResponseBody> {
    return ApiService.postJson<
      {
        externalId: string;
        partnerSystemId: number;
      },
      PartnerSystem.PostMapping.ResponseBody
    >(`/plan/${planId}/partner-system-mappings`, {
      externalId,
      partnerSystemId
    });
  }

  static async postPayrollSetup(
    sponsorPlanId: number,
    body: Payroll.PostPayrollSetup.RequestBody,
    selectedPayGroupIdToIntegrateWith: number = undefined
  ): Promise<Payroll.PostPayrollSetup.ResponseBody> {
    const res = await ApiService.postJson<
      Payroll.PostPayrollSetup.RequestBody,
      Payroll.PostPayrollSetup.ResponseBody
    >(`/plans/${sponsorPlanId}/payroll-setups`, body);

    if (selectedPayGroupIdToIntegrateWith) {
      await PayrollIntegrationsService.updatePayrollIntegration({
        integratedPayrollSetupId: selectedPayGroupIdToIntegrateWith,
        payrollSetupId: res.id,
        sponsorPlanId: sponsorPlanId
      });
    }

    return res;
  }

  static async putPartnerSystemMapping(
    planId: number,
    psmId: number,
    body: PartnerSystem.PutMapping.RequestBody
  ): Promise<PartnerSystem.PutMapping.ResponseBody> {
    return ApiService.putJson<
      PartnerSystem.PutMapping.RequestBody,
      PartnerSystem.PutMapping.ResponseBody
    >(`/plan/${planId}/partner-system-mappings/${psmId}`, {
      ...body
    });
  }

  static async createDocumentGroupingWithDetails(
    planId: number,
    grouping: CreateDocumentGroupingWithDetailsPayload
  ): Promise<CreateDocumentGroupingResponse> {
    return ApiService.postJson(`/plan/${planId}/documents/group`, grouping);
  }

  static async getPayrollProviders(): Promise<PayrollProviderDto[]> {
    const dto = await ApiService.getJson<PayrollProviderDto[]>(
      `/plans/payroll-providers`
    );

    if (!dto || !Array.isArray(dto)) {
      throw new Error(`invalid JSON received from backend `);
    }
    return dto;
  }

  static async getNextPayrollDates(
    queryParams: Payroll.GetNextDates.RequestQuery
  ): Promise<Payroll.GetNextDates.ResponseBody> {
    return ApiService.getJson<Payroll.GetNextDates.ResponseBody>(
      `/plans/payroll-dates`,
      queryParams
    );
  }

  static async updatePayrollSetups(
    sponsorPlanId: string | number,
    data: Payroll.PutPayrollSetupBulk.RequestBody
  ): Promise<unknown> {
    return ApiService.putJson(`/plans/${sponsorPlanId}/payroll-setups`, data);
  }

  static async updatePayrollSetup(
    sponsorPlanId: number,
    payGroupId: number,
    data: PayrollSetupCreateRequest,
    selectedPayGroupIdToIntegrateWith: number = undefined,
    payrollSetupIdIsIntegrated: boolean = undefined
  ): Promise<PayrollSetupResponse> {
    const res = await ApiService.putJson<
      PayrollSetupCreateRequest,
      PayrollSetupResponse
    >(`/plans/${sponsorPlanId}/payroll-setups/${payGroupId}`, data);

    if (selectedPayGroupIdToIntegrateWith) {
      if (payrollSetupIdIsIntegrated) {
        await PayrollIntegrationsService.deletePayrollIntegration({
          payrollSetupId: payGroupId
        });
      }
      await PayrollIntegrationsService.updatePayrollIntegration({
        integratedPayrollSetupId: selectedPayGroupIdToIntegrateWith,
        payrollSetupId: payGroupId,
        sponsorPlanId: sponsorPlanId
      });
    }

    return res;
  }

  static async getIntegrationMethods(
    payrollProviderId: number,
    payrollIntegrationTypeId: number
  ): Promise<{ value: number; option: string }[]> {
    return ApiService.getJson<{ value: number; option: string }[]>(
      `/plans/payroll/${payrollProviderId}/integration/${payrollIntegrationTypeId}/methods`
    );
  }

  static async getPartnerSystemMappings(
    entityTypeId: number,
    internalId: number,
    partnerSystemName?: string | undefined
  ): Promise<PartnerSystemMappingDto[]> {
    const dto: PartnerSystemMappingDto[] = await ApiService.getJson<
      PartnerSystemMappingDto[]
    >('/plans/partner-system-mappings', {
      entityTypeId,
      internalId,
      partnerSystemName
    });

    if (!dto || !Array.isArray(dto)) {
      throw new Error(
        `invalid JSON received from backend for internalId=${internalId}`
      );
    }
    return dto;
  }

  static async getDocumentKeysByCategory(
    categoryId: string | number
  ): Promise<string[]> {
    return ApiService.getJson(`/plan/documents/category/${categoryId}/keys`);
  }

  static async getPlanParticipantsCommunications(
    sponsorPlanId: number,
    params: GetCommunicationsParams
  ): Promise<PlanCommunications> {
    return ApiService.getJson(`/plan/${sponsorPlanId}/emails/participants`, {
      ...params
    });
  }

  static getPlanParticipantsEmailsCategories(
    sponsorPlanId: number
  ): Promise<string[]> {
    return ApiService.getJson(
      `/plan/${sponsorPlanId}/emails/participants/categories`
    );
  }

  static async getPlanSponsorCommunications(
    sponsorPlanId: number,
    params: GetCommunicationsParams
  ): Promise<PlanCommunications> {
    return ApiService.getJson(`/plan/${sponsorPlanId}/emails/sponsor`, {
      ...params
    });
  }

  static getPlanSponsorEmailsCategories(
    sponsorPlanId: number,
    sponsorId?: number
  ): Promise<string[]> {
    if (!sponsorId) {
      throw new Error('sponsorId now provided');
    }
    return ApiService.getJson(
      `/plan/${sponsorPlanId}/emails/sponsor/categories`,
      { sponsorId }
    );
  }

  static getEmailMessagePreviewById(
    messageId: number
  ): Promise<{ data: string }> {
    return ApiService.getJson(`/plan/email/preview/message/${messageId}`);
  }

  static async getPlanDates(planId: string): Promise<PlanDate[]> {
    return ApiService.getJson<PlanDate[]>(`plan/${planId}/dates`);
  }

  static async sendParticipantsBlackoutNotice(planId: string): Promise<void> {
    return ApiService.postJson(
      `/plan/${planId}/send/blackout-notice/participants`,
      {}
    );
  }

  static getExpectedPayrollDates(
    payrollSetupId: number,
    year: number
  ): Promise<Payroll.GetExpectedDates.ResponseBody> {
    return ApiService.getJson(
      `/plans/payroll-setups/${payrollSetupId}/expected-dates`,
      { year }
    );
  }

  static async getLoanBriefsPerPlan(
    planId?: string | number
  ): Promise<LoanBriefInfo> {
    const dto = await ApiService.getJson<LoanBriefInfo>(
      `plan/${planId}/loan-briefs`
    );
    if (!dto) {
      throw new Error(
        `invalid JSON received from backend for planId=${planId}`
      );
    }
    return dto;
  }

  static async getWelcomeEmailDates(sponsorPlanId: string | number): Promise<
    {
      date: Date;
      dateType: string;
    }[]
  > {
    const response = await ApiService.getJson<Dates.GetPlanDates.ResponseBody>(
      `/plan/${sponsorPlanId}/dates`,
      {}
    );

    const welcomeEmailsDates = response
      .filter(date =>
        [
          PLAN_DATES_TYPES.WELCOME_EMAILS_SCHEDULED,
          PLAN_DATES_TYPES.WELCOME_EMAILS_SENT
        ].includes(date.dateType)
      )
      .sort((a, b) => b.date.localeCompare(a.date)) // newest to oldest
      .map(date => ({ ...date, date: new Date(date.date) }));

    return welcomeEmailsDates;
  }

  static async createScheduledWelcomeEmailsDate(
    sponsorPlanId: string | number,
    { date }: { date: Date }
  ): Promise<void> {
    await ApiService.postJson(`/plan/${sponsorPlanId}/dates`, {
      date,
      dateType: PLAN_DATES_TYPES.WELCOME_EMAILS_SCHEDULED
    });
  }

  static async deleteScheduledWelcomeEmailsDates(
    sponsorPlanId: string | number
  ): Promise<void> {
    await ApiService.deleteJson(
      `/plan/${sponsorPlanId}/dates?dateType=${PLAN_DATES_TYPES.WELCOME_EMAILS_SCHEDULED}`
    );
  }

  static async getPlanConversions(
    planId: string | number
  ): Promise<Conversion[]> {
    return ApiService.getJson<Conversion[]>(`/plan/${planId}/conversions`);
  }

  static async getPageConversions(
    pageSize: number,
    pageNumber: number,
    byPlan?: string,
    status?: string
  ) {
    let planId: number | undefined = undefined;
    let planName: string | undefined = undefined;
    const numericPattern = /^[0-9]+$/;
    if (byPlan) {
      if (numericPattern.test(byPlan)) {
        planId = +byPlan;
      } else if (byPlan.trim().length > 0) {
        planName = byPlan;
      }
    }

    return ApiService.getJson<PageConversionResponse>('/plan/conversions', {
      pageNumber,
      pageSize,
      planId,
      planName,
      status
    });
  }

  static async getRothDepositReport(
    planId: string | number
  ): Promise<RothDepositReportDto[]> {
    return ApiService.getJson<RothDepositReportDto[]>(
      '/plan/conversions/roth-report',
      { planId }
    );
  }

  static async postConversionStatus(
    planId: string | number,
    isComplete: boolean
  ): Promise<unknown> {
    return ApiService.postJson('/plan/conversions/actions', {
      isComplete,
      planId
    });
  }

  static async getPooledPlansPage(
    pageNumber = 1,
    pageSize = this.pageSize,
    search = '',
    filterType = 'plan_id',
    statuses: string[] = [],
    types: string[] = [],
    sortBy = 'status',
    sortDirection = 'asc'
  ): Promise<PooledPlanDto> {
    return ApiService.getJson('/pooled-plan', {
      filterType,
      pageNumber,
      pageSize,
      search,
      sortBy,
      sortDirection,
      statuses,
      types
    });
  }

  static async getPooledPlanStats(
    pooledPlanIds: number[]
  ): Promise<AllPlanStatsDto[]> {
    return ApiService.getJson(`/plan/overview/stats/total`, {
      pooledPlanIds
    });
  }

  static async getTpaPlanStats(tpaIds: number[]): Promise<AllPlanStatsDto[]> {
    return ApiService.getJson(`/plan/overview/stats/total`, {
      tpaIds
    });
  }

  static async getPooledPlanById(planId: string | number): Promise<PooledPlan> {
    return ApiService.getJson(`/pooled-plan/${planId}`);
  }

  static async getConversionRequests(
    planId: string | number,
    conversionId?: number
  ): Promise<ConversionInvestmentsResponse[]> {
    return ApiService.getJson<ConversionInvestmentsResponse[]>(
      `/plan/${planId}/conversions/${conversionId}/requests`
    );
  }

  static async getSponsorPlanYearEndTestingYears(
    planId: number | string
  ): Promise<number[]> {
    return ApiService.getJson(`/plans/${planId}/yet`);
  }

  static async getSponsorPlanYearEndTesting(
    planId: number | string,
    year: number | string
  ): Promise<YearEndTestingDto> {
    return ApiService.getJson(`/plans/${planId}/yet/${year}`);
  }

  static async postUnlockSubmission(
    planId: number | string,
    year: number | string
  ): Promise<void> {
    return ApiService.postJson(`/plans/${planId}/yet/${year}/unlock`, {});
  }

  static async postYETStatus(
    action: string,
    processId: number | undefined
  ): Promise<ProcessTransitionResponse> {
    return ApiService.postJson('/plans/transition', { action, processId });
  }

  static async postYETTestResults(
    planId: number,
    data: TestExecutionResultsDto | YearEndContributionResultsDto,
    year: number | string
  ): Promise<DocumentTestResultResponse[]> {
    return ApiService.postJson(
      `/plans/${planId}/yet/${year}/plan-test-results`,
      data
    );
  }

  static async getYETTestResults(
    planId: number,
    planYear: number
  ): Promise<PlanTestResultDto> {
    return ApiService.getJson(`/plans/${planId}/plan-test-results`, {
      planYear
    });
  }

  static async sendYETTestingResultsToSponsors(planId: number): Promise<void> {
    return ApiService.postJson(`/plans/${planId}/yet/test-results-loaded`, {});
  }

  static async sendYETCompliancePackage(planId: number): Promise<void> {
    return ApiService.postJson(`/plans/${planId}/yet/compliance-package`, {});
  }

  static async createPooledPlan(
    pooledPlanDTO: CreatePooledPlanDto
  ): Promise<PooledPlan> {
    return ApiService.postJson('/pooled-plan', pooledPlanDTO);
  }

  static async getScheduledChanges(
    planId: number,
    applied = false
  ): Promise<ScheduledChangesDTO> {
    return ApiService.getJson(`/plan/${planId}/plan-design/schedule`, {
      applied
    });
  }

  static async createScheduleChange(
    planId: number | string,
    scheduledChanges: Omit<ScheduledChange, 'createdAt' | 'createdBy' | 'id'>
  ): Promise<{ data: Record<string, never> }> {
    return ApiService.postJson(
      `/plan/${planId}/plan-design/schedule`,
      scheduledChanges
    );
  }

  static async updateScheduleChange(
    planId: number | string,
    scheduleId: number,
    scheduledChange: Omit<ScheduledChange, 'createdAt' | 'createdBy'>
  ): Promise<{ data: Record<string, never> }> {
    return ApiService.patchJson(
      `/plan/${planId}/plan-design/schedule/${scheduleId}`,
      scheduledChange
    );
  }

  static async deleteScheduleChange(
    planId: number | string,
    scheduleId: number
  ): Promise<''> {
    return ApiService.deleteJson(
      `/plan/${planId}/plan-design/schedule/${scheduleId}`
    );
  }

  static async getPlanWithdrawals(
    planId: string | number
  ): Promise<FormattedWithdrawalDto[]> {
    const dto = await ApiService.getJson<any>(`/plan/${planId}/withdrawals`);

    if (!dto) {
      throw new Error(
        `invalid JSON received from backend for planId=${planId}`
      );
    }
    return dto?.data?.map((rawWithdrawal: any) => {
      return {
        ...rawWithdrawal,
        amount: formatters.formatDollars(rawWithdrawal.amount),
        id: +rawWithdrawal.withdrawalId,
        reason: formatters.formatWithdrawalReason(rawWithdrawal.reason),
        requestDate: formatters.formatFromIsoDateCustom(
          rawWithdrawal.requestDate,
          'MM/DD/YYYY'
        )
      };
    });
  }

  static async getLoans(sponsorPlanId: number): Promise<LoanResponseDto> {
    const dto: LoanResponseDto = await ApiService.getJson(
      `/plan/${sponsorPlanId}/loans`
    );

    if (!dto) {
      throw new Error(
        `invalid JSON received from backend for planId=${sponsorPlanId}`
      );
    }

    return dto;
  }

  static async getPlanDeconversion(
    planId: string | number
  ): Promise<PlanDeconversionResponse> {
    return ApiService.getJson(`/plan/${planId}/deconversions`);
  }

  static async getDeconversionRequest(
    planId: string | number
  ): Promise<DeconversionRequestDto> {
    return ApiService.getJson(`/plan/${planId}/deconversions/request`);
  }

  static async updateDeconversionDate(
    sponsorPlanId: string | number,
    date: string,
    type: 'liquidation' | 'blackout'
  ): Promise<void> {
    await ApiService.postJson(
      `/plan/${sponsorPlanId}/deconversions/${type}-date?date=${date}`,
      {}
    );
  }

  static async updateDisbursementInstructions(
    sponsorPlanId: string | number,
    data: DisbursementInstructionsDto
  ): Promise<void> {
    await ApiService.postJson(
      `/plan/${sponsorPlanId}/deconversions/disbursement-instructions`,
      {
        ...data
      }
    );
  }

  static async updateDeconversion(data: UpdateDeconversionDto): Promise<void> {
    await ApiService.putJson(`/plan/${data.sponsorPlanId}/deconversions`, {
      ...data
    });
  }

  static async disburseCash(sponsorPlanId: number): Promise<void> {
    await ApiService.post(`/plan/${sponsorPlanId}/deconversions/disburse-cash`);
  }

  static async getDeconversionDestinations(
    planId: string | number
  ): Promise<DeconversionDestinationResponse> {
    return ApiService.getJson(`/plan/${planId}/deconversions/destinations`);
  }

  static async getLoansIdsPerPlan(
    planId?: string | number
  ): Promise<LoanIdsPerPlanDto> {
    const dto = await ApiService.getJson<LoanIdsPerPlanDto>(
      `plan/${planId}/loans`
    );
    if (!dto) {
      throw new Error(
        `invalid JSON received from backend for planId=${planId}`
      );
    }
    return dto;
  }

  static async getPlanYearEndConfiguration(
    planId: number,
    planYear: number
  ): Promise<PlanYearConfiguration> {
    return ApiService.getJson(`/plans/${planId}/yet-configuration`, {
      planYear
    });
  }

  static async postPlanYearEndConfiguration(
    planId: number,
    planYear: number
  ): Promise<PostPlanYearConfigResponse> {
    return ApiService.postJson(`/plans/${planId}/eligibility`, {
      planIds: [planId],
      planYear
    });
  }

  static async deletePlanYearEndConfiguration(
    planId: number,
    planYear: number
  ): Promise<void> {
    return ApiService.deleteJson(
      `/plans/${planId}/eligibility/year/${planYear}`
    );
  }

  static getPlanFeatures(planId: number): Promise<PlanFeaturesDto> {
    return ApiService.getJson(`/plan/${planId}/features`);
  }

  static getParticipantDuplicates(
    planId: number,
    participantId: number
  ): Promise<DuplicateParticipantsDto[]> {
    return ApiService.getJson(
      `/plan/${planId}/participant/${participantId}/duplicates`
    );
  }

  static async getStats(
    planId: number | string
  ): Promise<Plans.GetStats.ResponseBody> {
    return ApiService.getJson<Plans.GetStats.ResponseBody>(
      `/plan/${planId}/stats`
    );
  }

  static async getOverviewStats(planIds: number[]): Promise<PlanStat[]> {
    return ApiService.getJson<PlanStat[]>(`/plan/overview/stats/multiple`, {
      planIds
    });
  }

  static getBillingPeriods(pageSize: number, pageNumber: number) {
    return ApiService.getJson<{ data: any[] }>('/plans/billing-periods', {
      pageNumber,
      pageSize
    });
  }

  static async getAuditLogs(params: {
    planId: number;
    take: number;
    skip: number;
    initiatorTypes?: string[];
    eventTypeNames?: string[];
    startDate?: string;
    endDate?: string;
    search?: string;
    orderBy?: string;
    order?: string;
  }): Promise<AuditLogsDto> {
    return ApiService.getJson<AuditLogsDto>(
      `/plan/${params.planId}/audit-logs`,
      params
    );
  }

  static getBillingPeriodById(billingPeriodId: number) {
    return ApiService.getJson<BillingPeriod>(
      `/plans/billing-periods/${billingPeriodId}`
    );
  }

  static createBillingPeriod(data: CreateBillingPeriodDto) {
    return ApiService.postJson('/plans/billing-periods', data);
  }

  static async validateBillingFile(file: File, data: CreateBillingPeriodDto) {
    if (!file) throw Error('File is required');

    const formData = new FormData();

    formData.append('file', file, file.name);

    return ApiService.postFormData<BillingValidationResponse>(
      `/plans/billing-periods/validate?startDate=${data.periodStartDate}&endDate=${data.periodEndDate}&purpose=${data.purpose}&notes=${data.notes}&recordkeeperName=${data.recordkeeperName}`,
      formData
    );
  }

  static async getBillingFees(
    billingPeriodId: number,
    pageSize: number,
    pageNumber: number,
    planId?: string,
    status?: string
  ): Promise<BillingPeriodDetails> {
    return ApiService.getJson(
      `/plans/billing-periods/${billingPeriodId}/fees`,
      {
        pageNumber,
        pageSize,
        planId,
        status
      }
    );
  }

  static async submitBillingRequest(billingPeriodId: number) {
    return ApiService.postJson(
      `/plans/billing-periods/${billingPeriodId}/submit`,
      {}
    );
  }

  static async updateBillingPeriod(
    billingPeriodId: number,
    dto: UpdateBillingPeriodDto
  ) {
    return ApiService.patchJson(
      `/plans/billing-periods/${billingPeriodId}`,
      dto
    );
  }

  static getBillingFeeByPlan(
    billingPeriodId: number | undefined,
    planId: number
  ) {
    return ApiService.getJson<BillingFeeDto>(
      `/plans/billing-periods/${billingPeriodId}/plan/${planId}`,
      {}
    );
  }

  static getTpaContacts(planId: number) {
    return ApiService.getJson<TpaContact[]>(`/plan/${planId}/tpa-contacts`, {});
  }

  static createTpaContact(planId: number, contact: PostTpaContact) {
    return ApiService.postJson<PostTpaContact, void>(
      `/plan/${planId}/tpa-contacts`,
      contact
    );
  }

  static updateTpaContact(
    planId: number,
    tpaContactId: number,
    contact: PatchTpaContact
  ) {
    return ApiService.patchJson<PatchTpaContact, void>(
      `/plan/${planId}/tpa-contacts/${tpaContactId}`,
      contact
    );
  }

  static deleteTpaContact(planId: number, tpaContactId: number) {
    return ApiService.deleteJson<void>(
      `/plan/${planId}/tpa-contacts/${tpaContactId}`
    );
  }

  static async getSubaPlanDetails(
    planId: string | number,
    date: string
  ): Promise<SubaPlanDetailsResponse> {
    return ApiService.getJson(`/plan/${planId}/suba-plan-details?date=${date}`);
  }

  static async getSubaTransactions(params: {
    planId: string | number;
    accountType?: string;
    endDate?: string;
    pageSize?: number;
    pageNumber?: number;
  }): Promise<SubaDetailsTransactionsResponse['data']> {
    return ApiService.getJson(
      `/plan/${params.planId}/suba-transactions`,
      params
    );
  }

  static async getSubaTransactionsReport(
    planId: string | number,
    accountType?: string,
    bookType?: string
  ): Promise<any> {
    return ApiService.getJson(`/plan/${planId}/suba-transactions-report`, {
      accountType,
      bookType
    });
  }

  static resendEmails(ids: number[]) {
    return ApiService.postJson(`/plan/resend/emails`, { ids });
  }

  static async updateDocument(
    document: Omit<DocumentBodyDto, 'groupDetails'> & {
      moveGroup?: { newGroupId: number; oldGroupId: number };
      effectiveDate?: string;
    }
  ): Promise<DocumentDto> {
    return ApiService.patchJson(`/plan/documents/${document.id}`, document);
  }

  static async publishDraftGroupDocuments(
    planId,
    categoryId,
    group: {
      newGroupId: number;
      effectiveDate: string;
    }
  ): Promise<{ successes: number; errors: number }> {
    return ApiService.patchJson(
      `/plan/${planId}/documents/category/${categoryId}/publish-draft`,
      group
    );
  }

  static async getPlanParticipantsMailerLetters(
    sponsorPlanId: number,
    params: GetPlanParticipantsMailerLettersParams
  ): Promise<PlanParticipantsMailerLetters> {
    return ApiService.getJson(`/plan/${sponsorPlanId}/mails/participants`, {
      ...params
    });
  }
  static async postYetProcessEvent(
    planId: number,
    year: number,
    eventName: string,
    eventData: Record<string, any>
  ): Promise<Plan[]> {
    return ApiService.postJson(`/plans/${planId}/yet/${year}/event`, {
      eventData,
      eventName
    });
  }

  static async updateDigitalOnboardingStatePlanDesign(
    planId: number
  ): Promise<{ planDesign: string }> {
    return ApiService.patchJson(
      `/plans/${planId}/digital-onboarding-state/plan-design`,
      {
        planId
      }
    );
  }

  static async updateDigitalOnboardingStatePlanLaunchCall(
    planId: number
  ): Promise<{ planLaunch: string }> {
    return ApiService.patchJson(
      `/plans/${planId}/digital-onboarding-state/plan-launch-call`,
      {
        planId
      }
    );
  }
}

export type {
  CreateAdopterPlanDto,
  CreatePlanDto,
  CreatePooledPlanDto,
  EditPlanDto,
  Plan,
  PlanCreatedDto,
  PlanEditedDto
};
export {
  CreateAdopterPlanSchema,
  CreatePlanSchema,
  CreatePooledPlanSchema,
  EditPlanSchema,
  PlanService
};
