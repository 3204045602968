import SearchIcon from '@mui/icons-material/Search';
import {
  Button,
  FormControl,
  FormControlLabel,
  OutlinedInput,
  Stack,
  Switch
} from '@mui/material';

import { Field, Form, useFormikContext } from 'formik';
import { FC } from 'react';

export type SubAccountsSearchFormValues = {
  hasDebitSubAccounts: boolean;
  query: string;
};

export const SubAccountsSearchForm: FC = () => {
  const formikContext = useFormikContext<SubAccountsSearchFormValues>();

  return (
    <Form onSubmit={formikContext.handleSubmit}>
      <Stack alignItems='center' direction='row' spacing={2} useFlexGap>
        <FormControl fullWidth variant='outlined'>
          <Field
            as={OutlinedInput}
            autoComplete='off'
            endAdornment={<SearchIcon color='secondary' />}
            inputProps={{
              'aria-labelledby': 'search-accounts-heading',
              'data-testid': 'query-input'
            }}
            name='query'
            placeholder='Account ID, VW Core Account ID, participant name, or Participant ID'
            size='small'
          />
        </FormControl>
        <Button
          data-testid='all-sub-accounts-apply-button'
          type='submit'
          variant='outlined'>
          Apply
        </Button>
        <FormControlLabel
          control={
            <Switch
              checked={formikContext.values.hasDebitSubAccounts}
              onChange={async (_event, newValue) => {
                await formikContext.setFieldValue(
                  'hasDebitSubAccounts',
                  newValue
                );
                formikContext.handleSubmit();
              }}
            />
          }
          data-testid='filter-debit-sub-accounts-switch'
          label='View accounts in debit only'
        />
      </Stack>
    </Form>
  );
};
