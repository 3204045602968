import ReconExceptionService from '@/services/suba/recon-exceptions/ReconException.service';
import formatters from '@/utils/Formatters';
import { BreakageDataType } from '@vestwell-sub-accounting/models/recon/BreakageDataType';
import { BreakageProcess } from '@vestwell-sub-accounting/models/recon/BreakageProcess';
import { BreakageStatus } from '@vestwell-sub-accounting/models/recon/BreakageStatus';

export const exportReconExceptions = async (
  searchParams: Parameters<typeof ReconExceptionService.search>[0]
) => {
  return (
    await ReconExceptionService.search({
      ...searchParams,
      page: 1,
      pageSize: 0 // all results
    })
  ).results.map(reconException => {
    return {
      ID: reconException.id,
      Status: formatters.getValueKey(BreakageStatus, reconException.status),
      // eslint-disable-next-line sort-keys-plus/sort-keys
      'Parent Account': reconException.parentAccount.plan?.name,
      'Parent Account ID': reconException.parentAccountId,
      // eslint-disable-next-line sort-keys-plus/sort-keys
      'Break Type': formatters.displayCase(
        formatters.getValueKey(BreakageDataType, reconException.dataType)
      ),
      // eslint-disable-next-line sort-keys-plus/sort-keys
      'Break Process': formatters.displayCase(
        formatters.getValueKey(BreakageProcess, reconException.process)
      ),
      Ticker: reconException.security?.symbol,
      // eslint-disable-next-line sort-keys-plus/sort-keys
      CUSIP: reconException.security?.cusip,
      'Fund Name': reconException.security?.description,
      // eslint-disable-next-line sort-keys-plus/sort-keys
      'Exception Date': formatters.formatFromIsoDateCustom(
        reconException.exceptionDate,
        'MM/DD/YYYY'
      ),
      Notes: reconException.notes,
      'Parent Value': reconException.parentValue,
      // eslint-disable-next-line sort-keys-plus/sort-keys
      'Comparison Value': reconException.comparisonValue,
      'Value Difference': reconException.valueDifference,
      // eslint-disable-next-line sort-keys-plus/sort-keys
      'Parent Units': reconException.parentUnits,
      // eslint-disable-next-line sort-keys-plus/sort-keys
      'Comparison Units': reconException.comparisonUnits,
      'Units Difference': reconException.unitsDifference,
      // eslint-disable-next-line sort-keys-plus/sort-keys
      Assignee: reconException.assignee
    };
  });
};
