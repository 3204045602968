import { CardPlaceholder } from '@/components/card';
import { JSONViewer } from '@/components/json-viewer';
import { PendingRequestDto } from '@/models/suba/pending-requests/PendingRequestDTO.model';
import { Box, Stack } from '@mui/material';

import { FC } from 'react';

export const CustodianRequestsDetailCellRenderer: FC = ({
  data: rowData
}: {
  data: PendingRequestDto;
}) => (
  <Box
    sx={{
      background: theme => theme.palette.grey['50'],
      borderTop: '1px solid rgba(0, 0, 0, 0.12);',
      fontSize: 12,
      height: '275px',
      overflow: 'auto',
      px: 2,
      py: 1
    }}>
    {rowData.payload && (
      <Box>
        <JSONViewer collapseStringsAfterLength={100} json={rowData.payload} />
      </Box>
    )}
    {!rowData.payload && (
      <Stack
        alignItems='center'
        justifyContent='center'
        sx={{ height: '100%' }}>
        <CardPlaceholder subtitle='No payload.' />
      </Stack>
    )}
  </Box>
);
