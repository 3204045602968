import { useUrlState } from '@/hooks/useUrlState.hook';
import { AlertSearchRequest } from '@/models/suba/alerts/AlertSearchRequest.model';
import {
  Alerts,
  AlertsProps
} from '@/routes/suba/common/components/alerts/Alerts.component';
import { AlertsFiltersFormValues } from '@/routes/suba/common/components/alerts/components/AlertsFiltersForm.component';
import { MergeExclusive } from '@/utils/types/MergeExclusive';
import { AlertPriority } from '@vestwell-sub-accounting/models/common/AlertPriority';
import { AlertStatus } from '@vestwell-sub-accounting/models/common/AlertStatus';
import { AlertSubType } from '@vestwell-sub-accounting/models/common/AlertSubType';
import { AlertType } from '@vestwell-sub-accounting/models/common/AlertType';

import { FC, useCallback } from 'react';

export type AlertsTabUrlState = Pick<
  AlertSearchRequest,
  | 'alertType'
  | 'orderBy'
  | 'orderByDirection'
  | 'page'
  | 'pageSize'
  | keyof AlertsFiltersFormValues
>;

type CommonProps = Pick<
  AlertsProps,
  'onAlertOpen' | 'onAlertTypeChange' | 'onDrawerClose'
>;

type AlertsByAccountIdProps = {
  parentAccountId: string;
};

type AlertsByPlanIdProps = {
  planId: string;
};

type AlertsTabProps = CommonProps &
  MergeExclusive<AlertsByAccountIdProps, AlertsByPlanIdProps>;

export const AlertsTab: FC<AlertsTabProps> = props => {
  // state

  const [urlState, setUrlState] = useUrlState<AlertsTabUrlState>(
    {
      alertStatus: Object.values(AlertStatus).filter(
        status => status !== AlertStatus.Closed
      ),
      page: 1,
      pageSize: 25
    },
    {
      arrayParamKeys: ['alertStatus'],
      parsedValueTypes: {
        alertStatus: AlertStatus,
        alertSubType: AlertSubType,
        alertType: AlertType,
        assignee: 'string',
        orderBy: 'string',
        orderByDirection: 'string',
        page: 'number',
        pageSize: 'number',
        priority: AlertPriority,
        query: 'string'
      }
    }
  );

  // callbacks

  const handleAlertTypeChange = useCallback<AlertsProps['onAlertTypeChange']>(
    newAlertType => {
      setUrlState(prevUrlState => ({
        ...prevUrlState,
        alertType: newAlertType,
        page: 1
      }));
    },
    []
  );

  const handleAssigneeChange = useCallback<AlertsProps['onAssigneeChange']>(
    newAssignee => {
      setUrlState(prevUrlState => ({
        ...prevUrlState,
        assignee: newAssignee,
        page: 1
      }));
    },
    []
  );

  const handleFiltersFormSubmit = useCallback<
    AlertsProps['onFiltersFormSubmit']
  >(values => {
    setUrlState(prevUrlState => ({
      ...prevUrlState,
      ...values,
      alertSubType: values.alertSubType || undefined,
      page: 1,
      priority: values.priority || undefined,
      query: values.query || undefined
    }));
  }, []);

  const handlePageChange = useCallback<AlertsProps['onPageChange']>(newPage => {
    setUrlState(prevUrlState => ({
      ...prevUrlState,
      page: newPage
    }));
  }, []);

  const handlePageSizeChange = useCallback<AlertsProps['onPageChange']>(
    newPageSize => {
      setUrlState(prevUrlState => ({
        ...prevUrlState,
        page: 1,
        pageSize: newPageSize
      }));
    },
    []
  );

  const handleSortChange = useCallback<AlertsProps['onSortChange']>(newSort => {
    setUrlState(prevUrlState => ({
      ...prevUrlState,
      ...newSort,
      page: 1
    }));
  }, []);

  return (
    <Alerts
      onAlertOpen={props.onAlertOpen}
      onAlertTypeChange={handleAlertTypeChange}
      onAssigneeChange={handleAssigneeChange}
      onDrawerClose={props.onDrawerClose}
      onFiltersFormSubmit={handleFiltersFormSubmit}
      onPageChange={handlePageChange}
      onPageSizeChange={handlePageSizeChange}
      onSortChange={handleSortChange}
      searchParams={{
        ...urlState,
        parentAccountId: props.parentAccountId,
        planId: props.planId
      }}
    />
  );
};
